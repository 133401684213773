<script>
  import { onMount } from "svelte";
  import _ from "underscore/underscore";
  import autosize from "autosize";

  export let title;
  export let value;
  export let isValid = true;
  export let serverValue;
  export let readOnly;
  export let required;

  let id = _.uniqueId("input");
  let textarea;

  onMount(() => {
    autosize(textarea);
  });
</script>

<div class="row string form-group">
  <label class="string col-sm-6 col-form-label" for={id}>
    {title}
  </label>
  <div class="col-sm-12">
    <textarea
      bind:this={textarea}
      class="string form-control fixed-height"
      class:form-control-dirty={serverValue !== value && isValid}
      {required}
      class:is-invalid={!isValid}
      {id}
      name={id}
      bind:value
      {readOnly}
    />
  </div>
</div>

<style>
  .fixed-height {
    max-height: 200px;
  }
</style>
