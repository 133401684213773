<script>
  import { notyf_top_right } from "misc/notyf_top_right";
  import _ from "lodash";
  import { onMount } from "svelte";
  import { reportStore, syncInfoStore } from "components/report/stores/reportStore";
  import { isDirty, dirtyParams } from "components/report/stores/DirtyReportParams";
  import { specificationTree } from "components/report/stores/specificationTree";

  import ReportsRepository from "repositories/ReportsRepository.js";
  import ReportsOptionsRepository from "repositories/ReportsOptionsRepository.js";
  import ReportTemplatesRepository from "repositories/ReportTemplatesRepository.js";
  import ReportOptions from "components/reports/ReportOptions.svelte";

  import LoadingReport from "components/reports/LoadingReport.svelte";
  import GeneratingReport from "components/reports/GeneratingReport.svelte";
  import GeneratedReport from "components/reports/GeneratedReport.svelte";
  import FailedReport from "components/reports/FailedReport.svelte";

  import ZoomControls from "components/reports/utils/ZoomControls.svelte";

  import { createConsumer } from "@rails/actioncable";

  export let reportEndpoint;
  export let reportOptionsEndpoint;
  export let reportTemplatesEndpoint;
  export let pdfUrlBase;
  export let areaTrackingUrlBase;
  export let currentProjectionHash;
  export let unpermittedReportAtrributes;
  export let currentSelectedTaxYear;

  const MaterializedReportLoadingStatuses = Object.freeze({
    initialLoading: 1,
    done: 2,
    failed: 3
  });
  const MaterializedReportGeneratingStatuses = Object.freeze({
    generating: "generating",
    generated: "generated",
    failed: "failed"
  });
  const ReportStatuses = Object.freeze({
    loading: "loading",
    done: "done"
  });

  const checkIntervalMs = 10000;
  const reportsRepository = new ReportsRepository(reportEndpoint);
  const reportsOptionsRepository = new ReportsOptionsRepository(reportOptionsEndpoint);
  const reportTemplatesRepository = new ReportTemplatesRepository(reportTemplatesEndpoint);

  let reports = [];
  let templates = [];
  let materializedReportStatus = MaterializedReportLoadingStatuses.initialLoading;
  let reportStatus = ReportStatuses.loading;
  let isReportLoaded = false;
  let materializedReport = {};
  let memoizedReport = {};
  let reportReloadingTimeout = undefined;

  $: projectionsMatch = $syncInfoStore.reportProjectionHash === currentProjectionHash;
  $: taxYearMismatch = $syncInfoStore.taxYear !== currentSelectedTaxYear;
  $: dirtyParams.set(
    new Set(
      Object.entries($reportStore).flatMap(([key, value]) => {
        return memoizedReport[key] !== value ? key : [];
      })
    )
  );
  $: pdfUrl = pdfUrlBase.replace("@", $reportStore.id);
  $: areaTrackingUrl = areaTrackingUrlBase.replace("@", $reportStore.id);
  $: showTemplateButtons = templates.length > 0;
  $: isGenerated =
    reports.length > 0 &&
    materializedReport &&
    materializedReport.status == MaterializedReportGeneratingStatuses.generated;

  let showOptionsPane = localStorage.showOptionsPane === "true";

  async function loadReports() {
    reportStatus = ReportStatuses.loading;

    try {
      reports = await reportsOptionsRepository.all();
      const report = reports[0];
      const storedReport = findLocalStorageReport(report);
      applyReport(storedReport || report);
    } catch (error) {
      if (error.name === "AbortError") return;
      materializedReportStatus = MaterializedReportLoadingStatuses.failed;
      notyf_top_right.error(error.message);
    } finally {
      reportStatus = ReportStatuses.done;
    }
  }

  async function loadMaterializedReport() {
    materializedReportStatus = MaterializedReportLoadingStatuses.initialLoading;

    try {
      materializedReport = await reportsRepository.one($reportStore.id);

      if (materializedReport.status === MaterializedReportGeneratingStatuses.generated)
        clearTimeout(reportReloadingTimeout);

      materializedReportStatus = MaterializedReportLoadingStatuses.done;

      if (materializedReport.status === MaterializedReportGeneratingStatuses.generating) {
        reportReloadingTimeout = setTimeout(loadMaterializedReport, checkIntervalMs);
      }
    } catch (error) {
      if (error.name === "AbortError") return;
      notyf_top_right.error(error.message);
      materializedReportStatus = MaterializedReportLoadingStatuses.failed;
    }
  }

  function findLocalStorageReport(report) {
    const storedUsersReport = JSON.parse(localStorage.getItem("storedUsersReport"));

    if (storedUsersReport && storedUsersReport.v1[report.user_id]) {
      return _.find(reports, { id: storedUsersReport.v1[report.user_id].report_id });
    } else {
      return null;
    }
  }

  function applyReport(report) {
    const storedUsersReport = { v1: { [report.user_id]: { report_id: report.id } } };
    localStorage.setItem("storedUsersReport", JSON.stringify(storedUsersReport));
    reportStore.set(report);
    memoizeReport(report);
    reportStatus = ReportStatuses.done;
  }

  function memoizeReport(report) {
    memoizedReport = _.clone(report);
  }

  function enableReportOptionsShortcut() {
    // Tooltip for 'Ctrl + /' title
    window.$('[data-toggle="delayed-tooltip"]').tooltip({
      delay: { show: 1000, hide: 100 }
    });

    // Tooltip for 'There are unsaved changes...' mark
    window.$('[data-toggle="tooltip"]').tooltip();

    // Options pane by Ctrl + /
    document.addEventListener("keydown", (e) => {
      if (e.ctrlKey && e.code === "Slash") {
        toggleReportOptionsPane();
        e.preventDefault();
      }
    });
  }

  async function refreshReportParams(params, refreshType) {
    const message =
      "You're about to update your report with a different, " +
      (currentSelectedTaxYear || "") +
      " tax year projection data. Proceed?";
    if (taxYearMismatch && !confirm(message)) return false;

    try {
      const response = await reportsOptionsRepository.get_updated_params({ params_to_refresh: params });
      const new_report_values = response.updated_params;
      reportStore.update((prevReport) => ({ ...prevReport, ...new_report_values }));
      updateReport(refreshType);
    } catch (error) {
      if (error.name === "AbortError") return;
      notyf_top_right.error(error.message);
    }
  }

  function updateReport(refreshType) {
    reportStatus = ReportStatuses.loading;
    materializedReportStatus = MaterializedReportLoadingStatuses.initialLoading;
    handleReportResponse(() => reportsOptionsRepository.update($reportStore.id, $reportStore, refreshType));
  }

  function createReport() {
    reportStatus = ReportStatuses.loading;
    materializedReportStatus = MaterializedReportLoadingStatuses.initialLoading;
    handleReportResponse(() => reportsOptionsRepository.create());
  }

  async function deleteReport() {
    if (!confirm("This will delete current report. Are you sure you want to proceed?")) return false;

    try {
      await reportsOptionsRepository.delete($reportStore.id);
      reports = reports.filter((report) => report.id !== $reportStore.id);

      await loadReports();
      await loadMaterializedReport();
      notyf_top_right.success("Report has been deleted!");
    } catch (error) {
      if (error.name === "AbortError") return;
      notyf_top_right.error(error.message);
    }
  }

  async function updateReportName(name) {
    reportStatus = ReportStatuses.loading;

    const response = await reportsOptionsRepository.update($reportStore.id, { name });
    const report_id = response.report_id;

    reports = await reportsOptionsRepository.all();
    const report = _.find(reports, { id: report_id });

    applyReport(report);
    notyf_top_right.success("Report name has been updated");
  }

  async function handleReportResponse(reportResponse) {
    try {
      const response = await reportResponse();
      const report_id = response.report_id;

      reports = await reportsOptionsRepository.all();
      const report = _.find(reports, { id: report_id });

      applyReport(report);

      await loadMaterializedReport();
      notyf_top_right.success(response.notice);
    } catch (error) {
      if (error.name === "AbortError") return;
      materializedReportStatus = MaterializedReportLoadingStatuses.failed;
      notyf_top_right.error(error.message);
    } finally {
      reportStatus = ReportStatuses.done;
    }
  }

  async function loadReportTemplates() {
    try {
      templates = await reportTemplatesRepository.all();
    } catch (error) {
      if (error.name === "AbortError") return;
      notyf_top_right.error(error.message);
    }
  }

  async function createTemplate(name) {
    try {
      const temp = await reportTemplatesRepository.create({ report_id: $reportStore.id, name });
      notyf_top_right.success("Your Template has been successfully saved!");
      templates = await reportTemplatesRepository.all();
    } catch (error) {
      if (error.name === "AbortError") return;
      notyf_top_right.error(error.message);
    }
  }

  async function updateTemplate(id, options) {
    try {
      const temp = await reportTemplatesRepository.update(id, options);
      notyf_top_right.success("Your template has been successfully updated!");
      templates = await reportTemplatesRepository.all();
    } catch (error) {
      if (error.name === "AbortError") return;
      notyf_top_right.error(error.message);
    }
  }

  async function deleteTemplate(id) {
    if (!confirm("This will delete template. Are you sure you want to proceed?")) return false;

    try {
      await reportTemplatesRepository.delete(id);
      templates = await reportTemplatesRepository.all();
      notyf_top_right.success("Your Template has been deleted!");
      return { name: "success", templates };
    } catch (error) {
      if (error.name === "AbortError") return;
      notyf_top_right.error(error.message);
      return error;
    }
  }

  function toggleReportOptionsPane() {
    showOptionsPane = !showOptionsPane;
    localStorage.showOptionsPane = showOptionsPane;
  }

  function ensureChangesAreSavedBeforeUnload() {
    window.onbeforeunload = () => {
      if ($isDirty) return true;
    };
  }

  async function undoCurrentChanges() {
    if (
      !confirm(
        "This will undo current changes and restore report options to the saved ones. Are you sure you want to proceed?"
      )
    )
      return false;

    reportStore.set(_.cloneDeep(memoizedReport));
  }

  onMount(async () => {
    await loadReports();
    await loadMaterializedReport();
    await loadReportTemplates();

    let consumer = createConsumer();
    consumer.subscriptions.create("ReportsChannel", {
      received(data) {
        if (data === "report_generated") loadMaterializedReport();
      }
    });

    enableReportOptionsShortcut();
    ensureChangesAreSavedBeforeUnload();
  });
</script>

<template lang="pug">
  .tpp-report
    .tpp-report-header.pageheader
      .tpp-report-header__buttons.float-right
        +if('isGenerated')
          a.btn.btn-sm.btn-info.mb-0(href="{pdfUrl}") Download PDF
        +if('reports.length > 0')
          .btn.btn-sm.btn-info.mb-0(
            on:click="{toggleReportOptionsPane}",
            data-toggle="delayed-tooltip",
            data-placement="bottom",
            title="Ctrl + /"
          ) { showOptionsPane ? "Hide" : "Show" } Report Options
      h1.tpp-report-header__title
        .tpp-report-header__label Report
        +if('isGenerated') 
          ZoomControls
        +if('materializedReportStatus === MaterializedReportLoadingStatuses.initialLoading || reportStatus !== ReportStatuses.done')
          .tpp-report-header__loading-indicator
            .tpp-loading-indicator
              .tpp-loading-indicator__bounce1
              .tpp-loading-indicator__bounce2
              .tpp-loading-indicator__bounce3

    .tpp-report-layout
      section.tpp-report-layout__report_pane
        +if('materializedReportStatus === MaterializedReportLoadingStatuses.initialLoading')
          LoadingReport
          +elseif('materializedReportStatus === MaterializedReportLoadingStatuses.done')
            +if('materializedReport.status === MaterializedReportGeneratingStatuses.generating')
              GeneratingReport
              +elseif('materializedReport.status === MaterializedReportGeneratingStatuses.generated')
                GeneratedReport(pdfPath="{pdfUrl}" areaTrackingPath="{areaTrackingUrl}")
              +else
                FailedReport(regenerateReport="{function() { updateReport(); }}")
          +else
            FailedReport
      +if('reports.length > 0')
        ReportOptions(
          {updateReport},
          {undoCurrentChanges},
          {showOptionsPane},
          {reports},
          {applyReport},
          {loadMaterializedReport},
          {createReport},
          {updateReportName},
          {memoizedReport},
          {deleteReport},
          {createTemplate},
          {updateTemplate},
          {templates},
          {showTemplateButtons},
          {deleteTemplate},
          {refreshReportParams},
          {reportStatus},
          {projectionsMatch},
          {taxYearMismatch},
          {currentSelectedTaxYear},
          {unpermittedReportAtrributes}
        )
</template>

<style lang="sass">
  $top-bar-and-report-bar-height: 116px // TODO: Kostyl & Fragile

  .tpp-report-layout
    display: flex

    &__report_pane
      width: 100%
      padding: 0 15px 0 15px
      height: calc(100vh - #{$top-bar-and-report-bar-height}) // TODO: Kostyl & Fragile
      overflow-y: auto

  .tpp-report-header
    &__title
      display: flex
    &__loading-indicator
      margin-left: 12px
    &__buttons
      & a
        margin:
          right: 5px

  .tpp-loading-indicator
    &__bounce1, &__bounce2, &__bounce3
      width: 10px
      height: 10px
      margin-right: 2px
      background-color: #1d212a

      border-radius: 100%
      display: inline-block
      animation: sk-bouncedelay 1s infinite ease-in-out both

    &__bounce1
      animation-delay: -0.32s

    &__bounce2
      animation-delay: -0.16s
</style>
