<script>
  import { afterUpdate } from "svelte";
  import Quill from "quill";
  import jQuery from "jquery";
  import { notyf_top_right } from "misc/notyf_top_right";

  export let content;
  export let csrf;
  export let updateLoginPagePath;

  let editor;
  let quill;
  let isEditing = false;

  function handleUpdate() {
    let updated_content = editor.firstChild.innerHTML;
    if (content === updated_content || updated_content === "") {
      isEditing = false;
    } else {
      jQuery
        .ajax({
          type: "PATCH",
          beforeSend: function (xhr) {
            xhr.setRequestHeader("X-CSRF-Token", csrf);
          },
          url: updateLoginPagePath,
          data: { login_page: { content: updated_content } }
        })
        .done(function (data) {
          content = data.content;
          isEditing = false;
          notyf_top_right.success("Successfully updated Login Page info.");
        })
        .fail(function () {
          notyf_top_right.error("Failed to update Login Page info. Please try again.");
        });
    }
  }

  afterUpdate(() => {
    if (isEditing) {
      quill = new Quill(editor, {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            ["link"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["clean"]
          ]
        },
        placeholder: "Type something...",
        theme: "snow"
      });
      const delta = quill.clipboard.convert(content);
      quill.setContents(delta, "silent");
    }
  });
</script>

{#if isEditing}
  <form on:submit|preventDefault={handleUpdate}>
    <div bind:this={editor} />
    <div class="buttons">
      <button class="btn btn-success">Save</button>
      <button class="btn btn-secondary" on:click={() => (isEditing = false)}>Cancel</button>
    </div>
  </form>
{:else}
  <div>
    <div class="content-container">
      {#if content}
        <div class="content">{@html content}</div>
      {:else}
        <div class="content-missing">There is no login page info yet.</div>
      {/if}
    </div>
    <div class="buttons">
      <button class="btn btn-primary" on:click={() => (isEditing = true)}>Edit</button>
    </div>
  </div>
{/if}

<style lang="scss">
  .buttons {
    margin-top: 15px;
  }

  .content-container {
    margin-top: 15px;
    border: 1px solid;
  }

  .content,
  .content-missing {
    padding: 15px;
  }

  .content-missing {
    color: grey;
  }
</style>
