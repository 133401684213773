export default class BaseSyncRepository {
  constructor(baseEndpoint) {
    this.baseEndpoint = baseEndpoint;
    this.controller = new AbortController();
    this.headers = Object.freeze({
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": this.csrfToken()
    });
  }

  async baseQuery(endpoint, queryParams = {}) {
    this.controller.abort();
    this.controller = new AbortController();

    let result = undefined;
    let data = undefined;

    try {
      const response = await fetch(
        endpoint,
        Object.assign(queryParams, {
          signal: this.controller.signal
        })
      );
      result = response.ok;
      const rawData = await response.text();
      data = JSON.parse(rawData);
    } catch (error) {
      if (error.name === "AbortError") throw error;
      console.log(error);
      throw new Error(BaseSyncRepository.ERROR_MSG);
    }

    if (data.error) throw new Error(data.error);
    if (!result) throw new Error(BaseSyncRepository.ERROR_MSG);

    return data;
  }

  csrfToken() {
    return window.$('meta[name="csrf-token"]').attr("content");
  }
}

BaseSyncRepository.ERROR_MSG =
  "Something went wrong. If the error persists, please do not hesitate to reach out for support using the chat button at the bottom right corner of the page.";
