<script>
  export let key;
  export let store;
  export let validation;
  export let visitors;
  export let visitField;
  export let title;
  export let required = false;
  export let options = {};
  export let disabled = false;

  const id = Math.random().toString(16).substr(2, 8);

  $: hasError = $visitors.has(key) && $validation.has(key);
</script>

<div class="row form-group select {required ? 'required' : 'optional'}">
  <label
    class="col-sm-4 col-form-label select {required ? 'required' : 'optional'}"
    class:text-danger={hasError}
    for={id}
  >
    {title}
  </label>
  <div class="col-sm-8">
    <select
      {id}
      value={$store[key]}
      on:change={(e) => ($store[key] = e.target.value)}
      on:blur={() => visitField(key)}
      class="form-control select {required ? 'required' : 'optional'}"
      {disabled}
      class:is-invalid={hasError}
    >
      {#each Array.isArray(options) ? options : Object.entries(options) as [name, value]}
        <option {value}>
          {name}
        </option>
      {/each}
    </select>
    {#if hasError}
      <small class="text-danger">{$validation.get(key)}</small>
    {/if}
  </div>
</div>
