<script>
  import { visitField, personalProjectionVisitors } from "../Store";
  import { getContext } from "svelte";
  import { fieldsToLabels } from "../FieldsToLabels";

  export let key;
  export let visitKey = key;
  export let value;
  export let hasError;
  export let caption;
  export let options;

  const { type } = getContext("ppType");

  let fieldHtmlId;
  let hasSuccess;

  $: hasSuccess = $personalProjectionVisitors.has(visitKey) && !hasError && type !== "simulator";
  $: fieldHtmlId = `personal_projection_parameters[${key}]`;
</script>

<div class="personal-projection-form-content__column">
  <div class="form-group tpp-form-group">
    <label for={fieldHtmlId} class="col-form-label tpp-form-label">
      <div>
        <span>{fieldsToLabels[key]}</span>
      </div>
    </label>
    <select
      id={fieldHtmlId}
      bind:value
      on:blur={() => visitField(visitKey)}
      class="personal-projection-form-validation personal-projection-form--viewed form-control"
      class:is-invalid={hasError}
      class:is-valid={hasSuccess}
    >
      <option disabled="" value="">-- {caption} --</option>
      {#each options as o}
        <option value={o.value}>{o.title}</option>
      {/each}
    </select>
    <span class="form-text" />
  </div>
</div>
