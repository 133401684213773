<script>
  import accounting from "accounting";

  export let bookkeeperPrice;
  export let basicPrice;
  export let proPrice;
  export let purchasePathBase;
  export let trialPath;
  export let trialUsed;
</script>

<section id="main-content">
  <div class="plan-container">
    <div class="row justify-content-center mb-4">
      <div class="col-md-12 col-lg-4 mb-3">
        <div class="card text-center plan plan-bookkeeper">
          <div class="card-body">
            <h5 class="card-title plan__title">Bookkeeper</h5>
            <h6 class="card-subtitle mb-2 text-muted plan__subtitle">1 year</h6>
            <ul class="list-unstyled plan__features">
              <li class="plan-features__entry">Up to 25 Clients</li>
              <li class="plan-features__entry">Access for 1 year</li>
              <li class="plan-features__entry">Business Projections</li>
              <li class="plan-features__entry">Personal Projections</li>
              <li class="plan-features__entry">Tax Plan Simulator</li>
              <li class="plan-features__entry">Basic Reports</li>
              <li class="plan-features__entry">Chat Support</li>
            </ul>
            <p class="card-text plan__fee">
              One time fee of {accounting.formatMoney(bookkeeperPrice, { precision: 0 })}
            </p>
            {#if trialUsed}
              <a
                data-method="post"
                href={purchasePathBase.replace(":plan:", "bookkeeper")}
                class="btn btn-primary plan-subscribe-button">Get Bookkeeper Plan</a
              >
            {:else}
              <p class="plan__trial--details">
                You'll be charged after the trial period ends<br />
                <span>You can cancel trial any time in your profile during the trial period</span>
              </p>
              <a href={trialPath} data-method="post" class="btn btn-success plan-trial-button"
                >Try for 3 days</a
              >
            {/if}
          </div>
        </div>
      </div>

      <div class="col-md-12 col-lg-4 mb-3">
        <div class="card text-center plan plan-basic">
          <div class="card-body">
            <h5 class="card-title plan__title">Tax CPA</h5>
            <h6 class="card-subtitle mb-2 text-muted plan__subtitle">1 year</h6>
            <ul class="list-unstyled plan__features">
              <li class="plan-features__entry">Unlimited Clients</li>
              <li class="plan-features__entry">Access for 1 year</li>
              <li class="plan-features__entry">Business Projections</li>
              <li class="plan-features__entry">Personal Projections</li>
              <li class="plan-features__entry">Tax Plan Simulator</li>
              <li class="plan-features__entry">Tax Strategy Module</li>
              <li class="plan-features__entry">Advanced Reports</li>
              <li class="plan-features__entry">Priority Chat Support</li>
            </ul>
            <p class="card-text plan__fee">
              One time fee of {accounting.formatMoney(basicPrice, { precision: 0 })}
            </p>
            <a
              data-method="post"
              href={purchasePathBase.replace(":plan:", "basic")}
              class="btn btn-primary plan-subscribe-button">Get Tax CPA Plan</a
            >
          </div>
        </div>
      </div>

      <div class="col-md-12 col-lg-4 mb-3">
        <div class="card text-center plan plan-pro">
          <div class="card-body">
            <h5 class="card-title plan__title">Lifetime Tax CPA</h5>
            <h6 class="card-subtitle mb-2 text-muted plan__subtitle">Lifetime</h6>
            <ul class="list-unstyled plan__features">
              <li class="plan-features__entry">Unlimited Clients</li>
              <li class="plan-features__entry">Lifetime access</li>
              <li class="plan-features__entry">Business Projections</li>
              <li class="plan-features__entry">Personal Projections</li>
              <li class="plan-features__entry">Tax Plan Simulator</li>
              <li class="plan-features__entry">Tax Strategy Module</li>
              <li class="plan-features__entry">Advanced Reports</li>
              <li class="plan-features__entry">Priority Chat Support</li>
            </ul>
            <p class="card-text plan__fee">
              One time fee of {accounting.formatMoney(proPrice, { precision: 0 })}
            </p>
            <a
              data-method="post"
              href={purchasePathBase.replace(":plan:", "pro")}
              class="btn btn-primary plan-subscribe-button">Get Lifetime Tax CPA Plan</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style lang="scss">
  .plan-container {
    display: flex;
    justify-content: center;
    padding-top: 30px;
  }

  .plan {
    padding: 20px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #e3e6eb;
    border-radius: 8px;
    height: 100%;

    @media (min-width: 992px) {
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.05);
      }
    }

    &__title {
      font-size: 22px;
      font-weight: 600;
      color: #000;
    }

    &__subtitle {
      font-family: "Helvetica", sans-serif;
      font-size: 14px;
    }

    &__features {
      flex-grow: 1;
      margin-bottom: 20px;
      align-self: center;
    }

    &__fee {
      font-family: "Montserrat", sans-serif;
      font-size: 22px;
      font-weight: 600;
      color: #000;
      margin: 16px 0 10px;
    }

    &__trial {
      margin-bottom: 10px;

      &--details {
        font-size: 0.9em;
        color: #666;
      }
    }
  }

  .plan-subscribe-button {
    margin-top: auto;
    border-radius: 5px;
    border: none;
    background-color: #532774;
    color: #fff;
    text-align: center;
    padding: 10px 20px;
    font-size: 17px;
    font-family: "Helvetica", sans-serif;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 5px 0;

    &:hover {
      background-color: darken(#532774, 10%);
    }
  }

  .plan-trial-button {
    margin-top: auto;
    border-radius: 5px;
    border: none;
    color: #fff;
    text-align: center;
    padding: 10px 20px;
    font-size: 17px;
    font-family: "Helvetica", sans-serif;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 5px 0;
  }

  .plan-features {
    list-style: none;
    padding: 0;

    &__entry {
      font-size: 15px;
      margin: 16px 0;
      text-align: left;

      &::before {
        content: "✓";
        margin-right: 10px;
        color: #417505;
      }
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
  }
</style>
