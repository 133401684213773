<script>
  import { isNil } from "lodash";
  import Handy from "./Handy.svelte";
  import Intuit from "./Intuit.svelte";

  export let statusNotice;
  export let sourceType;
  export let personalProjectionPath;
</script>

<div class="card-body">
  <div class="business-projection-form-content__status">
    {#if !isNil(statusNotice)}
      <div class="font-weight-light text-right text-muted">
        {statusNotice}
      </div>
    {:else}
      &nbsp;
    {/if}
  </div>
  <div class="table-responsive col-lg-12">
    {#if sourceType == "handy"}
      <Handy {personalProjectionPath} />
    {:else}
      <Intuit sourceTitle={sourceType.toUpperCase()} {personalProjectionPath} />
    {/if}
  </div>
</div>
