<script>
  import AdminUserListPagination from "./AdminUserListPagination.svelte";
  import ModalContent from "./ModalContent.svelte";
  import Modal from "svelte-simple-modal";

  export let users;
  export let updatePasswordPath;
  export let updateEmailPath;
  export let ghostInPath;
  export let turnProPath;
  export let usersPath;
  export let newUserPath;
  export let activateUserPath;
  export let deactivateUserPath;

  let changingPasswordForId = null;
  let newPassword = "";
  let busy = false;
  let currentPage = 1;
  let searchTerm = "";
  let perPage = 10;
  let newUsers = [];
  let notices = [];

  $: lowerCaseSearchTerm = searchTerm.toLowerCase();
  $: filteredUsers = users.filter(function (user) {
    return user.email.toLowerCase().includes(lowerCaseSearchTerm);
  });
  $: totalPages = Math.ceil(filteredUsers.length / perPage);
  $: adjustedCurrentPage = Math.min(currentPage, totalPages);
  $: filteredPaginatedUsers = filteredUsers.slice(
    (adjustedCurrentPage - 1) * perPage,
    adjustedCurrentPage * perPage
  );

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": window.$('meta[name="csrf-token"]').attr("content")
  };

  const request = async (endpoint, params = {}) => {
    try {
      const response = await fetch(endpoint, { headers, ...params });
      return await response.json();
    } catch (error) {
      // throw error;
      throw new Error(
        "Something went wrong. If the error persists, please do not hesitate to reach out for support using the chat button at the bottom right corner of the page."
      );
    }
  };

  const updatePasswordOnServer = async (userId, futurePassword) => {
    busy = true;
    try {
      const data = await request(updatePasswordPath.replace("@", userId), {
        method: "POST",
        body: JSON.stringify({
          new_password: futurePassword
        })
      });
      if (data.success) {
        requestUserList();
        notify(data.success);
      }
      if (data.error) notify(data.error, "alert-danger");
    } catch (error) {
      notify(error.message, "alert-danger");
    }
    busy = false;
  };

  const updateEmailOnServer = async (userId, email) => {
    busy = true;
    try {
      const data = await request(updateEmailPath.replace("@", userId), {
        method: "PATCH",
        body: JSON.stringify({ email })
      });
      if (data.success) {
        requestUserList();
        notify(data.success);
      }
      if (data.error) notify(data.error, "alert-danger");
    } catch (error) {
      notify(error.message, "alert-danger");
    }
    busy = false;
  };

  const makeProOnServer = async (userId) => {
    try {
      const data = await request(turnProPath.replace("@", userId), {
        method: "POST",
        body: JSON.stringify({})
      });
      if (data.success) {
        requestUserList();
        notify(data.success);
      }
      if (data.error) notify(data.error, "alert-danger");
    } catch (error) {
      notify(error.message, "alert-danger");
    }
  };

  function startChangingPassword(id) {
    changingPasswordForId = id;
    newPassword = "";
  }

  function finishChangingPassword() {
    updatePasswordOnServer(changingPasswordForId, newPassword);
    changingPasswordForId = null;
    newPassword = "";
    users = users;
  }

  function cancelChangingPassword() {
    changingPasswordForId = null;
    newPassword = "";
  }

  function ghostIn(userId) {
    window.open(ghostInPath.replace("@", userId));
  }

  const changeUserActivation = async (path, userId) => {
    try {
      const data = await request(path.replace("@", userId), {
        method: "POST",
        body: JSON.stringify({})
      });
      if (data.success) {
        requestUserList();
        notify(data.success);
      }
      if (data.error) notify(data.error, "alert-danger");
    } catch (error) {
      notify(error.message, "alert-danger");
    }
  };

  const activateUser = async (userId) => {
    await changeUserActivation(activateUserPath, userId);
  };
  const deactivateUser = async (userId) => {
    await changeUserActivation(deactivateUserPath, userId);
  };

  function generate() {
    newPassword = Math.random().toString(36).slice(2);
  }

  const createUser = async (userUuid) => {
    const user = newUsers.find((user) => user.uuid === userUuid);
    try {
      const data = await request(usersPath, {
        method: "POST",
        body: JSON.stringify({
          email: user.email,
          password: user.password,
          pro: !!user.pro,
          active: !!user.active
        })
      });
      if (data.success) {
        requestUserList();
        removeNewUser(userUuid);
        notify(data.success);
      }
      if (data.error) notify(data.error, "alert-danger");
    } catch (error) {
      notify(error.message, "alert-danger");
    }
  };

  const requestUserList = async () => {
    const data = await request(usersPath, { method: "GET" });
    users = data;
  };

  const addNewUser = async () => {
    try {
      const data = await request(newUserPath, { method: "GET" });
      if (data.error) {
        notify(data.error, "alert-danger");
      } else {
        newUsers = [...newUsers, { uuid: uuid(), email: null, password: null, pro: null, active: true }];
      }
    } catch (error) {
      notify(error.message, "alert-danger");
    }
  };

  function removeNewUser(userUuid) {
    newUsers = newUsers.filter((user) => user.uuid != userUuid);
  }

  const createUsers = async () => {
    newUsers.forEach((user) => user.email && createUser(user.uuid));
  };

  function removeNewUsers() {
    newUsers = [];
  }

  function notify(message, style = "alert-info") {
    notices = [...notices, { uuid: uuid(), message, style }];
  }

  function removeNotice(uuid) {
    notices = notices.filter((e) => e.uuid !== uuid);
  }

  function uuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      let r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
</script>

{#each notices as notice}
  <div class="alert animated fadeIn {notice.style ? notice.style : 'alert-info'}">
    <button class="close" on:click={() => removeNotice(notice.uuid)}>x</button>
    {@html notice.message}
  </div>
{/each}

<div class="form-group has-feedback">
  <span class="fa fa-search form-control-feedback" style="top: 0px; right: 1px;" />
  <input type="text" class="form-control" placeholder="Search" bind:value={searchTerm} />
</div>
<AdminUserListPagination bind:perPage bind:currentPage {adjustedCurrentPage} {totalPages} />

<table id="user-list" class="table table-striped user-table" class:busy>
  <tbody>
    <tr>
      <th>Email</th>
      <th>New Password</th>
      <th>Subscription</th>
      <th />
      <th />
      <th />
    </tr>

    {#if filteredPaginatedUsers && filteredPaginatedUsers.length > 0}
      {#each filteredPaginatedUsers as user}
        <tr>
          <td>
            <span>{user.email}</span>
            {#each [...[]] as _}
              <div />
            {:else}
              <Modal>
                <ModalContent {user} {updateEmailOnServer} />
              </Modal>
            {/each}
          </td>
          <td>
            {#if user.id === changingPasswordForId}
              <div class="form-group">
                <input class="form-control" bind:value={newPassword} />
                <a href="/#" on:click|preventDefault={generate}>
                  <small>Generate Random</small>
                </a>
              </div>
              <button class="btn btn-success" on:click|preventDefault={finishChangingPassword}>Save</button>
              or
              <a href="/#" on:click|preventDefault={cancelChangingPassword}>Cancel</a>
            {:else}
              <a href="/#" on:click|preventDefault={() => startChangingPassword(user.id)}>Change</a>
            {/if}
          </td>
          <td>{user.subscription}</td>
          <td>
            <button class="btn btn-success btn-sm" on:click={() => ghostIn(user.id)}>
              <i class="fa fa-user">&nbsp;</i>
              Ghost In
            </button>
          </td>
          <td>
            <button class="btn btn-success btn-sm" on:click={() => makeProOnServer(user.id)}>
              {#if user.can_turn_rtp}
                Make Lifetime RTP
              {:else}
                <div>
                  <i class="fa fa-check" aria-hidden="true" />
                  Lifetime RTP
                </div>
                <div class="small">Click to reactivate</div>
              {/if}
            </button>
          </td>
          <td>
            {#if user.active}
              <button class="btn btn-danger btn-sm" on:click={() => deactivateUser(user.id)}>
                <i class="fa fa-ban">&nbsp;Ban</i>
              </button>
            {:else}
              <button class="btn btn-warning btn-sm" on:click={() => activateUser(user.id)}>
                <i class="fa fa-check">&nbsp;Activate</i>
              </button>
            {/if}
          </td>
        </tr>
      {/each}
    {:else}
      <tr>
        <td colspan="5">
          <i>No users</i>
        </td>
      </tr>
    {/if}
  </tbody>
</table>

<AdminUserListPagination bind:perPage bind:currentPage {adjustedCurrentPage} {totalPages} />

{#if newUsers.length > 0}
  <table id="new-user-list" class="table table-striped user-table">
    <thead>
      <th />
      <th />
      <th />
      <th />
      <th />
      <th />
    </thead>
    <tbody>
      {#each newUsers as newUser}
        <tr>
          <td class="col-md-2">
            <input
              bind:value={newUser.email}
              class="form-control string required"
              type="text"
              placeholder="Email"
            />
          </td>
          <td class="col-md-2">
            <input
              bind:value={newUser.password}
              class="form-control string email optional"
              type="password"
              placeholder="Password"
            />
          </td>
          <td class="col-md-2">
            <label for="user_lifetime_rtp">
              <input id="user_lifetime_rtp" bind:checked={newUser.pro} type="checkbox" />
              <span data-toggle="popover-hover" data-content="RTP Lifetime">&nbsp;RTP Lifetime</span>
            </label>
          </td>
          <td class="col-md-2">
            <label for="user_active">
              <input id="user_active" bind:checked={newUser.active} type="checkbox" />
              <span data-toggle="popover-hover" data-content="Active">&nbsp;Active</span>
            </label>
          </td>
          <td class="text-right">
            <button
              class="btn btn-sm btn-success"
              on:click={() => createUser(newUser.uuid)}
              disabled={!newUser.email}
            >
              <i class="fa fa-check-square-o">&nbsp;Accept</i>
            </button>
            &nbsp;
            <button class="btn btn-sm btn-danger" on:click={() => removeNewUser(newUser.uuid)}>
              <i class="fa fa-trash-o" />
            </button>
          </td>
          <td />
        </tr>
      {/each}
    </tbody>
  </table>
{/if}

<div>
  <button class="btn btn-primary add-client-btn" on:click={() => addNewUser()}>
    <i class="fa fa-plus-square-o">&nbsp;Add New User</i>
  </button>
  {#if newUsers.length > 0}
    <button
      class="btn btn-success add-client-btn"
      on:click={() => createUsers()}
      disabled={!newUsers.some((user) => user.email)}
    >
      <i class="fa fa-check-square-o">&nbsp;Accept All</i>
    </button>
    <button class="btn btn-danger add-client-btn" on:click={() => removeNewUsers()}>
      <i class="fa fa-trash-o">&nbsp;Clear New</i>
    </button>
  {/if}
</div>

<style>
  .user-table {
    transition: opacity 0.15s ease-in-out;
    margin-bottom: 0;
  }

  .busy {
    opacity: 0.5;
  }
</style>
