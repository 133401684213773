<script>
  import PersonalTable from "components/common/PersonalTable.svelte";
  import TaxTotals from "components/dashboard/TaxTotals.svelte";

  export let dashboard;
</script>

<template lang="pug">
  .card.flex-align.mb-3
    .card-header(
      class:bg-primary="{dashboard.hasBasicSubscription}"
      class:bg-danger="{!dashboard.hasBasicSubscription}"
    )
      h3.card-title.mb-0 Personal Summary
      +if('dashboard.hasBasicSubscription')
        .actions.float-right
          +if('dashboard.personalProjection')
            a.btn.btn-primary.btn-sm(href="{dashboard.personalProjectionPath}") EDIT
            +else
              a.btn.btn-primary.btn-trans.btn-sm(href="{dashboard.personalProjectionPath}") FILL IN
    .card-body.d-flex.flex-column.h-100.p-3
      +if('dashboard.personalProjection')
        .js-personal-projection-body
          PersonalTable(personalProjection="{dashboard.personalProjection}")
        TaxTotals(
          federalTax="{dashboard.personalProjection.resFederalTaxRemaining}",
          stateTax="{dashboard.personalProjection.resStateTax}",
          showStateTax="{dashboard.personalProjection.resStateTaxShouldDisplay}",
          ranged="{!dashboard.hasProSubscription}"
        )

        +else
          i
            | Please
            a(href="{dashboard.personalProjectionPath}") fill in the personal projection details
            | .
</template>

<style lang="sass" global>

</style>
