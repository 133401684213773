<script>
  import ClientAdditions from "./ClientAdditions.svelte";
  import jQuery from "jquery";

  export let clientAdditions;
  export let csrfToken;
  export let updateAdditionsSrc;

  let newNotes = clientAdditions.notes;
  let currentNotes = clientAdditions.notes;
  let newSummaryEmailAdminCopy = clientAdditions.summary_email_admin_copy;
  let currentSummaryEmailAdminCopy = clientAdditions.summary_email_admin_copy;
  let additionsChangeSubmitted = false;
  let changeAdditionsInProgress = false;
  let error = "";

  function resetControl() {
    additionsChangeSubmitted = false;
    changeAdditionsInProgress = false;
    currentNotes = newNotes;
    currentSummaryEmailAdminCopy = newSummaryEmailAdminCopy;
    error = "";
  }

  function submitChange() {
    changeAdditionsInProgress = true;

    jQuery.ajax({
      url: updateAdditionsSrc,
      method: "PATCH",
      data: {
        notes: newNotes,
        summary_email_admin_copy: newSummaryEmailAdminCopy
      },
      dataType: "JSON",
      headers: { "X-CSRF-Token": csrfToken },
      success: (data) => {
        if (data.notes == null) {
          newNotes = "";
        }
        if (data.summary_email_admin_copy == null) {
          newSummaryEmailAdminCopy = false;
        }
        additionsChangeSubmitted = true;
      },
      error: (data) => (data.status == 422 ? (error = data.responseJSON.error) : (error = "Fatal error")),
      complete: () => (changeAdditionsInProgress = false)
    });
  }
</script>

<div class="card mb-3">
  <div class="card-header">
    <h3 class="card-title mb-0">Additional Information</h3>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-lg-12">
        <ClientAdditions
          bind:newNotes
          {currentNotes}
          bind:newSummaryEmailAdminCopy
          {currentSummaryEmailAdminCopy}
          {additionsChangeSubmitted}
          {changeAdditionsInProgress}
          {error}
          onSubmitChange={submitChange}
          onResetControl={resetControl}
        />
      </div>
    </div>
  </div>
</div>
