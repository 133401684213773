import _ from "lodash";
import { writable, derived } from "svelte/store";
import * as yup from "yup";
import { serializePersonalProjection } from "./Serialization";

export const filingStatusOptions = [
  { value: "single", title: "Single" },
  { value: "joint", title: "Married Joint" },
  { value: "head", title: "Head-of-Household" },
  { value: "separate", title: "Married Separate" }
];

export const statesOfResidencyOptions = [
  { value: "AK", title: "Alaska" },
  { value: "AL", title: "Alabama" },
  { value: "AR", title: "Arkansas" },
  { value: "AS", title: "American Samoa" },
  { value: "AZ", title: "Arizona" },
  { value: "CA", title: "California" },
  { value: "CO", title: "Colorado" },
  { value: "CT", title: "Connecticut" },
  { value: "DC", title: "District of Columbia" },
  { value: "DE", title: "Delaware" },
  { value: "FL", title: "Florida" },
  { value: "GA", title: "Georgia" },
  { value: "GU", title: "Guam" },
  { value: "HI", title: "Hawaii" },
  { value: "IA", title: "Iowa" },
  { value: "ID", title: "Idaho" },
  { value: "IL", title: "Illinois" },
  { value: "IN", title: "Indiana" },
  { value: "KS", title: "Kansas" },
  { value: "KY", title: "Kentucky" },
  { value: "LA", title: "Louisiana" },
  { value: "MA", title: "Massachusetts" },
  { value: "MD", title: "Maryland" },
  { value: "ME", title: "Maine" },
  { value: "MI", title: "Michigan" },
  { value: "MN", title: "Minnesota" },
  { value: "MO", title: "Missouri" },
  { value: "MP", title: "Northern Mariana Islands" },
  { value: "MS", title: "Mississippi" },
  { value: "MT", title: "Montana" },
  { value: "NC", title: "North Carolina" },
  { value: "ND", title: "North Dakota" },
  { value: "NE", title: "Nebraska" },
  { value: "NH", title: "New Hampshire" },
  { value: "NJ", title: "New Jersey" },
  { value: "NM", title: "New Mexico" },
  { value: "NV", title: "Nevada" },
  { value: "NY", title: "New York" },
  { value: "OH", title: "Ohio" },
  { value: "OK", title: "Oklahoma" },
  { value: "OR", title: "Oregon" },
  { value: "PA", title: "Pennsylvania" },
  { value: "PR", title: "Puerto Rico" },
  { value: "RI", title: "Rhode Island" },
  { value: "SC", title: "South Carolina" },
  { value: "SD", title: "South Dakota" },
  { value: "TN", title: "Tennessee" },
  { value: "TX", title: "Texas" },
  { value: "UT", title: "Utah" },
  { value: "VA", title: "Virginia" },
  { value: "VI", title: "Virgin Islands" },
  { value: "VT", title: "Vermont" },
  { value: "WA", title: "Washington" },
  { value: "WI", title: "Wisconsin" },
  { value: "WV", title: "West Virginia" },
  { value: "WY", title: "Wyoming" }
];

export const indianaCountyOptions = [
  { value: 1, title: "Adams" },
  { value: 2, title: "Allen" },
  { value: 3, title: "Bartholomew" },
  { value: 4, title: "Benton" },
  { value: 5, title: "Blackford" },
  { value: 6, title: "Boone" },
  { value: 7, title: "Brown" },
  { value: 8, title: "Carroll" },
  { value: 9, title: "Cass" },
  { value: 10, title: "Clark" },
  { value: 11, title: "Clay" },
  { value: 12, title: "Clinton" },
  { value: 13, title: "Crawford" },
  { value: 14, title: "Daviess" },
  { value: 15, title: "Dearborn" },
  { value: 16, title: "Decatur" },
  { value: 17, title: "DeKalb" },
  { value: 18, title: "Delaware" },
  { value: 19, title: "Dubois" },
  { value: 20, title: "Elkhart" },
  { value: 21, title: "Fayette" },
  { value: 22, title: "Floyd" },
  { value: 23, title: "Fountain" },
  { value: 24, title: "Franklin" },
  { value: 25, title: "Fulton" },
  { value: 26, title: "Gibson" },
  { value: 27, title: "Grant" },
  { value: 28, title: "Greene" },
  { value: 29, title: "Hamilton" },
  { value: 30, title: "Hancock" },
  { value: 31, title: "Harrison" },
  { value: 32, title: "Hendricks" },
  { value: 33, title: "Henry" },
  { value: 34, title: "Howard" },
  { value: 35, title: "Huntington" },
  { value: 36, title: "Jackson" },
  { value: 37, title: "Jasper" },
  { value: 38, title: "Jay" },
  { value: 39, title: "Jefferson" },
  { value: 40, title: "Jennings" },
  { value: 41, title: "Johnson" },
  { value: 42, title: "Knox" },
  { value: 43, title: "Kosciusko" },
  { value: 44, title: "LaGrange" },
  { value: 45, title: "Lake" },
  { value: 46, title: "LaPorte" },
  { value: 47, title: "Lawrence" },
  { value: 48, title: "Madison" },
  { value: 49, title: "Marion" },
  { value: 50, title: "Marshall" },
  { value: 51, title: "Martin" },
  { value: 52, title: "Miami" },
  { value: 53, title: "Monroe" },
  { value: 54, title: "Montgomery" },
  { value: 55, title: "Morgan" },
  { value: 56, title: "Newton" },
  { value: 57, title: "Noble" },
  { value: 58, title: "Ohio" },
  { value: 59, title: "Orange" },
  { value: 60, title: "Owen" },
  { value: 61, title: "Parke" },
  { value: 62, title: "Perry" },
  { value: 63, title: "Pike" },
  { value: 64, title: "Porter" },
  { value: 65, title: "Posey" },
  { value: 66, title: "Pulaski" },
  { value: 67, title: "Putnam" },
  { value: 68, title: "Randolph" },
  { value: 69, title: "Ripley" },
  { value: 70, title: "Rush" },
  { value: 71, title: "St. Joseph" },
  { value: 72, title: "Scott" },
  { value: 73, title: "Shelby" },
  { value: 74, title: "Spencer" },
  { value: 75, title: "Starke" },
  { value: 76, title: "Steuben" },
  { value: 77, title: "Sullivan" },
  { value: 78, title: "Switzerland" },
  { value: 79, title: "Tippecanoe" },
  { value: 80, title: "Tipton" },
  { value: 81, title: "Union" },
  { value: 82, title: "Vanderburgh" },
  { value: 83, title: "Vermillion" },
  { value: 84, title: "Vigo" },
  { value: 85, title: "Wabash" },
  { value: 86, title: "Warren" },
  { value: 87, title: "Warrick" },
  { value: 88, title: "Washington" },
  { value: 89, title: "Wayne" },
  { value: 90, title: "Wells" },
  { value: 91, title: "White" },
  { value: 92, title: "Whitley" }
];

export const marylandCountyOptions = [
  { value: 1, title: "Allegany" },
  { value: 2, title: "Anne Arundel" },
  { value: 3, title: "Baltimore City" },
  { value: 4, title: "Baltimore County" },
  { value: 5, title: "Calvert" },
  { value: 6, title: "Caroline" },
  { value: 7, title: "Carrol" },
  { value: 8, title: "Cecil" },
  { value: 9, title: "Charles" },
  { value: 10, title: "Dorchester" },
  { value: 11, title: "Frederick" },
  { value: 12, title: "Garrett" },
  { value: 13, title: "Harford" },
  { value: 14, title: "Howard" },
  { value: 15, title: "Kent" },
  { value: 16, title: "Montgomery" },
  { value: 17, title: "Prince George's" },
  { value: 18, title: "Queen Anne's" },
  { value: 19, title: "St. Mary's" },
  { value: 20, title: "Somerset" },
  { value: 21, title: "Talbot" },
  { value: 22, title: "Washington" },
  { value: 23, title: "Wicomico" },
  { value: 24, title: "Worcester" },
  { value: 25, title: "Nonresidents" }
];

// Add state specific parameters here + make sure you update the `schema` below
// to handle validations
export const allStateParameters = {
  AL: ["state_529_plan_contributions", "state_unreimbursed_employee_expenses"],
  AZ: ["tax_exempt_interest"],
  CO: ["taxpayer_age"],
  GA: ["fed_tax_free_interest"],
  KS: [
    "tax_exempt_interest",
    "fed_inc_tax_refund",
    "state_529_plan_contributions",
    "state_ks_tax_credits",
    "state_child_dependent_care_expenses"
  ],
  IL: ["tax_exempt_interest", "state_529_plan_contributions", "state_dependent_education_expenses"],
  IN: [
    "state_dependent_education_expenses",
    "state_in_county_code",
    "state_ks_tax_credits",
    "state_sec_179_deduction"
  ],
  MD: [
    "state_md_county_code",
    "tax_exempt_interest",
    "state_529_plan_contributions",
    "state_child_dependent_care_expenses"
  ],
  OH: ["state_529_plan_contributions", "tax_exempt_interest", "state_ks_tax_credits"],
  OR: [
    "tax_exempt_interest",
    "fed_tax_free_interest",
    "fed_passive_activity_losses",
    "state_529_plan_contributions",
    "taxpayer_age",
    "state_ks_tax_credits"
  ],
  MI: ["tax_exempt_interest", "state_529_plan_contributions", "taxpayer_age", "state_ks_tax_credits"],
  MN: ["tax_exempt_interest", "state_unreimbursed_employee_expenses"],
  NC: [
    "tax_exempt_interest",
    "state_529_plan_contributions",
    "state_depreciation_adjustment",
    "state_ks_tax_credits",
    "state_sec_179_deduction"
  ],
  PA: [
    "state_unreimbursed_employee_expenses",
    "state_529_plan_contributions",
    "fed_estate_trust_income",
    "fed_gambling_income",
    "state_ks_tax_credits"
  ],
  SC: [
    "tax_exempt_interest",
    "state_529_plan_contributions",
    "taxpayer_age",
    "state_ks_tax_credits",
    "state_sc_college_tuition"
  ],
  UT: ["tax_exempt_interest", "state_ks_tax_credits"],
  VA: ["tax_exempt_interest", "state_529_plan_contributions", "state_child_dependent_care_expenses"]
};

let schema = yup.object().shape(
  {
    fed_est_tax_pay_march_taxpayer: yup.number().required(),
    fed_est_tax_pay_march_spouse: yup.number().required(),
    fed_est_tax_pay_june_taxpayer: yup.number().required(),
    fed_est_tax_pay_june_spouse: yup.number(),
    fed_est_tax_pay_september_taxpayer: yup.number().required(),
    fed_est_tax_pay_september_spouse: yup.number(),
    fed_est_tax_pay_december_taxpayer: yup.number().required(),
    fed_est_tax_pay_december_spouse: yup.number(),
    state_estimated_tax_payments_q1_taxpayer: yup.number().required(),
    state_estimated_tax_payments_q1_spouse: yup.number(),
    state_estimated_tax_payments_q2_taxpayer: yup.number().required(),
    state_estimated_tax_payments_q2_spouse: yup.number(),
    state_estimated_tax_payments_q3_taxpayer: yup.number().required(),
    state_estimated_tax_payments_q3_spouse: yup.number(),
    state_estimated_tax_payments_q4_taxpayer: yup.number().required(),
    state_estimated_tax_payments_q4_spouse: yup.number(),
    wages: yup.array().of(
      yup.object().shape({
        employer_name: yup.string().required(),
        employer_type: yup.string().required(),
        paystub_date: yup
          .string()
          .matches(/^([01]?\d)\/([0123]?\d)\/\d\d\d\d$/)
          .required(),
        wages: yup.number().required(),
        federal_income_tax_withheld: yup.number().required(),
        state_income_tax_withheld: yup.number().required(),
        dependent_care_benefits: yup.number().required(),
        "401k_contributions": yup.number().required(),
        age: yup.number().required(),
        covered_by_retirement_plan: yup.boolean().required(),
        business_owned: yup.boolean().required()
      })
    ),
    state_adj_additions_taxpayer: yup.number().required(),
    state_adj_additions_spouse: yup.number(),
    state_adj_substractions_taxpayer: yup.number().required(),
    state_adj_substractions_spouse: yup.number(),
    state_adj_tax_credit_taxpayer: yup.number().required(),
    state_adj_tax_credit_spouse: yup.number(),
    fed_inc_adj_health_insurance_premium_taxpayer: yup.number().required(),
    fed_inc_adj_health_insurance_premium_spouse: yup.number(),
    ira_deduction_taxpayer: yup.number().required(),
    ira_deduction_spouse: yup.number(),
    per_ira_deduction_taxpayer: yup.number().required(),
    per_ira_deduction_spouse: yup.number(),
    per_health_savings_acct_taxpayer: yup.number().required(),
    per_health_savings_acct_spouse: yup.number(),
    other_deductions_taxpayer: yup.number().required(),
    other_deductions_spouse: yup.number(),
    usr_scorp_business_income_taxpayer: yup.number().required(),
    usr_scorp_business_income_spouse: yup.number(),
    usr_schedule_c_income_taxpayer: yup.number().required(),
    usr_schedule_c_income_spouse: yup.number(),
    fed_tax_owed_other_taxes_taxpayer: yup.number().required(),
    fed_tax_owed_other_taxes_spouse: yup.number(),
    fed_tax_owed_prior_year_amt_carryforward_taxpayer: yup.number().required(),
    fed_tax_owed_prior_year_amt_carryforward_spouse: yup.number(),
    fed_tax_owed_tax_credits_taxpayer: yup.number().required(),
    fed_tax_owed_tax_credits_spouse: yup.number(),
    social_security_taxpayer: yup.number().required(),
    social_security_spouse: yup.number().required(),
    fed_inc_bank_1_interest_taxpayer: yup.number().required(),
    fed_inc_bank_1_interest_spouse: yup.number(),
    fed_inc_ordinary_dividends_taxpayer: yup.number().required(),
    fed_inc_ordinary_dividends_spouse: yup.number(),
    fed_inc_money_market_1_interest_taxpayer: yup.number().required(),
    fed_inc_money_market_1_interest_spouse: yup.number(),
    fed_inc_stock_qualified_div_taxpayer: yup.number().required(),
    fed_inc_stock_qualified_div_spouse: yup.number(),
    fed_inc_stock_st_div_gains_taxpayer: yup.number().required(),
    fed_inc_stock_st_div_gains_spouse: yup.number(),
    fed_inc_stock_lt_cap_gains_taxpayer: yup.number().required(),
    fed_inc_stock_lt_cap_gains_spouse: yup.number(),
    fed_inc_adj_capital_losses_this_year_taxpayer: yup.number().required(),
    fed_inc_adj_capital_losses_this_year_spouse: yup.number(),
    fed_inc_stock_60man_cap_gains_taxpayer: yup.number().required(),
    fed_inc_stock_60man_cap_gains_spouse: yup.number(),
    fed_inc_other_income_taxpayer: yup.number().required(),
    fed_inc_other_income_spouse: yup.number(),
    fed_inc_ca_tax_refund_taxpayer: yup.number(),
    fed_inc_ca_tax_refund_spouse: yup.number(),
    fed_ded_mortgage_interest_taxpayer: yup.number().required(),
    fed_ded_mortgage_interest_spouse: yup.number(),
    fed_ded_state_income_taxes_taxpayer: yup.number().required(),
    fed_ded_state_income_taxes_spouse: yup.number(),
    fed_ded_local_income_taxes_taxpayer: yup.number().required(),
    fed_ded_local_income_taxes_spouse: yup.number(),
    fed_ded_prior_state_inc_taxes_taxpayer: yup.number().required(),
    fed_ded_prior_state_inc_taxes_spouse: yup.number(),
    fed_ded_property_taxes_taxpayer: yup.number().required(),
    fed_ded_property_taxes_spouse: yup.number(),
    fed_ded_personal_property_taxes_taxpayer: yup.number().required(),
    fed_ded_personal_property_taxes_spouse: yup.number(),
    fed_ded_home_loan_points_taxpayer: yup.number().required(),
    fed_ded_home_loan_points_spouse: yup.number(),
    fed_ded_medical_and_dental_taxpayer: yup.number().required(),
    fed_ded_medical_and_dental_spouse: yup.number(),
    fed_ded_cash_donations_taxpayer: yup.number().required(),
    fed_ded_cash_donations_spouse: yup.number(),
    fed_ded_noncash_donations_taxpayer: yup.number().required(),
    fed_ded_noncash_donations_spouse: yup.number(),
    fed_ded_other_deductions_taxpayer: yup.number().required(),
    fed_ded_other_deductions_spouse: yup.number(),
    fed_tax_owed_child_tax_credit_override_taxpayer: yup
      .number()
      .when("fed_tax_owed_child_tax_credit_override_guard", {
        is: 1,
        then: yup.number().required()
      }),
    fed_tax_owed_child_tax_credit_override_guard_taxpayer: yup.number().oneOf([0, 1]),
    fed_tax_owed_child_tax_credit_override_spouse: yup
      .number()
      .when("fed_tax_owed_child_tax_credit_override_guard", {
        is: 1,
        then: yup.number().required()
      }),
    fed_tax_owed_child_tax_credit_override_guard_spouse: yup.number().oneOf([0, 1]),
    res_inc_itemized_override_taxpayer: yup.number().when("res_inc_itemized_override_guard", {
      is: 1,
      then: yup.number().required()
    }),
    res_inc_itemized_override_guard_taxpayer: yup.number().oneOf([0, 1]),
    res_inc_itemized_override_spouse: yup.number().when("res_inc_itemized_override_guard", {
      is: 1,
      then: yup.number().required()
    }),
    res_inc_itemized_override_guard_spouse: yup.number().oneOf([0, 1]),
    res_passthrough_deduction_override_taxpayer: yup
      .number()
      .when("res_passthrough_deduction_override_guard", {
        is: 1,
        then: yup.number().required()
      }),
    res_passthrough_deduction_override_guard_taxpayer: yup.number().oneOf([0, 1]),
    res_passthrough_deduction_override_spouse: yup.number().when("res_passthrough_deduction_override_guard", {
      is: 1,
      then: yup.number().required()
    }),
    res_passthrough_deduction_override_guard_spouse: yup.number().oneOf([0, 1]),
    res_se_tax_override_taxpayer: yup.number().when("res_se_tax_override_guard", {
      is: 1,
      then: yup.number().required()
    }),
    res_se_tax_override_guard_taxpayer: yup.number().oneOf([0, 1]),
    res_se_tax_override_spouse: yup.number().when("res_se_tax_override_guard", {
      is: 1,
      then: yup.number().required()
    }),
    res_se_tax_override_guard_spouse: yup.number().oneOf([0, 1]),
    res_total_tax_override_taxpayer: yup.number().when("res_total_tax_override_guard", {
      is: 1,
      then: yup.number().required()
    }),
    res_total_tax_override_guard_taxpayer: yup.number().oneOf([0, 1]),
    res_total_tax_override_spouse: yup.number().when("res_total_tax_override_guard", {
      is: 1,
      then: yup.number().required()
    }),
    res_total_tax_override_guard_spouse: yup.number().oneOf([0, 1]),
    res_state_tax_before_payments_override_taxpayer: yup
      .number()
      .when("res_state_tax_before_payments_override_guard", {
        is: 1,
        then: yup.number().required()
      }),
    res_state_tax_before_payments_override_guard_taxpayer: yup.number().oneOf([0, 1]),
    res_state_tax_before_payments_override_spouse: yup
      .number()
      .when("res_state_tax_before_payments_override_guard", {
        is: 1,
        then: yup.number().required()
      }),
    res_state_tax_before_payments_override_guard_spouse: yup.number().oneOf([0, 1]),
    business_inc_ccorp_fed_tax_override_taxpayer: yup
      .number()
      .when("business_inc_ccorp_fed_tax_override_guard", {
        is: 1,
        then: yup.number().required()
      }),
    business_inc_ccorp_fed_tax_override_guard_taxpayer: yup.number().oneOf([0, 1]),
    business_inc_ccorp_fed_tax_override_spouse: yup
      .number()
      .when("business_inc_ccorp_fed_tax_override_guard", {
        is: 1,
        then: yup.number().required()
      }),
    business_inc_ccorp_fed_tax_override_guard_spouse: yup.number().oneOf([0, 1]),
    business_inc_ccorp_state_tax_override_taxpayer: yup
      .number()
      .when("business_inc_ccorp_state_tax_override_guard", {
        is: 1,
        then: yup.number().required()
      }),
    business_inc_ccorp_state_tax_override_guard_taxpayer: yup.number().oneOf([0, 1]),
    business_inc_ccorp_state_tax_override_spouse: yup
      .number()
      .when("business_inc_ccorp_state_tax_override_guard", {
        is: 1,
        then: yup.number().required()
      }),
    business_inc_ccorp_state_tax_override_guard_spouse: yup.number().oneOf([0, 1]),
    type: yup
      .string()
      .oneOf(filingStatusOptions.map((option) => option.value))
      .required(),
    state_of_residency: yup
      .string()
      .oneOf(statesOfResidencyOptions.map((state) => state.value))
      .required(),
    fed_par_no_of_dependents_from_0_to_6: yup.number().required(),
    fed_par_no_of_dependents_from_6_to_17: yup.number().required(),
    fed_par_no_of_dependents_over_17: yup.number().required(),
    rental_real_estate: yup.array().of(
      yup.object().shape({
        name: yup.string().required(),
        income: yup.number().required(),
        advertising: yup.number().required(),
        auto_expenses: yup.number().required(),
        cleaning: yup.number().required(),
        commissions: yup.number().required(),
        depreciation: yup.number().required(),
        insurance: yup.number().required(),
        legal_expenses: yup.number().required(),
        management_fees: yup.number().required(),
        mortage_interest: yup.number().required(),
        repairs: yup.number().required(),
        supplies: yup.number().required(),
        taxes: yup.number().required(),
        utilities: yup.number().required(),
        other: yup.number().required()
      })
    ),
    fed_estate_trust_income_taxpayer: yup.number().when("state_of_residency", {
      is: (usState) => ["PA"].includes(usState),
      then: yup.number().required()
    }),
    fed_estate_trust_income_spouse: yup.number(),
    fed_gambling_income_taxpayer: yup.number().when("state_of_residency", {
      is: (usState) => ["PA"].includes(usState),
      then: yup.number().required()
    }),
    fed_gambling_income_spouse: yup.number(),
    fed_tax_free_interest_taxpayer: yup.number().when("state_of_residency", {
      is: (usState) => ["GA", "OR"].includes(usState),
      then: yup.number().required()
    }),
    fed_tax_free_interest_spouse: yup.number(),
    fed_passive_activity_losses_taxpayer: yup.number().when("state_of_residency", {
      is: (usState) => ["OR"].includes(usState),
      then: yup.number().required()
    }),
    fed_passive_activity_losses_spouse: yup.number(),
    fed_inc_tax_refund_taxpayer: yup.number().when("state_of_residency", {
      is: (usState) => ["KS"].includes(usState),
      then: yup.number().required()
    }),
    fed_inc_tax_refund_spouse: yup.number(),
    state_ks_tax_credits_taxpayer: yup.number().when("state_of_residency", {
      is: (usState) => ["KS", "MI", "IN", "NC", "OR", "PA", "UT"].includes(usState),
      then: yup.number().required()
    }),
    state_ks_tax_credits_spouse: yup.number(),
    state_529_plan_contributions_taxpayer: yup.number().when("state_of_residency", {
      is: (usState) => ["AL", "KS", "IL", "MI", "NC", "OR", "PA", "VA"].includes(usState),
      then: yup.number().required()
    }),
    state_529_plan_contributions_spouse: yup.number(),
    state_child_dependent_care_expenses_taxpayer: yup.number().when("state_of_residency", {
      is: (usState) => ["VA"].includes(usState),
      then: yup.number().required()
    }),
    state_child_dependent_care_expenses_spouse: yup.number(),
    state_dependent_education_expenses_taxpayer: yup.number().when("state_of_residency", {
      is: (usState) => ["IL", "IN"].includes(usState),
      then: yup.number().required()
    }),
    state_dependent_education_expenses_spouse: yup.number(),
    state_unreimbursed_employee_expenses_taxpayer: yup.number().when("state_of_residency", {
      is: (usState) => ["AL", "MN", "PA"].includes(usState),
      then: yup.number().required()
    }),
    state_unreimbursed_employee_expenses_spouse: yup.number(),
    state_in_county_code: yup.number().when("state_of_residency", {
      is: (usState) => ["IN"].includes(usState),
      then: yup.number().required()
    }),
    state_md_county_code: yup.number().when("state_of_residency", {
      is: (usState) => ["MD"].includes(usState),
      then: yup.number().required()
    }),
    state_sec_179_deduction_taxpayer: yup.number().when("state_of_residency", {
      is: (usState) => ["IN", "NC"].includes(usState),
      then: yup.number().required()
    }),
    state_sec_179_deduction_spouse: yup.number(),
    tax_exempt_interest_taxpayer: yup.number().when("state_of_residency", {
      is: (usState) => ["AZ", "KS", "IL", "MI", "MN", "NC", "OR", "UT", "VA"].includes(usState),
      then: yup.number().required()
    }),
    state_depreciation_adjustment_taxpayer: yup.number().when("state_of_residency", {
      is: (usState) => ["NC"].includes(usState),
      then: yup.number().required()
    }),
    tax_exempt_interest_spouse: yup.number(),
    taxpayer_age: yup.number().when("state_of_residency", {
      is: (usState) => ["CO", "MI", "OR"].includes(usState),
      then: yup.number().required()
    }),
    state_sc_college_tuition_taxpayer: yup.number().when("state_of_residency", {
      is: (usState) => ["SC"].includes(usState),
      then: yup.number().required()
    }),
    state_sc_college_tuition_spouse: yup.number().when("state_of_residency", {
      is: (usState) => ["SC"].includes(usState),
      then: yup.number().required()
    })
  },
  [
    ["fed_par_no_of_dependents_from_0_to_6_taxpayer", "fed_par_no_of_dependents_from_0_to_6_spouse"],
    ["fed_est_tax_pay_march_taxpayer", "fed_est_tax_pay_march_spouse"]
  ]
);

let businessCompaniesSchema = yup.lazy((obj) =>
  yup.object(
    _.mapValues(obj, () => {
      return yup.object({
        revenue: yup.number().required(),
        expense: yup.number().required()
      });
    })
  )
);

// turns:
//   {
//     a: [ { name: "Vasya" }, { name: "Petya" } ],
//     b: 123
//   }
// into:
//   [ 'a[0].name', 'a[1].name', 'b' ]
function flattenKeyStructure(root) {
  function recursiveFlatten(obj) {
    let result = [];

    _.forOwn(obj, (value, key) => {
      if (Array.isArray(value)) {
        _.each(value, (entry, index) => {
          result = [...result, ..._.map(recursiveFlatten(entry), (v) => `.${key}[${index}]${v}`)];
        });
      } else {
        result = [...result, `.${key}`];
      }
    });

    return result;
  }

  return _.map(recursiveFlatten(root), (e) => e.substring(1));
}

export const personalProjection = writable({});
export const businessCompaniesStore = writable({});

export const personalProjectionValidation = derived(personalProjection, ($pp) => {
  try {
    schema.validateSync($pp, { abortEarly: false });
    return new Set();
  } catch (err) {
    return new Set(err.inner.map((err) => err.path));
  }
});

export const personalProjectionSerialized = derived(
  [personalProjection, businessCompaniesStore],
  ([$pp, $bc]) => {
    try {
      schema.validateSync($pp, { abortEarly: true });
      if (Object.keys($bc).length > 0) {
        businessCompaniesSchema.validateSync($bc, { abortEarly: true });
      }
      return serializePersonalProjection($pp);
    } catch {
      return null;
    }
  }
);

export const personalProjectionFlattenedFields = derived(personalProjection, flattenKeyStructure);

export const personalProjectionVisitors = writable(new Set([]));

export function visitField(field) {
  personalProjectionVisitors.update((visitors) => new Set([...visitors, field]));
}

export const businessCompaniesValidation = derived(businessCompaniesStore, ($businessCompanies) => {
  try {
    businessCompaniesSchema.validateSync($businessCompanies, {
      abortEarly: false
    });
    return new Set();
  } catch (err) {
    return new Set(err.inner.map((err) => err.path));
  }
});
