<script>
  import ResultValue from "components/common/ResultValue.svelte";

  export let mfjProjection;
  export let mfsTaxpayerProjection;
  export let mfsSpouseProjection;
</script>

<div class="col-sm-12 container widget-div">
  <div class="col-sm-12 overflow-x__auto">
    <table class="table table-striped flash-table">
      <thead>
        <tr>
          <th />
          <th class="table-header">Married Filing Joint</th>
          <th class="table-header">Married Filing Separate Taxpayer</th>
          <th class="table-header">Married Filing Separate Spouse</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="header"> Wages </td>
          <td class="value">
            <ResultValue value={mfjProjection["resWages"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsTaxpayerProjection["resWages"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsSpouseProjection["resWages"]} />
          </td>
        </tr>
        <tr>
          <td class="header"> Interest & Dividends </td>
          <td class="value">
            <ResultValue value={mfjProjection["resInterestAndDividents"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsTaxpayerProjection["resInterestAndDividents"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsSpouseProjection["resInterestAndDividents"]} />
          </td>
        </tr>
        <tr>
          <td class="header"> Capital Gains </td>
          <td class="value">
            <ResultValue value={mfjProjection["resCapitalGains"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsTaxpayerProjection["resCapitalGains"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsSpouseProjection["resCapitalGains"]} />
          </td>
        </tr>
        <tr>
          <td class="header"> Business Income </td>
          <td class="value">
            <ResultValue value={mfjProjection["resBusinessIncome"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsTaxpayerProjection["resBusinessIncome"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsSpouseProjection["resBusinessIncome"]} />
          </td>
        </tr>
        <tr>
          <td class="header"> Rental Income </td>
          <td class="value">
            <ResultValue value={mfjProjection["resRentalIncome"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsTaxpayerProjection["resRentalIncome"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsSpouseProjection["resRentalIncome"]} />
          </td>
        </tr>
        <tr>
          <td class="header"> Other Income </td>
          <td class="value">
            <ResultValue value={mfjProjection["resOtherIncome"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsTaxpayerProjection["resOtherIncome"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsSpouseProjection["resOtherIncome"]} />
          </td>
        </tr>
        <tr>
          <td class="header total"> Total Income </td>
          <td class="value">
            <ResultValue value={mfjProjection["resIncTotal"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsTaxpayerProjection["resIncTotal"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsSpouseProjection["resIncTotal"]} />
          </td>
        </tr>
        <tr>
          <td class="header"> Adjustments </td>
          <td class="value">
            <ResultValue value={mfjProjection["resIncAdjustments"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsTaxpayerProjection["resIncAdjustments"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsSpouseProjection["resIncAdjustments"]} />
          </td>
        </tr>
        <tr>
          <td class="header"> Itemized Deductions </td>
          <td class="value">
            <ResultValue value={mfjProjection["resIncItemized"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsTaxpayerProjection["resIncItemized"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsSpouseProjection["resIncItemized"]} />
          </td>
        </tr>
        <tr>
          <td class="header"> QBI Deduction </td>
          <td class="value">
            <ResultValue value={mfjProjection["resPassthroughDeduction"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsTaxpayerProjection["resPassthroughDeduction"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsSpouseProjection["resPassthroughDeduction"]} />
          </td>
        </tr>
        <tr>
          <td class="header total"> Taxable Income </td>
          <td class="value">
            <ResultValue value={mfjProjection["resTaxableIncome"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsTaxpayerProjection["resTaxableIncome"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsSpouseProjection["resTaxableIncome"]} />
          </td>
        </tr>
        <tr>
          <td class="header"> Income Tax </td>
          <td class="value">
            <ResultValue value={mfjProjection["resIncomeTax"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsTaxpayerProjection["resIncomeTax"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsSpouseProjection["resIncomeTax"]} />
          </td>
        </tr>
        <tr>
          <td class="header"> SE Tax </td>
          <td class="value">
            <ResultValue value={mfjProjection["resSeTax"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsTaxpayerProjection["resSeTax"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsSpouseProjection["resSeTax"]} />
          </td>
        </tr>
        <tr>
          <td class="header"> Total Federal Tax </td>
          <td class="value">
            <ResultValue value={mfjProjection["resTotalTax"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsTaxpayerProjection["resTotalTax"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsSpouseProjection["resTotalTax"]} />
          </td>
        </tr>
        <tr>
          <td class="header"> Total State Tax </td>
          <td class="value">
            <ResultValue value={mfjProjection["resStateTaxBeforePayments"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsTaxpayerProjection["resStateTaxBeforePayments"]} />
          </td>
          <td class="value">
            <ResultValue value={mfsSpouseProjection["resStateTaxBeforePayments"]} />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<style lang="scss">
  .container {
    padding: 10px;
  }
  .flash-table {
    border-spacing: 0px;
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border-top: 0px;
        }
      }
    }
    > thead > tr {
      > th,
      > td {
        border-bottom-width: 0px;
      }
    }
  }
  .widget-div {
    display: flex;
    flex-wrap: wrap;
  }
  .value {
    text-align: end;
  }
  .header {
    font-weight: bold;
  }
  .total {
    text-align: center;
  }
  .table-header {
    background-color: #452461;
    color: #ffffff;
  }
</style>
