<script>
  import { find } from "lodash";
  import Select from "svelte-select";
  import { reportStore } from "./stores/reportStore";

  export let reports;
  export let loadMaterializedReport;
  export let applyReport;

  $: items = reports.map((report) => {
    return { id: report.id, label: report.name };
  });
  $: selectedValue = { id: $reportStore.id, label: $reportStore.name };

  const optionIdentifier = "id";
  const isClearable = false;
  const showIndicator = true;
  const containerStyles = "margin-bottom: 5px;";

  function handleSelect(event) {
    const selected_report_id = event.detail.id;
    const report = find(reports, { id: selected_report_id });
    applyReport(report);
    loadMaterializedReport();
  }
</script>

<div id="reportSelector">
  <Select
    {containerStyles}
    {items}
    {optionIdentifier}
    {isClearable}
    {showIndicator}
    value={selectedValue}
    on:select={handleSelect}
    --itemIsActiveBG="#27b6af"
    --itemHoverBG="#f6f8f8"
    --borderFocusColor="#452161"
  />
</div>

<style>
  div {
    margin-top: 15px;
  }
</style>
