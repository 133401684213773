<script>
  const paginationOptions = [10, 25, 50, 100];

  export let currentPage;
  export let adjustedCurrentPage;
  export let totalPages;
  export let perPage;

  function range(left, right) {
    return [...Array(right - left + 1).keys()].map((i) => i + left);
  }

  function changePage(page) {
    currentPage = page;
  }

  $: left = Math.max(1, adjustedCurrentPage - 3);
  $: right = Math.min(totalPages, adjustedCurrentPage + 3);
</script>

<select class="form-control per-page-selector" bind:value={perPage}>
  {#each paginationOptions as perPageOption}
    <option value={perPageOption}>
      {perPageOption} per page
    </option>
  {/each}
</select>

<ul class="pagination smaller-margins">
  {#if left > 1}
    <li class="page-item" class:active={adjustedCurrentPage == 1}>
      <a class="page-link" href="/#" on:click|preventDefault={() => changePage(1)}>First</a>
    </li>
    <li class="page-item">
      <span class="text-muted">&hellip;</span>
    </li>
  {/if}
  {#each range(left, right) as page}
    <li class="page-item" class:active={adjustedCurrentPage == page}>
      <a class="page-link" href="/#" on:click|preventDefault={() => changePage(page)}>{page}</a>
    </li>
  {/each}
  {#if right < totalPages}
    <li class="page-item">
      <span class="text-muted">&hellip;</span>
    </li>
    <li class="page-item" class:active={adjustedCurrentPage == totalPages}>
      <a class="page-link" href="/#" on:click|preventDefault={() => changePage(totalPages)}>Last</a>
    </li>
  {/if}
</ul>

<style>
  .smaller-margins {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .per-page-selector {
    float: left;
    width: 150px;
    margin: 10px 10px 0 0;
  }
</style>
