<script>
  import { onMount } from "svelte";

  export let authenticityToken;
  export let description;
  export let smsConfirmationsPath;
  export let smsCode;
  export let cancelPath;
  export let secondsTillNewCode;
  export let secondsTillCodeExpiration;

  onMount(() => {
    let newCodeTimer = setInterval(() => {
      if (secondsTillNewCode <= 0) {
        clearInterval(newCodeTimer);
        return;
      }
      secondsTillNewCode -= 1;
    }, 1000);
    let codeExpirationTimer = setInterval(() => {
      if (secondsTillCodeExpiration <= 0) {
        clearInterval(codeExpirationTimer);
        return;
      }
      secondsTillCodeExpiration -= 1;
    }, 1000);
  });
</script>

<form action={smsConfirmationsPath} method="post">
  <input type="hidden" name="_method" value="patch" />
  <input type="hidden" name="authenticity_token" value={authenticityToken} />
  <p>{@html description}</p>
  <div class="form-group">
    <label class="col-form-label" for="code">Code</label>
    <input class="form-control" type="text" name="code" id="code" value={smsCode} />
  </div>
  {#if secondsTillCodeExpiration <= 0}
    <!-- TOOD: Css styles -->
    <p style="color: red; padding-bottom: 5px">
      The code has expired. Please, request a new one by clicking 'Proceed'.
    </p>
  {/if}
  <!-- TOOD: Css styles -->
  <div class="form-group" style="display: flex; gap: 5px">
    <input type="submit" name="commit" value="Proceed" class="btn btn-success" />
    {#if secondsTillNewCode > 0}
      <!-- TOOD: Css styles -->
      <div class="btn btn-success" style="background: #efefef; color: #333333; cursor: wait">
        Send new code ({secondsTillNewCode} seconds)
      </div>
    {:else}
      <!-- TOOD: Css styles -->
      <a class="btn btn-success" data-method="delete" href={smsConfirmationsPath}>Send new code</a>
    {/if}
    <!-- TOOD: Css styles -->
    <a
      class="btn btn-warning"
      data-method="delete"
      href={cancelPath}
      style="background: #efefef; color: #333333"
    >
      Cancel
    </a>
  </div>
</form>
