<script>
  import { flatSpecTree } from "../stores/specificationTree";
  import DirtyParamTooltip from "../helpers/DirtyParamTooltip.svelte";

  export let checked;
  export let hidden;
  export let specTreeNodeId;

  $: reportOption = $flatSpecTree && specTreeNodeId && $flatSpecTree[specTreeNodeId];
</script>

<template lang="pug">
  .row({hidden})
    .col-sm-12
      .report_option__title-group
        .report_option__checkbox
          input(type="checkbox", bind:checked)
        .report_option__title {reportOption.title}
        DirtyParamTooltip(param="{specTreeNodeId}")
</template>
