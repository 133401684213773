<script>
  import TextInput from "./TextInput.svelte";
  import { credentialsValidation } from "./credentialsSchema";
  import { intersperse } from "utils/intersperse";

  export let newPassword;
  export let credentialChangeSubmitted;
  export let changeCredentialsInProgress;
  export let newEmail;
  export let newName;
  export let error;
  export let currentEmail;
  export let currentName;
  export let onSubmitChange;
  export let onResetControl;

  let invalidFields = new Set();

  $: passwordChanged = newPassword.length > 0;
  $: emailChanged = newEmail != currentEmail;
  $: nameChanged = newName != currentName && newName.trim().length > 0;
  $: canChangeCredentials = passwordChanged || emailChanged || nameChanged;
  $: feedback =
    error &&
    typeof error === "object" &&
    error.name &&
    Array.isArray(error.name) &&
    intersperse(error.name, " and ");

  function submittedMessage() {
    let clearedEmail = newEmail.trim().length == 0;

    let messages = [];
    if (clearedEmail) {
      messages.push('<span key="email">You have cleared the email</span>');
      if (passwordChanged) {
        messages.push(`<span key="password">and changed the password to: <b>${newPassword}</b></span>`);
      }
      if (nameChanged) {
        messages.push(`<span key="name">and changed the name to: <b>${newName}</b></span>`);
      }
    } else {
      messages.push(`<span key="prefix">You have changed</span>`);

      let credentials = [];
      if (passwordChanged) {
        credentials.push(`<span key="password">the password to: <b>${newPassword}</b></span>`);
      }
      if (emailChanged) {
        credentials.push(`<span key="email">the email to: <b>${newEmail}</b></span>`);
      }
      if (nameChanged) {
        credentials.push(`<span key="name">the name to: <b>${newName}</b></span>`);
      }
      messages.push(intersperse(credentials, " and "));
    }

    return messages;
  }

  function handleChange() {
    invalidFields = credentialsValidation({
      name: newName
    });
  }
</script>

{#if credentialChangeSubmitted}
  <div>
    <p>{@html intersperse(submittedMessage(), " ")}</p>
    <p>
      <button class="btn btn-primary" on:click={onResetControl}>OK, Got It</button>
    </p>
  </div>
{:else}
  <div class="credential-changer-form" class:credential-changer____in-progress={changeCredentialsInProgress}>
    {#if error && (typeof error === "string" || error instanceof String) && error.length > 0}
      <p class="error">{error}</p>
    {/if}

    <TextInput
      title="Name"
      bind:value={newName}
      serverValue={currentName}
      required={true}
      {feedback}
      onInput={handleChange}
      isValid={!invalidFields.has("name") && !(feedback && feedback.length > 0)}
    />
    <TextInput title="Email" bind:value={newEmail} serverValue={currentEmail} />
    <TextInput title="New Password" bind:value={newPassword} serverValue="" />

    <button class="btn btn-primary" on:click|preventDefault={onSubmitChange} disabled={!canChangeCredentials}>
      Update Credentials
    </button>
  </div>
{/if}

<style>
  .credential-changer-form {
    transition: opacity 0.5s ease-in-out;
  }

  .credential-changer____in-progress {
    opacity: 0.5;
  }
</style>
