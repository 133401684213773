<script>
  import NProgress from "nprogress";
  import { fade } from "svelte/transition";
  import { flip } from "svelte/animate";
  import { isString, isObject } from "underscore";
  import { isNil, kebabCase } from "lodash";
  import { orderedInitialFieldsToLabels, resultFieldsToLabels } from "../personal_projection/FieldsToLabels";
  import { personalProjectionSerialized } from "../personal_projection/Store";
  import { notyf_top_right } from "misc/notyf_top_right";

  export let simulatedProjections;
  export let simulatedPPRepository;
  export let initialPersonalProjection;

  let appliedSimulations = [];
  let comparedFields = {};
  let comparedValues = [];
  let comparedResults = [];
  let comparisonDisplayOrder = [];
  let draggingSimulation = null;

  $: {
    comparedValues = [];
    comparedResults = [];
    for (const [field, fieldLocation] of orderedInitialFieldsToLabels) {
      if (field in comparedFields) {
        comparedValues = [...comparedValues, [fieldLocation, comparedFields[field]]];
      }
    }
    for (const [field, label] of resultFieldsToLabels) {
      if (field in comparedFields) {
        comparedResults = [...comparedResults, [label, comparedFields[field]]];
      }
    }
  }
  $: isDragging = isObject(draggingSimulation);
  $: draggingSimulationIndex =
    isDragging && appliedSimulations.findIndex(({ id }) => id === draggingSimulation.id);

  const isAppliedSimulation = () => draggingSimulationIndex !== -1;
  const isSimulationFirstToApply = () => appliedSimulations.length === 0;
  const isInSimulationsToApply = (simulation_id) =>
    simulatedProjections.some(({ id }) => id === simulation_id);
  const comparedToOptions = {
    currentSimulation: "Current Simulation",
    currentProjection: "Current Personal Projection"
  };

  let payload = $personalProjectionSerialized;

  // We don't directly compare `business_inc_n_expense` and `business_inc_n_revenue`.
  // We don't diplay their changes in the "Values" row.
  // Only the difference in Business Income from `business_income` is shown in the "Results" row.
  // However, this might not be immediately clear, leading to potential confusion about the origin of these changes.
  // While it might be more informative to display changes in the "Values" row, this adds complexity.
  // Currently, the comparison is handled on the backend for this method,
  // whereas on the Simulator page, where changes to `business_inc_n_revenue` and `business_inc_n_expense` are visible, the comparison is done on the frontend.
  async function compareProjections() {
    const simulationsData = [
      { type: "params", payload },
      ...appliedSimulations.map(({ id }) => ({ type: "id", payload: { id } }))
    ];

    const data = await simulatedPPRepository.compare(simulationsData);

    if (!data) throw new Error("Failed to retrieve data.");

    comparedFields = data.result;
    comparisonDisplayOrder = Array.from({ length: appliedSimulations.length + 1 }, (_, i) => i);
  }

  function dragover(e, simulation) {
    if (!isDragging || draggingSimulation.id === simulation.id) return;

    const simulationIndex = appliedSimulations.findIndex(({ id }) => id === simulation.id);
    const newIndex =
      e.offsetX < e.target.offsetWidth / 2 ? Math.max(simulationIndex - 1, 0) : simulationIndex + 1;

    if (newIndex === draggingSimulationIndex) return;

    swapSimulations(draggingSimulationIndex, newIndex);
  }

  function applySimulation() {
    const orderIndex = comparedValues.length;

    appliedSimulations = [...appliedSimulations, draggingSimulation];
    comparisonDisplayOrder = [...comparisonDisplayOrder, orderIndex];
  }

  function swapSimulations(draggingSimulationIndex, newIndex) {
    appliedSimulations.splice(draggingSimulationIndex, 1);
    const [orderIndex] = comparisonDisplayOrder.splice(draggingSimulationIndex + 1, 1);

    comparisonDisplayOrder.splice(newIndex + 1, 0, orderIndex);
    appliedSimulations.splice(newIndex, 0, draggingSimulation);

    appliedSimulations = appliedSimulations;
    comparisonDisplayOrder = comparisonDisplayOrder;
  }

  function removeFromApplied(simulationIndex) {
    comparisonDisplayOrder.splice(simulationIndex + 1, 1);
    const [appliedSimulation] = appliedSimulations.splice(simulationIndex, 1);

    if (!isInSimulationsToApply(appliedSimulation.id)) {
      simulatedProjections = [...simulatedProjections, appliedSimulation];
    }

    appliedSimulations = appliedSimulations;
    comparisonDisplayOrder = comparisonDisplayOrder;
  }

  function dragstart(simulation) {
    draggingSimulation = simulation;

    if (!isSimulationFirstToApply()) applySimulation();
  }

  async function dragend() {
    try {
      if (!isAppliedSimulation() || isInSimulationsToApply(draggingSimulation.id)) {
        NProgress.start();

        if (isSimulationFirstToApply()) {
          appliedSimulations = [draggingSimulation];
        }

        await compareProjections();
        simulatedProjections = simulatedProjections.filter((sp) => sp.id !== draggingSimulation.id);
      }
    } catch (e) {
      console.error(`${e.name}: ${e.message}`);
      notyf_top_right.error(
        "Something went wrong. If the error persists, please do not hesitate to reach out for support using the chat button at the bottom right corner of the page."
      );
      removeFromApplied(draggingSimulationIndex);
    } finally {
      draggingSimulation = null;
      NProgress.done();
    }
  }

  function valueStyle(val) {
    if (isString(val)) {
      const sign = val.slice(0, 1);
      if (sign === "+") {
        return "changed-field__positive-value";
      } else if (sign === "-") {
        return "changed-field__negative-value";
      }
    }
    return "";
  }

  function handleSelectComparedTo(e) {
    payload =
      e.target.value === "currentProjection" ? initialPersonalProjection : $personalProjectionSerialized;
    compareProjections();
  }
</script>

<div class="comparison-layout">
  {#if simulatedProjections.length > 0 || appliedSimulations.length > 0}
    <section class="comparison-layout__pane">
      {#if appliedSimulations.length > 0}
        <div class="table-responsive">
          <table class="table table-hover styled-table">
            <thead>
              <tr>
                <th>Title</th>
                <th>
                  <div class="select-wrapper">
                    <select id="comparedTo" class="compared-to-selector" on:change={handleSelectComparedTo}>
                      {#each Object.entries(comparedToOptions) as [key, value]}
                        <option value={key}>{value}</option>
                      {/each}
                    </select>
                  </div>
                </th>
                {#each appliedSimulations as sp, i (sp.id)}
                  <th
                    draggable={!isDragging}
                    animate:flip={{ duration: 300 }}
                    on:dragstart={() => (draggingSimulation = sp)}
                    on:dragover|preventDefault={(e) => dragover(e, sp)}
                    on:dragend={dragend}
                    class:dragging={isDragging && draggingSimulation.id === sp.id}
                    class="simulation-name draggable"
                  >
                    <div>
                      <!-- This span needed for testing purposes -->
                      <span id={`testing-drag-to-${kebabCase(sp.name)}`} />
                      <span class:disable-events={isDragging}>{sp.name}</span>
                      <i
                        on:click={() => removeFromApplied(i)}
                        class="fa fa-remove"
                        class:disable-events={isDragging}
                      />
                    </div>
                  </th>
                {/each}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="section-title" colspan="1000">Values</td>
              </tr>
              {#if comparedValues.length > 0}
                {#each comparedValues as [fieldLocation, values]}
                  <tr in:fade>
                    <td class="changed-field__label">
                      {#each fieldLocation as locationPart}
                        <span>
                          <wbr />
                          {locationPart}
                        </span>
                      {/each}
                    </td>
                    {#each comparisonDisplayOrder as orderIndex}
                      <td class={`changed-field__value ${valueStyle(values[orderIndex] || "")}`}>
                        {isNil(values[orderIndex]) ? "" : values[orderIndex]}
                      </td>
                    {/each}
                  </tr>
                {/each}
              {:else}
                <tr>
                  <td colspan="1000"><span>All values are equal</span></td>
                </tr>
              {/if}
              <tr>
                <td class="section-title" colspan="1000">Results</td>
              </tr>
              {#if comparedResults.length > 0}
                {#each comparedResults as [label, values]}
                  <tr in:fade>
                    <td>{label}</td>
                    {#each comparisonDisplayOrder as orderIndex}
                      <td class={`changed-field__value ${valueStyle(values[orderIndex] || "")}`}>
                        {isNil(values[orderIndex]) ? "" : values[orderIndex]}
                      </td>
                    {/each}
                  </tr>
                {/each}
              {:else}
                <tr>
                  <td colspan="1000"><span>All results are equal</span></td>
                </tr>
              {/if}
            </tbody>
          </table>
        </div>
      {:else}
        <div in:fade={{ duration: 300 }} on:dragover|preventDefault on:drop|preventDefault class="visual">
          <div class="dropzone__container">
            <div class="dropzone">
              <div class="dropzone__message">
                <h1>Drop saved simulation here</h1>
                <p>You can compare one or more simulations by dropping them into comparison area</p>
              </div>
            </div>
          </div>
        </div>
      {/if}
    </section>

    <div class="simulations-list">
      <h2 class="simulations-list__header">Simulations</h2>
      {#if simulatedProjections.length > 0}
        <ul class="list-group">
          {#each simulatedProjections as sp}
            <li
              transition:fade={{ duration: 300 }}
              id={sp.id}
              draggable={!isDragging}
              on:dragstart={() => dragstart(sp)}
              on:dragend={dragend}
              class="list-group-item draggable"
              class:dragging={isDragging && draggingSimulation.id === sp.id}
            >
              <span>{sp.name}</span>
            </li>
          {/each}
        </ul>
      {:else}
        <p class="simulations-list_blank text-center">No simulations left to compare</p>
      {/if}
    </div>
  {:else}
    <div in:fade={{ duration: 300 }} class="no-simulations__notification">
      You need to have saved simulations to compare against
    </div>
  {/if}
</div>

<style lang="scss">
  .comparison-layout {
    display: flex;

    &__pane {
      width: 100%;
      height: calc(100vh - 116px);
      overflow-y: auto;
    }
  }

  table th + th,
  table td + td {
    border-left: 1px solid #ddd;
  }

  .simulations-list {
    width: 400px;
    background: #f6f8f8;
    border-left: 1px solid #dce0e6;
  }

  .simulations-list__header {
    padding: 12px;
    margin-bottom: 0;
  }

  .draggable {
    user-select: none;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
  }

  .draggable.dragging {
    opacity: 0.5;
    transition: opacity 0.2s;
  }

  li.list-group-item {
    padding: 5px 10px;
    padding: 15px;
    border-radius: 0;
    border-left: none;
    border-right: none;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .table td,
  .table th {
    width: 1%;
  }

  .styled-table {
    border-collapse: collapse;
    font-size: 0.9em;
    white-space: nowrap;
  }

  .simulation-name {
    cursor: grab;
  }

  .simulation-name div {
    display: flex;
    justify-content: space-between;
  }

  .disable-events {
    pointer-events: none;
  }

  i.fa.fa-remove {
    opacity: 0;
    cursor: pointer;
    padding-left: 7px;
    font-size: 13px;
    transition: opacity 0.2s;
  }

  .simulation-name:hover div i.fa.fa-remove {
    opacity: 1;
  }

  .styled-table thead tr th {
    background-color: #452161;
    color: #ffffff;
    text-align: left;
  }

  .styled-table th,
  .styled-table td {
    padding: 12px 15px;
  }

  .styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }

  .changed-field__label {
    vertical-align: middle;
  }

  .changed-field__label span {
    &::after {
      content: "\f178";
      font-family: FontAwesome;
      margin: 0 10px;
    }
  }

  .changed-field__label span:last-of-type {
    &::after {
      content: "";
    }
  }

  .section-title {
    font-size: 1.5em;
  }

  .changed-field__negative-value {
    color: #ff0000;
  }

  .changed-field__positive-value {
    color: #01a501;
  }

  .changed-field__value {
    white-space: normal;
    vertical-align: middle;
    word-break: break-word;
  }

  .no-simulations__notification {
    display: flex;
    min-height: 100vh;
    width: 100%;
    justify-content: center;
    margin-top: 50px;
    font-size: 1.3em;
  }

  .visual {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 16rem;
  }

  .dropzone__container {
    border-radius: 2.5rem;
    border: 3px solid white;
    background: white;
    height: 21.875rem;
    width: 100%;
    max-width: 21.875rem;
    margin: auto;
    box-shadow: 0 0.625rem 1.25rem #0000001a;
  }

  .dropzone {
    height: 100%;
    width: 100%;
    border-radius: 2.5rem;
    padding: 1rem;
    border-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
  }

  .dropzone__message {
    text-align: center;
  }

  .simulations-list_blank {
    margin: 15px;
    font-size: 15px;
  }

  select.compared-to-selector {
    width: 100%;
    border: none;
    background: transparent;
    cursor: pointer;
    outline: none;
    box-shadow: none;
    min-width: 160px;
    position: relative;
    z-index: 100;
    padding-right: 20px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .select-wrapper::after {
    content: "\f107";
    font-family: "FontAwesome";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: white;
  }

  .select-wrapper select {
    font-weight: bold;
    color: white;
  }
</style>
