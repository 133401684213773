<script>
  import { sum, at, concat, isNil, isNumber } from "lodash";
  import numeral from "numeral";
  import { buildNewRentalRealEstate } from "../Models";
  import { personalProjection, personalProjectionValidation } from "../Store";
  import CheckboxField from "../fields/CheckboxField.svelte";
  import NumericField from "../fields/NumericField.svelte";
  import TextField from "../fields/TextField.svelte";

  export let serverCalculatedParameters;
  export let statusNotice = null;

  let currentTab = 0;

  $: currentRREIncome =
    $personalProjection.rental_real_estate.length > 0
      ? $personalProjection.rental_real_estate[currentTab].income
      : 0;
  $: currentRREExpenses =
    $personalProjection.rental_real_estate.length > 0
      ? sum(
          at($personalProjection.rental_real_estate[currentTab], [
            "advertising",
            "auto_expenses",
            "cleaning",
            "commissions",
            "depreciation",
            "insurance",
            "legal_expenses",
            "management_fees",
            "mortage_interest",
            "repairs",
            "supplies",
            "taxes",
            "utilities",
            "other"
          ])
        )
      : 0;
  $: currentRRETotal = currentRREIncome - currentRREExpenses;

  function removeRentalRealEstate(idx) {
    personalProjection.update((pp) => {
      currentTab = 0;
      return {
        ...pp,
        rental_real_estate: pp.rental_real_estate.filter((_, i) => i != idx)
      };
    });
  }

  function addRentalRealEstate() {
    personalProjection.update((pp) => {
      const newRentalRealEstate = concat(pp.rental_real_estate, buildNewRentalRealEstate());
      currentTab = newRentalRealEstate.length - 1;
      return { ...pp, rental_real_estate: newRentalRealEstate };
    });
  }

  function fieldHasError(set, tab, field) {
    return set.has(`rental_real_estate[${tab}].${field}`);
  }
</script>

<div class="">
  <div class="personal-projection-form-content__section-header">
    <h2 class="personal-projection-form-content__section-name">Rental Real Estate</h2>
    {#if !isNil(statusNotice)}
      <small
        class="personal-projection-form-content__section-header-status font-weight-light col-lg-3 text-right text-muted"
      >
        {statusNotice}
      </small>
    {/if}
  </div>
  <div>
    <div class="personal-projection-form-content__fields">
      <CheckboxField
        key="rental_real_estate_professional"
        bind:value={$personalProjection.rental_real_estate_professional}
        title="I am a rental real estate professional"
      />
    </div>
  </div>
  <div id="section-4">
    <ul role="tablist" class="nav nav-tabs">
      {#each $personalProjection.rental_real_estate as tab, i}
        <li role="presentation" class="tab__wrapper nav-item">
          <a
            class="nav-link"
            class:active={currentTab === i}
            id="section-2-tab-0"
            role="tab"
            aria-controls="section-2-pane-0"
            aria-selected="true"
            href="#"
            on:click={() => (currentTab = i)}
          >
            <div class="tab__number-circle">{i + 1}</div>
            &nbsp;{tab.name}&nbsp;
            <span>
              <button
                type="button"
                class="btn btn-custom2 btn-circle-sm float-right"
                aria-label="Remove Employer Name"
                on:click={() => removeRentalRealEstate(i)}
              >
                <i class="fa fa-remove" />
              </button>
            </span>
          </a>
        </li>
      {/each}
      {#if $personalProjection.rental_real_estate.length < 8}
        <li role="presentation" class="tab__wrapper nav-item">
          <a
            class="nav-link"
            id="section-2-tab-2"
            role="tab"
            aria-controls="section-2-pane-2"
            tabindex="-1"
            aria-selected="false"
            href="#"
            on:click={addRentalRealEstate}
          >
            <span>
              &nbsp;&nbsp;
              <span>
                <button type="button" class="btn btn-custom btn-xs" aria-label="Add Rental Real Estate">
                  <span>Add</span>
                  <i class="fa fa-plus" />
                </button>
              </span>
            </span>
          </a>
        </li>
      {/if}
    </ul>
    <div class="tab-content">
      {#if $personalProjection.rental_real_estate.length > 0}
        <div
          id="section-4-pane-0"
          aria-labelledby="section-4-tab-0"
          role="tabpanel"
          aria-hidden="false"
          class="fade tab tab-pane active show"
        >
          <br />
          <br />
          <div>
            <div class="personal-projection-form-content__fields">
              <TextField
                key={`name_${currentTab}`}
                visitKey={`rental_real_estate[${currentTab}].name`}
                tooltip="Enter the property name"
                bind:value={$personalProjection.rental_real_estate[currentTab].name}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "name")}
              />
              <NumericField
                key={`income_${currentTab}`}
                visitKey={`rental_real_estate[${currentTab}].income`}
                bind:value={$personalProjection.rental_real_estate[currentTab].income}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "income")}
                inputAddon="$"
              />
            </div>
            <div class="personal-projection-form-content__fields">
              <NumericField
                key={`advertising_${currentTab}`}
                visitKey={`rental_real_estate[${currentTab}].advertising`}
                bind:value={$personalProjection.rental_real_estate[currentTab].advertising}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "advertising")}
                inputAddon="$"
              />
              <NumericField
                key={`auto_expenses_${currentTab}`}
                visitKey={`rental_real_estate[${currentTab}].auto_expenses`}
                bind:value={$personalProjection.rental_real_estate[currentTab].auto_expenses}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "auto_expenses")}
                inputAddon="$"
              />
              <NumericField
                key={`cleaning_${currentTab}`}
                visitKey={`rental_real_estate[${currentTab}].cleaning`}
                bind:value={$personalProjection.rental_real_estate[currentTab].cleaning}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "cleaning")}
                inputAddon="$"
              />
              <NumericField
                key={`commissions_${currentTab}`}
                visitKey={`rental_real_estate[${currentTab}].commissions`}
                bind:value={$personalProjection.rental_real_estate[currentTab].commissions}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "commissions")}
                inputAddon="$"
              />
              <NumericField
                key={`depreciation_${currentTab}`}
                visitKey={`rental_real_estate[${currentTab}].depreciation`}
                bind:value={$personalProjection.rental_real_estate[currentTab].depreciation}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "depreciation")}
                inputAddon="$"
              />
              <NumericField
                key={`insurance_${currentTab}`}
                visitKey={`rental_real_estate[${currentTab}].insurance`}
                bind:value={$personalProjection.rental_real_estate[currentTab].insurance}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "insurance")}
                inputAddon="$"
              />
              <NumericField
                key={`legal_expenses_${currentTab}`}
                visitKey={`rental_real_estate[${currentTab}].legal_expenses`}
                bind:value={$personalProjection.rental_real_estate[currentTab].legal_expenses}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "legal_expenses")}
                inputAddon="$"
              />
              <NumericField
                key={`management_fees_${currentTab}`}
                visitKey={`rental_real_estate[${currentTab}].management_fees`}
                bind:value={$personalProjection.rental_real_estate[currentTab].management_fees}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "management_fees")}
                inputAddon="$"
              />
              <NumericField
                key={`mortage_interest_${currentTab}`}
                visitKey={`rental_real_estate[${currentTab}].mortage_interest`}
                bind:value={$personalProjection.rental_real_estate[currentTab].mortage_interest}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "mortage_interest")}
                inputAddon="$"
              />
              <NumericField
                key={`repairs_${currentTab}`}
                visitKey={`rental_real_estate[${currentTab}].repairs`}
                bind:value={$personalProjection.rental_real_estate[currentTab].repairs}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "repairs")}
                inputAddon="$"
              />
              <NumericField
                key={`supplies_${currentTab}`}
                visitKey={`rental_real_estate[${currentTab}].supplies`}
                bind:value={$personalProjection.rental_real_estate[currentTab].supplies}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "supplies")}
                inputAddon="$"
              />
              <NumericField
                key={`taxes_${currentTab}`}
                visitKey={`rental_real_estate[${currentTab}].taxes`}
                bind:value={$personalProjection.rental_real_estate[currentTab].taxes}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "taxes")}
                inputAddon="$"
              />
              <NumericField
                key={`utilities_${currentTab}`}
                visitKey={`rental_real_estate[${currentTab}].utilities`}
                bind:value={$personalProjection.rental_real_estate[currentTab].utilities}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "utilities")}
                inputAddon="$"
              />
              <NumericField
                key={`other_${currentTab}`}
                visitKey={`rental_real_estate[${currentTab}].other`}
                bind:value={$personalProjection.rental_real_estate[currentTab].other}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "other")}
                inputAddon="$"
              />
            </div>
            <div class="personal-projection-form-content__fields">
              <div class="personal-projection-form-content__column">
                <div class="form-group tpp-form-group">
                  <label
                    id="current_rre_income"
                    class="col-form-label tpp-form-label text-success tpp-form-total"
                  >
                    <span>Income</span>
                  </label>
                  <div class="input-group">
                    <div
                      aria-labelledby="current_rre_income"
                      class="personal-projection-form-calculation-result-field text-success tpp-form-total"
                    >
                      {numeral(currentRREIncome).format("$0,0[.]00")}
                    </div>
                  </div>
                </div>
              </div>
              <div class="personal-projection-form-content__column">
                <div class="form-group tpp-form-group">
                  <label
                    id="current_rre_expenses"
                    class="col-form-label tpp-form-label text-danger tpp-form-total"
                  >
                    <span>Expenses</span>
                  </label>
                  <div class="input-group">
                    <div
                      aria-labelledby="current_rre_expenses"
                      class="personal-projection-form-calculation-result-field text-danger tpp-form-total"
                    >
                      {numeral(currentRREExpenses).format("$0,0[.]00")}
                    </div>
                  </div>
                </div>
              </div>

              <div class="personal-projection-form-content__column">
                <div class="form-group tpp-form-group">
                  <label
                    id="current_rre_total"
                    class="col-form-label tpp-form-label tpp-form-total"
                    class:text-success={currentRRETotal >= 0.0}
                    class:text-danger={currentRRETotal < 0.0}
                  >
                    <span>Total</span>
                  </label>
                  <div class="input-group">
                    <div
                      aria-labelledby="current_rre_total"
                      class="personal-projection-form-calculation-result-field tpp-form-total"
                      class:text-success={currentRRETotal >= 0.0}
                      class:text-danger={currentRRETotal < 0.0}
                    >
                      {numeral(currentRRETotal).format("$0,0[.]00")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div
          id="section-4-pane-1"
          aria-labelledby="section-4-tab-1"
          role="tabpanel"
          aria-hidden="true"
          class="fade tab tab-pane"
        >
          <br />
        </div>
      {/if}
    </div>
  </div>
  <div>
    <div class="personal-projection-form-content__fields">
      <div class="personal-projection-form-content__column">
        <div class="form-group tpp-form-group">
          <label
            id="rental_real_estate_income"
            class="col-form-label tpp-form-label text-success personal-projection-form-calculation-result-field results__row-title text-uppercase"
          >
            <span>Total Income</span>
          </label>
          <div class="input-group">
            {#if isNumber(serverCalculatedParameters.rental_real_estate_income)}
              <div
                aria-labelledby="rental_real_estate_income"
                class="personal-projection-form-calculation-result-field text-success personal-projection-form-calculation-result-field results__row-title text-uppercase"
              >
                {numeral(serverCalculatedParameters.rental_real_estate_income).format("$0,0[.]00")}
              </div>
            {:else}
              <div class="personal-projection-form-calculation-result-field__loading" />
            {/if}
          </div>
        </div>
      </div>
      <div class="personal-projection-form-content__column">
        <div class="form-group tpp-form-group">
          <label
            id="rental_real_estate_expenses"
            class="col-form-label tpp-form-label text-danger personal-projection-form-calculation-result-field results__row-title text-uppercase"
          >
            <span>Total Expenses</span>
          </label>
          <div class="input-group">
            {#if isNumber(serverCalculatedParameters.rental_real_estate_expenses)}
              <div
                aria-labelledby="rental_real_estate_expenses"
                class="personal-projection-form-calculation-result-field text-danger personal-projection-form-calculation-result-field results__row-title text-uppercase"
              >
                {numeral(serverCalculatedParameters.rental_real_estate_expenses).format("$0,0[.]00")}
              </div>
            {:else}
              <div class="personal-projection-form-calculation-result-field__loading" />
            {/if}
          </div>
        </div>
      </div>
      <div class="personal-projection-form-content__column">
        <div class="form-group tpp-form-group">
          <label
            id="rental_real_estate_total"
            class="col-form-label tpp-form-label text-primary personal-projection-form-calculation-result-field results__row-title text-uppercase"
          >
            <span>Grand Total</span>
          </label>
          <div class="input-group">
            {#if isNumber(serverCalculatedParameters.rental_real_estate_total)}
              <div
                aria-labelledby="rental_real_estate_total"
                class="personal-projection-form-calculation-result-field text-primary personal-projection-form-calculation-result-field results__row-title text-uppercase"
                class:text-success={serverCalculatedParameters.rental_real_estate_total >= 0.0}
                class:text-danger={serverCalculatedParameters.rental_real_estate_total < 0.0}
              >
                {numeral(serverCalculatedParameters.rental_real_estate_total).format("$0,0[.]00")}
              </div>
            {:else}
              <div class="personal-projection-form-calculation-result-field__loading" />
            {/if}
          </div>
        </div>
      </div>
      <div class="personal-projection-form-content__column">
        <div class="form-group tpp-form-group">
          <label
            id="per_rental_income"
            class="col-form-label tpp-form-label text-info personal-projection-form-calculation-result-field results__row-title text-uppercase"
          >
            <span>Taxable Profit</span>
          </label>
          <div class="input-group">
            {#if isNumber(serverCalculatedParameters.per_rental_income)}
              <div
                aria-labelledby="per_rental_income"
                class="personal-projection-form-calculation-result-field text-info personal-projection-form-calculation-result-field results__row-title text-uppercase"
                class:text-success={serverCalculatedParameters.per_rental_income >= 0.0}
                class:text-danger={serverCalculatedParameters.per_rental_income < 0.0}
              >
                {numeral(serverCalculatedParameters.per_rental_income).format("$0,0[.]00")}
              </div>
            {:else}
              <div class="personal-projection-form-calculation-result-field__loading" />
            {/if}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
