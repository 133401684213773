import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().trim().required()
});

export const credentialsValidation = (values) => {
  try {
    schema.validateSync(values, { abortEarly: false });
    return new Set();
  } catch (err) {
    return new Set(err.inner.map((err) => err.path));
  }
};
