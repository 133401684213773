<script>
  import { notyf_top_right } from "misc/notyf_top_right";
  import { fade } from "svelte/transition";

  export let id;
  export let name;
  export let handleUpdate;
  export let handleDelete;
  export let handleApply;
  export let lastApplied;

  let isEditing = false;
  let memoizedName = name;

  function handleRenameClick() {
    isEditing = true;
  }

  async function handleSave() {
    try {
      const response = await handleUpdate(id, { name });
      if (response && response.result === "success") {
        memoizedName = name;
      } else {
        name = memoizedName;
      }
    } catch (error) {
      if (error.name === "AbortError") return;
      notyf_top_right.error(error.message);
    } finally {
      isEditing = false;
    }
  }

  function handleCancel() {
    name = memoizedName;
    isEditing = false;
  }

  function _handleDelete() {
    handleDelete(id);
  }

  function _handleApply() {
    handleApply(id);
  }

  async function _handleUpdate() {
    try {
      await handleUpdate(id);
    } catch (error) {
      if (error.name === "AbortError") return;
      notyf_top_right.error(error.message);
    }
  }
</script>

<template>
  <div class="row">
    {#if isEditing}
      <div class="col-sm-7">
        <input
          name="simulated_projection"
          class="form-control"
          bind:value={name}
          on:keydown={(e) => e.key === "Enter" && handleSave()}
        />
      </div>
      <div in:fade={{ duration: 300 }} class="col-sm-5 buttons">
        <button
          class="btn button-save"
          disabled={memoizedName === name || name === ""}
          on:click|preventDefault={handleSave}
        >
          Save
        </button>
        <button class="btn button-cancel" on:click|preventDefault={handleCancel}>Cancel</button>
      </div>
    {:else}
      <div class="col-sm-5">
        <div class="form-control">
          {name}
          {#if lastApplied}
            <span class="active-item">Last Applied</span>
          {/if}
        </div>
      </div>
      <div in:fade={{ duration: 300 }} class="col-sm-7 buttons">
        {#if handleApply}
          {#if lastApplied}
            <button class="btn button-update" on:click|preventDefault={_handleUpdate}>Update</button>
          {:else}
            <button class="btn button-apply" on:click|preventDefault={_handleApply}>Apply</button>
          {/if}
        {/if}
        <button class="btn button-rename" on:click|preventDefault={handleRenameClick}>Rename</button>
        <button class="btn button-delete" on:click|preventDefault={_handleDelete}>Delete</button>
      </div>
    {/if}
  </div>
</template>

<style lang="scss">
  .buttons {
    display: flex;
    justify-content: flex-end;
    align-self: center;
    text-align: right;
  }

  button {
    background: transparent;
    font-family: "Montserrat";
    font-size: 0.8rem;
    padding: 0 0 0 10px;
    margin-bottom: 0;

    &:before {
      margin-right: 5px;
    }
  }

  .button-rename,
  .button-save,
  .button-cancel,
  .button-apply,
  .button-update {
    color: #1b83ff;

    &:hover,
    &:active,
    &:focus {
      color: #0010ff;
    }
  }

  .button-rename {
    &:before {
      content: "\f040";
      font-family: FontAwesome;
    }
  }

  .button-save {
    &:before {
      content: "\f0c7";
      font-family: FontAwesome;
    }

    &:disabled {
      background: transparent;
      opacity: 0.6;
    }
  }

  .button-cancel {
    &:before {
      content: "\f00d";
      font-family: FontAwesome;
    }
  }

  .button-delete {
    color: #c90c0c;

    &:hover,
    &:active,
    &:focus {
      color: #ff0000;
    }

    &:before {
      content: "\f014";
      font-family: FontAwesome;
    }
  }

  .button-apply {
    &:before {
      content: "\f058";
      font-family: FontAwesome;
    }
  }

  .button-update {
    &:before {
      content: "\f021";
      font-family: FontAwesome;
    }
  }

  input {
    border: 1px solid #ccc;
    font-size: 16px;
    border-radius: 4px;
  }

  div.form-control {
    font-size: 16px;
    border: 1px solid transparent;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    word-break: break-word;
    height: 100%;
    min-height: 0px;
  }

  input.form-control {
    height: 40px;
  }

  .active-item {
    font-family: "Montserrat";
    font-size: 0.7rem;
    margin-bottom: 0;
    color: darkgrey;
    margin-top: 3px;
  }
</style>
