<script>
  import camelCase from "lodash/fp/camelCase";
  import RefreshParamsButton from "./buttons/RefreshParamsButton.svelte";
  import { flatSpecTree } from "../report/stores/specificationTree";
  import DirtyParamTooltip from "../report/helpers/DirtyParamTooltip.svelte";
  import { searchMatches } from "./stores/searchStore";
  import { isNil } from "lodash";
  import { pdfBookmarksStore } from "./stores/pdfBookmarksStore.js";

  export let hidden;
  export let checked;
  export let classNames = "";
  export let scrollToId;
  export let refreshReportParams;
  export let specTreeNodeId;

  $: reportOption = $flatSpecTree && specTreeNodeId && $flatSpecTree[specTreeNodeId];
  $: reportTitleId = reportOption && specTreeNodeId;

  function scrollTo() {
    document.getElementById(scrollToId).scrollIntoView({ behavior: "smooth" });
  }
</script>

<template lang="pug">
  div({hidden})
    .card-header.report_option(role="tab")
      +if('!isNil(checked)')
        +if('scrollToId && $pdfBookmarksStore.has(scrollToId)')
          div
            span(class="scroll-to", on:click="{scrollTo}")
          +elseif('scrollToId')
            div
              span(class="scroll-to", disabled)
        .report_option__checkbox
          input(type="checkbox", bind:checked)
      a(
        class="report_option__dropdown {classNames}",
        class:collapsed="{!$searchMatches.matched}",
        role="button",
        data-toggle="collapse",
        data-parent="#accordion",
        href="#{reportTitleId}",
        aria-expanded="{!!$searchMatches.matched}")
        h3.card-title.report_option__header.mb-0 {reportOption.title}
        DirtyParamTooltip(param="{specTreeNodeId}")
        +if('reportOption.updatable')
          RefreshParamsButton({refreshReportParams}, {specTreeNodeId})
    div(id="{reportTitleId}",
      class="panel-collapse collapse",
      class:show="{$searchMatches.matched}",
      role="tabpanel",
      aria-expanded="{!!$searchMatches.matched}")
      .card-body
        slot
</template>

<style lang="scss">
  input[type="checkbox"] {
    margin: 5px 0 0;
  }

  .card-header {
    border-bottom: 0;
  }

  .card-body {
    border-top: 1px solid #ddd;
  }
</style>
