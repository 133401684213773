<script>
  import Inputmask from "inputmask";
  import { onMount } from "svelte";
  import RefreshParamsButton from "../buttons/RefreshParamsButton.svelte";
  import { flatSpecTree } from "../stores/specificationTree";
  import DirtyParamTooltip from "../helpers/DirtyParamTooltip.svelte";

  export let value;
  export let hidden;
  export let refreshReportParams;
  export let specTreeNodeId;

  let formattedValue = value;
  let currencyInput;
  const id = Math.random().toString(16).substr(2, 8);

  $: reportOption = $flatSpecTree && specTreeNodeId && $flatSpecTree[specTreeNodeId];

  onMount(() => {
    Inputmask({
      alias: "currency",
      rightAlign: false,
      allowMinus: true,
      allowPlus: false,
      digitsOptional: true,
      autoUnmask: true,
      clearMaskOnLostFocus: false,
      clearIncomplete: true,
      placeholder: "",
      onUnMask: function (maskedValue, unmaskedValue) {
        const value = parseFloat(unmaskedValue);
        return isNaN(value) ? "" : value;
      }
    }).mask(currencyInput);
  });
</script>

<template lang="pug">
  .row({hidden})
    .col-sm-12
      .form-group
        label(for="{id}", class="report_option__title") {reportOption.title}
        DirtyParamTooltip(param="{specTreeNodeId}")
        +if('reportOption.updatable')
          RefreshParamsButton({refreshReportParams}, {specTreeNodeId})
        .input-group
          .input-group-prepend
            +if('typeof value === "undefined" || value === null || value === ""')
              | N/A
              +else
                | $
          input.form-control(id="{id}", bind:this="{currencyInput}", bind:value, class="form-control")
</template>
