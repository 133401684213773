<script>
  export let href;
  export let title;
  export let isActive = false;
  export let isDisabled = false;
  export let onClick;
</script>

{#if href.startsWith("#")}
  <a
    {href}
    class="tpp-tabs__link {isActive ? 'tpp-tabs__link_active' : ''} {isDisabled
      ? 'tpp-tabs__link_disabled'
      : ''}"
    on:click|preventDefault={onClick}
  >
    {#if isDisabled}
      <i class="tpp-tabs__link-icon fa fa-lock" />
    {/if}
    {title}
  </a>
{:else}
  <a {href} class="tpp-tabs__link">{title}</a>
{/if}
