<script>
  import { reportStore } from "../stores/reportStore";
  import { specificationTree } from "../stores/specificationTree";
  import SelectInputField from "../inputs/SelectInput.svelte";
  import Dropdown from "../Dropdown.svelte";
  import { searchMatches } from "../stores/searchStore";

  export let refreshReportParams;

  const STRATEGY_PICKS = {
    section179: "Section 179",
    expense_acceleration: "Expense Acceleration",
    missing_deductions: "Missing Deductions",
    income_deferment: "Income Deferment",
    sep_ira: "SEP IRA",
    fourteen_day_home_rental: "14 Day Home Rental",
    employing_your_children: "Employing Your Children",
    plan_401k: "Plan 401K",
    traditional_ira: "Traditional IRA",
    business_entity: "Business Entity",
    health_savings: "Health Savings",
    defined_benefit: "Defined Benefit"
  };
</script>

<template lang="pug">
  Dropdown(
    {refreshReportParams},
    specTreeNodeId="top_3_strategy_picks_enable",
    bind:checked="{$reportStore.top_3_strategy_picks_enable}",
    hidden="{$searchMatches.isHidden('Top 3 Strategy Picks')}",
    scrollToId="pdfTop3StrategyPicks"
  )
    SelectInputField(
      specTreeNodeId="top_3_strategy_picks_first_type",
      bind:value="{$reportStore.top_3_strategy_picks_first_type}",
      title="Strategy Pick #1",
      options="{STRATEGY_PICKS}",
      hidden="{$searchMatches.isHidden('Strategy Pick #1')}"
    )
    SelectInputField(
      specTreeNodeId="top_3_strategy_picks_second_type",
      bind:value="{$reportStore.top_3_strategy_picks_second_type}",
      title="Strategy Pick #2",
      options="{STRATEGY_PICKS}",
      hidden="{$searchMatches.isHidden('Strategy Pick #2')}"
    )
    SelectInputField(
      specTreeNodeId="top_3_strategy_picks_third_type",
      bind:value="{$reportStore.top_3_strategy_picks_third_type}",
      title="Strategy Pick #3",
      options="{STRATEGY_PICKS}",
      hidden="{$searchMatches.isHidden('Strategy Pick #3')}"
    )
</template>
