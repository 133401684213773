<script>
  import { onMount } from "svelte";
  import _ from "underscore";
  import { notyf_top_right } from "misc/notyf_top_right";
  import { line, curveCatmullRom, scaleLinear, extent } from "d3";
  import accounting from "accounting";
  import BaseSyncRepository from "repositories/BaseSyncRepository.js";
  import PersonalTable from "components/common/PersonalTable.svelte";
  import Axis from "components/common/Axis.svelte";

  export let qbidSalaryMaximizerEndpoint;
  export let personalProjection = {};
  export let taxesByDelta = [];
  export let planningDescription = null;
  export let conceptResources = null;

  let recalculating = true;

  let chart, extentX, xScale, extentY, yScale, path;
  let tooltipValue, tooltipVisible, tooltipStyle;

  let pannelWidth, width;
  const height = 440;
  const margin = { top: 5, bottom: 60, left: 100, right: 20 };
  const axisMargin = { bottom: 50, left: 90 };

  // x axis labels string formatting
  let label = (v) => formatMoney(v);

  $: {
    if (pannelWidth) width = pannelWidth - 30;
    // scales
    extentX = extent(taxesByDelta, (d) => +d.delta);
    xScale = scaleLinear()
      .domain(extentX)
      .range([margin.left, width - margin.right]);

    extentY = extent(taxesByDelta, (d) => +d.tax);
    yScale = scaleLinear()
      .domain(extentY)
      .range([height - margin.bottom, margin.top]);

    path = line()
      .x((d) => xScale(+d.delta))
      .y((d) => yScale(+d.tax))
      .curve(curveCatmullRom.alpha(0.5));
  }

  const baseSyncRepository = new BaseSyncRepository(qbidSalaryMaximizerEndpoint);

  const recalculate = _.debounce(async () => {
    recalculating = true;
    try {
      const data = await baseSyncRepository.baseQuery(baseSyncRepository.baseEndpoint, {
        method: "POST",
        headers: baseSyncRepository.headers,
        body: JSON.stringify({
          qbid_salary_maximizer: {}
        })
      });

      personalProjection = data.personalProjection;
      taxesByDelta = data.taxesByDelta.map((e) => ({ delta: parseFloat(e.delta), tax: parseFloat(e.tax) }));
    } catch (error) {
      notyf_top_right.error(error.message);
    } finally {
      recalculating = false;
    }
  }, 1000);

  function formatMoney(val) {
    return accounting.formatMoney(val, {
      symbol: "$",
      precision: 0,
      format: {
        pos: "%s%v",
        neg: "%s(%v)",
        zero: "%s%v"
      }
    });
  }

  function mousemove(e, d) {
    tooltipStyle = `left: ${e.offsetX - 100}px; top: ${e.offsetY + 30}px;`;
    tooltipValue = `<b>Change in Wages:</b> ${label(d.delta)}<br><b>Federal Tax:</b> ${label(d.tax)}`;
  }

  onMount(recalculate);
</script>

<section id="main-content">
  <div class="row">
    <div class="col-lg-6 d-flex flex-column flex-grow-1">
      {#if planningDescription}
        <div class="card mb-3 flex-align">
          <div class="card-body">
            <div class="planning-description__left">
              {@html planningDescription}
            </div>
          </div>
        </div>
      {/if}

      {#if conceptResources}
        <div class="card mb-3 flex-align planning-module-placeholder">
          <div class="card-header">
            <h3 class="card-title mb-0">Concept Resources</h3>
          </div>
          <div class="card-body planning-module-placeholder__calculation-body">
            {@html conceptResources}
          </div>
        </div>
      {/if}
    </div>

    <div class="col-lg-6 d-flex flex-column flex-grow-1">
      <div class="qbid-salary-maximizer__spinner" hidden={!recalculating}>
        <span class="qbid-salary-maximizer__spinner-bounce1" />
        <span class="qbid-salary-maximizer__spinner-bounce2" />
        <span class="qbid-salary-maximizer__spinner-bounce3" />
      </div>
      <div class="card mb-3 flex-align" class:qbid-salary-maximizer__blur={recalculating}>
        <div class="card-header">
          <h3 class="card-title mb-0">Per Company Payroll Adjustments</h3>
        </div>
        <div class="card-body">
          <div class="planning-description__right">
            {#if recalculating}
              <center><i>Hold tight, we're optimizing your taxes...</i></center>
            {:else if _.isEmpty(personalProjection.changeInWagesByCompanies)}
              <center><i>No adjustments required</i></center>
            {:else}
              <table class="table table____no-top-border">
                <tbody>
                  {#each Object.entries(personalProjection.changeInWagesByCompanies) as [name, value]}
                    <tr>
                      <td>
                        <b>{name}</b>
                        :
                      </td>
                      <td>{formatMoney(value)}</td>
                    </tr>
                  {/each}
                </tbody>
              </table>
            {/if}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-sm-6 col-lg-6 col-xl-6 d-flex flex-column">
      <div class="qbid-salary-maximizer__spinner" hidden={!recalculating}>
        <span class="qbid-salary-maximizer__spinner-bounce1" />
        <span class="qbid-salary-maximizer__spinner-bounce2" />
        <span class="qbid-salary-maximizer__spinner-bounce3" />
      </div>
      <div class:qbid-salary-maximizer__blur={recalculating}>
        <div class="card mb-3 flex-align">
          <div class="card-header">
            <h3 class="card-title mb-0">Personal Summary</h3>
          </div>
          <div class="card-body d-flex flex-column h-100">
            <div class="js-personal-projection-body">
              <PersonalTable {personalProjection} />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-sm-6 col-lg-6 col-xl-6 d-flex flex-column">
      <div class="qbid-salary-maximizer__spinner" hidden={!recalculating}>
        <span class="qbid-salary-maximizer__spinner-bounce1" />
        <span class="qbid-salary-maximizer__spinner-bounce2" />
        <span class="qbid-salary-maximizer__spinner-bounce3" />
      </div>
      <div class:qbid-salary-maximizer__blur={recalculating}>
        <div class="card mb-3 flex-align">
          <div class="card-header">
            <h3 class="card-title mb-0">Federal Tax Due by Wages</h3>
          </div>
          <div
            bind:clientWidth={pannelWidth}
            class="card-body d-flex flex-column h-100 chart"
            style="--height: {height + 20}px;"
          >
            {#if width}
              <svg bind:this={chart} transform="translate({0}, {0})">
                <Axis
                  {width}
                  {height}
                  margin={axisMargin}
                  scale={xScale}
                  position="bottom"
                  {label}
                  caption="Total Salaries Adjustment"
                />
                <Axis
                  {width}
                  {height}
                  margin={axisMargin}
                  scale={yScale}
                  position="left"
                  {label}
                  caption="Total Tax"
                />
                <g>
                  <!-- line -->
                  <path d={path(taxesByDelta)} fill="none" stroke="steelblue" stroke-width="1.5" />
                </g>

                <!-- points -->
                {#each taxesByDelta as d}
                  <circle
                    bind:this={d.circle}
                    on:mouseover={(_) => (tooltipVisible = true)}
                    on:mousemove={(e) => mousemove(e, d)}
                    on:mouseleave={(_) => (tooltipVisible = false)}
                    cx={xScale(+d.delta)}
                    cy={yScale(+d.tax)}
                    r={3}
                    fill="white"
                    stroke="rebeccapurple"
                    stroke-width="1.5"
                  />
                {/each}
              </svg>

              <!-- tooltip -->
              <div
                class:tooltip__visible={tooltipVisible}
                class:tooltip__invisible={!tooltipVisible}
                class="tooltip"
                style={tooltipStyle}
              >
                {@html tooltipValue}
              </div>
            {/if}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
  svg {
    width: 100%;
    height: 100%;
  }
  .tick {
    font-size: 11px;
  }
  .chart {
    min-height: var(--height);
  }
  .table____no-top-border > thead > tr:first-child > td,
  .table____no-top-border > tbody > tr:first-child > td {
    border: none;
  }
  .tooltip {
    background-color: white;
    border: solid;
    border-width: 2px;
    border-radius: 5px;
    padding: 5px;
  }
  .tooltip__visible {
    opacity: 1;
  }
  .tooltip__invisible {
    opacity: 0;
  }
  .qbid-salary-maximizer__blur {
    filter: blur(1px) opacity(0.2);
  }
  .qbid-salary-maximizer__spinner {
    position: absolute;
    z-index: 100;
    width: 100%;
    top: 50%;
    text-align: center;
  }
  .qbid-salary-maximizer__spinner-bounce1,
  .qbid-salary-maximizer__spinner-bounce2,
  .qbid-salary-maximizer__spinner-bounce3 {
    width: 18px;
    height: 18px;
    background-color: #1d212a;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  .qbid-salary-maximizer__spinner-bounce1 {
    animation-delay: -0.32s;
  }
  .qbid-salary-maximizer__spinner-bounce2 {
    animation-delay: -0.16s;
  }
</style>
