<script>
  import { isNil } from "lodash";
  import TaxpayerSpouseNumericField from "../fields/TaxpayerSpouseNumericField.svelte";
  import TaxpayerSpouseNumericOverrideField from "../fields/TaxpayerSpouseNumericOverrideField.svelte";

  export let statusNotice = null;
  export let serverCalculatedParameters;
</script>

<div>
  <div class="personal-projection-form-content__section-header">
    <h2 class="personal-projection-form-content__section-name">Taxes</h2>
    {#if !isNil(statusNotice)}
      <small
        class="personal-projection-form-content__section-header-status font-weight-light col-lg-3 text-right text-muted"
      >
        {statusNotice}
      </small>
    {/if}
  </div>
  <div>
    <div class="personal-projection-form-content__fields">
      <TaxpayerSpouseNumericField key="fed_tax_previous_year" />
      <TaxpayerSpouseNumericField key="state_tax_previous_year" />
      <TaxpayerSpouseNumericField key="fed_tax_owed_other_taxes" />
      <TaxpayerSpouseNumericField key="fed_tax_owed_prior_year_amt_carryforward" />
      <TaxpayerSpouseNumericField key="fed_tax_owed_tax_credits" tooltip="Federal Tax Credits" />
      <TaxpayerSpouseNumericOverrideField
        {serverCalculatedParameters}
        key="fed_tax_owed_child_tax_credit"
        override_key="fed_tax_owed_child_tax_credit_override"
        override_guard_key="fed_tax_owed_child_tax_credit_override_guard"
        tooltipLabel="Child Tax Credits"
        tooltipInfo="Child tax credit value you can override"
      />
      <TaxpayerSpouseNumericOverrideField
        {serverCalculatedParameters}
        key="res_inc_itemized"
        override_key="res_inc_itemized_override"
        override_guard_key="res_inc_itemized_override_guard"
        tooltipLabel="Itemized Deductions"
        tooltipInfo="Itemized deductions value you can override"
      />
      <TaxpayerSpouseNumericOverrideField
        {serverCalculatedParameters}
        key="res_passthrough_deduction"
        override_key="res_passthrough_deduction_override"
        override_guard_key="res_passthrough_deduction_override_guard"
        tooltipLabel="Passthrough Deduction"
        tooltipInfo="Passthrough deduction value you can override"
      />
      <TaxpayerSpouseNumericOverrideField
        {serverCalculatedParameters}
        key="res_se_tax"
        override_key="res_se_tax_override"
        override_guard_key="res_se_tax_override_guard"
        tooltipLabel="SE Tax"
        tooltipInfo="SE tax value you can override"
      />
      <TaxpayerSpouseNumericOverrideField
        {serverCalculatedParameters}
        key="res_total_tax"
        override_key="res_total_tax_override"
        override_guard_key="res_total_tax_override_guard"
        tooltipLabel="Total Federal Tax"
        tooltipInfo="Total federal tax value you can override"
      />
      <TaxpayerSpouseNumericOverrideField
        {serverCalculatedParameters}
        key="res_state_tax_before_payments"
        override_key="res_state_tax_before_payments_override"
        override_guard_key="res_state_tax_before_payments_override_guard"
        tooltipLabel="Total State Tax"
        tooltipInfo="Total state tax value you can override"
      />
      <TaxpayerSpouseNumericOverrideField
        {serverCalculatedParameters}
        key="business_inc_ccorp_fed_tax"
        override_key="business_inc_ccorp_fed_tax_override"
        override_guard_key="business_inc_ccorp_fed_tax_override_guard"
        tooltipLabel="C Corp Federal Tax"
        tooltipInfo="C corp federal tax value you can override"
      />
      <TaxpayerSpouseNumericOverrideField
        {serverCalculatedParameters}
        key="business_inc_ccorp_state_tax"
        override_key="business_inc_ccorp_state_tax_override"
        override_guard_key="business_inc_ccorp_state_tax_override_guard"
        tooltipLabel="C Corp State Tax"
        tooltipInfo="C corp state tax value you can override"
      />
    </div>
  </div>
</div>
