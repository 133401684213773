<script>
  import { reportStore } from "../stores/reportStore";
  import Dropdown from "../Dropdown.svelte";
  import SelectInputField from "../inputs/SelectInput.svelte";
  import ColorPicker from "../inputs/ColorPicker.svelte";
  import { searchMatches } from "../stores/searchStore";
  import ScrollTo from "../helpers/ScrollTo.svelte";

  const options = {
    default: "Template 1 - Original",
    violet: "Template 2 - Minimalist",
    orange: "Template 3 - Transitional"
  };

  $: isVioletLayout = $reportStore.layout_name === "violet";
  $: isOrangeLayout = $reportStore.layout_name === "orange";
</script>

<template>
  <Dropdown specTreeNodeId="layout_params" hidden={$searchMatches.isHidden("Layout")}>
    <SelectInputField
      specTreeNodeId="layout_name"
      bind:value={$reportStore.layout_name}
      title="Name"
      {options}
      hidden={$searchMatches.isHidden("Name")}
    />

    <!-- Violet -->

    <ColorPicker
      specTreeNodeId="layout_violet_color_text_primary"
      bind:value={$reportStore.layout_violet_color_text_primary}
      defaultValue={"000000"}
      hidden={!isVioletLayout || $searchMatches.isHidden("Primary")}
    >
      <div slot="description">The primary text color of the report.</div>
    </ColorPicker>
    <ColorPicker
      specTreeNodeId="layout_violet_color_text_secondary"
      bind:value={$reportStore.layout_violet_color_text_secondary}
      defaultValue={"231f20"}
      hidden={!isVioletLayout || $searchMatches.isHidden("Secondary")}
    >
      <div slot="description">
        The color of the Max Deduction notice, located within the
        <ScrollTo bookmark="pdfStrategyDetails">Strategy Tax Saving Options</ScrollTo> section.
      </div>
    </ColorPicker>
    <ColorPicker
      specTreeNodeId="layout_violet_color_text_reverse"
      bind:value={$reportStore.layout_violet_color_text_reverse}
      defaultValue={"ffffff"}
      hidden={!isVioletLayout || $searchMatches.isHidden("Light")}
    >
      <div slot="description">The color of the text used on dark surfaces.</div>
    </ColorPicker>
    <ColorPicker
      specTreeNodeId="layout_violet_color_text_green"
      bind:value={$reportStore.layout_violet_color_text_green}
      defaultValue={"299154"}
      hidden={!isVioletLayout || $searchMatches.isHidden("Profit")}
    >
      <div slot="description">The color of the text for elements indicating profit.</div>
    </ColorPicker>
    <ColorPicker
      specTreeNodeId="layout_violet_color_text_green_light"
      bind:value={$reportStore.layout_violet_color_text_green_light}
      defaultValue={"85cf42"}
      hidden={!isVioletLayout || $searchMatches.isHidden("Profit (on dark background)")}
    >
      <div slot="description">
        The green color is chosen to ensure visibility on dark blocks. It is utilized in
        <ScrollTo bookmark="pdfTop3StrategyPicks">Top 3 Strategy Picks</ScrollTo> ("would save tax dollars").
      </div>
    </ColorPicker>
    <ColorPicker
      specTreeNodeId="layout_violet_color_text_red"
      bind:value={$reportStore.layout_violet_color_text_red}
      defaultValue={"bb2e19"}
      hidden={!isVioletLayout || $searchMatches.isHidden("Losses")}
    >
      <div slot="description">The color of the text for elements indicating losses.</div>
    </ColorPicker>
    <ColorPicker
      specTreeNodeId="layout_violet_color_bg_primary"
      bind:value={$reportStore.layout_violet_color_bg_primary}
      defaultValue={"291d56"}
      hidden={!isVioletLayout || $searchMatches.isHidden("Primary Background")}
    >
      <div slot="description">The primary background color of the report.</div>
    </ColorPicker>
    <ColorPicker
      specTreeNodeId="layout_violet_color_bg_secondary"
      bind:value={$reportStore.layout_violet_color_bg_secondary}
      defaultValue={"8b4da6"}
      hidden={!isVioletLayout || $searchMatches.isHidden("Secondary Background")}
    >
      <div slot="description">
        It is utilized in
        <ScrollTo bookmark="pdfTaxProjectionSummary">Tax Projection Summary</ScrollTo> (last page of the section)
        and
        <ScrollTo bookmark="pdfStrategyDetails">Strategy Details sections</ScrollTo> (what it is items color, ratings
        color, subheadings background).
      </div>
    </ColorPicker>
    <ColorPicker
      specTreeNodeId="layout_violet_color_bg_tertiary"
      bind:value={$reportStore.layout_violet_color_bg_tertiary}
      defaultValue={"a65892"}
      hidden={!isVioletLayout || $searchMatches.isHidden("Tertiary Background")}
    >
      <div slot="description">
        It is utilized in
        <ScrollTo bookmark="pdfTraditionalRothIra">Traditional and ROTH IRA</ScrollTo> ("Contributions" text) and
        <ScrollTo bookmark="pdfImportantTaxReturnDueDates">Important Tax Return Due Dates</ScrollTo> ("Personal
        Estimated Tax Payments" heading and table).
      </div>
    </ColorPicker>

    <!-- Orange -->

    <ColorPicker
      specTreeNodeId="layout_orange_color_text_primary"
      bind:value={$reportStore.layout_orange_color_text_primary}
      defaultValue={"000000"}
      hidden={!isOrangeLayout || $searchMatches.isHidden("Primary")}
    >
      <div slot="description">The primary text color of the report.</div>
    </ColorPicker>
    <ColorPicker
      specTreeNodeId="layout_orange_color_text_reverse"
      bind:value={$reportStore.layout_orange_color_text_reverse}
      defaultValue={"ffffff"}
      hidden={!isOrangeLayout || $searchMatches.isHidden("Light")}
    >
      <div slot="description">The color of the text used on dark surfaces.</div>
    </ColorPicker>
    <ColorPicker
      specTreeNodeId="layout_orange_color_text_green"
      bind:value={$reportStore.layout_orange_color_text_green}
      defaultValue={"299154"}
      hidden={!isOrangeLayout || $searchMatches.isHidden("Profit")}
    >
      <div slot="description">The color of the text for elements indicating profit.</div>
    </ColorPicker>
    <ColorPicker
      specTreeNodeId="layout_orange_color_text_green_light"
      bind:value={$reportStore.layout_orange_color_text_green_light}
      defaultValue={"177648"}
      hidden={!isOrangeLayout || $searchMatches.isHidden("Profit (on dark background)")}
    >
      <div slot="description">
        The green color is chosen to ensure visibility on dark blocks. It is utilized in
        <ScrollTo bookmark="pdfTop3StrategyPicks">Top 3 Strategy Picks</ScrollTo> ("would save tax dollars").
      </div>
    </ColorPicker>
    <ColorPicker
      specTreeNodeId="layout_orange_color_text_red"
      bind:value={$reportStore.layout_orange_color_text_red}
      defaultValue={"bb2e19"}
      hidden={!isOrangeLayout || $searchMatches.isHidden("Losses")}
    >
      <div slot="description">The color of the text for elements indicating losses.</div>
    </ColorPicker>
    <ColorPicker
      specTreeNodeId="layout_orange_color_bg_primary"
      bind:value={$reportStore.layout_orange_color_bg_primary}
      defaultValue={"f05d22"}
      hidden={!isOrangeLayout || $searchMatches.isHidden("Primary Background")}
    >
      <div slot="description">The primary background color of the report.</div>
    </ColorPicker>
    <ColorPicker
      specTreeNodeId="layout_orange_color_bg_secondary"
      bind:value={$reportStore.layout_orange_color_bg_secondary}
      defaultValue={"f3d362"}
      hidden={!isOrangeLayout || $searchMatches.isHidden("Secondary Background")}
    >
      <div slot="description">The secondary background color of the report.</div>
    </ColorPicker>
  </Dropdown>
</template>
