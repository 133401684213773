<script>
  import { tooltips } from "utils/tooltips";

  export let tooltipLabel;
  export let tooltipInfo;
  export let tooltipExtraClass;
</script>

<div class={`${tooltipExtraClass}`} use:tooltips={tooltipInfo}>
  <span>{tooltipLabel}</span>
  <i class="fa fa-info-circle ml-1" />
</div>
