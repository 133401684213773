<script>
  let emailValue = "";
  let passwordValue = "";
  export let loginPath = "";
  export let passRecoveryPath = "";
  export let authenticityToken = "";
  export let registrationPath = "";
</script>

<div>
  <h1>
    <b>
      Welcome
      <br />
      Back !
    </b>
  </h1>
  <div class="devise-form">
    <form id="new_user" class="devise-form__container" novalidate action={loginPath} method="post">
      <input type="hidden" name="authenticity_token" value={authenticityToken} />
      <input
        class="devise-form__input"
        name="accountant[email]"
        type="email"
        placeholder="Email Address"
        bind:value={emailValue}
      />
      <input
        class="devise-form__input"
        name="accountant[password]"
        type="password"
        placeholder="Password"
        bind:value={passwordValue}
      />
      <a class="devise-form__action-link devise-form__mt-1" href={passRecoveryPath}>Forgot your password?</a>
      <button type="submit" class="devise-form__button devise-form__sign-in">Log in</button>
      <a href={registrationPath} class="devise-form__button devise-form__register-button">Sign up</a>
    </form>
  </div>
</div>
