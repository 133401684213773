<script>
  import Select from "svelte-select";

  export let clients = [];
  export let selectedClient = {};

  const onChange = function (e) {
    window.location = e.detail.target;
  };
</script>

<Select
  items={clients}
  value={selectedClient}
  placeholder="Navigate to a Client"
  noOptionsMessage="No Clients"
  isSearchable={true}
  isClearable={false}
  on:select={onChange}
  --itemIsActiveBG="#27b6af"
  --itemHoverBG="#f6f8f8"
  --borderFocusColor="#452161"
/>

<style lang="scss">
</style>
