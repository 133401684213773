import moment from "moment";
import BaseSyncRepository from "repositories/BaseSyncRepository.js";

export default class ReportsOptionsRepository extends BaseSyncRepository {
  async one(id) {
    return this.baseQuery(this.baseEndpoint.replace(":report_id", id));
  }

  async all() {
    return this.baseQuery(this.baseEndpoint.replace(":report_id", ""));
  }

  async get_updated_params(options) {
    return this.baseQuery(this.baseEndpoint.replace(":report_id", "get_updated_params"), {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify({ report: options })
    });
  }

  async update(id, options, refreshType) {
    return this.baseQuery(this.baseEndpoint.replace(":report_id", id), {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify({ report: options, sync_type: refreshType })
    });
  }

  async create() {
    return this.baseQuery(this.baseEndpoint.replace(":report_id", ""), {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify({
        report: {
          name: `Report ${moment(window.getCurrentTimeFromEpoch() * 1000).format("L LT")}`
        }
      })
    });
  }

  async delete(id) {
    return this.baseQuery(this.baseEndpoint.replace(":report_id", id), {
      method: "DELETE",
      headers: this.headers
    });
  }
}
