import tippy from "tippy.js";

export function tooltips(node, initialContent) {
  const instance = tippy(node, {
    animation: "scale",
    offset: [10, 10],
    content: initialContent
  });

  return {
    update(newValue) {
      instance.setContent(newValue);
    },
    destroy() {
      instance.destroy();
    }
  };
}
