<script>
  import {
    specifiedTypes,
    ownerTypes,
    businessTypes,
    businessProjection,
    stateOptions
  } from "components/business_projection/Store";
  import TextField from "./fields/TextField.svelte";
  import NumericField from "./fields/NumericField.svelte";
  import DateField from "./fields/DateField.svelte";
  import SelectField from "./fields/SelectField.svelte";
  import CheckboxField from "./fields/CheckboxField.svelte";

  export let personalProjectionPath;
</script>

<table class="table business-projection-form">
  <tbody>
    <tr class="projection-row">
      <td class="projection-row__title">
        {#if $businessProjection.isCCorp}
          Dividends Distribution, %:
        {:else}
          Ownership, %:
        {/if}
      </td>
      <td>
        <NumericField key="business-share-percent" placeholder="100" />
      </td>
    </tr>
    <tr class="projection-row">
      <td class="projection-row__title">Owner:</td>
      <td>
        <SelectField key="owner" options={ownerTypes} />
      </td>
    </tr>
    <tr class="projection-row">
      <td class="projection-row__title">Company Name:</td>
      <td>
        <TextField key="company-name" />
      </td>
    </tr>
    <tr class="projection-row">
      <td class="projection-row__title">Business Type:</td>
      <td>
        <SelectField key="business-type" options={businessTypes} />
      </td>
    </tr>
    <tr class="projection-row">
      <td class="projection-row__title">State:</td>
      <td>
        <SelectField selectDisabled={true} key="business-state" options={stateOptions} />
        <!-- TODO: Make dynamic (/personal_projection) -->
        <small>
          * For now, the state can only be changed for the entire <a href={personalProjectionPath}>
            personal projection
          </a>
        </small>
      </td>
    </tr>
    <tr class="projection-row">
      <td class="projection-row__title">Business Start:</td>
      <td>
        <DateField key="business-start" />
      </td>
    </tr>
    {#if !$businessProjection.isCCorp}
      <tr class="projection-row">
        <td class="projection-row__title">Payroll:</td>
        <td>
          <NumericField key="payroll" placeholder="0" />
        </td>
      </tr>
      <tr class="projection-row">
        <td class="projection-row__title">Fixed Assets:</td>
        <td>
          <NumericField key="fixed-assets" placeholder="0" />
        </td>
      </tr>
      <tr class="projection-row">
        <td class="projection-row__title">Specified Service Business:</td>
        <td>
          <SelectField key="is-specified" options={specifiedTypes} />
        </td>
      </tr>
      <tr class="projection-row">
        <td class="projection-row__title">Rental Real Estate Business:</td>
        <td>
          <CheckboxField key="rental-real-estate" />
        </td>
      </tr>
    {/if}
    <tr>
      <td />
      <td><small>* Enter total amounts for this year</small></td>
    </tr>
  </tbody>
</table>

<style lang="scss">
  a:link {
    text-decoration: underline;
  }

  td.no-border {
    border-top: none;
  }
</style>
