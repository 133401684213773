<script>
  import _ from "underscore/underscore";
  import GhostInBtn from "./GhostInBtn.svelte";
  import ConnectBtn from "./ConnectBtn.svelte";

  export let companies;
  export let clientSrc;
  export let companyConnectUrlBase;
  export let companyDisconnectUrlBase;
  export let server;

  let inProcess = false;
  let requestCanceled = false;

  function cancelRequest() {
    requestCanceled = true;
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function queryClient() {
    return jQuery.get(clientSrc);
  }

  function isNewStateAcquired(response, companyId, targetState) {
    if (!response || !response.client_page_params || !response.client_page_params.companies) return false;

    let company = response.client_page_params.companies.find((c) => c.id === companyId);
    if (!company) return false;

    return company.is_connected === targetState;
  }

  async function fetchClientPageParams(companyId, targetState) {
    let response = null;

    for (let c = 0; c < 300; c++) {
      response = await queryClient();
      if (isNewStateAcquired(response, companyId, targetState) || requestCanceled) break;
      await sleep(1000);
    }

    if (response) server = response.client_page_params;
    requestCanceled = false;
    inProcess = !inProcess;
  }

  function handleConnect(companyId) {
    fetchClientPageParams(companyId, true);
  }

  function handleDisconnect(companyId) {
    fetchClientPageParams(companyId, false);
  }
</script>

<div class="card mb-3">
  <div class="card-header">
    <h3 class="card-title mb-0">Business Data</h3>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-lg-12">
        {#if companies.length > 0}
          {#each companies as company, i (i)}
            <div class="btn-group" role="group" aria-label={company["name"]}>
              <GhostInBtn redirectUrl={company["redirect_url"]} caption={company["name"]} />
              {#if ["intuit", "xero"].includes(company["data_source_type"])}
                <div class="btn-group">
                  <!-- TODO: Here is a bug: inProcess state is related to ALL companies connections, not only the target one -->
                  {#if inProcess}
                    <button type="button" on:click={cancelRequest} class="btn btn-danger btn-sm btn-cancel">
                      <span class="glyphicon glyphicon-remove" />
                    </button>
                  {/if}
                  {#if company["is_connected"]}
                    <ConnectBtn
                      bind:inProcess
                      className="btn-danger"
                      caption={"Disconnect"}
                      url={companyDisconnectUrlBase.replace(":company_id:", company.id)}
                      inProcessCaption={"Disconnecting..."}
                      handleConnection={() => handleDisconnect(company.id)}
                    />
                  {:else}
                    <ConnectBtn
                      bind:inProcess
                      className="btn-success"
                      caption={"Connect"}
                      url={companyConnectUrlBase.replace(":company_id:", company.id)}
                      inProcessCaption={"Connecting..."}
                      handleConnection={() => handleConnect(company.id)}
                    />
                  {/if}
                </div>
              {/if}
              &nbsp;
            </div>
          {/each}
        {:else}
          <h3>User has no companies</h3>
        {/if}
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .btn-cancel {
    margin: 1px 1px;
  }
</style>
