<script>
  import { onMount } from "svelte";
  import _ from "underscore";
  import { notyf_top_right } from "misc/notyf_top_right";
  import BaseSyncRepository from "repositories/BaseSyncRepository.js";

  export let companyTypeEndpoint;
  export let matrix;
  export let vector;
  export let summary;
  export let ownerships;

  let recalculating = true;
  let ownershipData = Object.fromEntries(
    Array.from(Array(100 + 1).keys()).map((i) => [i, { text: `${i}%`, value: i }])
  );

  const baseSyncRepository = new BaseSyncRepository(companyTypeEndpoint);

  const recalculate = _.debounce(async () => {
    recalculating = true;
    try {
      const data = await baseSyncRepository.baseQuery(baseSyncRepository.baseEndpoint, {
        method: "POST",
        headers: baseSyncRepository.headers,
        body: JSON.stringify({
          company_type: {
            ownerships: ownerships
          }
        })
      });

      matrix = data.matrix;
      vector = data.vector;
      summary = data.summary;
    } catch (error) {
      notyf_top_right.error(error.message);
    } finally {
      recalculating = false;
    }
  }, 1000);

  function numberToCurrency(value) {
    const roundValue = Math.round(value);
    return (
      roundValue < 0
        ? `(${numberToCurrency(-roundValue)})`
        : (roundValue == undefined ? "-" : roundValue).toLocaleString("en-US", {
            style: "currency",
            currency: "USD"
          })
    ).replace(".00", "");
  }

  onMount(recalculate);
</script>

<div class="row">
  <div class="col-lg-12 company-type__overflow-x">
    <div class="text-center summary">Business Entity Options</div>
    <br />
    <div class="company-type__spinner" hidden={!recalculating}>
      <span class="company-type__spinner-bounce1" />
      <span class="company-type__spinner-bounce2" />
      <span class="company-type__spinner-bounce3" />
    </div>
    <table class="table table-sm company-type" class:company-type__blur={recalculating}>
      <thead>
        <tr>
          <th />
          {#each vector as company}
            <th>{company.company_name}</th>
          {/each}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><b>Distributions to Owners (Applicable to C-corps only)</b></td>
          {#each vector as company}
            <td>
              <select
                class="form-control"
                bind:value={ownerships[company.company_id]}
                on:input={recalculate}
                disabled={recalculating}
              >
                {#each Object.values(ownershipData).reverse() as data}
                  <option value={data.value}>
                    {data.text}
                  </option>
                {/each}
              </select>
            </td>
          {/each}
        </tr>
        <tr>
          <td><b>Taxable Income</b></td>
          {#each vector as company}
            <td>{numberToCurrency(company.taxable_income || 0)}</td>
          {/each}
        </tr>
        {#each Object.entries(matrix) as [businessType, calculations]}
          <tr>
            <td>
              <b>{businessType}</b>
              <div class="col"><small>SE Tax</small></div>
              {#if businessType === "S Corporation"}
                <div class="col"><small>FICA Tax</small></div>
              {/if}
              {#if businessType === "C Corporation"}
                <div class="col"><small>Income Tax (Corporate)</small></div>
              {/if}
              <div class="col"><small>Income Tax</small></div>
              <div class="col"><small>State Tax</small></div>
              <div class="col"><small>Total Tax</small></div>
            </td>
            {#each calculations as calculation, i}
              <td
                class:optimal-business-type={(vector[i].optimal_combination || [])[0] >=
                  calculation.total_tax}
                class:selected-business-type={calculation.misc === "selected_business_type"}
              >
                <b>&nbsp;</b>
                <div class="col">{numberToCurrency(calculation.se_tax)}</div>
                {#if businessType === "S Corporation"}
                  <div class="col">{numberToCurrency(calculation.fica_tax)}</div>
                {/if}
                {#if businessType === "C Corporation"}
                  <div class="col">{numberToCurrency(calculation.income_tax_corporate)}</div>
                {/if}
                <div class="col">{numberToCurrency(calculation.income_tax)}</div>
                <div class="col">{numberToCurrency(calculation.state_tax)}</div>
                <div class="col"><b>{numberToCurrency(calculation.total_tax)}</b></div>
              </td>
            {/each}
          </tr>
        {/each}
        <tr>
          <td>
            <b>Optimal Combination</b>
          </td>
          {#each vector as company}
            <td>
              {#each [(company.optimal_combination || [])[1]] as businessType}
                <div class="col"><b>{businessType}</b></div>
              {/each}
            </td>
          {/each}
        </tr>
        <tr>
          <td>Total Tax</td>
          <td colspan={vector.length}>
            <b>{numberToCurrency(summary)}</b>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<style>
  .company-type {
    transition: 0.2s filter linear;
  }
  .company-type__blur {
    filter: blur(1px) opacity(0.2);
  }
  .company-type__spinner {
    position: absolute;
    z-index: 100;
    width: 100%;
    top: 50%;
    text-align: center;
  }
  .company-type__spinner-bounce1,
  .company-type__spinner-bounce2,
  .company-type__spinner-bounce3 {
    width: 18px;
    height: 18px;
    background-color: #1d212a;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  .company-type__spinner-bounce1 {
    animation-delay: -0.32s;
  }
  .company-type__spinner-bounce2 {
    animation-delay: -0.16s;
  }
  .company-type__overflow-x {
    overflow-x: auto;
  }
  .selected-business-type,
  .optimal-business-type {
    color: white;
  }
  .selected-business-type {
    background-color: #b55eb4;
  }
  .optimal-business-type,
  .selected-business-type.optimal-business-type {
    background-color: #14b026;
  }
</style>
