<script>
  import { onMount } from "svelte";
  import accounting from "accounting";
  import Chart from "chart.js";

  export let dashboard;

  let personalSourceChartCanvas;

  function incomeStructureChart(type, dataSource, context) {
    const colors = [
      "#CFEADD",
      "#E4B470",
      "#EFADC8",
      "#C6E9A3",
      "#B0CBE6",
      "#BEBE97",
      "#C6B8BA",
      "#EAAAA0",
      "#8EDDB2",
      "#DDE480",
      "#88D9DA",
      "#D7BFE5",
      "#B7C277",
      "#B7D3B6",
      "#ECD1A8",
      "#ECDCDC",
      "#27B6AF",
      "#556B8D",
      "#EDCE8C",
      "#1F7BB6",
      "#909AA0"
    ];
    let labels = [];
    let values = [];

    const ref = dataSource.entries;

    for (const category in ref) {
      const value = ref[category];
      labels.push(category);
      values.push(value);
    }

    const config = {
      type: type,
      data: {
        datasets: [
          {
            data: values,
            backgroundColor: colors
          }
        ],
        labels: labels
      },
      options: {
        maintainAspectRatio: false,
        responsive: false,
        aspectRatio: 1,
        legend: {
          position: "bottom",
          onClick: () => {},
          labels: {
            boxWidth: 20,
            fontSize: 11,
            padding: 5
          }
        },
        tooltips: {
          mode: "single",
          callbacks: {
            label: function (tooltipItem, data) {
              const i = tooltipItem.index;
              const value = accounting.formatMoney(data.datasets[0].data[i], "$", 0);

              return `${data.labels[i]} ${value}`;
            }
          }
        }
      }
    };
    return new Chart(context, config);
  }

  onMount(async (_promise) => {
    incomeStructureChart(
      "doughnut",
      dashboard.widgets.personalIncomeSourceDoughnut,
      personalSourceChartCanvas.getContext("2d")
    );
  });
</script>

<template lang="pug">
  .col-sm-12.col-sm-6.col-lg-6.col-xl-3.d-flex.flex-column
    .card.flex-align.mb-3
      .card-header
        h3.card-title.mb-0 Personal Income by Source
      .card-body.aligned-panels__panel.js-personal-source-chart.source-chart(
        class:inactive="{!dashboard.widgets.personalIncomeSourceDoughnut.active}"
      )
        canvas(bind:this="{personalSourceChartCanvas}")
</template>

<style lang="sass">
  .inactive
    opacity: 0.2
</style>
