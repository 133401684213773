import { writable, derived, get } from "svelte/store";
import { specificationTree } from "./specificationTree";

export const dirtyParams = writable(new Set());
export const isDirty = derived(dirtyParams, ($dirtyParams) => $dirtyParams.size > 0);
export const dirtyNodes = derived(dirtyParams, ($dirtyParams) => {
  return new Set(
    [...$dirtyParams].flatMap((nodeId) => {
      return foldedTree[nodeId] ? [nodeId, ...foldedTree[nodeId]] : [nodeId];
    })
  );
});

function foldTreeParents(tree, parentIds = [], references = {}) {
  for (const item of tree) {
    const itemId = item.id;
    references = { ...references, [itemId]: parentIds };

    const children = item.children;

    if (children) {
      references = foldTreeParents(children, [...parentIds, itemId], references);
    }
  }

  return references;
}

const foldedTree = foldTreeParents(get(specificationTree));
