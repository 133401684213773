export class FeedbackModals {
  constructor(taskId) {
    this.taskId = taskId;

    window.$("#planning-modals").on("click", ".js-planning-module-feedback", (event) => {
      event.preventDefault();
      const $form = window.$(event.currentTarget).closest(".modal-content").find("form");
      this._submitFormWithQueryParam($form);
    });
  }

  _submitFormWithQueryParam($form) {
    const url = $form.attr("action");
    const method = $form.attr("method") || "POST";
    const formData = $form.serialize();

    window.$.ajax({
      url: `${url}?task_id=${encodeURIComponent(this.taskId)}`,
      type: method,
      data: formData
    });
  }

  getModalId(slug, desiredState) {
    const stateToSuffixMap = {
      completed: "complete",
      declined: "decline"
    };
    const suffix = stateToSuffixMap[desiredState];
    return `#${slug}_${suffix}_modal`;
  }

  hasModal(slug, desiredState) {
    return window.$(this.getModalId(slug, desiredState)).length > 0;
  }

  presentModal(slug, desiredState) {
    window.$(this.getModalId(slug, desiredState)).modal("show");
  }
}
