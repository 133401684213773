<script>
  import { reportStore } from "../stores/reportStore";
  import TextInputField from "../inputs/TextInput.svelte";
  import TextareaInputField from "../inputs/TextareaInput.svelte";
  import Dropdown from "../Dropdown.svelte";
  import { searchMatches } from "../stores/searchStore";
</script>

<template lang="pug">
  Dropdown(
    specTreeNodeId="abstract_enable",
    bind:checked="{$reportStore.abstract_enable}",
    hidden="{$searchMatches.isHidden('Abstract')}",
    scrollToId="pdfAbstract"
  )
    TextInputField(
      specTreeNodeId="abstract_introduction",
      bind:value="{$reportStore.abstract_introduction}",
      hidden="{$searchMatches.isHidden('Introduction')}"
    )
    TextInputField(
      specTreeNodeId="abstract_title",
      bind:value="{$reportStore.abstract_title}",
      hidden="{$searchMatches.isHidden('Title')}"
    )
    TextareaInputField(
      specTreeNodeId="abstract_inscription",
      bind:value="{$reportStore.abstract_inscription}",
      hidden="{$searchMatches.isHidden('Inscription')}"
    )
    TextareaInputField(
      specTreeNodeId="abstract_description",
      bind:value="{$reportStore.abstract_description}",
      hidden="{$searchMatches.isHidden('Description')}"
    )
    TextInputField(
      specTreeNodeId="abstract_postscript",
      bind:value="{$reportStore.abstract_postscript}",
      hidden="{$searchMatches.isHidden('Postscript')}"
    )
    TextInputField(
      specTreeNodeId="abstract_post_postscript",
      bind:value="{$reportStore.abstract_post_postscript}",
      hidden="{$searchMatches.isHidden('Post-postscript')}"
    )
</template>
