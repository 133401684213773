import { writable, derived, get } from "svelte/store";
import { specificationTree } from "./specificationTree";
import _ from "lodash";

export const searchTerm = writable("");

export const searchMatches = derived([searchTerm, specificationTree], ([$searchTerm, $specificationTree]) => {
  const matches = match($specificationTree, $searchTerm)[0];
  return {
    matches,
    isHidden: (title) => !matches[title.replace(/&quot;/g, '"').replace(/&amp;/g, "&")],
    matched: Object.values(matches).some((e) => !e),
    noMatch: Object.values(matches).every((e) => !e)
  };
});

function match(specification, query, branch = false) {
  return specification.reduce(
    ([obj, adj], { title, children }) => {
      if (!title) return [obj, adj];

      const matched = branch || title.toUpperCase().includes((query || "").trim().toUpperCase());
      const [nested, downstream] = Array.isArray(children) ? match(children, query, matched) : [{}, matched];
      return [_.mergeWith(obj, nested, { [title]: downstream }, (v1, v2) => v1 || v2), adj || downstream];
    },
    [{}, branch]
  );
}

// Draft for the match function testing
//
// let spec = [
//   {title: "fuu"},
//   {title: "Filt", children: [
//     {title: "Blab", children: [
//       {title: "first", children: [
//         {title: "one"},
//         {title: "two", children: []}
//       ]},
//       {title: "NaNa", children: [
//         {title: "second", children: null}
//       ]}
//     ]}
//   ]},
//   {title: "third", children: [{title: "one"}]},
// ];
// _.isEqual(match(spec, 'uu')[0], {
//   fuu: true,
//   one: false,
//   two: false,
//   first: false,
//   second: false,
//   NaNa: false,
//   Blab: false,
//   Filt: false,
//   third: false,
// });
// {
// _.isEqual(match(spec, 'TW')[0], {
//   fuu: false,
//   one: false,
//   two: true,
//   first: true,
//   second: false,
//   NaNa: false,
//   Blab: true,
//   Filt: true,
//   third: false,
// });
// _.isEqual(match(spec, 'filt')[0], {
//   fuu: false,
//   one: true,
//   two: true,
//   first: true,
//   second: true,
//   NaNa: true,
//   Blab: true,
//   Filt: true,
//   third: false,
// });
// _.isEqual(match(spec, 'first ')[0], {
//   fuu: false,
//   one: true,
//   two: true,
//   first: true,
//   second: false,
//   NaNa: false,
//   Blab: true,
//   Filt: true,
//   third: false,
// });
// _.isEqual(match(spec, ' ON')[0], {
//   fuu: false,
//   one: true,
//   two: false,
//   first: true,
//   second: true,
//   NaNa: true,
//   Blab: true,
//   Filt: true,
//   third: true,
// });
//
//          X
//          |
//          X
//         / \
//        O   X (matched)
//       /\  /|\
//      O O X X X
//
