import "core-js/stable";
import "regenerator-runtime/runtime";
import "../utils/timeHelper";

import "./jquery-load";
import "bootstrap/dist/js/bootstrap.bundle.min";

import "vendor/vendor-bootstrap-datepicker/bootstrap-datepicker.js";

// jQuery ujs: Unobtrusive scripting adapter for jQuery
// See github.com/rails/jquery-ujs
import "jquery-ujs";

// Video.js: most popular open source HTML5 player framework
// See videojs.com
import "video.js/dist/video.js";

// Wow: Reveal Animations When You Scroll
// See wowjs.uk
// 1) requires animate.css as dependency (above)
// 2) WOW.js cannot detect dom mutations: call .sync() after loading new content
import WOW from "wow.js/dist/wow";
window.WOW = new WOW();
window.WOW.init();

import "@/misc/sidebar-toggle-left";

// TODO: Remove when will be no ajax requests
window.$.ajaxSetup({
  headers: {
    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
  }
});

// Svelte Components

import Report from "@/views/reports/Report.svelte";
import Login from "@/views/login/Login.svelte";
import SMSLogin from "@/views/login/SMSLogin.svelte";
import AutoLease from "@/views/planning/AutoLease.svelte";
import PersonalProjection from "@/views/personal_projection/PersonalProjection.svelte";
import SimulatorDashboard from "@/components/simulator/SimulatorDashboard.svelte";
import ClientSelect from "@/components/common/ClientSelect.svelte";
import CompanyConnection from "@/components/companies/CompanyConnection.svelte";
import CreateCompany from "@/components/companies/create_company/CreateCompany.svelte";
import CompaniesInLeftSideBar from "@/components/companies/CompaniesInLeftSideBar.svelte";
import XeroTenantSwitch from "@/components/companies/XeroTenantSwitch.svelte";
import PlanningCompanyType from "@/views/planning/CompanyType.svelte";
import PlanningHealthSavings from "@/views/planning/HealthSavings.svelte";
import PlanningHomeOfficeAccountable from "@/views/planning/HomeOfficeAccountable.svelte";
import PlanningQBIDSalaryMaximizer from "@/views/planning/QBIDSalaryMaximizer.svelte";
import PlanningMfjMfs from "@/views/planning/MfjMfs.svelte";
import PlanningChildrenEmploymentContainer from "@/views/planning/PlanningChildrenEmploymentContainer.svelte";
import PlanningEstimatedTaxPayments from "@/views/planning/EstimatedTaxPayments.svelte";
import PlanningVariants from "@/views/planning/common/PlanningVariants.svelte";
import Dashboard from "@/components/dashboard/Dashboard.svelte";
import ModuleTilesContainer from "@/components/planning/ModuleTilesContainer.svelte";
import SummaryEmailForm from "@/components/profile/SummaryEmailForm.svelte";
import SmsConfirmation from "@/components/profile/SmsConfirmation.svelte";
import YearSwitch from "@/components/personal_projection/YearSwitch.svelte";
import BusinessProjection from "@/components/business_projection/BusinessProjection.svelte";
import VaultDocsUpload from "@/components/vault_docs/VaultDocsUpload.svelte";
import UtcDateTimeToLocal from "@/components/common/UtcDateTimeToLocal.svelte";

import AdminUserList from "@/components/accountant/AdminUserList.svelte";
import AdminUserListPagination from "@/components/accountant/AdminUserListPagination.svelte";
import ClientList from "@/components/accountant/ClientList.svelte";
import Pagination from "@/components/accountant/Pagination.svelte";
import SearchBox from "@/components/accountant/SearchBox.svelte";
import AccountantLogin from "@/components/accountant/Login.svelte";
import LoginPageManagement from "@/components/accountant/LoginPageManagement.svelte";
import ModalContent from "@/components/accountant/ModalContent.svelte";
import Subscription from "@/components/accountant/Subscription.svelte";
import UserEditDialog from "@/components/accountant/UserEditDialog.svelte";

import ClientAdditionsContainer from "@/components/accountant/client_page/ClientAdditionsContainer.svelte";
import ClientSettings from "@/components/accountant/client_page/ClientSettings.svelte";
import ClientCompaniesContainer from "@/components/accountant/client_page/ClientCompaniesContainer.svelte";
import CheckboxInput from "@/components/accountant/client_page/CheckboxInput.svelte";
import CredentialsChanger from "@/components/accountant/client_page/CredentialsChanger.svelte";
import ClientPage from "@/components/accountant/client_page/ClientPage.svelte";
import ClientSummaryInfo from "@/components/accountant/client_page/ClientSummaryInfo.svelte";
import GhostInBtn from "@/components/accountant/client_page/GhostInBtn.svelte";
import ClientSettingsContainer from "@/components/accountant/client_page/ClientSettingsContainer.svelte";
import ClientAdditions from "@/components/accountant/client_page/ClientAdditions.svelte";
import TextArea from "@/components/accountant/client_page/TextArea.svelte";
import ClientPageHeaderContainer from "@/components/accountant/client_page/ClientPageHeaderContainer.svelte";
import CredentialsChangerContainer from "@/components/accountant/client_page/CredentialsChangerContainer.svelte";
import ConnectBtn from "@/components/accountant/client_page/ConnectBtn.svelte";
import TextInput from "@/components/accountant/client_page/TextInput.svelte";

import svelteLoader from "./SvelteComponentsLoader";

svelteLoader.setup({
  Report,
  Login,
  SMSLogin,
  PersonalProjection,
  SimulatorDashboard,
  AutoLease,
  PlanningCompanyType,
  PlanningHealthSavings,
  PlanningHomeOfficeAccountable,
  PlanningQBIDSalaryMaximizer,
  PlanningVariants,
  PlanningMfjMfs,
  PlanningEstimatedTaxPayments,
  Dashboard,
  BusinessProjection,
  ClientSelect,
  CompanyConnection,
  CreateCompany,
  CompaniesInLeftSideBar,
  XeroTenantSwitch,
  PlanningChildrenEmploymentContainer,
  ModuleTilesContainer,
  SummaryEmailForm,
  SmsConfirmation,
  YearSwitch,
  VaultDocsUpload,
  UtcDateTimeToLocal,

  AdminUserList,
  AdminUserListPagination,
  ClientList,
  Pagination,
  SearchBox,
  AccountantLogin,
  LoginPageManagement,
  ModalContent,
  Subscription,
  UserEditDialog,

  ClientAdditionsContainer,
  ClientSettings,
  ClientCompaniesContainer,
  CheckboxInput,
  CredentialsChanger,
  ClientPage,
  ClientSummaryInfo,
  GhostInBtn,
  ClientSettingsContainer,
  ClientAdditions,
  TextArea,
  ClientPageHeaderContainer,
  CredentialsChangerContainer,
  ConnectBtn,
  TextInput
});
