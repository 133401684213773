<script>
  import TaxTotal from "components/dashboard/TaxTotal.svelte";

  export let federalTax;
  export let stateTax;
  export let showStateTax;
  export let ranged;
</script>

<template lang="pug">
  .flex-align__wrap
    slot
  .d-flex(style="flex-align: wrap")
    TaxTotal(amount="{federalTax}", type="Federal", ranged="{ranged}")
    +if('showStateTax')
      TaxTotal(amount="{stateTax}", type="State", ranged="{ranged}")
</template>
