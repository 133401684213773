<script>
  import { reportStore } from "../stores/reportStore";
  import { specificationTree } from "../stores/specificationTree";
  import TextareaInputField from "../inputs/TextareaInput.svelte";
  import SelectInputField from "../inputs/SelectInput.svelte";
  import Dropdown from "../Dropdown.svelte";
  import { searchMatches } from "../stores/searchStore";

  const options = {
    big: "Big",
    small: "Small"
  };
</script>

<template lang="pug">
  Dropdown(
    bind:checked="{$reportStore.tips_and_advices_for_you_enable}",
    specTreeNodeId="tips_and_advices_for_you_enable",
    hidden="{$searchMatches.isHidden('Tips And Advice For You')}",
    scrollToId="pdfTaxTipsAdvice"
  )
    TextareaInputField(
      bind:value="{$reportStore.tips_and_advices_for_you_text}",
      specTreeNodeId="tips_and_advices_for_you_text",
      hidden="{$searchMatches.isHidden('Description')}"
    )
    SelectInputField(
      bind:value="{$reportStore.tips_and_advices_for_you_font_size}",
      specTreeNodeId="tips_and_advices_for_you_font_size",
      title="Font Size",
      options="{options}",
      hidden="{$searchMatches.isHidden('Font Size')}"
    )
</template>
