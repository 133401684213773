import { getHeaders } from "../../utils/apiHelper";

export class PlanningModulesDataSource {
  constructor(modulesPath, completeModulePathBase, declineModulePathBase) {
    this.modulesPath = modulesPath;
    this.completeModulePathBase = completeModulePathBase;
    this.declineModulePathBase = declineModulePathBase;
    this.controller = new AbortController();
  }

  async _makeFetchRequest(url, taskId, options = {}) {
    try {
      const headers = getHeaders();
      const urlWithParam = `${url}?task_id=${encodeURIComponent(taskId)}`;
      const response = await fetch(urlWithParam, { ...options, headers, signal: this.controller.signal });
      if (!response.ok) throw new Error("Network response was not ok.");
      return response;
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("There was a problem with the fetch operation:", error.message);
      }
    }
  }

  loadModules(taskId) {
    return this._makeFetchRequest(this.modulesPath, taskId);
  }

  completeModule(module, taskId) {
    const url = this.completeModulePathBase.replace(":module:", module);
    return this._makeFetchRequest(url, taskId, { method: "POST" });
  }

  declineModule(module, taskId) {
    const url = this.declineModulePathBase.replace(":module:", module);
    return this._makeFetchRequest(url, taskId, { method: "POST" });
  }
}
