<script>
  import { reportStore } from "../stores/reportStore";
  import { specificationTree } from "../stores/specificationTree";
  import DatePicker from "../inputs/DatePicker.svelte";
  import TextInputField from "../inputs/TextInput.svelte";
  import CheckboxInput from "../inputs/CheckboxInput.svelte";
  import Dropdown from "../Dropdown.svelte";
  import { searchMatches } from "../stores/searchStore";

  export let refreshReportParams;
</script>

<template lang="pug">
  Dropdown(
    {refreshReportParams},
    specTreeNodeId="front_page_enable",
    bind:checked="{$reportStore.front_page_enable}",
    hidden="{$searchMatches.isHidden('Front Page')}",
    scrollToId="pdfFrontPage"
  )
    TextInputField(
      {refreshReportParams},
      specTreeNodeId="front_page_prepared_for_organization",
      bind:value="{$reportStore.front_page_prepared_for_organization}",
      hidden="{$searchMatches.isHidden('Prepared for')}"
    )
    TextInputField(
      {refreshReportParams},
      specTreeNodeId="front_page_prepared_by_organization",
      bind:value="{$reportStore.front_page_prepared_by_organization}",
      hidden="{$searchMatches.isHidden('Prepared by')}"
    )
    DatePicker(
      {refreshReportParams},
      specTreeNodeId="front_page_date",
      bind:value="{$reportStore.front_page_date}",
      hidden="{$searchMatches.isHidden('Preparation Date')}"
    )
    CheckboxInput(
      specTreeNodeId="front_page_center_image_enable",
      bind:checked="{$reportStore.front_page_center_image_enable}",
      hidden="{$searchMatches.isHidden('Show front page image')}"
    )
</template>
