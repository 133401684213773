<script>
  import TaxpayerSpouseNumericFieldBase from "./TaxpayerSpouseNumericFieldBase.svelte";
  import { personalProjection, personalProjectionValidation, visitField } from "../Store";

  import { onMount, afterUpdate } from "svelte";
  import { isNil } from "lodash";
  import { tooltips } from "utils/tooltips";
  import { fieldsToLabels } from "../FieldsToLabels";

  export let key;
  export let tooltip = null;

  let spouseFieldIsShown;
  let spouseFieldIsTouched = false;

  function showSpouseData(_event) {
    spouseFieldIsShown = true;
    spouseFieldIsTouched = true;
  }
  function hideSpouseData(_event) {
    spouseFieldIsShown = false;
  }

  $: spouseFieldIsZero = $personalProjection[`${key}_spouse`] == 0;
  $: spouseFieldIsShown = !spouseFieldIsZero || spouseFieldIsTouched;

  function markSpouseFieldVisitedIfHidden() {
    if (!spouseFieldIsZero) return;

    visitField(`${key}_spouse`);
  }

  // always visited
  onMount(markSpouseFieldVisitedIfHidden);
  afterUpdate(markSpouseFieldVisitedIfHidden);
</script>

<div class="personal-projection-form-content__column">
  <div class="form-group tpp-form-group">
    <label for={`personal_projection_parameters[${key}_taxpayer]`} class="col-form-label tpp-form-label">
      {#if !isNil(tooltip)}
        <div use:tooltips={tooltip}>
          <span>{fieldsToLabels[`${key}_taxpayer`]}</span>
          <i class="fa fa-info-circle ml-1" />
        </div>
      {:else}
        <div>
          <span>{fieldsToLabels[`${key}_taxpayer`]}</span>
        </div>
      {/if}
    </label>
    <span class="input-group input-group-twin">
      <div class="input-group-prepend">
        <span class="input-group-text">$</span>
      </div>
      <div class="input-group-twin__part">
        <TaxpayerSpouseNumericFieldBase
          key={`${key}_taxpayer`}
          bind:value={$personalProjection[`${key}_taxpayer`]}
          hasError={$personalProjectionValidation.has(`${key}_taxpayer`)}
          label="Taxpayer"
          actionLabel="Spouse ›"
          actionShow={!spouseFieldIsShown}
          on:click={showSpouseData}
        />
      </div>
      <div
        class="input-group-twin__part input-group-twin__part_second"
        class:input-group-twin__part_hidden={!spouseFieldIsShown}
      >
        <TaxpayerSpouseNumericFieldBase
          key={`${key}_spouse`}
          bind:value={$personalProjection[`${key}_spouse`]}
          hasError={$personalProjectionValidation.has(`${key}_spouse`)}
          label="Spouse"
          actionLabel="‹ Hide"
          actionShow={spouseFieldIsShown && spouseFieldIsZero}
          on:click={hideSpouseData}
        />
      </div>
    </span>
    <span class="form-text" />
  </div>
</div>
