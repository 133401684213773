<script>
  import { onMount } from "svelte";
  import { reportStore } from "../stores/reportStore";

  export let onSave;
  export let onCancel;

  let name = $reportStore.name;

  function _onSave() {
    onSave(name);
  }

  function _onCancel() {
    onCancel();
  }

  onMount(() => {
    document.body.style.position = "initial";
  });
</script>

<h2>Rename Report</h2>

<input class="form-control" bind:value={name} on:keypress={(e) => e.key === "Enter" && _onSave()} />

<div class="buttons">
  <button class="btn btn-success" disabled={$reportStore.name === name} on:click|preventDefault={_onSave}>
    Save
  </button>
  <button class="btn btn-secondary" on:click|preventDefault={_onCancel}>Cancel</button>
</div>

<style>
  h2 {
    margin-bottom: 15px;
    font-size: 2rem;
    text-align: center;
  }

  input {
    width: 100%;
    padding: 1.2em 0.7em;
    font-size: 1rem;
  }

  .buttons {
    margin-top: 1em;
  }

  label {
    font-size: 1rem;
    font-weight: normal;
    opacity: 0.6;
  }
</style>
