import BaseSyncRepository from "repositories/BaseSyncRepository.js";

export default class ReportTemplatesRepository extends BaseSyncRepository {
  async all() {
    return this.baseQuery(this.baseEndpoint.replace(":template_id", ""));
  }

  async create(options) {
    return this.baseQuery(this.baseEndpoint.replace(":template_id", ""), {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify({ template: options })
    });
  }

  async update(id, options) {
    return this.baseQuery(this.baseEndpoint.replace(":template_id", id), {
      method: "PUT",
      headers: this.headers,
      body: JSON.stringify({ template: options })
    });
  }

  async delete(id) {
    return this.baseQuery(this.baseEndpoint.replace(":template_id", id), {
      method: "DELETE",
      headers: this.headers
    });
  }
}
