<script>
  import ResultRow from "components/common/ResultRow.svelte";

  export let value;
  export let title;
  export let additionalClasses = "";

  let classes = `results__row__total ${additionalClasses}`;
</script>

<template lang="pug">
  ResultRow({value}, {title}, additionalClasses="{classes}")
</template>
