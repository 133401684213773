import BaseSyncRepository from "repositories/BaseSyncRepository.js";

export default class SimulatedPersonalProjectionsRepository extends BaseSyncRepository {
  async create(params) {
    return this.baseQuery(this.baseEndpoint, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify({ simulator_parameters: params })
    });
  }

  async one(id) {
    return this.baseQuery(`${this.baseEndpoint}/${id}`, {
      method: "GET",
      headers: this.headers
    });
  }

  async all() {
    return this.baseQuery(`${this.baseEndpoint}`, {
      method: "GET",
      headers: this.headers
    });
  }

  async update(id, params) {
    return this.baseQuery(`${this.baseEndpoint}/${id}`, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify({ simulator_parameters: params })
    });
  }

  async delete(id) {
    return this.baseQuery(`${this.baseEndpoint}/${id}`, {
      method: "DELETE",
      headers: this.headers
    });
  }

  async compare(simulations_data) {
    return this.baseQuery(`${this.baseEndpoint}/compare`, {
      method: "POST",
      headers: this.headers,
      body: JSON.stringify({ simulations_data: simulations_data })
    });
  }
}
