<script>
  import { isDirty } from "../report/stores/DirtyReportParams";

  export let regenerateReport;
</script>

<div class="text-center">
  <h2>Report Failed To Load</h2>
  <p>
    Sorry, but we were unable to load your report. Please
    {#if regenerateReport}
      click the Retry button below.
    {:else}
      try reloading the page.
    {/if}
  </p>
  <p>
    If the error persists, please do not hesitate to reach out for support using the chat button at the bottom
    right corner of the page.
  </p>
  <p>Thank you!</p>
  {#if regenerateReport}
    <button class="btn btn-primary" on:click={regenerateReport} disabled={$isDirty}>Retry</button>
  {/if}
</div>

<style lang="scss">
  .text-center {
    margin-top: 15px;
  }
</style>
