<script>
  import NavTab from "./NavTab.svelte";

  export let companyConnectionPath;
  export let companyData;
  export let sourceType;
  export let activeTab;

  const createTabs = (sourceType) => {
    let baseTabs = [
      { href: companyConnectionPath, title: "Connection" },
      { href: "#business-data", title: "Business Data", isDisabled: !companyData.hasBusinessProjection }
    ];

    if (sourceType === "handy") {
      baseTabs.push({
        href: "#income-and-expenses",
        title: "Income And Expenses",
        isDisabled: !companyData.hasBusinessProjection
      });
    }

    return baseTabs;
  };

  let tabs = createTabs(sourceType);

  const handleTabClick = (tabTitle) => {
    activeTab = tabTitle;
  };
</script>

<nav class="tpp-tabs">
  {#each tabs as { href, title, isDisabled } (title)}
    <NavTab
      {href}
      {title}
      isActive={activeTab === title}
      {isDisabled}
      onClick={() => handleTabClick(title)}
    />
  {/each}
</nav>
