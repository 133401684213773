import _ from "lodash";
import moment from "moment";

export function deserializeBusinessProjection(data) {
  return {
    ...data,
    businessStart: moment(data.businessStart, "YYYY-MM-DD").format("MM/DD/YYYY")
  };
}

export function serializeBusinessProjection(data) {
  return {
    ...data,
    businessStart: moment(data.businessStart, "MM/DD/YYYY").format("YYYY-MM-DD")
  };
}
