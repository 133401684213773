<script>export let labelWrapper;
export let hex;
export let label;
/* svelte-ignore unused-export-let */
export let isOpen;
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<label class="color-picker-label"
	bind:this={labelWrapper}
	on:click|preventDefault={() => {
		/* prevent default behavior on safari */
	}}
	on:mousedown|preventDefault={() => {
		/* prevent default behavior on safari */
	}}
>
	<div class="color-picker-container">
		<input
			type="color"
      class="color-picker-input"
			value={hex}
			on:click|preventDefault={() => {
				/* prevent default behavior on most browsers */
			}}
			on:mousedown|preventDefault={() => {
				/* prevent default behavior on safari */
			}}
			aria-haspopup="dialog"
		/>
		<div class="color-picker-alpha" />
		<div class="color-picker-color" style="background: {hex}" />
	</div>
	{label}
</label>

<style>
	.color-picker-label {
		display: flex;
		align-items: center;
		gap: 8px;
		cursor: pointer;
		border-radius: 3px;
		margin: 4px;
    font-weight: 500
	}

	.color-picker-container {
		position: relative;
		display: block;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.color-picker-input {
		margin: 0;
		padding: 0;
		border: none;
		width: 4px;
		height: 4px;
		flex-shrink: 0;
		cursor: pointer;
		border-radius: 50%;
		margin: 0 12px;
	}

	.color-picker-alpha {
		clip-path: circle(50%);
		background-image: linear-gradient(
				to right,
				rgba(238, 238, 238, 0.75),
				rgba(238, 238, 238, 0.75)
			),
			linear-gradient(to right, black 50%, white 50%),
			linear-gradient(to bottom, black 50%, white 50%);
		background-blend-mode: normal, difference, normal;
		background-size: 12px 12px;
	}

	.color-picker-alpha,
	.color-picker-color {
		position: absolute;
		width: 30px;
		height: 30px;
		border-radius: 15px;
		user-select: none;
	}

	.color-picker-input:focus {
		outline: 2px solid var(--focus-color, red);
		outline-offset: 15px;
	}
</style>
