<script>
  import ResultValue from "./ResultValue.svelte";
  import accounting from "accounting";
  import { tooltips } from "utils/tooltips";
  import _ from "lodash";

  export let value;
  export let title = "";
  export let additionalClasses = "";
  export let tooltip = null;
  export let priceAdditionalInfo = "";
  export let showTooltip = true;

  let tooltipNode;
  let parametrizedTitle = parametrize(title);

  $: classes = `${additionalClasses} ${parametrizedTitle}`;

  function parametrize(val) {
    return `results__row__${val.toLowerCase().replaceAll(" ", "-")}`;
  }
</script>

<template lang="pug">
  tr(class="{classes}")
    +if('title')
      td.col-lg-7.col-sm-8.results__row-title { title }:&nbsp;
      +if('!_.isNil(tooltip) && showTooltip')
        td.col-lg-5.col-sm-4.text-right.results__row-value
          span(use:tooltips="{tooltipNode}")
            ResultValue(value="{value}")
            div
              small {priceAdditionalInfo}
          .d-none(bind:this="{tooltipNode}") { @html tooltip }
        +else
          td.col-lg-5.col-sm-4.text-right.results__row-value
            ResultValue(value="{value}")
</template>
