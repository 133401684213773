<script>
  import Modal from "components/common/Modal.svelte";
  import TextInput from "components/common/TextInput.svelte";
  import SelectInput from "components/common/SelectInput.svelte";
  import DatePickerInput from "components/common/DatePickerInput.svelte";
  import {
    init,
    company,
    companySerialized,
    companyValidation,
    companyVisitors,
    visitField,
    showCreateCompanyModal
  } from "components/companies/create_company/Store";
  import { deserializeCompany } from "components/companies/create_company/Serialization";
  import { notyf_top_right } from "misc/notyf_top_right";
  import { onMount, onDestroy } from "svelte";
  import _ from "lodash";

  export let businessTypes = [];
  export let ownerTypes = [];
  export let newCompanyPath;
  export let createCompanyPath;
  export let csrfToken;

  let saving = false;
  let disabled = true;
  let unsubscribe;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": csrfToken // window.$('meta[name="csrf-token"]').attr('content'),
  };

  const request = async (endpoint, params = {}) => {
    try {
      const response = await fetch(endpoint, { headers, ...params });
      return await response.json();
    } catch (error) {
      // throw error;
      throw new Error(
        "Something went wrong. If the error persists, please do not hesitate to reach out for support using the chat button at the bottom right corner of the page."
      );
    }
  };

  const requestNewCompany = async () => {
    try {
      const data = await request(newCompanyPath, { method: "GET" });
      if (data.success) {
        company.set(deserializeCompany(data.success));
        disabled = false;
      } else if (data.error) {
        notyf_top_right.error(data.error.message);
      }
    } catch (error) {
      notyf_top_right.error(error.message);
    }
  };

  const acceptNewCompany = async () => {
    try {
      const data = await request(createCompanyPath, {
        method: "POST",
        body: JSON.stringify($companySerialized)
      });
      if (data.success) {
        notyf_top_right.success(data.success.message);
        return data.success.redirect;
      } else if (data.error) {
        notyf_top_right.error(data.error.message);
      }
    } catch (error) {
      notyf_top_right.error(error.message);
    }
  };

  onMount(() => {
    unsubscribe = showCreateCompanyModal.subscribe((state) => state && onShow());
  });

  onDestroy(unsubscribe);

  const onShow = () => {
    init();
    disabled = true;
    requestNewCompany();
  };

  const onSave = async () => {
    saving = true;
    const pathToRedirect = await acceptNewCompany();
    saving = false;
    if (pathToRedirect) {
      _.delay(function (content) {
        window.location.href = pathToRedirect;
      }, 2000);
      return true;
    } else {
      return false;
    }
  };
</script>

<Modal
  title="CREATE NEW COMPANY"
  saveButtonTitle="Create"
  modalId="createCompany"
  modalTitleId="createCompanyLabel"
  showStateInStore={showCreateCompanyModal}
  {onSave}
  {saving}
  disabledSaveButton={$companyValidation.size != 0}
>
  <div class="row">
    <div class="col-sm-12">
      <TextInput
        key="name"
        store={company}
        validation={companyValidation}
        visitors={companyVisitors}
        {visitField}
        title="Name"
        required={true}
        {disabled}
      />
      <SelectInput
        key="businessType"
        store={company}
        validation={companyValidation}
        visitors={companyVisitors}
        {visitField}
        title="Business Type"
        required={false}
        options={businessTypes}
        {disabled}
      />
      <TextInput
        key="share"
        store={company}
        validation={companyValidation}
        visitors={companyVisitors}
        {visitField}
        title="Ownership %"
        required={true}
        {disabled}
      />
      <SelectInput
        key="owner"
        store={company}
        validation={companyValidation}
        visitors={companyVisitors}
        {visitField}
        title="Owner"
        options={ownerTypes}
        required={false}
        {disabled}
      />
      <DatePickerInput
        key="startDate"
        store={company}
        validation={companyValidation}
        visitors={companyVisitors}
        {visitField}
        title="Start Date"
        required={false}
        {disabled}
        options={{ endDate: "+0d", todayHighlight: true }}
        dateFormat="mm/dd/yyyy"
        id="wizard_business_setup_start_date"
      />
    </div>
  </div>
</Modal>
