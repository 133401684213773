<script>
  import SimulatorDashboard from "components/simulator/SimulatorDashboard.svelte";
  import BusinessIncomeExpense from "components/personal_projection/tabs/BusinessIncomeExpense.svelte";
  import Pane from "./Pane.svelte";
  import { onMount, setContext } from "svelte";
  import { businessCompaniesStore } from "components/personal_projection/Store.js";
  import { tabs, invalidTabs } from "components/personal_projection/tabsStore.js";
  import _ from "lodash";
  import { personalProjectionSerialized } from "../personal_projection/Store";
  import { initialFieldsToLabels } from "../personal_projection/FieldsToLabels";
  import { fade } from "svelte/transition";
  import { deserializePersonalProjection } from "components/personal_projection/Serialization.js";
  import { personalProjection } from "components/personal_projection/Store.js";
  import Comparison from "./Comparison.svelte";
  import SimulatedPersonalProjectionsRepository from "repositories/SimulatedPersonalProjectionsRepository.js";

  export let requestInFlight;
  export let businessCompanies;
  export let serverCalculatedParameters;
  export let simulated_personal_projections_url;
  export let initialForm;
  export let initialPersonalProjection;

  const simulatedPPRepository = new SimulatedPersonalProjectionsRepository(
    simulated_personal_projections_url
  );

  let currentTab = 0;
  let currentActiveMode = "simulation";
  let showSimulatorOptions = localStorage.showSimulatorOptions === "true";
  let summaryDisplayedFields = [];
  let lastAppliedProjection = {};
  let simulatedProjections = [];

  $: changedFieldsOfPersonalProjection = getChangedFields(
    initialForm,
    businessCompanies,
    $personalProjectionSerialized,
    $businessCompaniesStore
  );

  $: if (changedFieldsOfPersonalProjection) {
    const allProjectionFields = {
      ...initialForm,
      ...serializeBusinessFields(businessCompanies)
    };
    summaryDisplayedFields = Object.entries(changedFieldsOfPersonalProjection)
      .filter(([key]) => initialFieldsToLabels[key])
      .map(([key, value]) => ({
        humanizedField: initialFieldsToLabels[key],
        initialValue: allProjectionFields[key],
        changedValue: value
      }));
  }

  function getChangedFields(initialPersonal, initialBusiness, changedPersonal, changedBusiness) {
    const serializedInitialBusiness = serializeBusinessFields(initialBusiness);
    const serializedChangedBusiness = serializeBusinessFields(changedBusiness);

    return {
      ..._.pickBy(changedPersonal, (v, k) => !_.isEqual(v, initialPersonal[k])),
      ..._.pickBy(serializedChangedBusiness, (v, k) => !_.isEqual(v, serializedInitialBusiness[k]))
    };
  }

  function serializeBusinessFields(companies) {
    let serializedFields = {};
    for (const [company, values] of Object.entries(companies)) {
      serializedFields[`${company}_revenue`] = values.revenue;
      serializedFields[`${company}_expense`] = values.expense;
    }
    return serializedFields;
  }

  function setInitialParams() {
    businessCompaniesStore.set(_.cloneDeep(businessCompanies));
    const deserializedInitialFormState = deserializePersonalProjection(initialForm);
    personalProjection.set(deserializedInitialFormState);
    lastAppliedProjection = {};
  }

  function toggleReportOptionsPane() {
    showSimulatorOptions = !showSimulatorOptions;
    localStorage.showSimulatorOptions = showSimulatorOptions;
  }

  onMount(() => {
    businessCompaniesStore.set(_.cloneDeep(businessCompanies) || {});
    if (Object.keys($businessCompaniesStore).length > 0) {
      tabs.update((prevTabs) => [
        ...prevTabs,
        {
          component: BusinessIncomeExpense,
          title: "Business Income & Expense",
          componentName: "BusinessIncomeExpense"
        }
      ]);
    }
  });

  setContext("ppType", { type: "simulator" });
</script>

<div class="pageheader">
  <h1 class="personal-projection-form-header">Simulator</h1>
  {#if currentActiveMode !== "comparison"}
    <button
      class="btn btn-sm btn-info mb-0"
      on:click={toggleReportOptionsPane}
      data-toggle="delayed-tooltip"
      data-placement="bottom"
      title="Ctrl + /"
    >
      {showSimulatorOptions ? "Hide" : "Show"} Simulator Options
    </button>
  {/if}
</div>

<div class="card">
  <div class="card-header tab-modes">
    <ul class="nav nav-pills">
      <li class="nav-item">
        <a
          class="active nav-link"
          on:click={() => (currentActiveMode = "simulation")}
          data-toggle="tab"
          href="#simulation"
        >
          Simulation
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          on:click={() => (currentActiveMode = "comparison")}
          data-toggle="tab"
          href="#comparison"
        >
          Comparison
        </a>
      </li>
    </ul>
  </div>
  <div class="tab-content">
    {#if currentActiveMode === "simulation"}
      <div id="simulation" class="tab-pane fade show active">
        <div class="simulator-layout">
          <section id="main-content" class="simulator-layout__simulator_pane">
            <SimulatorDashboard
              loading={requestInFlight}
              results={serverCalculatedParameters}
              initialFedTax={serverCalculatedParameters.res_federal_tax_remaining}
              initialStateTax={serverCalculatedParameters.res_state_tax}
            />
            <div class="personal-projection-form">
              <div class="personal-projection-form__navigation">
                <div class="personal-projection-form-navigation">
                  {#each $tabs as tab, i}
                    <div
                      class="personal-projection-form-navigation__item"
                      class:personal-projection-form-navigation__item-active={currentTab === i}
                      on:click={() => (currentTab = i)}
                    >
                      {#if $invalidTabs.has(tab.componentName)}
                        <i class="fa fa-times personal-projection-form-navigation__item-invalid" />
                      {/if}
                      <span>{tab.title}</span>
                    </div>
                  {/each}
                </div>
              </div>
              <div class="personal-projection-form__content">
                <div class="personal-projection-form-content">
                  <svelte:component this={$tabs[currentTab].component} {serverCalculatedParameters} />
                  <button class="btn btn-primary btn-trans" on:click={setInitialParams}>Reset</button>
                </div>
              </div>
            </div>
            {#if Object.keys(summaryDisplayedFields).length > 0}
              <div transition:fade class="changed-fields-container">
                <h2>Changes</h2>
                <div transition:fade class="changed-fields-content">
                  {#each summaryDisplayedFields as field}
                    <div in:fade={{ duration: 300 }} class="changed-field">
                      {#each field["humanizedField"] as fieldPath}
                        <span class="changed-section">{fieldPath}</span>
                      {/each}
                      <div class="changed-field__values">
                        <span class="changed-field__initial-value">
                          {field["initialValue"] || "0"}
                        </span>
                        <span class="changed-field__changed-value">
                          {field["changedValue"] || "0"}
                        </span>
                      </div>
                    </div>
                  {/each}
                </div>
              </div>
            {/if}
          </section>

          <Pane
            bind:simulatedProjections
            showPane={showSimulatorOptions}
            {initialForm}
            {simulatedPPRepository}
            {setInitialParams}
            {businessCompanies}
            {changedFieldsOfPersonalProjection}
            {getChangedFields}
            bind:lastAppliedProjection
          />
        </div>
      </div>
    {:else}
      <div id="comparison" class="tab-pane fade">
        <Comparison {simulatedProjections} {simulatedPPRepository} {initialPersonalProjection} />
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .pageheader {
    display: flex;
    justify-content: space-between;
  }

  .personal-projection-form-header {
    min-height: 28px;
  }

  .simulator-layout {
    display: flex;

    &__simulator_pane {
      width: 100%;
      padding: 0 15px 0 15px;
      height: calc(100vh - 116px);
      overflow-y: auto;
    }
  }

  .changed-fields-container {
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    background: white;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
    font-family: "Montserrat";
  }

  .changed-fields-content {
    display: flex;
    flex-wrap: wrap;
  }

  .changed-field {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 515px;
    font-size: 13px;
    margin: 10px;
  }

  .changed-field__values {
    margin-left: 15px;
  }

  .changed-field__initial-value {
    text-decoration: line-through;
    color: grey;
  }

  .changed-field__changed-value {
    margin: 0 5px;
  }

  .changed-section + .changed-section {
    &::before {
      content: "\f178";
      font-family: FontAwesome;
      margin: 0 10px;
    }
  }

  h2 {
    margin: 10px;
  }

  .tab-modes {
    padding: 15px;
  }
</style>
