<script>
  import { onMount } from "svelte";

  export let onCancel = () => {};
  export let onSave = () => {};
  export let userEmail;

  let value = userEmail;

  let onChange = () => {};

  function _onCancel() {
    onCancel();
  }

  function _onSave() {
    onSave(value);
  }

  $: onChange(value);

  onMount(() => {
    document.body.style.position = "initial";
  });
</script>

<h2>Edit User</h2>

<label for="user_email">Email:</label>
<input
  id="user_email"
  class="form-control"
  type="email"
  bind:value
  on:keydown={(e) => e.code === "Enter" && _onSave()}
/>

<div class="buttons">
  <button class="btn btn-success" disabled={userEmail === value} on:click|preventDefault={_onSave}>
    Save
  </button>
  <button class="btn btn-secondary" on:click|preventDefault={_onCancel}>Cancel</button>
</div>

<style>
  h2 {
    font-size: 2rem;
    text-align: center;
  }

  input {
    width: 100%;
    padding: 1.2em 0.7em;
    font-size: 1rem;
  }

  .buttons {
    margin-top: 1em;
  }

  label {
    font-size: 1rem;
    font-weight: normal;
    opacity: 0.6;
  }
</style>
