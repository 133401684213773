<script>
  import { businessCompaniesStore, personalProjection, businessCompaniesValidation } from "../Store";
  import NumericField from "../fields/NumericField.svelte";

  let businessCompanies = Object.entries($businessCompaniesStore);
  let currentCompany = businessCompanies[0][0];
  let currentTab = 0;

  function updatePersonalProjection() {
    const value =
      $businessCompaniesStore[currentCompany].revenue - $businessCompaniesStore[currentCompany].expense;
    $personalProjection[`${currentCompany}_value_override`] = value;
    $personalProjection[`${currentCompany}_value_override_guard`] = 1;
  }

  function fieldHasError(set, tab, field) {
    return set.has(`${tab}.${field}`);
  }

  function handleTabClick(company, tab) {
    currentCompany = company;
    currentTab = tab;
  }
</script>

<div>
  <div class="personal-projection-form-content__section-header">
    <h2 class="personal-projection-form-content__section-name">Business Income & Expense</h2>
  </div>
  <div></div>
  <div>
    <ul role="tablist" class="nav nav-tabs">
      {#each businessCompanies as [company, fields], i}
        <li role="presentation" class="nav-item tab__wrapper">
          <a
            role="tab"
            href="#"
            class="nav-link"
            on:click|preventDefault={() => handleTabClick(company, i)}
            class:active={currentCompany === company}
          >
            <div class="tab__number-circle">{i + 1}</div>
            &nbsp;{fields["name"]}
          </a>
        </li>
      {/each}
    </ul>
    <div class="tab-content">
      <div role="tabpanel" class="fade tab tab-collapsed tab-collapsed-expand tab-pane active show">
        <br />
        <br />
        <div>
          <div class="personal-projection-form-content__fields">
            <div class="personal-projection-form-content__column">
              <NumericField
                key={`revenue_${currentTab}`}
                bind:value={$businessCompaniesStore[currentCompany].revenue}
                handleChange={updatePersonalProjection}
                inputAddon="$"
                hasError={fieldHasError($businessCompaniesValidation, currentCompany, "revenue")}
              />
              <NumericField
                key={`expense_${currentTab}`}
                bind:value={$businessCompaniesStore[currentCompany].expense}
                handleChange={updatePersonalProjection}
                inputAddon="$"
                hasError={fieldHasError($businessCompaniesValidation, currentCompany, "expense")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
