<script>
  import CredentialsChanger from "./CredentialsChanger.svelte";
  import jQuery from "jquery";

  export let clientCredentials;
  export let updateCredentialsSrc;
  export let csrfToken;

  let newPassword = "";
  let credentialChangeSubmitted = false;
  let newEmail = clientCredentials.email || "";
  let currentEmail = clientCredentials.email || "";
  let newName = clientCredentials.name || "";
  let currentName = clientCredentials.name || "";
  let changeCredentialsInProgress = false;
  let error = "";

  function resetControl() {
    newPassword = "";
    credentialChangeSubmitted = false;
    changeCredentialsInProgress = false;
    currentEmail = newEmail;
    currentName = newName;
    error = "";
  }

  function submitChange() {
    changeCredentialsInProgress = true;

    jQuery.ajax({
      url: updateCredentialsSrc,
      method: "PATCH",
      data: {
        password: newPassword,
        email: newEmail,
        name: newName
      },
      dataType: "JSON",
      headers: { "X-CSRF-Token": csrfToken },
      success: (data) => {
        if (data.email == null) {
          newEmail = "";
        }
        credentialChangeSubmitted = true;
      },
      error: (data) => (data.status == 422 ? (error = data.responseJSON.error) : (error = "Fatal error")),
      complete: () => (changeCredentialsInProgress = false)
    });
  }
</script>

<div class="card mb-3">
  <div class="card-header">
    <h3 class="card-title mb-0">Client Credentials</h3>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-lg-12">
        <CredentialsChanger
          bind:newPassword
          {credentialChangeSubmitted}
          {changeCredentialsInProgress}
          bind:newEmail
          bind:newName
          {error}
          {currentEmail}
          {currentName}
          onSubmitChange={submitChange}
          onResetControl={resetControl}
        />
      </div>
    </div>
  </div>
</div>
