import BaseSyncRepository from "repositories/BaseSyncRepository.js";

export default class AutoLeaseRepository extends BaseSyncRepository {
  async update(params) {
    return this.baseQuery(this.baseEndpoint, {
      method: "PATCH",
      headers: this.headers,
      body: JSON.stringify({ auto_lease: params })
    });
  }

  async recalculate() {
    return this.baseQuery(`${this.baseEndpoint}/recalculate`, {
      method: "GET",
      headers: this.headers
    });
  }
}
