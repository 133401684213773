<script>
  import { getContext } from "svelte";
  import { fly } from "svelte/transition";
  import SaveTemplateDialog from "../dialogs/SaveTemplateDialog.svelte";
  import { isDirty } from "../stores/DirtyReportParams";
  import { reportStore } from "../stores/reportStore";

  export let createTemplate;
  export let updateTemplate;
  export let templates;

  const { open, close } = getContext("simple-modal");

  const onSave = (name, existingTemplate) => {
    if (existingTemplate) {
      updateTemplate(existingTemplate.id, { report_id: $reportStore.id });
    } else {
      createTemplate(name);
    }

    close();
  };

  const onCancel = () => {
    close();
  };

  const showDialog = () => {
    open(
      SaveTemplateDialog,
      {
        onSave,
        onCancel,
        templates
      },
      {
        styleWindow: { width: "35%", height: "auto" },
        closeButton: false,
        closeOnOuterClick: false,
        transitionWindow: fly,
        transitionWindowProps: {
          y: -100,
          duration: 500
        }
      }
    );
  };
</script>

<template lang="pug">
  button.btn(on:click|preventDefault="{showDialog}", disabled="{$isDirty}") Save as Firm Template
</template>

<style lang="scss">
  button {
    color: #1b83ff;
    background: transparent;
    font-family: "Montserrat";
    font-size: 0.8rem;
    padding: 8px 10px;

    &:before {
      content: "\f0c7";
      font-family: FontAwesome;
      margin-right: 5px;
    }

    &:hover,
    &:active,
    &:focus {
      color: #0060d6;
    }

    &:disabled {
      background: transparent;
      color: #1b83ff;
      opacity: 0.6;
    }
  }
</style>
