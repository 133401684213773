<script>
  import { specificationTree } from "../stores/specificationTree";
  import { loadingStatesStore } from "../stores/loadingStatesStore";
  import { isDirty } from "../stores/DirtyReportParams";
  import { fmapConcat } from "../utils";

  export let specTreeNodeId;
  export let refreshReportParams;
  export let refreshType = "partial";

  let iconRef;

  $: loading = $loadingStatesStore.includes(specTreeNodeId);
  $: {
    if (iconRef) {
      const icon = window.$(iconRef);
      icon.tooltip({
        title: "Update to Current Value",
        toggle: "tooltip",
        placement: "top"
      });
    }
  }

  function findNode(tree, id) {
    for (const item of tree) {
      if (item.id === id) return [item];
      const children = item.children;

      if (children) {
        const foundNode = findNode(children, id);
        if (foundNode.length !== 0) return foundNode;
      }
    }

    return [];
  }

  async function handleUpdate() {
    const node = findNode($specificationTree, specTreeNodeId);
    const attributes = fmapConcat((item) => [item], node);
    const params = attributes.flatMap((item) => item.param || []);
    const ids = attributes.flatMap((item) => [item.id] || []);

    loadingStatesStore.set(ids);

    try {
      await refreshReportParams(params, refreshType);
    } finally {
      loadingStatesStore.set([]);
    }
  }
</script>

{#if specTreeNodeId}
  {#if $isDirty}
    <i class:loading class="fa refresh-button" disabled />
  {:else}
    <i
      bind:this={iconRef}
      class:loading
      class="fa refresh-button"
      on:click|stopPropagation|self={handleUpdate}
    />
  {/if}
{/if}
