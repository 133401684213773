<script>
  import XeroButton from "./sso/XeroButton.svelte";

  let emailValue = "";
  let passwordValue = "";
  let ssoLogin = false;
  export let loginPath = "";
  export let accountantPath;
  export let passRecoveryPath = "";
  export let authenticityToken = "";
  export let intuitSsoPath = "";
  export let xeroSsoPath = "";
  export let registrationPath = "";
  export let disableSignup = false;
</script>

<div>
  {#if ssoLogin}
    <h1>
      <b>
        Use Payroll
        <br />
        Provider
      </b>
    </h1>
    <div class="tpp-login-sso">
      <div class="tpp-login-sso__button_intuit">
        <a href={intuitSsoPath} class="intuit-signin__link devise-form__mt-2" type="button">
          Sign in with Intuit
        </a>
      </div>
      <div class="tpp-login-sso__button_xero">
        <XeroButton {xeroSsoPath} />
      </div>
    </div>
    <div class="devise-form__back-to-login devise-form__mt-5" on:click={() => (ssoLogin = false)}>
      Back to login
    </div>
  {:else}
    <h1>
      <b>
        Welcome
        <br />
        Back !
      </b>
    </h1>
    <div class="devise-form">
      <form id="new_user" class="devise-form__container" novalidate action={loginPath} method="post">
        <input type="hidden" name="authenticity_token" value={authenticityToken} />
        <input
          class="devise-form__input"
          name="user[email]"
          type="email"
          placeholder="Email Address"
          bind:value={emailValue}
        />
        <input
          class="devise-form__input"
          name="user[password]"
          type="password"
          placeholder="Password"
          bind:value={passwordValue}
        />
        <a class="devise-form__action-link devise-form__mt-1" href={passRecoveryPath}>
          Forgot your password?
        </a>
        <button type="submit" class="devise-form__button devise-form__sign-in">Log in</button>
        <div class="devise-form__action-link" on:click={() => (ssoLogin = true)}>Other login options</div>
        <a class="devise-form__action-link" style="margin-top: 3px" href={accountantPath}>
          Are you an accountant?
        </a>
        {#if !disableSignup}
          <a
            href={registrationPath}
            style="margin-top: 30px"
            class="devise-form__button devise-form__register-button"
          >
            Sign up
          </a>
        {/if}
      </form>
    </div>
  {/if}
</div>

<style>
  .tpp-login-sso {
    display: flex;
    flex-direction: column;
    gap: 9px;
  }
</style>
