<script>
  import { isNil } from "lodash";
  import numeral from "numeral";
  import { onMount, afterUpdate } from "svelte";
  import { personalProjectionValidation, personalProjection, visitField } from "../Store";
  import Tooltip from "./Tooltip.svelte";
  import TaxpayerSpouseNumericFieldBase from "./TaxpayerSpouseNumericFieldBase.svelte";

  export let key;
  export let override_key;
  export let override_guard_key;

  export let serverCalculatedParameters;
  export let tooltipLabel;
  export let tooltipInfo;

  let taxpayer_override_key = `${override_key}_taxpayer`;
  let taxpayer_override_guard_key = `${override_guard_key}_taxpayer`;

  let spouse_override_key = `${override_key}_spouse`;
  let spouse_override_guard_key = `${override_guard_key}_spouse`;
  let rawCalculatedValue;
  let calculatedValue;
  let isLoading = true;

  $: isOverride = $personalProjection[taxpayer_override_guard_key] !== 0;
  $: fieldHtmlId = `personal_projection_parameters[${taxpayer_override_key}]`;
  $: guardFieldHtmlId = `personal_projection_parameters[${taxpayer_override_guard_key}]`;

  function roundCalculatedValue(value) {
    return Math.round(10000 * value) / 10000;
  }

  $: {
    const serverCalculatedValue = serverCalculatedParameters[key];

    if (isNil(serverCalculatedValue)) {
      isLoading = true;
    } else {
      rawCalculatedValue = roundCalculatedValue(serverCalculatedValue);
      calculatedValue = numeral(serverCalculatedValue).format("0,0[.]0000");
      isLoading = false;
    }
  }

  function onChange() {
    if ($personalProjection[taxpayer_override_guard_key] === 0) {
      $personalProjection[taxpayer_override_guard_key] = 1;
      $personalProjection[spouse_override_guard_key] = 1; // Unused field, so null it anyway
      const serverCalculatedValue = serverCalculatedParameters[key];
      if (serverCalculatedValue) {
        $personalProjection[taxpayer_override_key] = roundCalculatedValue(serverCalculatedValue);
        $personalProjection[spouse_override_key] = 0.0;
      }
    } else {
      $personalProjection[taxpayer_override_guard_key] = 0;
      $personalProjection[spouse_override_guard_key] = 0; // Unused field, so null it anyway
      $personalProjection[taxpayer_override_key] = 0.0;
      $personalProjection[spouse_override_key] = 0.0;
    }
  }

  let spouseFieldIsShown;
  let spouseFieldIsTouched = false;

  function showSpouseData(_event) {
    spouseFieldIsShown = true;
    spouseFieldIsTouched = true;
  }
  function hideSpouseData(_event) {
    spouseFieldIsShown = false;
  }

  $: spouseFieldIsZero = $personalProjection[spouse_override_key] == 0;
  $: spouseFieldIsShown = !spouseFieldIsZero || spouseFieldIsTouched;

  function markFieldsVisited() {
    visitField(taxpayer_override_guard_key);
    visitField(spouse_override_guard_key);

    if (!isOverride) visitField(taxpayer_override_key);

    if (isOverride && spouseFieldIsShown) return;

    visitField(spouse_override_key);
  }

  onMount(markFieldsVisited);
  afterUpdate(markFieldsVisited);
</script>

<div class="personal-projection-form-content__column">
  <div class="form-group tpp-form-group">
    <label for={fieldHtmlId} class="col-form-label tpp-form-label">
      <Tooltip
        {tooltipLabel}
        {tooltipInfo}
        tooltipExtraClass={`tpp-input-label--personal_projection_parameters[${override_key}]`}
      />
    </label>

    <small class="ml-2">
      <label for={guardFieldHtmlId}>
        <input
          type="checkbox"
          id={guardFieldHtmlId}
          checked={isOverride}
          disabled={isOverride ? false : isLoading}
          on:change={onChange}
        />
        Override
      </label>
    </small>

    <span class="input-group input-group-twin">
      <div class="input-group-prepend">
        <span class="input-group-text">$</span>
      </div>
      {#if isOverride}
        <div class="input-group-twin__part">
          <TaxpayerSpouseNumericFieldBase
            key={taxpayer_override_key}
            bind:value={$personalProjection[taxpayer_override_key]}
            hasError={$personalProjectionValidation.has(taxpayer_override_key)}
            label="Taxpayer"
            placeholder={rawCalculatedValue}
            actionLabel="Spouse ›"
            actionShow={!spouseFieldIsShown}
            on:click={showSpouseData}
          />
        </div>
        <div
          class="input-group-twin__part input-group-twin__part_second"
          class:input-group-twin__part_hidden={!spouseFieldIsShown}
        >
          <TaxpayerSpouseNumericFieldBase
            key={spouse_override_key}
            bind:value={$personalProjection[spouse_override_key]}
            hasError={$personalProjectionValidation.has(spouse_override_key)}
            label="Spouse"
            actionLabel="‹ Hide"
            actionShow={spouseFieldIsShown && spouseFieldIsZero}
            on:click={hideSpouseData}
          />
        </div>
      {:else}
        <input
          value={isLoading ? "Loading..." : calculatedValue}
          disabled
          placeholder="0.0"
          id={fieldHtmlId}
          class="personal-projection-form-validation form-control"
        />
      {/if}
    </span>

    <span class="form-text" />
  </div>
</div>
