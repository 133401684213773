<script>
  import { isNil } from "lodash";
  import TaxpayerSpouseNumericField from "../fields/TaxpayerSpouseNumericField.svelte";

  export let statusNotice = null;
</script>

<div class="">
  <div class="personal-projection-form-content__section-header">
    <h2 class="personal-projection-form-content__section-name">Income</h2>
    {#if !isNil(statusNotice)}
      <small
        class="personal-projection-form-content__section-header-status font-weight-light col-lg-3 text-right text-muted"
      >
        {statusNotice}
      </small>
    {/if}
  </div>
  <div>
    <div class="personal-projection-form-content__fields">
      <TaxpayerSpouseNumericField
        key="social_security"
        tooltip="Enter the portion of Social Security benefits"
      />
      <TaxpayerSpouseNumericField key="fed_inc_bank_1_interest" />
      <TaxpayerSpouseNumericField key="fed_inc_ordinary_dividends" />
      <TaxpayerSpouseNumericField key="fed_inc_money_market_1_interest" />
      <TaxpayerSpouseNumericField key="fed_inc_stock_qualified_div" />
      <TaxpayerSpouseNumericField key="fed_inc_stock_st_div_gains" />
      <TaxpayerSpouseNumericField key="fed_inc_stock_lt_cap_gains" />
      <TaxpayerSpouseNumericField key="fed_inc_adj_capital_losses_this_year" />
      <TaxpayerSpouseNumericField key="fed_inc_stock_60man_cap_gains" />
      <TaxpayerSpouseNumericField key="fed_inc_other_income" />
      <TaxpayerSpouseNumericField key="fed_inc_ca_tax_refund" />
    </div>
  </div>
</div>
