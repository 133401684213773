<script>
  import NProgress from "nprogress";
  import { fade } from "svelte/transition";
  import ManagedItem from "../common/ManagedItem.svelte";
  import { deserializePersonalProjection } from "components/personal_projection/Serialization.js";
  import {
    businessCompaniesStore,
    personalProjection,
    personalProjectionSerialized
  } from "../personal_projection/Store";
  import { notyf_top_right } from "misc/notyf_top_right";
  import _ from "lodash";

  export let showPane;
  export let initialForm;
  export let setInitialParams;
  export let changedFieldsOfPersonalProjection;
  export let businessCompanies;
  export let lastAppliedProjection;
  export let simulatedProjections;
  export let simulatedPPRepository;
  export let getChangedFields;

  let initialPersonalProjectionSerialized = {};
  let initialBusinessCompanies = {};

  let simulatorName = "";

  async function saveSimulatedProjection() {
    const data = await handleRequest(
      simulatedPPRepository.create({
        ...changedFieldsOfPersonalProjection,
        name: simulatorName
      })
    );

    if (!data) return;

    lastAppliedProjection = data.result;
    simulatedProjections = [...simulatedProjections, lastAppliedProjection];
    simulatorName = "";
  }

  async function loadSimulatedProjections() {
    const data = await handleRequest(simulatedPPRepository.all());

    if (!data) return;

    simulatedProjections = data.result;
  }

  async function handleApply(id) {
    const data = await handleRequest(simulatedPPRepository.one(id));

    if (!data) return;

    lastAppliedProjection = simulatedProjections.find((p) => p.id === id);
    const simulatedProjection = data.result;
    const form = { ...initialForm, ...simulatedProjection };
    const deserializedForm = deserializePersonalProjection(form);
    personalProjection.set(deserializedForm);
    businessCompaniesStore.update(() => {
      let newCompanies = _.cloneDeep(businessCompanies);
      for (const company of Object.keys(newCompanies)) {
        newCompanies[company].revenue = form[`${company}_revenue`] || newCompanies[company].revenue;
        newCompanies[company].expense = form[`${company}_expense`] || newCompanies[company].expense;
      }
      return newCompanies;
    });
    initialPersonalProjectionSerialized = _.cloneDeep($personalProjectionSerialized);
    initialBusinessCompanies = _.cloneDeep($businessCompaniesStore);
  }

  async function handleRequest(request) {
    NProgress.start();
    try {
      const data = await request;
      return data;
    } catch (err) {
      if (err.name == "AbortError") return;
      notyf_top_right.error(err.message);
      return;
    } finally {
      NProgress.done();
    }
  }

  async function handleDelete(id) {
    const data = await handleRequest(simulatedPPRepository.delete(id));

    if (!data) return;

    if (data.result === "success") {
      if (lastAppliedProjection.id === id) {
        setInitialParams();
      }
      loadSimulatedProjections();
    }
  }

  function handleUpdate(id, customParams) {
    const params =
      customParams ||
      getChangedFields(
        initialPersonalProjectionSerialized,
        initialBusinessCompanies,
        $personalProjectionSerialized,
        $businessCompaniesStore
      );
    if (_.isEmpty(params)) return;

    return handleRequest(simulatedPPRepository.update(id, params));
  }

  const loadingProjections = loadSimulatedProjections();
</script>

<div class={`simulator-options-pane ${showPane ? "simulator-options-pane_visible" : ""}`}>
  <div class="simulator-options-pane__section">
    <h2 class="simulator-options-pane__header">Simulations</h2>
    <div class="simulated-pp-container">
      <input
        bind:value={simulatorName}
        type="string"
        id="simulated_projection_name"
        placeholder="Name"
        class="form-control simulated-pp-name"
      />
      <button class="btn btn-primary" disabled={simulatorName === ""} on:click={saveSimulatedProjection}>
        Save Current Simulation
      </button>
    </div>
    {#await loadingProjections}
      <div class="no-simulations loading-simulations">
        <span class="pane-loading-indicator__bounce1" />
        <span class="pane-loading-indicator__bounce2" />
        <span class="pane-loading-indicator__bounce3" />
      </div>
    {:then _}
      {#if simulatedProjections.length > 0}
        <ul class="list-group">
          {#each simulatedProjections as projection, i (projection.id)}
            <li transition:fade class="list-group-item">
              <ManagedItem
                id={projection.id}
                name={projection.name}
                lastApplied={projection.id === lastAppliedProjection.id}
                {handleUpdate}
                {handleDelete}
                {handleApply}
              />
            </li>
          {/each}
        </ul>
      {:else}
        <div class="no-simulations">No Simulations</div>
      {/if}
    {/await}
  </div>
</div>

<style lang="scss">
  .simulated-pp-container {
    display: flex;
  }

  .simulated-pp-name {
    border: 1px solid;
    margin-right: 5px;
    height: 38px;
  }

  .simulator-title {
    display: flex;
  }

  .simulator-options-pane {
    width: 700px;
    background: #f6f8f8;
    border-left: 1px solid #dce0e6;
    display: none;

    &_visible {
      display: flex;
      flex-direction: column;
      position: relative;
      height: calc(100vh - 116px);
      overflow-y: auto;
    }
    &__section {
      overflow-y: auto;
      overflow-x: hidden;
      padding: 15px 10px 0 10px;
    }
  }

  .simulator-options-pane__header {
    margin-bottom: 12px;
  }

  ul {
    padding-top: 0.5em;
  }

  li.list-group-item {
    padding: 5px 10px;
  }

  .no-simulations {
    font-family: "Montserrat";
    font-size: 1.2rem;
    padding-top: 20px;
    color: darkgrey;
    text-align: center;
  }
</style>
