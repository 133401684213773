<script>
  import { onMount } from "svelte";
  import _, { debounce, zip, mergeWith, get, capitalize } from "lodash";
  import { notyf_top_right } from "misc/notyf_top_right";
  import BaseSyncRepository from "repositories/BaseSyncRepository.js";

  export let homeOfficeAccountableEndpoint;
  export let homeOfficeAccountablePrintEndpoint;
  export let pld = {};

  const months = Array.from({ length: 12 }, (e, i) => {
    return new Date(null, i + 1, null).toLocaleDateString("en", { month: "long" }).toLowerCase();
  });

  const defaults = months.reduce(
    (o, month) => ({
      ...o,
      [month]: {
        variableHomeExpenses: {
          repairs: 0.0,
          clearning: 0.0,
          lawncare: 0.0,
          utilitiesMonthly: 0.0,
          totalVariableHomeExpenses: 0.0,
          deductiblePortionOfVariableHomeExpenses: 0.0
        },
        officeExpenses: {
          internet: 0.0,
          cellPhone: 0.0,
          landLine: 0.0,
          officeSupplies: 0.0,
          printing: 0.0,
          otherCosts: 0.0,
          businessUseAllocation: 100.0,
          totalOfficeExpenses: 0.0,
          deductiblePortionOfOfficeExpenses: 0.0
        },
        otherOutOfPocketExpenses: {
          travelCosts: 0.0,
          parkingTolls: 0.0,
          businessMeals: 0.0,
          otherExpenses: 0.0,
          deductiblePortionOfOtherOutOfPocketExpenses: 0.0
        },
        totalReimbursement: 0.0
      }
    }),
    {
      currentMonth: months[new Date().getMonth()],
      homeOfficeVariables: {
        homeOfficeSqft: 0.0,
        totalHomeSqft: 0.0,
        businessUsePercentage: 0.0
      },
      fullYearHomeExpenses: {
        rent: 0.0,
        mortgageInterest: 0.0,
        propertyTaxes: 0.0,
        homeInsurance: 0.0,
        utilitiesAnnual: 0.0,
        condoDues: 0.0,
        other: 0.0,
        totalFullYearHomeExpenses: 0.0,
        deductiblePortionOfFullYearHomeExpenses: 0.0
      }
    }
  );

  pld = _.merge(_.cloneDeep(defaults), pld);

  let recalculating = false;

  const baseSyncRepository = new BaseSyncRepository(homeOfficeAccountableEndpoint);

  const recalculate = _.debounce(async () => {
    if (recalculating) return false;
    recalculating = true;
    try {
      const data = await baseSyncRepository.baseQuery(baseSyncRepository.baseEndpoint, {
        method: "POST",
        headers: baseSyncRepository.headers,
        body: JSON.stringify({
          home_office_accountable: constraint(pld)
        })
      });

      pld = data.pld;
    } catch (error) {
      notyf_top_right.error(error.message);
    } finally {
      recalculating = false;
    }
  }, 1000);

  function constraint(pld, path = []) {
    return Object.entries(pld).reduce((o, [k, v]) => {
      return {
        ...o,
        [k]:
          typeof v === "object" && v !== null
            ? constraint(v, [...path, k])
            : v == null || (Number.isFinite(v) && v < 0.0)
              ? _.get(defaults, [...path, k])
              : v
      };
    }, {});
  }

  function numberToCurrency(value, round = 2) {
    const roundValue = Number(value).toFixed(round);
    return roundValue < 0
      ? `(${numberToCurrency(-roundValue)})`
      : `$${parseFloat(
          (roundValue == undefined ? "-" : roundValue).toLocaleString("en-US", {
            style: "currency",
            currency: "USD"
          })
        )}`;
  }

  onMount(async () => {
    await recalculate();
    if (!homeOfficeAccountablePrintEndpoint) {
      window.print();
      window.onfocus = () => window.close();
    }
  });
</script>

<div class="row">
  <div class="col-lg-12 home-office-accountable__overflow-x">
    {#if homeOfficeAccountablePrintEndpoint}
      <div class="float-right p-b-sm">
        <a href={homeOfficeAccountablePrintEndpoint} target="_blank">
          <input type="button" class="float-right btn btn-primary btn-trans" value="Print" />
        </a>
      </div>
    {/if}
    <div class="printable-area">
      <div class="row">
        <span class="visible-print header-strong text-center"><b>{_.capitalize(pld.currentMonth)}</b></span>
      </div>
      <div class="stepwizard hidden-print">
        <div class="stepwizard-row setup-panel">
          {#each months as month, i}
            <div class="stepwizard-step col-sm-1">
              <a
                href={`#step-${i + 1}`}
                type="button"
                class="btn btn-circle"
                class:btn-primary={pld.currentMonth == month}
                class:btn-secondary={pld.currentMonth != month}
                on:click={(_) => {
                  pld.currentMonth = month;
                  recalculate();
                }}
              >
                {i + 1}
              </a>
              <p><small>{_.capitalize(month)}</small></p>
            </div>
          {/each}
        </div>
      </div>
      <table class="table table-sm company-type">
        <thead class="header">
          <tr>
            <th>Home Office Variables</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Square Footage of Home Office</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">SQFT</span>
                </div>
                <input
                  id="homeOfficeSqft"
                  class="form-control"
                  type="number"
                  bind:value={pld.homeOfficeVariables.homeOfficeSqft}
                  placeholder="0"
                  min="0"
                  max={pld.homeOfficeVariables.totalHomeSqft || 0}
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">{pld.homeOfficeVariables.homeOfficeSqft} SQFT</span>
            </td>
          </tr>
          <tr>
            <td>Total Home Square Footage</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">SQFT</span>
                </div>
                <input
                  id="totalHomeSqft"
                  class="form-control"
                  type="number"
                  bind:value={pld.homeOfficeVariables.totalHomeSqft}
                  placeholder="0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">{pld.homeOfficeVariables.totalHomeSqft} SQFT</span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Business Use Percentage</td>
            <td>
              <div
                class="text-right home-office-accountable"
                class:home-office-accountable__blur={recalculating}
              >
                {parseFloat(pld.homeOfficeVariables.businessUsePercentage.toFixed(2))}%
              </div>
            </td>
          </tr>
        </tfoot>
      </table>

      <table class="table table-sm company-type">
        <thead class="header">
          <tr>
            <th>Full Year Home Expenses (Annual)</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Rent</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="rent"
                  class="form-control"
                  type="number"
                  bind:value={pld.fullYearHomeExpenses.rent}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">${pld.fullYearHomeExpenses.rent}</span>
            </td>
          </tr>
          <tr>
            <td>Mortgage Interest</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="mortgageInterest"
                  class="form-control"
                  type="number"
                  bind:value={pld.fullYearHomeExpenses.mortgageInterest}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">${pld.fullYearHomeExpenses.mortgageInterest}</span>
            </td>
          </tr>
          <tr>
            <td>Property Taxes</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="propertyTaxes"
                  class="form-control"
                  type="number"
                  bind:value={pld.fullYearHomeExpenses.propertyTaxes}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">${pld.fullYearHomeExpenses.propertyTaxes}</span>
            </td>
          </tr>
          <tr>
            <td>Home Insurance</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="homeInsurance"
                  class="form-control"
                  type="number"
                  bind:value={pld.fullYearHomeExpenses.homeInsurance}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">${pld.fullYearHomeExpenses.homeInsurance}</span>
            </td>
          </tr>
          <tr>
            <td>Utilities (annual)</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="utilitiesAnnual"
                  class="form-control"
                  type="number"
                  bind:value={pld.fullYearHomeExpenses.utilitiesAnnual}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">${pld.fullYearHomeExpenses.utilitiesAnnual}</span>
            </td>
          </tr>
          <tr>
            <td>HOA/Condo Dues</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="condoDues"
                  class="form-control"
                  type="number"
                  bind:value={pld.fullYearHomeExpenses.condoDues}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">${pld.fullYearHomeExpenses.condoDues}</span>
            </td>
          </tr>
          <tr>
            <td>Other</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="other"
                  class="form-control"
                  type="number"
                  bind:value={pld.fullYearHomeExpenses.other}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">${pld.fullYearHomeExpenses.other}</span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Total Full Year Home Expenses</td>
            <td>
              <div
                class="text-right home-office-accountable"
                class:home-office-accountable__blur={recalculating}
              >
                {numberToCurrency(pld.fullYearHomeExpenses.totalFullYearHomeExpenses)}
              </div>
            </td>
          </tr>
          <tr>
            <td>Deductible Portion of Full Year Home Expenses</td>
            <td>
              <div
                class="text-right home-office-accountable"
                class:home-office-accountable__blur={recalculating}
              >
                {numberToCurrency(pld.fullYearHomeExpenses.deductiblePortionOfFullYearHomeExpenses)}
              </div>
            </td>
          </tr>
        </tfoot>
      </table>

      <table class="table table-sm company-type">
        <thead class="header">
          <tr>
            <th>Variable Home Expenses (Monthly)</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Repairs</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="repairs"
                  class="form-control"
                  type="number"
                  bind:value={pld[pld.currentMonth].variableHomeExpenses.repairs}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">${pld[pld.currentMonth].variableHomeExpenses.repairs}</span>
            </td>
          </tr>
          <tr>
            <td>Cleaning</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="clearning"
                  class="form-control"
                  type="number"
                  bind:value={pld[pld.currentMonth].variableHomeExpenses.clearning}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">${pld[pld.currentMonth].variableHomeExpenses.clearning}</span>
            </td>
          </tr>
          <tr>
            <td>Lawncare</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="lawncare"
                  class="form-control"
                  type="number"
                  bind:value={pld[pld.currentMonth].variableHomeExpenses.lawncare}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">${pld[pld.currentMonth].variableHomeExpenses.lawncare}</span>
            </td>
          </tr>
          <tr>
            <td>Utilities (monthly)</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="utilitiesMonthly"
                  class="form-control"
                  type="number"
                  bind:value={pld[pld.currentMonth].variableHomeExpenses.utilitiesMonthly}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">
                ${pld[pld.currentMonth].variableHomeExpenses.utilitiesMonthly}
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Total Variable Home Expenses</td>
            <td>
              <div
                class="text-right home-office-accountable"
                class:home-office-accountable__blur={recalculating}
              >
                {numberToCurrency(pld[pld.currentMonth].variableHomeExpenses.totalVariableHomeExpenses)}
              </div>
            </td>
          </tr>
          <tr>
            <td>Deductible Portion of Variable Home Expenses</td>
            <td>
              <div
                class="text-right home-office-accountable"
                class:home-office-accountable__blur={recalculating}
              >
                {numberToCurrency(
                  pld[pld.currentMonth].variableHomeExpenses.deductiblePortionOfVariableHomeExpenses
                )}
              </div>
            </td>
          </tr>
        </tfoot>
      </table>

      <table class="table table-sm company-type">
        <thead class="header">
          <tr>
            <th>Office Expenses (Monthly)</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Internet</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="internet"
                  class="form-control"
                  type="number"
                  bind:value={pld[pld.currentMonth].officeExpenses.internet}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">${pld[pld.currentMonth].officeExpenses.internet}</span>
            </td>
          </tr>
          <tr>
            <td>Cell Phone</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="cellPhone"
                  class="form-control"
                  type="number"
                  bind:value={pld[pld.currentMonth].officeExpenses.cellPhone}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">${pld[pld.currentMonth].officeExpenses.cellPhone}</span>
            </td>
          </tr>
          <tr>
            <td>Land Line</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="landLine"
                  class="form-control"
                  type="number"
                  bind:value={pld[pld.currentMonth].officeExpenses.landLine}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">${pld[pld.currentMonth].officeExpenses.landLine}</span>
            </td>
          </tr>
          <tr>
            <td>Office Supplies</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="officeSupplies"
                  class="form-control"
                  type="number"
                  bind:value={pld[pld.currentMonth].officeExpenses.officeSupplies}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">${pld[pld.currentMonth].officeExpenses.officeSupplies}</span>
            </td>
          </tr>
          <tr>
            <td>Printing</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="printing"
                  class="form-control"
                  type="number"
                  bind:value={pld[pld.currentMonth].officeExpenses.printing}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">${pld[pld.currentMonth].officeExpenses.printing}</span>
            </td>
          </tr>
          <tr>
            <td>Other Costs</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="otherCosts"
                  class="form-control"
                  type="number"
                  bind:value={pld[pld.currentMonth].officeExpenses.otherCosts}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">${pld[pld.currentMonth].officeExpenses.otherCosts}</span>
            </td>
          </tr>
          <tr>
            <td>Business Use Allocation</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">%</span>
                </div>
                <select
                  id="businessUseAllocation"
                  class="form-control"
                  bind:value={pld[pld.currentMonth].officeExpenses.businessUseAllocation}
                  on:blur={recalculate}
                >
                  <option disabled="" value="">-- Percentage --</option>
                  {#each [...Array(101).keys()].reverse() as i}
                    <option value={i}>{i}</option>
                  {/each}
                </select>
              </span>
              <span class="visible-print">
                <b>{pld[pld.currentMonth].officeExpenses.businessUseAllocation}%</b>
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Total Office Expenses</td>
            <td>
              <div
                class="text-right home-office-accountable"
                class:home-office-accountable__blur={recalculating}
              >
                {numberToCurrency(pld[pld.currentMonth].officeExpenses.totalOfficeExpenses)}
              </div>
            </td>
          </tr>
          <tr>
            <td>Deductible Portion of Office Expenses</td>
            <td>
              <div
                class="text-right home-office-accountable"
                class:home-office-accountable__blur={recalculating}
              >
                {numberToCurrency(pld[pld.currentMonth].officeExpenses.deductiblePortionOfOfficeExpenses)}
              </div>
            </td>
          </tr>
        </tfoot>
      </table>

      <table class="table table-sm company-type">
        <thead class="header">
          <tr>
            <th>Other Out of Pocket Expenses (not Paid by Business, Monthly)</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Travel Costs</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="travelCosts"
                  class="form-control"
                  type="number"
                  bind:value={pld[pld.currentMonth].otherOutOfPocketExpenses.travelCosts}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">${pld[pld.currentMonth].otherOutOfPocketExpenses.travelCosts}</span>
            </td>
          </tr>
          <tr>
            <td>Parking, Tolls</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="parkingTolls"
                  class="form-control"
                  type="number"
                  bind:value={pld[pld.currentMonth].otherOutOfPocketExpenses.parkingTolls}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">
                ${pld[pld.currentMonth].otherOutOfPocketExpenses.parkingTolls}
              </span>
            </td>
          </tr>
          <tr>
            <td>Business Meals</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="businessMeals"
                  class="form-control"
                  type="number"
                  bind:value={pld[pld.currentMonth].otherOutOfPocketExpenses.businessMeals}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">
                ${pld[pld.currentMonth].otherOutOfPocketExpenses.businessMeals}
              </span>
            </td>
          </tr>
          <tr>
            <td>Other Expenses</td>
            <td>
              <span class="input-group hidden-print">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input
                  id="otherExpenses"
                  class="form-control"
                  type="number"
                  bind:value={pld[pld.currentMonth].otherOutOfPocketExpenses.otherExpenses}
                  placeholder="0.0"
                  min="0"
                  on:change={recalculate}
                />
              </span>
              <span class="visible-print">
                ${pld[pld.currentMonth].otherOutOfPocketExpenses.otherExpenses}
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>Deductible Portion of Out of Pocket Expenses</td>
            <td>
              <div class="text-right" class:home-office-accountable__blur={recalculating}>
                {numberToCurrency(
                  pld[pld.currentMonth].otherOutOfPocketExpenses.deductiblePortionOfOtherOutOfPocketExpenses
                )}
              </div>
            </td>
          </tr>
        </tfoot>
      </table>

      <table class="table table-sm company-type">
        <thead class="header-strong">
          <tr>
            <th>Total Reimbursement</th>
            <th>
              <div class="text-right" class:home-office-accountable__blur={recalculating}>
                {numberToCurrency(pld[pld.currentMonth].totalReimbursement)}
              </div>
            </th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</div>

<style lang="sass">
  .visible-print
    display: none !important

  .home-office-accountable
    transition: 0.2s filter linear

  .home-office-accountable__blur
    filter: blur(1px) opacity(0.2)

  @media print
    .visible-print
      display: block !important

    .hidden-print
      display: none !important

    .home-office-accountable__blur
      transition: none !important
      filter: none !important

  .home-office-accountable__overflow-x
    overflow-x: auto

  .header
    background: #452161
    color: white
    &-strong
      text-transform: uppercase

  table > thead > tr > th
    border: none

  table > tfoot
    border-top: 1px solid #ddd

  table > tbody > tr > td
    border-top: none

  table > tfoot > tr > td
    border-top: none

  tbody tr:first-child td
    padding-top: 1rem

  tbody tr:last-child td
    padding-bottom: 1rem

  .stepwizard-step p
    margin-top: 0px
    color:#666

  .stepwizard-row
    display: table-row

  .stepwizard
    display: table
    width: 100%
    position: relative

  .stepwizard-row:before
    top: 14px
    left: 0
    bottom: 0
    position: absolute
    content:" "
    width: 100%
    height: 1px
    background-color: #ccc
    z-index: 0

  .stepwizard-step
    display: table-cell
    text-align: center
    position: relative

  .btn-secondary
    background-color: #F6F8F8
    color: #bbb

  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active
    background-color: #27B6AF
    color: white

  .btn-circle
    width: 30px
    height: 30px
    text-align: center
    padding: 6px 0
    font-size: 12px
    line-height: 1.428571429
    border-radius: 15px

  .p-b-sm
    padding-bottom: .5em
</style>
