<script>
  import RefreshParamsButton from "../buttons/RefreshParamsButton.svelte";
  import { flatSpecTree } from "../stores/specificationTree";
  import DirtyParamTooltip from "../helpers/DirtyParamTooltip.svelte";

  export let value;
  export let hidden;
  export let options;
  export let specTreeNodeId;
  export let refreshReportParams;
  export let isDisabled = false;

  $: reportOption = $flatSpecTree && specTreeNodeId && $flatSpecTree[specTreeNodeId];

  const id = Math.random().toString(16).substr(2, 8);
</script>

<template lang="pug">
  .row({hidden})
    .col-sm-12
      .form-group
        .report_option
          label(for="{id}", class="report_option__title") {reportOption.title}
          DirtyParamTooltip(param="{specTreeNodeId}")
          +if('reportOption.updatable')
            RefreshParamsButton({refreshReportParams}, {specTreeNodeId})
        select(id="{id}", disabled="{isDisabled}", class="form-control", bind:value)
          +each('Object.entries(options) as [key, value]')
            option(value="{key}") {value}
</template>
