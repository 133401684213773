<script>
  import { fade, fly } from "svelte/transition";
  import { quintOut } from "svelte/easing";

  export let showStateInStore;
  export let showBackdrop = true;
  export let onSave;
  export let saving = false;
  export let disabledSaveButton = false;
  export let title = "Modal title";
  export let saveButtonTitle = "Save changes";
  export let modalId = "sampleModal";
  export let modalTitleId = "sampleModalLabel";

  const modalClose = async (event) => {
    if (event == "save" && onSave && !(await onSave())) return;
    showStateInStore.set(false);
  };

  $: {
    $showStateInStore
      ? window.document.body.classList.add("modal-open")
      : window.document.body.classList.remove("modal-open");
  }
</script>

<svelte:body class="open-modal" />

{#if $showStateInStore}
  <div
    class="modal fade{$showStateInStore && ' show'}"
    id={modalId}
    tabindex="-1"
    role="dialog"
    aria-labelledby={modalTitleId}
    aria-hidden="false"
  >
    <div
      class="modal-dialog"
      role="document"
      in:fly={{ y: -50, duration: 300 }}
      out:fly={{ y: -50, duration: 300, easing: quintOut }}
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id={modalTitleId}>{title}</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            on:click={() => modalClose("close")}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            on:click={() => modalClose("close")}
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            on:click={() => modalClose("save")}
            disabled={disabledSaveButton || saving}
          >
            {#if saving}
              <div class="spinner">
                <span class="bounce1"></span>
                <span class="bounce2"></span>
                <span class="bounce3"></span>
              </div>
            {:else}
              {saveButtonTitle}
            {/if}
          </button>
        </div>
      </div>
    </div>
  </div>
  {#if showBackdrop}
    <div class="modal-backdrop fade show" transition:fade={{ duration: 150 }}></div>
  {/if}
{/if}

<style lang="sass">
  .modal
    display: block

  // Spinner

  .spinner
    margin: 0 auto
    width: 70px
    text-align: center

    & > span
      width: 8px
      height: 8px
      background-color: #fff

      border-radius: 100%
      display: inline-block
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both
      animation: sk-bouncedelay 1.4s infinite ease-in-out both

    & .bounce1
      -webkit-animation-delay: -0.32s
      animation-delay: -0.32s

    & .bounce2
      -webkit-animation-delay: -0.16s
      animation-delay: -0.16s
</style>
