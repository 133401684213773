<script>
  import { onMount, onDestroy } from "svelte";
  import ModuleTiles from "./ModuleTiles.svelte";
  import { createConsumer } from "@rails/actioncable";
  import { PlanningModulesDataSource } from "./PlanningModulesDataSource.js";
  import { FeedbackModals } from "./FeedbackModals.js";
  import { v4 as uuidv4 } from "uuid";

  export let modules = [];
  export let modulesPath;
  export let completeModulePathBase;
  export let declineModulePathBase;
  export let planningModulePathBase;

  const taskId = uuidv4();

  $: planners = modules.filter((module) => module.type === "planner");
  $: concepts = modules.filter((module) => module.type === "concept");
  $: hasPlanners = planners.length > 0;
  $: hasConcepts = concepts.length > 0;

  let _planningModulesDataSource = new PlanningModulesDataSource(
    modulesPath,
    completeModulePathBase,
    declineModulePathBase
  );
  let _feedbackModals = new FeedbackModals(taskId);

  function handleChangeState(key, newState) {
    if (_feedbackModals.hasModal(key, newState)) {
      _feedbackModals.presentModal(key, newState);
    } else {
      handleStateWithoutModal(key, newState);
    }
  }

  function handleStateWithoutModal(key, newState) {
    switch (newState) {
      case "declined":
        _planningModulesDataSource.declineModule(key, taskId);
        break;
      case "completed":
        _planningModulesDataSource.completeModule(key, taskId);
        break;
      default:
        alert(`Undefined new state ${newState}`);
    }
  }

  function updateModulesArray(newPlanner) {
    for (let i = 0; i < modules.length; i++) {
      if (modules[i].id === newPlanner.id) {
        modules[i] = { ...modules[i], ...newPlanner };
        break;
      }
    }
  }

  let consumer = createConsumer();
  let p_subscription;

  onMount(() => {
    p_subscription = consumer.subscriptions.create(
      { channel: "PlanningModulesChannel", task_id: taskId },
      {
        received: (planner) => updateModulesArray(planner),
        connected: () => _planningModulesDataSource.loadModules(taskId)
      }
    );
  });

  onDestroy(() => {
    if (p_subscription) consumer.subscriptions.remove(p_subscription);
  });
</script>

<div class="card">
  <div class="card-header">
    <ul class="nav nav-pills">
      {#if hasPlanners}
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#planners">Strategies</a>
        </li>
      {/if}
      {#if hasConcepts}
        <li class="nav-item" class:active={!hasPlanners}>
          <a class="nav-link" data-toggle="tab" href="#concepts">Concepts</a>
        </li>
      {/if}
    </ul>
  </div>
  <div class="card-body">
    <div class="tab-content">
      {#if hasPlanners}
        <ModuleTiles
          id={"planners"}
          active={true}
          modules={planners}
          {handleChangeState}
          {planningModulePathBase}
        />
      {/if}
      {#if hasConcepts}
        <ModuleTiles
          id={"concepts"}
          active={!hasPlanners}
          modules={concepts}
          {handleChangeState}
          {planningModulePathBase}
        />
      {/if}
    </div>
  </div>
</div>
