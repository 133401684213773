<script>
  import { onMount } from "svelte";
  import RefreshParamsButton from "../buttons/RefreshParamsButton.svelte";
  import { flatSpecTree } from "../stores/specificationTree";
  import DirtyParamTooltip from "../helpers/DirtyParamTooltip.svelte";

  export let value;
  export let hidden;
  export let specTreeNodeId;
  export let refreshReportParams;
  export let dateFormat = "mm/dd/yyyy";

  let datepicker;
  const id = Math.random().toString(16).substr(2, 8);

  $: reportOption = $flatSpecTree && specTreeNodeId && $flatSpecTree[specTreeNodeId];
  $: window.$(datepicker).datepicker("update", value);

  onMount(() => {
    const currentDatepicker = window.$(datepicker);
    currentDatepicker.datepicker({
      autoclose: true,
      language: "en",
      ...(dateFormat ? { format: dateFormat } : {})
    });

    currentDatepicker.on("changeDate", function () {
      value = currentDatepicker.datepicker("getFormattedDate");
    });
  });
</script>

<template lang="pug">
  .row({hidden})
    .col-sm-12
      .form-group
        .report_option
          label(for="{id}", class="report_option__title") {reportOption.title || 'Date'}
          DirtyParamTooltip(param="{specTreeNodeId}")
          +if('reportOption.updatable')
            RefreshParamsButton({refreshReportParams}, {specTreeNodeId})
        .input-group.date(
          bind:this="{datepicker}",
          data-provide="datepicker",
          data-format="{dateFormat}"
        )
          input.form-control(id="{id}")
          .input-group-prepend
            span.input-group-text.border-left-0.rounded-right
              i.fa.fa-calendar
</template>
