<script>
  import { onMount } from "svelte";
  import accounting from "accounting";
  import Chart from "chart.js";
  import _ from "underscore";

  export let dashboard;
  export let currentCompanyId;
  export let syncCompany;
  export let refreshDashboard;

  let syncing;
  let businessSourceChartCanvas;
  let businessSourceChartContext;
  let businessSourceChart;

  $: currentCompany = dashboard.companies.find((c) => c.id === currentCompanyId);
  $: {
    if (currentCompany && businessSourceChartContext) {
      if (businessSourceChart) businessSourceChart.destroy();
      businessSourceChart = incomeStructureChart(
        "pie",
        currentCompany.widgets.businessExpenseDestinationsPie,
        businessSourceChartContext
      );
    }
  }

  const sync = _.debounce(async () => {
    syncing = true;
    if (syncCompany && refreshDashboard && currentCompany) {
      await syncCompany(currentCompany.forceSyncPath);
      await refreshDashboard();
    }
    syncing = false;
  }, 400);

  function incomeStructureChart(type, dataSource, context) {
    const colors = [
      "#CFEADD",
      "#E4B470",
      "#EFADC8",
      "#C6E9A3",
      "#B0CBE6",
      "#BEBE97",
      "#C6B8BA",
      "#EAAAA0",
      "#8EDDB2",
      "#DDE480",
      "#88D9DA",
      "#D7BFE5",
      "#B7C277",
      "#B7D3B6",
      "#ECD1A8",
      "#ECDCDC",
      "#27B6AF",
      "#556B8D",
      "#EDCE8C",
      "#1F7BB6",
      "#909AA0"
    ];
    let labels = [];
    let values = [];

    const ref = dataSource.entries;

    for (const category in ref) {
      const value = ref[category];
      labels.push(category);
      values.push(value);
    }

    const config = {
      type: type,
      data: {
        datasets: [
          {
            data: values,
            backgroundColor: colors
          }
        ],
        labels: labels
      },
      options: {
        maintainAspectRatio: false,
        responsive: false,
        aspectRatio: 1,
        legend: {
          position: "bottom",
          onClick: () => {},
          labels: {
            boxWidth: 20,
            fontSize: 11,
            padding: 5
          }
        },
        tooltips: {
          mode: "single",
          callbacks: {
            label: function (tooltipItem, data) {
              const i = tooltipItem.index;
              const value = accounting.formatMoney(data.datasets[0].data[i], "$", 0);

              return `${data.labels[i]} ${value}`;
            }
          }
        }
      }
    };
    return new Chart(context, config);
  }

  onMount(async (_promise) => {
    if (businessSourceChartCanvas) businessSourceChartContext = businessSourceChartCanvas.getContext("2d");
  });
</script>

<template>
  {#if currentCompany}
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 d-flex flex-column">
      <div class="card flex-align mb-3">
        <div class="card-header">
          <h3 class="card-title mb-0 float-left">Business Expenses by Destination</h3>
          <a
            hidden={!currentCompany.widgets.businessExpenseDestinationsPie.active}
            title="Sync Now"
            class="card-title mb-0 btn refresh-btn js-business-source-chart__refresh-btn float-right"
            on:click={sync}
          >
            <span class="fa fa-refresh refresh-btn__icon" class:refresh-btn__icon-animation={syncing} />
          </a>
          <div class="clearfix" />
        </div>
        <div
          class="card-body aligned-panels__panel js-business-source-chart source-chart"
          class:inactive={!currentCompany.widgets.businessExpenseDestinationsPie.active}
        >
          <canvas bind:this={businessSourceChartCanvas} />
        </div>
      </div>
    </div>
  {/if}
</template>

<style lang="sass">
  .inactive
    opacity: 0.2
</style>
