<script>
  import { searchTerm } from "./stores/searchStore";
  export let title;
  export let placeholder = "";

  function handleClear() {
    searchTerm.set("");
  }
</script>

<template lang="pug">
  .row
    .col-sm-12
      .form-group
        +if('title')
          label(for="InputTitle", class="report_option__title") {title}
        .searchgroup.btn-group
          input({placeholder}, type="search", bind:value="{$searchTerm}", class="form-control")
</template>

<style>
  .searchgroup {
    width: 100%;
  }
  input[type="search"]::-webkit-search-cancel-button {
    cursor: pointer;
  }
</style>
