<script>
  import PlanningVariants from "./common/PlanningVariants.svelte";
  import CounterPanel from "./CounterPanel.svelte";
  import accounting from "accounting";

  export let model;
  export let isLoading;
  export let onNumberOfChildrenChanged;

  let businessIncome = accounting.formatMoney(model.business_profits, "$", 0);
</script>

<div>
  <div class="row">
    <div
      class="col-lg-6 widget-mini card-solid-default text-center planning-module-placeholder__panel wow animated zoomIn"
    >
      <div class="card-body">
        <span class="total">{businessIncome}</span>
        <span class="title">Current Business Income</span>
      </div>
    </div>
    <div
      class="col-lg-6 widget-mini card-solid-default text-center planning-module-placeholder__panel wow animated zoomIn"
    >
      <CounterPanel
        title="Number of Children"
        value={model.number_of_children}
        minValue={0}
        maxValue={8}
        onValueChanged={onNumberOfChildrenChanged}
      />
    </div>
  </div>
  <div class="row mb-3">
    {#each model.variants as variant, i (variant)}
      <div class="col-12 d-flex">
        <PlanningVariants
          firstTitle="If you pay each child per year"
          firstTotal={accounting.formatMoney(variant.expenses, "$", 0)}
          secondTitle="you will save"
          secondTotal={accounting.formatMoney(variant.savings, "$", 0)}
          containerClasses={`sep--ira__variant-${i + 1}`}
          {isLoading}
        />
      </div>
    {/each}
  </div>
</div>
