<script>
  import { afterUpdate } from "svelte";

  export let strategyUpdateUrl;
  export let strategyAfterUpdateUrl;
  export let previousYear;
  export let currentYear;
  export let selectedYear;
  export let csrf;

  let initialSelectedYear = selectedYear;
  let form = null;

  $: {
    if (initialSelectedYear !== selectedYear && form !== null) {
      afterUpdate(() => form.submit());
    }
  }

  function updateYear(event) {
    selectedYear = event.target.value;
  }
</script>

<div>
  <form class="form-inline">
    <small>Tax Year:</small>
    <select class="form-control form-control-sm ml-2" on:change={updateYear} bind:value={selectedYear}>
      <option value={previousYear}>{previousYear}</option>
      <option value={currentYear}>{currentYear}</option>
    </select>
  </form>

  <form action={strategyUpdateUrl} method="post" bind:this={form}>
    <input type="hidden" name="_method" value="put" />
    <input type="hidden" name="authenticity_token" value={csrf} />
    <input type="hidden" name="strategy" value={selectedYear} />
    <input type="hidden" name="redirect_after_update" value={strategyAfterUpdateUrl} />
  </form>
</div>

<style lang="scss">
  select.form-control {
    appearance: auto;
  }
</style>
