<script>
  export let redirectUrl;
  export let className;
  export let caption;
</script>

<a href={redirectUrl} class={`btn ${className} btn-sm btn-primary summary-info__btn`} target="_blank">
  <i class="fa fa-user" />
  &nbsp;{caption}
</a>
