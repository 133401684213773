<!-- 

  https://developer.xero.com/documentation/xero-app-store/app-partner-guides/sign-in/#2-create-your-sign-in-with-xero-button

  Default source code:

  ```
    <script src="https://edge.xero.com/platform/sso/xero-sso.js" defer></script>
    <span data-xero-sso data-href="{xeroSsoPath}" data-label="Sign in with Xero"></span>
  ```

  Code below is extracted from the code above

-->
<script>
  export let xeroSsoPath;
</script>

<span
  data-xero-sso
  data-href={xeroSsoPath}
  data-label="Sign in with Xero"
  data-size="normal"
  data-box-shadow="none"
  data-no-track="true"
  dara-border-color="#dddddd"
  data-border-width="4"
  class="xero-sso xero-sso-inline xero-sso-normal xero-sso-light xero-sso-font-family"
  style="background-color: transparent !important;"
>
  <a href={xeroSsoPath} class="xero-sso-link">
    <img
      src="data:image/svg+xml,%3Csvg viewBox='0 0 45 46' xmlns='http://www.w3.org/2000/svg'%3E %3Ctitle%3EXero%3C/title%3E %3Cpath fill='%2313B5EA' d='M22.457 45.49c12.402 0 22.456-10.072 22.456-22.495C44.913 10.57 34.86.5 22.457.5 10.054.5 0 10.57 0 22.995 0 35.418 10.054 45.49 22.457 45.49' /%3E %3Cpath fill='%23FFFFFF' d='M10.75 22.935l3.832-3.85a.688.688 0 0 0-.977-.965l-3.83 3.833-3.845-3.84a.687.687 0 0 0-.966.979l3.832 3.837-3.83 3.84a.688.688 0 1 0 .964.981l3.84-3.842 3.825 3.827a.685.685 0 0 0 1.184-.473.68.68 0 0 0-.2-.485l-3.83-3.846m22.782.003c0 .69.56 1.25 1.25 1.25a1.25 1.25 0 0 0-.001-2.5c-.687 0-1.246.56-1.246 1.25m-2.368 0c0-1.995 1.62-3.62 3.614-3.62 1.99 0 3.613 1.625 3.613 3.62s-1.622 3.62-3.613 3.62a3.62 3.62 0 0 1-3.614-3.62m-1.422 0c0 2.78 2.26 5.044 5.036 5.044s5.036-2.262 5.036-5.043c0-2.78-2.26-5.044-5.036-5.044a5.046 5.046 0 0 0-5.036 5.044m-.357-4.958h-.21c-.635 0-1.247.2-1.758.595a.696.696 0 0 0-.674-.54.68.68 0 0 0-.68.684l.002 8.495a.687.687 0 0 0 1.372-.002v-5.224c0-1.74.16-2.444 1.648-2.63.14-.017.288-.014.29-.014.406-.015.696-.296.696-.675a.69.69 0 0 0-.69-.688m-13.182 4.127c0-.02.002-.04.003-.058a3.637 3.637 0 0 1 7.065.055H16.2zm8.473-.13c-.296-1.403-1.063-2.556-2.23-3.296a5.064 5.064 0 0 0-5.61.15 5.098 5.098 0 0 0-1.973 5.357 5.08 5.08 0 0 0 4.274 3.767c.608.074 1.2.04 1.81-.12a4.965 4.965 0 0 0 1.506-.644c.487-.313.894-.727 1.29-1.222.006-.01.014-.017.022-.027.274-.34.223-.826-.077-1.056-.254-.195-.68-.274-1.014.156-.072.104-.153.21-.24.315-.267.295-.598.58-.994.802-.506.27-1.08.423-1.69.427-1.998-.023-3.066-1.42-3.447-2.416a3.716 3.716 0 0 1-.153-.58l-.01-.105h7.17c.982-.022 1.51-.717 1.364-1.51z' /%3E %3C/svg%3E"
      alt="Xero logo"
      aria-role="presentation"
      class="xero-sso-logo"
    />
    <span class="xero-sso-label">Sign in with Xero</span>
  </a>
</span>

<style lang="sass">
  // Xero Button Overrides
  .xero-sso-link
    padding: 1px 5px 0.5px 0px !important
    border-radius: 4px !important
    border-color: #dddddd !important
    font-size: 14px !important
    &:focus
      box-shadow: none !important

  // Default Styles
  .xero-sso
    box-sizing: border-box
    -webkit-animation: none 0s ease 0s 1 normal none running
    animation: none 0s ease 0s 1 normal none running
    -webkit-backface-visibility: visible
    backface-visibility: visible
    background: transparent none repeat 0 0/auto auto padding-box border-box scroll
    border: none
    border-collapse: separate
    -o-border-image: none
    border-image: none
    border-radius: 0
    border-spacing: 0
    bottom: auto
    box-shadow: none
    box-sizing: content-box
    caption-side: top
    clear: none
    clip: auto
    color: #000
    -moz-columns: auto
    -moz-column-count: auto
    -moz-column-fill: balance
    column-fill: balance
    grid-column-gap: normal
    -moz-column-gap: normal
    column-gap: normal
    -moz-column-rule: medium none currentColor
    column-rule: medium none currentColor
    -moz-column-span: 1
    column-span: 1
    -moz-column-width: auto
    columns: auto
    content: normal
    counter-increment: none
    counter-reset: none
    cursor: auto
    direction: ltr
    display: inline
    empty-cells: show
    float: none
    font-family: serif
    font-size: medium
    font-style: normal
    -webkit-font-feature-settings: normal
    font-feature-settings: normal
    font-variant: normal
    font-weight: 400
    font-stretch: normal
    line-height: normal
    height: auto
    -webkit-hyphens: none
    -ms-hyphens: none
    hyphens: none
    left: auto
    letter-spacing: normal
    list-style: disc outside none
    margin: 0
    max-height: none
    max-width: none
    min-height: 0
    min-width: 0
    opacity: 1
    orphans: 2
    outline: medium none invert
    overflow: visible
    overflow-x: visible
    overflow-y: visible
    page-break-after: auto
    page-break-before: auto
    page-break-inside: auto
    perspective: none
    perspective-origin: 50% 50%
    position: static
    right: auto
    -moz-tab-size: 8
    -o-tab-size: 8
    tab-size: 8
    table-layout: auto
    text-align: left
    -moz-text-align-last: auto
    text-align-last: auto
    text-decoration: none
    text-indent: 0
    text-shadow: none
    text-transform: none
    top: auto
    transform: none
    transform-origin: 50% 50% 0
    transform-style: flat
    transition: none 0s ease 0s
    unicode-bidi: normal
    vertical-align: baseline
    visibility: visible
    white-space: normal
    widows: 2
    width: auto
    word-spacing: normal
    z-index: auto
    all: initial
    font-family: inherit
    display: inline-block
    padding: 0
  
  .xero-sso-font-family
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif
  
  .xero-sso > *
    box-sizing: border-box
  
  .xero-sso-logo
    border-radius: 50%
    width: 2em
    margin: .25em 9px .25em .25em
    vertical-align: middle
  
  .xero-sso-link
    line-height: 1.4
    padding: 0 16px
    border: 1px solid transparent
    text-align: center
    font-weight: 700
    text-decoration: none
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    -webkit-font-smoothing: antialiased
  
    &:focus
      text-decoration: none
      outline: none
  
  .xero-sso-label
    vertical-align: middle
    display: inline-block
    text-align: left
  
  .xero-sso-error
    padding: .5em
    background: #f2f3f4
    border: 1px solid red
    border-radius: 3px
    font-size: .8125em
  
  .xero-sso-inline
    display: inline-block
  
  .xero-sso-block
    display: block
  
    > .xero-sso-link
      width: 100%
  
  .xero-sso-small > .xero-sso-link
    font-size: 12px
    border-radius: 2px
  
  .xero-sso-large > .xero-sso-link
    font-size: 15px
    border-radius: 4px
    min-height: 40px
  
  .xero-sso-normal > .xero-sso-link
    font-size: 13px
    border-radius: 3px
  
  .xero-sso-dark > .xero-sso-link, .xero-sso-mono > .xero-sso-link
    background-color: #003c64
    border-color: #003c64
    color: #fff
  
    &:hover
      background-color: #002a46
  
    &:focus
      box-shadow: 0 0 0 3px rgba(8, 48, 130, 0.4) !important
  
  .xero-sso-light > .xero-sso-link
    background: #fff
    border-color: #d1d2d3
    color: #404756
    box-shadow: none
  
    &:hover
      background-color: #f2f3f4
  
    &:focus
      box-shadow: 0 0 0 3px rgba(0, 10, 30, 0.2) !important
  
  .xero-sso-transparent > .xero-sso-link
    background-color: transparent
  
  .xero-sso-light.xero-sso-transparent > .xero-sso-link:hover
    background-color: rgba(0, 0, 0, 0.03)
  
  .xero-sso-dark.xero-sso-transparent > .xero-sso-link:hover, .xero-sso-mono.xero-sso-transparent > .xero-sso-link:hover
    background-color: rgba(0, 0, 0, 0.3)
</style>
