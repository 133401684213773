<script>
  import YearSwitch from "components/personal_projection/YearSwitch.svelte";
  import Tabs from "components/personal_projection/Tabs.svelte";
  import { tabs } from "components/personal_projection/tabsStore.js";

  export let previousYear;
  export let currentYear;
  export let selectedYear;
  export let strategyUpdateUrl;
  export let strategyAfterUpdateUrl;
  export let serverCalculatedParameters;
  export let statusNotice;
  export let projectionResetUrl;
  export let csrf;

  let currentTab = 0;

  function confirmReset(e) {
    if (!confirm("This will reset to defaults your current projection. Are you sure you want to proceed?")) {
      e.preventDefault();
    }
  }
</script>

<div class="pageheader">
  <h1 class="personal-projection-form-header">Personal Projection Data Entry</h1>
  <div class="personal-projection-form-top-actions breadcrumb-wrapper">
    <YearSwitch
      {previousYear}
      {currentYear}
      {selectedYear}
      {strategyUpdateUrl}
      {strategyAfterUpdateUrl}
      {csrf}
    />
  </div>
</div>

<section id="main-content">
  <div class="personal-projection-form">
    <div class="personal-projection-form__navigation">
      <div class="personal-projection-form-navigation">
        <Tabs bind:currentTab />
      </div>
    </div>
    <div class="personal-projection-form__content">
      <div class="personal-projection-form-content">
        <svelte:component this={$tabs[currentTab].component} {serverCalculatedParameters} {statusNotice} />
        <form action={projectionResetUrl} method="POST" on:submit={confirmReset}>
          <input type="hidden" name="authenticity_token" value={csrf} />
          <input type="hidden" name="_method" value="DELETE" />
          <button class="btn btn-primary btn-trans" type="submit">Reset</button>
        </form>
      </div>
    </div>
  </div>
</section>
