<script>
  import _ from "lodash";
  import { onMount, setContext } from "svelte";
  import moment from "moment";
  import jQuery from "jquery";
  import NProgress from "nprogress";
  import DefaultPersonal from "components/default_personal/DefaultPersonal.svelte";
  import Simulator from "components/simulator/Simulator.svelte";
  import { personalProjection, personalProjectionSerialized } from "components/personal_projection/Store.js";
  import { deserializePersonalProjection } from "components/personal_projection/Serialization.js";

  export let initial_form_state;
  export let personal_projection_result_fields;
  export let projection_save_url;
  export let projection_reset_url;
  export let strategy_update_url;
  export let strategy_after_update_url;
  export let csrf;
  export let current_year;
  export let previous_year;
  export let previous_year_strategy_selected;
  export let projection_type;
  export let business_companies;
  export let simulated_personal_projections_url;

  const selectedYear = previous_year_strategy_selected ? previous_year : current_year;

  let requestInFlight = null;
  let serverCalculatedParameters = {};
  let statusNotice = null;
  let initialPersonalProjection;

  const debouncedServerPost = _.debounce(function (pp) {
    NProgress.start();
    requestInFlight = jQuery
      .ajax({
        url: projection_save_url,
        type: "PUT",
        data: { personal_projection_parameters: pp },
        success: (response) => {
          requestInFlight = null;
          serverCalculatedParameters = response.personal_projection_result_fields;
          statusNotice = `Saved at ${moment().format("h:mm:ss a")}, no unsaved changes`;
        }
      })
      .fail((_err) => {
        requestInFlight.abort();
        requestInFlight = null;
      })
      .always(() => {
        NProgress.done();
      });
  }, 1500);

  function debouncedSave(pp) {
    serverCalculatedParameters = {};
    statusNotice = "Unsaved changes present. Saving...";
    if (!_.isNil(requestInFlight)) {
      console.log("Aborting request in flight");
      requestInFlight.abort();
      requestInFlight = null;
    }
    if (_.isNil(pp)) return;
    debouncedServerPost(pp);
  }

  function subscribeIgnoreFirst(store, fn) {
    let firedFirst = false;
    return store.subscribe((state) => {
      if (!firedFirst) {
        firedFirst = true;
      } else {
        fn(state);
      }
    });
  }

  function ensureChangesSaved() {
    window.onbeforeunload = () => {
      if (statusNotice === "Unsaved changes present. Saving...") return true;
    };
  }

  onMount(() => {
    jQuery.ajaxSetup({
      beforeSend: function (xhr) {
        xhr.setRequestHeader("X-CSRF-Token", csrf);
      }
    });

    const deserializedInitialFormState = deserializePersonalProjection(initial_form_state);
    personalProjection.set(deserializedInitialFormState);
    initialPersonalProjection = _.cloneDeep($personalProjectionSerialized);
    serverCalculatedParameters = personal_projection_result_fields;
    subscribeIgnoreFirst(personalProjectionSerialized, debouncedSave);
    ensureChangesSaved();
  });

  setContext("ppType", { type: "default" });
</script>

{#if projection_type === "simulator"}
  <Simulator
    {requestInFlight}
    {serverCalculatedParameters}
    businessCompanies={business_companies}
    {simulated_personal_projections_url}
    initialForm={initial_form_state}
    {initialPersonalProjection}
  />
{:else}
  <DefaultPersonal
    previousYear={previous_year}
    currentYear={current_year}
    {selectedYear}
    strategyUpdateUrl={strategy_update_url}
    strategyAfterUpdateUrl={strategy_after_update_url}
    {serverCalculatedParameters}
    {statusNotice}
    projectionResetUrl={projection_reset_url}
    {csrf}
  />
{/if}
