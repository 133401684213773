<script>
  import accounting from "accounting";
  import Speedometer from "svelte-speedometer";

  export let dashboard;

  function formatMoney(val) {
    return accounting.formatMoney(val, {
      symbol: "$",
      precision: 0,
      format: {
        pos: "%s%v",
        neg: "-%s%v",
        zero: "%s%v"
      }
    });
  }
</script>

<template lang="pug">
  .col-sm-12.col-sm-6.col-lg-6.col-xl-3.d-flex.flex-column
    .card.flex-align.mb-3
      .card-header
        h3.card-title.mb-0 Total Tax Due
      .total-tax-due.card-body(class:inactive="{dashboard.widgets.taxChart.current == null}")
        Speedometer(
          value="{dashboard.widgets.taxChart.current || 0}",
          minValue="{dashboard.widgets.taxChart.min_bound || -25000}",
          maxValue="{dashboard.widgets.taxChart.max_bound || 40000}",
          customSegmentStops="{[dashboard.widgets.taxChart.min_bound || -25000, dashboard.widgets.taxChart.current || 0, dashboard.widgets.taxChart.max_bound || 40000]}",
          segmentColors="{['#1ABC9C', '#F0F3F3']}",
          currentValueText="{formatMoney(dashboard.widgets.taxChart.current || 0)}",
          textColor="#1ABC9C",
          labelFontSize="0px",
          valueTextFontSize="19px",
          needleColor="#1D212A",
          needleTransitionDuration="{3000}",
          needleTransition="easeElastic"
        )
        <br>
</template>

<style lang="sass">
  .total-tax-due
    text-align: center
    min-height: 110%
    display: flex
    flex-direction: column
    justify-content: center
  .inactive
    opacity: 0.2
</style>
