<script>
  import _ from "underscore/underscore";

  export let title;
  export let value;
  export let feedback;
  export let readOnly;
  export let serverValue;
  export let required;
  export let isValid = true;
  export let onInput;

  let id = _.uniqueId("input");
</script>

<div class="row string form-group" class:has-feedback={!!feedback}>
  <label class="string col-form-label" class:col-sm-3={!!feedback} class:col-sm-6={!feedback} for={id}>
    {title}
  </label>
  {#if feedback}
    <small class="form-text text-danger col-sm-3">{feedback}</small>
  {/if}

  <div class="col-sm-6">
    <input
      {required}
      class="string form-control"
      class:form-control-dirty={serverValue !== value && isValid}
      class:is-invalid={!isValid}
      type="text"
      {id}
      name={id}
      bind:value
      {readOnly}
      on:input={onInput}
    />
  </div>
</div>
