<script>
  import { concat, isNil, isNumber } from "lodash";
  import { personalProjection, personalProjectionValidation } from "../Store";
  import numeral from "numeral";
  import { buildNewEmployer } from "../Models";
  import CheckboxField from "../fields/CheckboxField.svelte";
  import NumericField from "../fields/NumericField.svelte";
  import SelectField from "../fields/SelectField.svelte";
  import TextField from "../fields/TextField.svelte";

  const taxpayerOrSpouseOptions = [
    { value: "TAXPAYER", title: "Taxpayer" },
    { value: "SPOUSE", title: "Spouse" }
  ];

  export let serverCalculatedParameters;
  export let statusNotice = null;

  let currentTab = 0;

  function removeEmployer(idx) {
    personalProjection.update((pp) => {
      currentTab = 0;
      return { ...pp, wages: pp.wages.filter((_, i) => i != idx) };
    });
  }

  function addEmployer() {
    personalProjection.update((pp) => {
      const newWages = concat(pp.wages, buildNewEmployer());
      currentTab = newWages.length - 1;
      return { ...pp, wages: newWages };
    });
  }

  function fieldHasError(set, tab, field) {
    return set.has(`wages[${tab}].${field}`);
  }
</script>

<div class="">
  <div class="personal-projection-form-content__section-header">
    <h2 class="personal-projection-form-content__section-name">Wages/W-2s</h2>
    {#if !isNil(statusNotice)}
      <small
        class="personal-projection-form-content__section-header-status font-weight-light col-lg-3 text-right text-muted"
      >
        {statusNotice}
      </small>
    {/if}
  </div>
  <div />
  <div id="section-2">
    <ul role="tablist" class="nav nav-tabs">
      {#each $personalProjection.wages as tab, i}
        <li role="presentation" class="tab__wrapper nav-item">
          <a
            class="nav-link"
            class:active={currentTab === i}
            id="section-2-tab-0"
            role="tab"
            aria-controls="section-2-pane-0"
            aria-selected="true"
            href={"#"}
            on:click={() => (currentTab = i)}
          >
            <div class="tab__number-circle">{i + 1}</div>
            &nbsp;{tab.employer_name}&nbsp;
            <span>
              <button
                type="button"
                class="btn btn-custom2 btn-circle-sm float-right"
                aria-label="Remove Employer Name"
                on:click={() => removeEmployer(i)}
              >
                <i class="fa fa-remove" />
              </button>
            </span>
          </a>
        </li>
      {/each}
      {#if $personalProjection.wages.length < 8}
        <li role="presentation" class="tab__wrapper nav-item">
          <a
            class="nav-link"
            id="section-2-tab-2"
            role="tab"
            aria-controls="section-2-pane-2"
            tabindex="-1"
            aria-selected="false"
            href={"#"}
            on:click={addEmployer}
          >
            <span>
              &nbsp;&nbsp;
              <span>
                <button type="button" class="btn btn-custom btn-xs" aria-label="Add Employer Name">
                  <span>Add</span>
                  <i class="fa fa-plus" />
                </button>
              </span>
            </span>
          </a>
        </li>
      {/if}
    </ul>
    <div class="tab-content">
      {#if $personalProjection.wages.length > 0}
        <div
          id="section-2-pane-0"
          aria-labelledby="section-2-tab-0"
          role="tabpanel"
          aria-hidden="false"
          class="fade tab tab-collapsed tab-collapsed-expand tab-pane active show"
        >
          <br />
          <br />
          <div>
            <div class="personal-projection-form-content__fields">
              <TextField
                key={`employer_name_${currentTab}`}
                visitKey={`wages[${currentTab}].employer_name`}
                tooltip="Enter the employer name"
                bind:value={$personalProjection.wages[currentTab].employer_name}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "employer_name")}
              />
              <SelectField
                key={`employer_type_${currentTab}`}
                visitKey={`wages[${currentTab}].employer_type`}
                bind:value={$personalProjection.wages[currentTab].employer_type}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "employer_type")}
                caption="Select Taxpayer or Spouse"
                options={taxpayerOrSpouseOptions}
              />
              <TextField
                key={`paystub_date_${currentTab}`}
                visitKey={`wages[${currentTab}].paystub_date`}
                placeholder="12/31/1973"
                bind:value={$personalProjection.wages[currentTab].paystub_date}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "paystub_date")}
              />
              <TextField
                key={`age_${currentTab}`}
                visitKey={`wages[${currentTab}].age`}
                placeholder="21"
                bind:value={$personalProjection.wages[currentTab].age}
                hasError={fieldHasError($personalProjectionValidation, currentTab, "age")}
              />
            </div>
            <div class="personal-projection-form-content__fields">
              <CheckboxField
                key={`business_owned_${currentTab}`}
                visitKey={`wages[${currentTab}].business_owned`}
                bind:value={$personalProjection.wages[currentTab].business_owned}
                title="Business Owned by Taxpayer/Spouse?"
              />
            </div>
            <div class="personal-projection-form-content__fields">
              <CheckboxField
                key={`covered_by_retirement_plan_${currentTab}`}
                visitKey={`wages[${currentTab}].covered_by_retirement_plan`}
                bind:value={$personalProjection.wages[currentTab].covered_by_retirement_plan}
                title="Covered by retirement plan at work"
              />
            </div>
            <div class="personal-projection-form-content__fields">
              <div class="personal-projection-form-content__column">
                <NumericField
                  key={`wages_${currentTab}`}
                  visitKey={`wages[${currentTab}].wages`}
                  bind:value={$personalProjection.wages[currentTab].wages}
                  hasError={fieldHasError($personalProjectionValidation, currentTab, "wages")}
                  inputAddon="$"
                />
                <NumericField
                  key={`federal_income_tax_withheld_${currentTab}`}
                  visitKey={`wages[${currentTab}].federal_income_tax_withheld`}
                  bind:value={$personalProjection.wages[currentTab].federal_income_tax_withheld}
                  hasError={fieldHasError(
                    $personalProjectionValidation,
                    currentTab,
                    "federal_income_tax_withheld"
                  )}
                  inputAddon="$"
                />
                <NumericField
                  key={`state_income_tax_withheld_${currentTab}`}
                  visitKey={`wages[${currentTab}].state_income_tax_withheld`}
                  bind:value={$personalProjection.wages[currentTab].state_income_tax_withheld}
                  hasError={fieldHasError(
                    $personalProjectionValidation,
                    currentTab,
                    "state_income_tax_withheld"
                  )}
                  inputAddon="$"
                />
                <NumericField
                  key={`dependent_care_benefits_${currentTab}`}
                  visitKey={`wages[${currentTab}].dependent_care_benefits`}
                  bind:value={$personalProjection.wages[currentTab].dependent_care_benefits}
                  hasError={fieldHasError(
                    $personalProjectionValidation,
                    currentTab,
                    "dependent_care_benefits"
                  )}
                  inputAddon="$"
                />
                <NumericField
                  key={`401k_contributions_${currentTab}`}
                  visitKey={`wages[${currentTab}].401k_contributions`}
                  bind:value={$personalProjection.wages[currentTab]["401k_contributions"]}
                  hasError={fieldHasError($personalProjectionValidation, currentTab, "401k_contributions")}
                  inputAddon="$"
                />
              </div>
            </div>
          </div>
          <hr />
        </div>
      {/if}
    </div>
  </div>
  <div>
    <div class="personal-projection-form-content__fields">
      <div class="personal-projection-form-content__column">
        <div class="form-group tpp-form-group">
          <label
            id="fed_inc_primary_job_w2"
            class="col-form-label tpp-form-label text-primary personal-projection-form-calculation-result-field results__row-title"
          >
            <span>Taxpayer W-2 Income</span>
          </label>
          <div class="input-group">
            {#if isNumber(serverCalculatedParameters.fed_inc_primary_job_w2)}
              <div
                aria-labelledby="fed_inc_primary_job_w2"
                class="personal-projection-form-calculation-result-field text-primary personal-projection-form-calculation-result-field results__row-title"
              >
                {numeral(serverCalculatedParameters.fed_inc_primary_job_w2).format("$0,0[.]00")}
              </div>
            {:else}
              <div class="personal-projection-form-calculation-result-field__loading" />
            {/if}
          </div>
        </div>
        <div class="form-group tpp-form-group">
          <label
            id="business_salary_taxpayer"
            class="col-form-label tpp-form-label text-primary personal-projection-form-calculation-result-field results__row-title"
          >
            <span>Taxpayer S-Corp Wages</span>
          </label>
          <div class="input-group">
            {#if isNumber(serverCalculatedParameters.business_salary_taxpayer)}
              <div
                aria-labelledby="business_salary_taxpayer"
                class="personal-projection-form-calculation-result-field text-primary personal-projection-form-calculation-result-field results__row-title"
              >
                {numeral(serverCalculatedParameters.business_salary_taxpayer).format("$0,0[.]00")}
              </div>
            {:else}
              <div class="personal-projection-form-calculation-result-field__loading" />
            {/if}
          </div>
        </div>
        <div class="form-group tpp-form-group">
          <label
            id="taxpayer_w2_witholding"
            class="col-form-label tpp-form-label text-primary personal-projection-form-calculation-result-field results__row-title"
          >
            <span>Taxpayer W-2 Withholding</span>
          </label>
          <div class="input-group">
            {#if isNumber(serverCalculatedParameters.taxpayer_w2_witholding)}
              <div
                aria-labelledby="taxpayer_w2_witholding"
                class="personal-projection-form-calculation-result-field text-primary personal-projection-form-calculation-result-field results__row-title"
              >
                {numeral(serverCalculatedParameters.taxpayer_w2_witholding).format("$0,0[.]00")}
              </div>
            {:else}
              <div class="personal-projection-form-calculation-result-field__loading" />
            {/if}
          </div>
        </div>
        <div class="form-group tpp-form-group">
          <label
            id="taxpayer_state_withholding"
            class="col-form-label tpp-form-label text-primary personal-projection-form-calculation-result-field results__row-title"
          >
            <span>Taxpayer State Withholding</span>
          </label>
          <div class="input-group">
            {#if isNumber(serverCalculatedParameters.taxpayer_state_withholding)}
              <div
                aria-labelledby="taxpayer_state_withholding"
                class="personal-projection-form-calculation-result-field text-primary personal-projection-form-calculation-result-field results__row-title"
              >
                {numeral(serverCalculatedParameters.taxpayer_state_withholding).format("$0,0[.]00")}
              </div>
            {:else}
              <div class="personal-projection-form-calculation-result-field__loading" />
            {/if}
          </div>
        </div>
      </div>
    </div>
    <div class="personal-projection-form-content__fields">
      <div class="personal-projection-form-content__column">
        <div class="form-group tpp-form-group">
          <label
            id="fed_inc_second_job_w2"
            class="col-form-label tpp-form-label text-danger personal-projection-form-calculation-result-field results__row-title"
          >
            <span>Spouse W-2 Income</span>
          </label>
          <div class="input-group">
            {#if isNumber(serverCalculatedParameters.fed_inc_second_job_w2)}
              <div
                aria-labelledby="fed_inc_second_job_w2"
                class="personal-projection-form-calculation-result-field text-danger personal-projection-form-calculation-result-field results__row-title"
              >
                {numeral(serverCalculatedParameters.fed_inc_second_job_w2).format("$0,0[.]00")}
              </div>
            {:else}
              <div class="personal-projection-form-calculation-result-field__loading" />
            {/if}
          </div>
        </div>
        <div class="form-group tpp-form-group">
          <label
            id="business_salary_spouse"
            class="col-form-label tpp-form-label text-danger personal-projection-form-calculation-result-field results__row-title"
          >
            <span>Spouse S-Corp Wages</span>
          </label>
          <div class="input-group">
            {#if isNumber(serverCalculatedParameters.business_salary_spouse)}
              <div
                aria-labelledby="business_salary_spouse"
                class="personal-projection-form-calculation-result-field text-danger personal-projection-form-calculation-result-field results__row-title"
              >
                {numeral(serverCalculatedParameters.business_salary_spouse).format("$0,0[.]00")}
              </div>
            {:else}
              <div class="personal-projection-form-calculation-result-field__loading" />
            {/if}
          </div>
        </div>
        <div class="form-group tpp-form-group">
          <label
            id="spouse_w2_witholding"
            class="col-form-label tpp-form-label text-danger personal-projection-form-calculation-result-field results__row-title"
          >
            <span>Spouse W-2 Withholding</span>
          </label>
          <div class="input-group">
            {#if isNumber(serverCalculatedParameters.spouse_w2_witholding)}
              <div
                aria-labelledby="spouse_w2_witholding"
                class="personal-projection-form-calculation-result-field text-danger personal-projection-form-calculation-result-field results__row-title"
              >
                {numeral(serverCalculatedParameters.spouse_w2_witholding).format("$0,0[.]00")}
              </div>
            {:else}
              <div class="personal-projection-form-calculation-result-field__loading" />
            {/if}
          </div>
        </div>
        <div class="form-group tpp-form-group">
          <label
            id="spouse_state_withholding"
            class="col-form-label tpp-form-label text-danger personal-projection-form-calculation-result-field results__row-title"
          >
            <span>Spouse State Withholding</span>
          </label>
          <div class="input-group">
            {#if isNumber(serverCalculatedParameters.spouse_state_withholding)}
              <div
                aria-labelledby="spouse_state_withholding"
                class="personal-projection-form-calculation-result-field text-danger personal-projection-form-calculation-result-field results__row-title"
              >
                {numeral(serverCalculatedParameters.spouse_state_withholding).format("$0,0[.]00")}
              </div>
            {:else}
              <div class="personal-projection-form-calculation-result-field__loading" />
            {/if}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
