<script>
  import accounting from "accounting";
  import ResultRow from "components/common/ResultRow.svelte";
  import TotalResultRow from "components/dashboard/TotalResultRow.svelte";
  import TaxableIncome from "components/dashboard/TaxableIncome.svelte";
  import TaxTotals from "components/dashboard/TaxTotals.svelte";
  import { showCreateCompanyModal } from "components/companies/create_company/Store";

  export let dashboard;
  export let selectCompany;
  export let userHasNoBusiness;
  export let currentCompanyId;

  $: currentCompany = dashboard.companies.find((c) => c.id === currentCompanyId);

  function toPercent(val) {
    return accounting.formatMoney(val, {
      symbol: "%",
      precision: 1,
      format: {
        pos: "%v%s",
        neg: "(%v)%s",
        zero: "%v%s"
      }
    });
  }

  function openCreateCompanyModal() {
    showCreateCompanyModal.set(true);
  }
</script>

<template lang="pug">
  .card.flex-align.mb-3
    +if('userHasNoBusiness')
      .business-projection-no-business.card-header.bg-danger-light
        h3.card-title.mb-0 Business Summary
      .business-projection-no-business__info
        | No Businesses Owned
        a(on:click|preventDefault="{openCreateCompanyModal}", class="btn btn-primary btn-trans btn-sm", href="#") CREATE
      +else
        .card-header.bg-primary
          h3.card-title.mb-0 Business Summary
          .actions.float-right
            +if('currentCompany.businessProjection')
              a.btn.btn-primary.btn-sm(href="{currentCompany.editPath}") EDIT
              +else
                a.btn.btn-primary.btn-trans.btn-sm(href="{currentCompany.connectPath}") CONNECT
        .business-projection.business-projection__flex
          +each('dashboard.companies as company')
            +if('company.id == currentCompanyId')
              span.business-projection__link.business-projection__link__active { company.name }
              +else
                //- Dirty Trick: Start
                | <div class="business-projection__link"
                |      on:click={() => { selectCompany(company.id) }}>{ company.name }</div>
                //- Dirty Trick: End
        .card-body.clearfix.d-flex.flex-column.h-100.p-3
          +if('currentCompany.businessProjection')
            .business-projection-body
              table.summary-block.table.table-striped
                tbody
                  +each('currentCompany.businessProjection.incomeEntries as line')
                    ResultRow(title="{line.name}", value="{line.federalFinalProjection}")
                  TotalResultRow(
                    title="Total Income",
                    value="{currentCompany.businessProjection.totalIncome.federalFinalProjection}"
                  )
              table.summary-block.table.table-striped
                tbody
                  +each('currentCompany.businessProjection.expenseEntries as line')
                    ResultRow(title="{line.name}", value="{line.federalFinalProjection}")
                  TotalResultRow(
                    title="Total Expense",
                    value="{currentCompany.businessProjection.totalExpenses.federalFinalProjection}"
                  )
              table.summary-block.table.table-striped
                tbody
                  +if('currentCompany.businessProjection.isCCorp')
                    TotalResultRow(
                      title="Distribution",
                      value="{toPercent(currentCompany.businessProjection.businessSharePercent)}"
                    )
                    +else
                      TotalResultRow(
                        title="Ownership",
                        value="{toPercent(currentCompany.businessProjection.businessSharePercent)}"
                      )
            +if('currentCompany.businessProjection.isCCorp && currentCompany.personalProjection')
              TaxTotals(
                federalTax="{currentCompany.personalProjection.cCorpFedTax}",
                stateTax="{currentCompany.personalProjection.cCorpStateTax}",
                showStateTax="{!!currentCompany.personalProjection.cCorpStateTax}",
                ranged="{!dashboard.hasProSubscription}"
              )
                TaxableIncome(
                  taxableIncomeSent="{currentCompany.personalProjection.taxableIncomeSent}",
                  stateFranchiseTax="{currentCompany.stateFranchiseTax}"
                )
              +else
                .flex-align__wrap
                  TaxableIncome(
                    taxableIncomeSent="{currentCompany.personalProjection.taxableIncomeSent}",
                    stateFranchiseTax="{currentCompany.stateFranchiseTax}"
                  )
            +else
              +if('!userHasNoBusiness')
                i.text-center
                  | To see business income summary please
                  | #[a(href="{currentCompany.connectPath}") #[u connect]] Business Source.

</template>

<style lang="sass" global>
  .business-projection__link
    cursor: pointer
    &__active
      cursor: auto
</style>
