<script>
  import TaxTotal from "components/dashboard/TaxTotal.svelte";
  import accounting from "accounting";

  export let taxableIncomeSent;
  export let stateFranchiseTax;

  function formatValue(value) {
    if (typeof value === "string") return value;

    return formatMoney(value);
  }

  function formatMoney(val) {
    return accounting.formatMoney(val, {
      symbol: "$",
      precision: 0,
      format: {
        pos: "%s%v",
        neg: "%s(%v)",
        zero: "%s%v"
      }
    });
  }
</script>

<template lang="pug">
  .widget-mini.results__total.card-solid-default.flex-grow-1
    .card-body
      span.total.text-center { formatValue(taxableIncomeSent) }
      span.title.text-center Taxable Income
  +if("typeof stateFranchiseTax !== 'undefined'")
    #state_franchise_tax.flex-grow-1
      TaxTotal(amount="{stateFranchiseTax}", ranged=false, type="State Franchise")
</template>
