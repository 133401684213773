<script>
  import { times } from "lodash";

  const cellWidths = [10, 40, 5, 5, 5, 10, 25];
  const rowWidths = times(10);
</script>

<table id="client-list" class="table">
  <thead>
    <th class="p-0 border-top-0" colspan="7">
      <div class="fake-client-detail">
        {#each cellWidths as cellWidth}
          <div class="fake-client-detail__cell shimmer-head" style="max-width: {cellWidth}%;"></div>
        {/each}
      </div>
    </th>
  </thead>
  <tbody>
    {#each rowWidths as _}
      <tr>
        <td colspan="7" class="table-cell-loader">
          <div class="fake-client-detail">
            {#each cellWidths as cellWidth}
              <div class="fake-client-detail__cell shimmer-data" style="max-width: {cellWidth}%;"></div>
            {/each}
          </div>
        </td>
      </tr>
    {/each}
  </tbody>
</table>

<style lang="scss">
  th div,
  td div {
    border-radius: 3px;
  }

  th div {
    margin-bottom: 5px;
  }

  .table-cell-loader {
    padding: 0;
  }

  .fake-client-detail {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }

  .fake-client-detail__cell {
    flex: 1;
    padding: 10px;
    margin: 10px;
  }

  $light: rgba(238, 238, 238, 1);
  $dark: rgba(226, 226, 226, 1);

  $light2: rgb(252, 252, 252);
  $dark2: rgba(238, 238, 238, 1);

  @keyframes shimmering {
    0% {
      background-position: -1300px 0;
    }
    100% {
      background-position: 1300px 0;
    }
  }

  .shimmer-head {
    animation: shimmering 3.1s linear infinite;
    background: $dark;
    background: linear-gradient(90deg, $dark 9%, $light 18%, $dark 31%);
    background-size: 1300px 100%;
  }

  .shimmer-data {
    animation: shimmering 3.1s linear infinite;
    background: $dark2;
    background: linear-gradient(90deg, $dark2 9%, $light2 18%, $dark2 31%);
    background-size: 1300px 100%;
  }
</style>
