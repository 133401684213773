<script>
  export let removePhoneNumberSrc;
  export let csrfToken;
  export let hasPhoneNumber;
  import jQuery from "jquery";

  let requestInProgress = false;
  let error = "";

  function handleRemovePhone() {
    if (
      !confirm(
        "Once removed, the phone number will be no longer prompted until the client configures it again. " +
          "This may make client's account less secure. Do you want to proceed?"
      )
    ) {
      return false;
    }

    requestInProgress = true;
    jQuery.ajax({
      url: removePhoneNumberSrc,
      method: "PATCH",
      headers: { "X-CSRF-Token": csrfToken },
      success: () => {
        hasPhoneNumber = false;
        error = "";
      },
      error: (data) => {
        data.status === 422 ? (error = data.responseJSON.error) : (error = "Fatal error");
      },
      complete: () => (requestInProgress = false)
    });
  }
</script>

<div class="card remove-phone__form mb-3" class:remove-phone--in-progress={requestInProgress}>
  <div class="card-header">
    <h3 class="card-title mb-0">Phone number</h3>
  </div>
  <div class="card-body">
    {#if error.length > 0}
      <p class="error">{error}</p>
    {/if}
    {#if hasPhoneNumber}
      <button on:click={handleRemovePhone} class="btn btn-danger" disabled={requestInProgress}>
        Remove Phone Number
      </button>
    {:else}
      The client doesn't have a phone number associated with their account
    {/if}
  </div>
</div>

<style lang="scss">
  .remove-phone__form {
    transition: opacity 0.5s ease-in-out;
  }

  .remove-phone--in-progress {
    opacity: 0.5;
  }
</style>
