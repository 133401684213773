<script>
  import { camelCase } from "lodash";
  import { onMount } from "svelte";
  import {
    businessProjection,
    businessProjectionValidation,
    visitField,
    businessProjectionVisitors
  } from "../Store";

  export let key;
  export let storeKey = camelCase(key);
  export let placeholder = null;
  export let tooltip = null;
  export let dateFormat = "mm/dd/yyyy";

  let datepicker;

  $: window.$(datepicker).datepicker("update", $businessProjection[storeKey]);
  $: fieldHtmlId = `business-projection-${key}`;
  $: hasError = $businessProjectionValidation.has(key);
  $: hasSuccess = $businessProjectionVisitors.has(key) && !$businessProjectionValidation.has(key);
  $: tooltipWithError = $businessProjectionValidation.get(key) || tooltip;

  onMount(() => {
    const currentDatepicker = window.$(datepicker);
    currentDatepicker.datepicker({
      autoclose: true,
      language: "en",
      ...(dateFormat ? { format: dateFormat } : {})
    });

    currentDatepicker.on("changeDate", function () {
      $businessProjection[storeKey] = currentDatepicker.datepicker("getFormattedDate");
    });
  });
</script>

<div class="row">
  <div class="col-sm-12">
    {#if tooltipWithError}
      <label for={fieldHtmlId} class="col-form-label tpp-form-label">
        <div class:text-danger={hasError}>
          {tooltipWithError}
        </div>
      </label>
    {/if}
    <div class="input-group date" bind:this={datepicker} data-provide="datepicker" data-format={dateFormat}>
      <input
        id={fieldHtmlId}
        data-testid={fieldHtmlId}
        class="form-control"
        {placeholder}
        class:is-invalid={hasError}
        class:is-valid={hasSuccess}
        on:blur={() => visitField(key)}
        bind:value={$businessProjection[storeKey]}
      />
      <div class="input-group-prepend">
        <span class="input-group-text border-left-0 rounded-right">
          <i class="fa fa-calendar"></i>
        </span>
      </div>
    </div>
  </div>
</div>
