<script>
  import _ from "underscore/underscore";

  export let title;
  export let value;
  export let readOnly;
  export let serverValue;
  export let required;
  export let isValid = true;

  let id = _.uniqueId("input");
</script>

<div class="checkbox">
  <label for={id}>
    <input
      type="checkbox"
      class:form-control-dirty={serverValue !== value && isValid}
      class:required
      class:has-error={!isValid}
      required
      {id}
      name={id}
      bind:checked={value}
      {readOnly}
    />
    {title}
  </label>
</div>
