<script>
  import { reportStore } from "../stores/reportStore";
  import { specificationTree } from "../stores/specificationTree";
  import NumberInputField from "../inputs/NumberInput.svelte";
  import TextInputField from "../inputs/TextInput.svelte";
  import TextareaInputField from "../inputs/TextareaInput.svelte";
  import CurrencyInputField from "../inputs/CurrencyInput.svelte";
  import CurrencyEmptyInputField from "../inputs/CurrencyEmptyInput.svelte";
  import SelectInputField from "../inputs/SelectInput.svelte";
  import DatePickerField from "../inputs/DatePicker.svelte";
  import Dropdown from "../Dropdown.svelte";
  import { BUSINESS_TYPES, BUSINESS_TYPES_CODES, ORDERS, HEALTH_SAVING_TYPES } from "utils/business_objects";
  import { searchMatches } from "../stores/searchStore";

  export let refreshReportParams;
  export let unpermittedReportAtrributes;

  const isPermitted = (planner) => !unpermittedReportAtrributes.includes(planner);
</script>

<template lang="pug">
  Dropdown(
    {refreshReportParams},
    specTreeNodeId="sd_enable",
    bind:checked="{$reportStore.sd_enable}",
    hidden="{$searchMatches.isHidden('Strategy Details')}",
    scrollToId="pdfStrategyDetails"
  )
    +if("isPermitted('sd_sec_179_expense_enable')")
      Dropdown(
        {refreshReportParams},
        specTreeNodeId="sd_sec_179_expense_enable",
        bind:checked="{$reportStore.sd_sec_179_expense_enable}",
        classNames="report-suboption",
        hidden="{$searchMatches.isHidden('Section 179')}",
        scrollToId='pdfSection179Expense'
      )
        Dropdown(
          {refreshReportParams},
          specTreeNodeId="sd_sec_179_top_3_widget_options",
          classNames="report-suboption",
          hidden="{$searchMatches.isHidden('Top 3 Widget Options')}"
        )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_section179_adding",
            bind:value="{$reportStore.top_3_strategy_picks_section179_adding}",
            hidden="{$searchMatches.isHidden('Adding')}"
          )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_section179_saves_you",
            bind:value="{$reportStore.top_3_strategy_picks_section179_saves_you}",
            hidden="{$searchMatches.isHidden('Savings')}"
          )
        TextareaInputField(
          bind:value="{$reportStore.sd_sec_179_expense_what_it_is_text}",
          specTreeNodeId="sd_sec_179_expense_what_it_is_text",
          hidden="{$searchMatches.isHidden('Description')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_sec_179_expense_what_it_is_first_item}",
          specTreeNodeId="sd_sec_179_expense_what_it_is_first_item",
          hidden="{$searchMatches.isHidden('First Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_sec_179_expense_what_it_is_second_item}",
          specTreeNodeId="sd_sec_179_expense_what_it_is_second_item",
          hidden="{$searchMatches.isHidden('Second Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_sec_179_expense_what_it_is_third_item}",
          specTreeNodeId="sd_sec_179_expense_what_it_is_third_item",
          hidden="{$searchMatches.isHidden('Third Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_sec_179_expense_what_it_is_fourth_item}",
          specTreeNodeId="sd_sec_179_expense_what_it_is_fourth_item",
          hidden="{$searchMatches.isHidden('Fourth Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_sec_179_expense_what_it_is_fifth_item}",
          specTreeNodeId="sd_sec_179_expense_what_it_is_fifth_item",
          hidden="{$searchMatches.isHidden('Fifth Item')}"
        )
        TextareaInputField(
          bind:value="{$reportStore.sd_sec_179_expense_what_it_is_text_extra}",
          specTreeNodeId="sd_sec_179_expense_what_it_is_text_extra",
          hidden="{$searchMatches.isHidden('Second Description')}"
        )
        TextareaInputField(
          bind:value="{$reportStore.sd_sec_179_expense_ratings_text}",
          specTreeNodeId="sd_sec_179_expense_ratings_text",
          hidden="{$searchMatches.isHidden('Description of Ratings')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sec_179_expense_tax_saving_options_first_expense",
          bind:value="{$reportStore.sd_sec_179_expense_tax_saving_options_first_expense}",
          hidden="{$searchMatches.isHidden('Option A Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sec_179_expense_tax_saving_options_first_saves_you",
          bind:value="{$reportStore.sd_sec_179_expense_tax_saving_options_first_saves_you}",
          hidden="{$searchMatches.isHidden('Option A Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sec_179_expense_tax_saving_options_second_expense",
          bind:value="{$reportStore.sd_sec_179_expense_tax_saving_options_second_expense}",
          hidden="{$searchMatches.isHidden('Option B Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sec_179_expense_tax_saving_options_second_saves_you",
          bind:value="{$reportStore.sd_sec_179_expense_tax_saving_options_second_saves_you}",
          hidden="{$searchMatches.isHidden('Option B Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sec_179_expense_tax_saving_options_third_expense",
          bind:value="{$reportStore.sd_sec_179_expense_tax_saving_options_third_expense}",
          hidden="{$searchMatches.isHidden('Option C Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sec_179_expense_tax_saving_options_third_saves_you",
          bind:value="{$reportStore.sd_sec_179_expense_tax_saving_options_third_saves_you}",
          hidden="{$searchMatches.isHidden('Option C Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sec_179_expense_current_max_sec_179_deduction",
          bind:value="{$reportStore.sd_sec_179_expense_current_max_sec_179_deduction}",
          hidden="{$searchMatches.isHidden('Max Section 179 Deduction')}"
        )
    +if("isPermitted('sd_expense_acceleration_enable')")
      Dropdown(
        {refreshReportParams},
        specTreeNodeId="sd_expense_acceleration_enable",
        bind:checked="{$reportStore.sd_expense_acceleration_enable}",
        classNames="report-suboption",
        hidden="{$searchMatches.isHidden('Expense Acceleration')}",
        scrollToId='pdfExpenseAcceleration'
      )
        Dropdown(
          {refreshReportParams},
          specTreeNodeId="sd_expense_acceleration_top_3_widget_options",
          classNames="report-suboption",
          hidden="{$searchMatches.isHidden('Top 3 Widget Options')}"
        )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_expense_acceleration_adding",
            bind:value="{$reportStore.top_3_strategy_picks_expense_acceleration_adding}",
            hidden="{$searchMatches.isHidden('Adding')}"
          )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_expense_acceleration_saves_you",
            bind:value="{$reportStore.top_3_strategy_picks_expense_acceleration_saves_you}",
            hidden="{$searchMatches.isHidden('Savings')}"
          )
        TextareaInputField(
          bind:value="{$reportStore.sd_expense_acceleration_what_it_is_text}",
          specTreeNodeId="sd_expense_acceleration_what_it_is_text",
          hidden="{$searchMatches.isHidden('Description')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_expense_acceleration_what_it_is_first_item}",
          specTreeNodeId="sd_expense_acceleration_what_it_is_first_item",
          hidden="{$searchMatches.isHidden('First Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_expense_acceleration_what_it_is_second_item}",
          specTreeNodeId="sd_expense_acceleration_what_it_is_second_item",
          hidden="{$searchMatches.isHidden('Second Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_expense_acceleration_what_it_is_third_item}",
          specTreeNodeId="sd_expense_acceleration_what_it_is_third_item",
          hidden="{$searchMatches.isHidden('Third Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_expense_acceleration_what_it_is_fourth_item}",
          specTreeNodeId="sd_expense_acceleration_what_it_is_fourth_item",
          hidden="{$searchMatches.isHidden('Fourth Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_expense_acceleration_what_it_is_fifth_item}",
          specTreeNodeId="sd_expense_acceleration_what_it_is_fifth_item",
          hidden="{$searchMatches.isHidden('Fifth Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_expense_acceleration_what_it_is_sixth_item}",
          specTreeNodeId="sd_expense_acceleration_what_it_is_sixth_item",
          hidden="{$searchMatches.isHidden('Sixth Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_expense_acceleration_what_it_is_seventh_item}",
          specTreeNodeId="sd_expense_acceleration_what_it_is_seventh_item",
          hidden="{$searchMatches.isHidden('Seventh Item')}"
        )
        TextareaInputField(
          bind:value="{$reportStore.sd_expense_acceleration_ratings_text}",
          specTreeNodeId="sd_expense_acceleration_ratings_text",
          hidden="{$searchMatches.isHidden('Description of Ratings')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_expense_acceleration_tax_saving_options_first_expense",
          bind:value="{$reportStore.sd_expense_acceleration_tax_saving_options_first_expense}",
          hidden="{$searchMatches.isHidden('Option A Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_expense_acceleration_tax_saving_options_first_saves_you",
          bind:value="{$reportStore.sd_expense_acceleration_tax_saving_options_first_saves_you}",
          hidden="{$searchMatches.isHidden('Option A Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_expense_acceleration_tax_saving_options_second_expense",
          bind:value="{$reportStore.sd_expense_acceleration_tax_saving_options_second_expense}",
          hidden="{$searchMatches.isHidden('Option B Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_expense_acceleration_tax_saving_options_second_saves_you",
          bind:value="{$reportStore.sd_expense_acceleration_tax_saving_options_second_saves_you}",
          hidden="{$searchMatches.isHidden('Option B Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_expense_acceleration_tax_saving_options_third_expense",
          bind:value="{$reportStore.sd_expense_acceleration_tax_saving_options_third_expense}",
          hidden="{$searchMatches.isHidden('Option C Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_expense_acceleration_tax_saving_options_third_saves_you",
          bind:value="{$reportStore.sd_expense_acceleration_tax_saving_options_third_saves_you}",
          hidden="{$searchMatches.isHidden('Option C Saves You')}"
        )
    +if("isPermitted('sd_missing_deductions_enable')")
      Dropdown(
        {refreshReportParams},
        specTreeNodeId="sd_missing_deductions_enable",
        bind:checked="{$reportStore.sd_missing_deductions_enable}",
        classNames="report-suboption",
        hidden="{$searchMatches.isHidden('Missing Deductions')}",
        scrollToId='pdfMissingDeductions'
      )
        Dropdown(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_top_3_widget_options",
          classNames="report-suboption",
          hidden="{$searchMatches.isHidden('Top 3 Widget Options')}"
        )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_missing_deductions_adding",
            bind:value="{$reportStore.top_3_strategy_picks_missing_deductions_adding}",
            hidden="{$searchMatches.isHidden('Adding')}"
          )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_missing_deductions_saves_you",
            bind:value="{$reportStore.top_3_strategy_picks_missing_deductions_saves_you}",
            hidden="{$searchMatches.isHidden('Savings')}"
          )
        TextareaInputField(
          bind:value="{$reportStore.sd_missing_deductions_what_it_is_text}",
          specTreeNodeId="sd_missing_deductions_what_it_is_text",
          hidden="{$searchMatches.isHidden('Description')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_missing_deductions_what_it_is_first_item}",
          specTreeNodeId="sd_missing_deductions_what_it_is_first_item",
          hidden="{$searchMatches.isHidden('First Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_missing_deductions_what_it_is_second_item}",
          specTreeNodeId="sd_missing_deductions_what_it_is_second_item",
          hidden="{$searchMatches.isHidden('Second Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_missing_deductions_what_it_is_third_item}",
          specTreeNodeId="sd_missing_deductions_what_it_is_third_item",
          hidden="{$searchMatches.isHidden('Third Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_missing_deductions_what_it_is_fourth_item}",
          specTreeNodeId="sd_missing_deductions_what_it_is_fourth_item",
          hidden="{$searchMatches.isHidden('Fourth Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_missing_deductions_what_it_is_fifth_item}",
          specTreeNodeId="sd_missing_deductions_what_it_is_fifth_item",
          hidden="{$searchMatches.isHidden('Fifth Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_missing_deductions_what_it_is_sixth_item}",
          specTreeNodeId="sd_missing_deductions_what_it_is_sixth_item",
          hidden="{$searchMatches.isHidden('Sixth Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_missing_deductions_what_it_is_seventh_item}",
          specTreeNodeId="sd_missing_deductions_what_it_is_seventh_item",
          hidden="{$searchMatches.isHidden('Seventh Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_missing_deductions_what_it_is_eighth_item}",
          specTreeNodeId="sd_missing_deductions_what_it_is_eighth_item",
          hidden="{$searchMatches.isHidden('Eighth Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_missing_deductions_what_it_is_ninth_item}",
          specTreeNodeId="sd_missing_deductions_what_it_is_ninth_item",
          hidden="{$searchMatches.isHidden('Ninth Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_missing_deductions_what_it_is_tenth_item}",
          specTreeNodeId="sd_missing_deductions_what_it_is_tenth_item",
          hidden="{$searchMatches.isHidden('Tenth Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_missing_deductions_what_it_is_eleventh_item}",
          specTreeNodeId="sd_missing_deductions_what_it_is_eleventh_item",
          hidden="{$searchMatches.isHidden('Eleventh Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_missing_deductions_what_it_is_twelfth_item}",
          specTreeNodeId="sd_missing_deductions_what_it_is_twelfth_item",
          hidden="{$searchMatches.isHidden('Twelfth Item')}"
        )
        TextareaInputField(
          bind:value="{$reportStore.sd_missing_deductions_what_it_is_text_extra}",
          specTreeNodeId="sd_missing_deductions_what_it_is_text_extra",
          hidden="{$searchMatches.isHidden('Second Description')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_annual_meeting_expense_current",
          bind:value="{$reportStore.sd_missing_deductions_annual_meeting_expense_current}",
          hidden="{$searchMatches.isHidden('Current Meeting Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_annual_meeting_expense_recommended",
          bind:value="{$reportStore.sd_missing_deductions_annual_meeting_expense_recommended}",
          hidden="{$searchMatches.isHidden('Recommended Meeting Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_meals_current",
          bind:value="{$reportStore.sd_missing_deductions_meals_current}",
          hidden="{$searchMatches.isHidden('Current Meals')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_meals_recommended",
          bind:value="{$reportStore.sd_missing_deductions_meals_recommended}",
          hidden="{$searchMatches.isHidden('Recommended Meals')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_travel_current",
          bind:value="{$reportStore.sd_missing_deductions_travel_current}",
          hidden="{$searchMatches.isHidden('Current Travel')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_travel_recommended",
          bind:value="{$reportStore.sd_missing_deductions_travel_recommended}",
          hidden="{$searchMatches.isHidden('Recommended Travel')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_telephone_current",
          bind:value="{$reportStore.sd_missing_deductions_telephone_current}",
          hidden="{$searchMatches.isHidden('Current Telephone')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_telephone_recommended",
          bind:value="{$reportStore.sd_missing_deductions_telephone_recommended}",
          hidden="{$searchMatches.isHidden('Recommended Telephone')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_internet_current",
          bind:value="{$reportStore.sd_missing_deductions_internet_current}",
          hidden="{$searchMatches.isHidden('Current Internet')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_internet_recommended",
          bind:value="{$reportStore.sd_missing_deductions_internet_recommended}",
          hidden="{$searchMatches.isHidden('Recommended Internet')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_auto_current",
          bind:value="{$reportStore.sd_missing_deductions_auto_current}",
          hidden="{$searchMatches.isHidden('Current Auto')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_auto_recommended",
          bind:value="{$reportStore.sd_missing_deductions_auto_recommended}",
          hidden="{$searchMatches.isHidden('Recommended Auto')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_office_current",
          bind:value="{$reportStore.sd_missing_deductions_office_current}",
          hidden="{$searchMatches.isHidden('Current Office')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_office_recommended",
          bind:value="{$reportStore.sd_missing_deductions_office_recommended}",
          hidden="{$searchMatches.isHidden('Recommended Office')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_legal_current",
          bind:value="{$reportStore.sd_missing_deductions_legal_current}",
          hidden="{$searchMatches.isHidden('Current Legal')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_legal_recommended",
          bind:value="{$reportStore.sd_missing_deductions_legal_recommended}",
          hidden="{$searchMatches.isHidden('Recommended Legal')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_mortgage_current",
          bind:value="{$reportStore.sd_missing_deductions_mortgage_current}",
          hidden="{$searchMatches.isHidden('Current Mortgage')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_mortgage_recommended",
          bind:value="{$reportStore.sd_missing_deductions_mortgage_recommended}",
          hidden="{$searchMatches.isHidden('Recommended Mortgage')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_health_savings_account_current",
          bind:value="{$reportStore.sd_missing_deductions_health_savings_account_current}",
          hidden="{$searchMatches.isHidden('Current Health Savings Account')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_health_savings_account_recommended",
          bind:value="{$reportStore.sd_missing_deductions_health_savings_account_recommended}",
          hidden="{$searchMatches.isHidden('Recommended Health Savings Account')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_cash_charity_donations_current",
          bind:value="{$reportStore.sd_missing_deductions_cash_charity_donations_current}",
          hidden="{$searchMatches.isHidden('Current Charity Donations')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_cash_charity_donations_recommended",
          bind:value="{$reportStore.sd_missing_deductions_cash_charity_donations_recommended}",
          hidden="{$searchMatches.isHidden('Recommended Charity Donations')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_non_cash_donations_current",
          bind:value="{$reportStore.sd_missing_deductions_non_cash_donations_current}",
          hidden="{$searchMatches.isHidden('Current Non Cash Donations')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_non_cash_donations_recommended",
          bind:value="{$reportStore.sd_missing_deductions_non_cash_donations_recommended}",
          hidden="{$searchMatches.isHidden('Recommended Non Cash Donations')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_missing_deductions_total_tax_savings_comes_to",
          bind:value="{$reportStore.sd_missing_deductions_total_tax_savings_comes_to}",
          hidden="{$searchMatches.isHidden('Total Tax Savings')}"
        )
    +if("isPermitted('sd_income_deferment_enable')")
      Dropdown(
        {refreshReportParams},
        specTreeNodeId="sd_income_deferment_enable",
        bind:checked="{$reportStore.sd_income_deferment_enable}",
        classNames="report-suboption",
        hidden="{$searchMatches.isHidden('Income Deferment')}",
        scrollToId='pdfIncomeDeferment'
      )
        Dropdown(
          {refreshReportParams},
          specTreeNodeId="sd_income_deferment_top_3_widget_options",
          classNames="report-suboption",
          hidden="{$searchMatches.isHidden('Top 3 Widget Options')}"
        )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_income_deferment_adding",
            bind:value="{$reportStore.top_3_strategy_picks_income_deferment_adding}",
            hidden="{$searchMatches.isHidden('Adding')}"
          )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_income_deferment_saves_you",
            bind:value="{$reportStore.top_3_strategy_picks_income_deferment_saves_you}",
            hidden="{$searchMatches.isHidden('Savings')}"
          )
        TextareaInputField(
          bind:value="{$reportStore.sd_income_deferment_what_it_is_text}",
          specTreeNodeId="sd_income_deferment_what_it_is_text",
          hidden="{$searchMatches.isHidden('Description')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_income_deferment_what_it_is_first_item}",
          specTreeNodeId="sd_income_deferment_what_it_is_first_item",
          hidden="{$searchMatches.isHidden('First Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_income_deferment_what_it_is_second_item}",
          specTreeNodeId="sd_income_deferment_what_it_is_second_item",
          hidden="{$searchMatches.isHidden('Second Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_income_deferment_what_it_is_third_item}",
          specTreeNodeId="sd_income_deferment_what_it_is_third_item",
          hidden="{$searchMatches.isHidden('Third Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_income_deferment_what_it_is_fourth_item}",
          specTreeNodeId="sd_income_deferment_what_it_is_fourth_item",
          hidden="{$searchMatches.isHidden('Fourth Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_income_deferment_what_it_is_fifth_item}",
          specTreeNodeId="sd_income_deferment_what_it_is_fifth_item",
          hidden="{$searchMatches.isHidden('Fifth Item')}"
        )
        TextareaInputField(
          bind:value="{$reportStore.sd_income_deferment_ratings_text}",
          specTreeNodeId="sd_income_deferment_ratings_text",
          hidden="{$searchMatches.isHidden('Description of Ratings')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_income_deferment_tax_saving_options_first_expense",
          bind:value="{$reportStore.sd_income_deferment_tax_saving_options_first_expense}",
          hidden="{$searchMatches.isHidden('Option A Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_income_deferment_tax_saving_options_first_saves_you",
          bind:value="{$reportStore.sd_income_deferment_tax_saving_options_first_saves_you}",
          hidden="{$searchMatches.isHidden('Option A Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_income_deferment_tax_saving_options_second_expense",
          bind:value="{$reportStore.sd_income_deferment_tax_saving_options_second_expense}",
          hidden="{$searchMatches.isHidden('Option B Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_income_deferment_tax_saving_options_second_saves_you",
          bind:value="{$reportStore.sd_income_deferment_tax_saving_options_second_saves_you}",
          hidden="{$searchMatches.isHidden('Option B Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_income_deferment_tax_saving_options_third_expense",
          bind:value="{$reportStore.sd_income_deferment_tax_saving_options_third_expense}",
          hidden="{$searchMatches.isHidden('Option C Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_income_deferment_tax_saving_options_third_saves_you",
          bind:value="{$reportStore.sd_income_deferment_tax_saving_options_third_saves_you}",
          hidden="{$searchMatches.isHidden('Option C Saves You')}"
        )
    +if("isPermitted('sd_sep_ira_enable')")
      Dropdown(
        {refreshReportParams},
        specTreeNodeId="sd_sep_ira_enable",
        bind:checked="{$reportStore.sd_sep_ira_enable}",
        classNames="report-suboption",
        hidden="{$searchMatches.isHidden('SEP IRA')}",
        scrollToId='pdfSepIra'
      )
        Dropdown(
          {refreshReportParams},
          specTreeNodeId="sd_sep_ira_top_3_widget_options",
          classNames="report-suboption",
          hidden="{$searchMatches.isHidden('Top 3 Widget Options')}"
        )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_sep_ira_adding",
            bind:value="{$reportStore.top_3_strategy_picks_sep_ira_adding}",
            hidden="{$searchMatches.isHidden('Adding')}"
          )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_sep_ira_saves_you",
            bind:value="{$reportStore.top_3_strategy_picks_sep_ira_saves_you}",
            hidden="{$searchMatches.isHidden('Savings')}"
          )
        TextareaInputField(
          bind:value="{$reportStore.sd_sep_ira_what_it_is_text}",
          specTreeNodeId="sd_sep_ira_what_it_is_text",
          hidden="{$searchMatches.isHidden('Description')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_sep_ira_what_it_is_first_item}",
          specTreeNodeId="sd_sep_ira_what_it_is_first_item",
          hidden="{$searchMatches.isHidden('First Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_sep_ira_what_it_is_second_item}",
          specTreeNodeId="sd_sep_ira_what_it_is_second_item",
          hidden="{$searchMatches.isHidden('Second Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_sep_ira_what_it_is_third_item}",
          specTreeNodeId="sd_sep_ira_what_it_is_third_item",
          hidden="{$searchMatches.isHidden('Third Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_sep_ira_what_it_is_fourth_item}",
          specTreeNodeId="sd_sep_ira_what_it_is_fourth_item",
          hidden="{$searchMatches.isHidden('Fourth Item')}"
        )
        TextareaInputField(
          bind:value="{$reportStore.sd_sep_ira_ratings_text}",
          specTreeNodeId="sd_sep_ira_ratings_text",
          hidden="{$searchMatches.isHidden('Description of Ratings')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sep_ira_tax_saving_options_first_expense",
          bind:value="{$reportStore.sd_sep_ira_tax_saving_options_first_expense}",
          hidden="{$searchMatches.isHidden('Option A Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sep_ira_tax_saving_options_first_saves_you",
          bind:value="{$reportStore.sd_sep_ira_tax_saving_options_first_saves_you}",
          hidden="{$searchMatches.isHidden('Option A Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sep_ira_tax_saving_options_second_expense",
          bind:value="{$reportStore.sd_sep_ira_tax_saving_options_second_expense}",
          hidden="{$searchMatches.isHidden('Option B Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sep_ira_tax_saving_options_second_saves_you",
          bind:value="{$reportStore.sd_sep_ira_tax_saving_options_second_saves_you}",
          hidden="{$searchMatches.isHidden('Option B Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sep_ira_tax_saving_options_third_expense",
          bind:value="{$reportStore.sd_sep_ira_tax_saving_options_third_expense}",
          hidden="{$searchMatches.isHidden('Option C Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sep_ira_tax_saving_options_third_saves_you",
          bind:value="{$reportStore.sd_sep_ira_tax_saving_options_third_saves_you}",
          hidden="{$searchMatches.isHidden('Option C Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sep_ira_w_2_wages_tax_saving_options_first_expense",
          bind:value="{$reportStore.sd_sep_ira_w_2_wages_tax_saving_options_first_expense}",
          hidden="{$searchMatches.isHidden('Option A W-2 Wages Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sep_ira_w_2_wages_tax_saving_options_first_saves_you",
          bind:value="{$reportStore.sd_sep_ira_w_2_wages_tax_saving_options_first_saves_you}",
          hidden="{$searchMatches.isHidden('Option A W-2 Wages Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sep_ira_w_2_wages_tax_saving_options_second_expense",
          bind:value="{$reportStore.sd_sep_ira_w_2_wages_tax_saving_options_second_expense}",
          hidden="{$searchMatches.isHidden('Option B W-2 Wages Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sep_ira_w_2_wages_tax_saving_options_second_saves_you",
          bind:value="{$reportStore.sd_sep_ira_w_2_wages_tax_saving_options_second_saves_you}",
          hidden="{$searchMatches.isHidden('Option B W-2 Wages Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sep_ira_w_2_wages_tax_saving_options_third_expense",
          bind:value="{$reportStore.sd_sep_ira_w_2_wages_tax_saving_options_third_expense}",
          hidden="{$searchMatches.isHidden('Option C W-2 Wages Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sep_ira_w_2_wages_tax_saving_options_third_saves_you",
          bind:value="{$reportStore.sd_sep_ira_w_2_wages_tax_saving_options_third_saves_you}",
          hidden="{$searchMatches.isHidden('Option C W-2 Wages Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sep_ira_based_on_sep_income_of",
          bind:value="{$reportStore.sd_sep_ira_based_on_sep_income_of}",
          hidden="{$searchMatches.isHidden('SEP Income')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_sep_ira_increase_w_2_wages_to",
          bind:value="{$reportStore.sd_sep_ira_increase_w_2_wages_to}",
          hidden="{$searchMatches.isHidden('W-2 Wages')}"
        )
    +if("isPermitted('sd_fourteen_day_home_rental_enable')")
      Dropdown(
        {refreshReportParams},
        specTreeNodeId="sd_fourteen_day_home_rental_enable",
        bind:checked="{$reportStore.sd_fourteen_day_home_rental_enable}",
        classNames="report-suboption",
        hidden="{$searchMatches.isHidden('14 Day Home Rental')}",
        scrollToId='pdf14DayHomeRental'
      )
        Dropdown(
          {refreshReportParams},
          specTreeNodeId="sd_fourteen_day_home_rental_top_3_widget_options",
          classNames="report-suboption",
          hidden="{$searchMatches.isHidden('Top 3 Widget Options')}"
        )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_fourteen_day_home_rental_adding",
            bind:value="{$reportStore.top_3_strategy_picks_fourteen_day_home_rental_adding}",
            hidden="{$searchMatches.isHidden('Adding')}"
          )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_fourteen_day_home_rental_saves_you",
            bind:value="{$reportStore.top_3_strategy_picks_fourteen_day_home_rental_saves_you}",
            hidden="{$searchMatches.isHidden('Savings')}"
          )
        TextareaInputField(
          bind:value="{$reportStore.sd_fourteen_day_home_rental_what_it_is_text}",
          specTreeNodeId="sd_fourteen_day_home_rental_what_it_is_text",
          hidden="{$searchMatches.isHidden('Description')}"
        )
        TextareaInputField(
          bind:value="{$reportStore.sd_fourteen_day_home_rental_ratings_text}",
          specTreeNodeId="sd_fourteen_day_home_rental_ratings_text",
          hidden="{$searchMatches.isHidden('Description of Ratings')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_fourteen_day_home_rental_tax_saving_options_first_expense",
          bind:value="{$reportStore.sd_fourteen_day_home_rental_tax_saving_options_first_expense}",
          hidden="{$searchMatches.isHidden('Option A Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_fourteen_day_home_rental_tax_saving_options_first_saves_you",
          bind:value="{$reportStore.sd_fourteen_day_home_rental_tax_saving_options_first_saves_you}",
          hidden="{$searchMatches.isHidden('Option A Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_fourteen_day_home_rental_tax_saving_options_second_expense",
          bind:value="{$reportStore.sd_fourteen_day_home_rental_tax_saving_options_second_expense}",
          hidden="{$searchMatches.isHidden('Option B Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_fourteen_day_home_rental_tax_saving_options_second_saves_you",
          bind:value="{$reportStore.sd_fourteen_day_home_rental_tax_saving_options_second_saves_you}",
          hidden="{$searchMatches.isHidden('Option B Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_fourteen_day_home_rental_tax_saving_options_third_expense",
          bind:value="{$reportStore.sd_fourteen_day_home_rental_tax_saving_options_third_expense}",
          hidden="{$searchMatches.isHidden('Option C Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_fourteen_day_home_rental_tax_saving_options_third_saves_you",
          bind:value="{$reportStore.sd_fourteen_day_home_rental_tax_saving_options_third_saves_you}",
          hidden="{$searchMatches.isHidden('Option C Saves You')}"
        )
    +if("isPermitted('sd_employing_your_children_enable')")
      Dropdown(
        {refreshReportParams},
        specTreeNodeId="sd_employing_your_children_enable",
        bind:checked="{$reportStore.sd_employing_your_children_enable}",
        classNames="report-suboption",
        hidden="{$searchMatches.isHidden('Employing Your Children')}",
        scrollToId='pdfEmployingYourChildren'
      )
        Dropdown(
          {refreshReportParams},
          specTreeNodeId="sd_employing_your_children_top_3_widget_options",
          classNames="report-suboption",
          hidden="{$searchMatches.isHidden('Top 3 Widget Options')}"
        )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_employing_your_children_adding",
            bind:value="{$reportStore.top_3_strategy_picks_employing_your_children_adding}",
            hidden="{$searchMatches.isHidden('Adding')}"
          )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_employing_your_children_saves_you",
            bind:value="{$reportStore.top_3_strategy_picks_employing_your_children_saves_you}",
            hidden="{$searchMatches.isHidden('Savings')}"
          )
        TextareaInputField(
          bind:value="{$reportStore.sd_employing_your_children_what_it_is_text}",
          specTreeNodeId="sd_employing_your_children_what_it_is_text",
          hidden="{$searchMatches.isHidden('Description')}"
        )
        TextareaInputField(
          bind:value="{$reportStore.sd_employing_your_children_ratings_text}",
          specTreeNodeId="sd_employing_your_children_ratings_text",
          hidden="{$searchMatches.isHidden('Description of Ratings')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_employing_your_children_tax_saving_options_first_expense",
          bind:value="{$reportStore.sd_employing_your_children_tax_saving_options_first_expense}",
          hidden="{$searchMatches.isHidden('Option A Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_employing_your_children_tax_saving_options_first_saves_you",
          bind:value="{$reportStore.sd_employing_your_children_tax_saving_options_first_saves_you}",
          hidden="{$searchMatches.isHidden('Option A Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_employing_your_children_tax_saving_options_second_expense",
          bind:value="{$reportStore.sd_employing_your_children_tax_saving_options_second_expense}",
          hidden="{$searchMatches.isHidden('Option B Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_employing_your_children_tax_saving_options_second_saves_you",
          bind:value="{$reportStore.sd_employing_your_children_tax_saving_options_second_saves_you}",
          hidden="{$searchMatches.isHidden('Option B Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_employing_your_children_tax_saving_options_third_expense",
          bind:value="{$reportStore.sd_employing_your_children_tax_saving_options_third_expense}",
          hidden="{$searchMatches.isHidden('Option C Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_employing_your_children_tax_saving_options_third_saves_you",
          bind:value="{$reportStore.sd_employing_your_children_tax_saving_options_third_saves_you}",
          hidden="{$searchMatches.isHidden('Option C Saves You')}"
        )
    +if("isPermitted('sd_plan_401k_enable')")
      Dropdown(
        {refreshReportParams},
        specTreeNodeId="sd_plan_401k_enable",
        bind:checked="{$reportStore.sd_plan_401k_enable}",
        classNames="report-suboption",
        hidden="{$searchMatches.isHidden('401K')}",
        scrollToId='pdfPlan401K'
      )
        Dropdown(
          {refreshReportParams},
          specTreeNodeId="sd_plan_401k_top_3_widget_options",
          classNames="report-suboption",
          hidden="{$searchMatches.isHidden('Top 3 Widget Options')}"
        )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_plan_401k_contributions",
            bind:value="{$reportStore.top_3_strategy_picks_plan_401k_contributions}",
            hidden="{$searchMatches.isHidden('Contributions')}"
          )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_plan_401k_saves_you",
            bind:value="{$reportStore.top_3_strategy_picks_plan_401k_saves_you}",
            hidden="{$searchMatches.isHidden('Savings')}"
          )
        TextareaInputField(
          bind:value="{$reportStore.sd_plan_401k_what_it_is_text}",
          specTreeNodeId="sd_plan_401k_what_it_is_text",
          hidden="{$searchMatches.isHidden('Description')}"
        )
        TextareaInputField(
          bind:value="{$reportStore.sd_plan_401k_ratings_text}",
          specTreeNodeId="sd_plan_401k_ratings_text",
          hidden="{$searchMatches.isHidden('Description of Ratings')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_plan_401k_tax_saving_options_first_contribute",
          bind:value="{$reportStore.sd_plan_401k_tax_saving_options_first_contribute}",
          hidden="{$searchMatches.isHidden('Option A Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_plan_401k_tax_saving_options_first_saves_you",
          bind:value="{$reportStore.sd_plan_401k_tax_saving_options_first_saves_you}",
          hidden="{$searchMatches.isHidden('Option A Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_plan_401k_tax_saving_options_second_contribute",
          bind:value="{$reportStore.sd_plan_401k_tax_saving_options_second_contribute}",
          hidden="{$searchMatches.isHidden('Option B Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_plan_401k_tax_saving_options_second_saves_you",
          bind:value="{$reportStore.sd_plan_401k_tax_saving_options_second_saves_you}",
          hidden="{$searchMatches.isHidden('Option B Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_plan_401k_tax_saving_options_third_contribute",
          bind:value="{$reportStore.sd_plan_401k_tax_saving_options_third_contribute}",
          hidden="{$searchMatches.isHidden('Option C Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_plan_401k_tax_saving_options_third_saves_you",
          bind:value="{$reportStore.sd_plan_401k_tax_saving_options_third_saves_you}",
          hidden="{$searchMatches.isHidden('Option C Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_plan_401k_taxpayer_wages",
          bind:value="{$reportStore.sd_plan_401k_taxpayer_wages}",
          hidden="{$searchMatches.isHidden('Taxpayer Wages')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_plan_401k_spouse_wages",
          bind:value="{$reportStore.sd_plan_401k_spouse_wages}",
          hidden="{$searchMatches.isHidden('Spouse Wages')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_plan_401k_current_taxpayer_401k_contributions",
          bind:value="{$reportStore.sd_plan_401k_current_taxpayer_401k_contributions}",
          hidden="{$searchMatches.isHidden('Taxpayer 401k Contributions')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_plan_401k_current_spouse_401k_contributions",
          bind:value="{$reportStore.sd_plan_401k_current_spouse_401k_contributions}",
          hidden="{$searchMatches.isHidden('Spouse 401k Contributions')}"
        )
    +if("isPermitted('sd_business_entity_planner_enable')")
      Dropdown(
        {refreshReportParams},
        specTreeNodeId="sd_business_entity_planner_enable",
        bind:checked="{$reportStore.sd_business_entity_planner_enable}",
        classNames="report-suboption",
        hidden="{$searchMatches.isHidden('Business Entity Planner')}",
        scrollToId='pdfBusinessEntity'
      )
        Dropdown(
          {refreshReportParams},
          specTreeNodeId="sd_business_entity_planner_top_3_widget_options",
          classNames="report-suboption",
          hidden="{$searchMatches.isHidden('Top 3 Widget Options')}"
        )
          TextInputField(
            {refreshReportParams},
            specTreeNodeId="{`top_3_strategy_picks_business_entity_best_company_name`}",
            bind:value="{$reportStore.top_3_strategy_picks_business_entity_best_company_name}",
            hidden="{$searchMatches.isHidden('Company Name')}"
          )
          TextInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_business_entity_best_company_recommended",
            bind:value="{$reportStore.top_3_strategy_picks_business_entity_best_company_recommended}",
            hidden="{$searchMatches.isHidden('Recommended Type')}"
          )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_business_entity_best_company_saves_you",
            bind:value="{$reportStore.top_3_strategy_picks_business_entity_best_company_saves_you}",
            hidden="{$searchMatches.isHidden(`Savings`)}"
          )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_business_entity_other_companies_saves_you",
            bind:value="{$reportStore.top_3_strategy_picks_business_entity_other_companies_saves_you}",
            hidden="{$searchMatches.isHidden(`Other Companies Savings`)}"
          )
        Dropdown(
          {refreshReportParams},
          specTreeNodeId="sd_business_entity_planner_what_it_is_tree",
          classNames="report-suboption",
          hidden="{$searchMatches.isHidden('What it is')}"
        )
          TextareaInputField(
            bind:value="{$reportStore.sd_business_entity_planner_what_it_is_text}",
            specTreeNodeId="sd_business_entity_planner_what_it_is_text",
            hidden="{$searchMatches.isHidden('Description')}"
          )
          +each('BUSINESS_TYPES_CODES as business_type')
            TextareaInputField(
              bind:value="{$reportStore[`sd_business_entity_planner_what_it_is_${business_type.code}_description`]}",
              specTreeNodeId="{`sd_business_entity_planner_what_it_is_${business_type.code}_description`}",
              hidden="{$searchMatches.isHidden(`${business_type.name} Description`)}"
            )
            TextareaInputField(
              bind:value="{$reportStore[`sd_business_entity_planner_what_it_is_${business_type.code}_pros`]}",
              specTreeNodeId="{`sd_business_entity_planner_what_it_is_${business_type.code}_pros`}",
              hidden="{$searchMatches.isHidden(`${business_type.name} Pros`)}"
            )
            TextareaInputField(
              bind:value="{$reportStore[`sd_business_entity_planner_what_it_is_${business_type.code}_cons`]}",
              specTreeNodeId="{`sd_business_entity_planner_what_it_is_${business_type.code}_cons`}",
              hidden="{$searchMatches.isHidden(`${business_type.name} Cons`)}"
            )
            NumberInputField(
              {refreshReportParams},
              bind:value="{$reportStore[`sd_business_entity_planner_what_it_is_${business_type.code}_percent`]}",
              specTreeNodeId="{`sd_business_entity_planner_what_it_is_${business_type.code}_percent`}",
              hidden="{$searchMatches.isHidden(`${business_type.name} Percent`)}"
            )
        Dropdown(
          {refreshReportParams},
          specTreeNodeId="sd_business_entity_planner_comparison_tree",
          classNames="report-suboption",
          hidden="{$searchMatches.isHidden('Comparison')}"
        )
          TextareaInputField(
            bind:value="{$reportStore.sd_business_entity_planner_comparison_description}",
            specTreeNodeId="sd_business_entity_planner_comparison_description",
            hidden="{$searchMatches.isHidden('Description')}"
          )
          +each('ORDERS as order')
            Dropdown(
              {refreshReportParams},
              specTreeNodeId="{`sd_business_entity_planner_${order.code}_company_tree`}",
              classNames="report-suboption",
              hidden="{$searchMatches.isHidden(`${order.name} Company`)}"
            )
              TextInputField(
                specTreeNodeId="{`sd_business_entity_planner_${order.code}_company_name`}",
                bind:value="{$reportStore[`sd_business_entity_planner_${order.code}_company_name`]}",
                hidden="{$searchMatches.isHidden('Name')}"
              )
              SelectInputField(
                {refreshReportParams},
                specTreeNodeId="{`sd_business_entity_planner_${order.code}_company_type_current`}",
                bind:value="{$reportStore[`sd_business_entity_planner_${order.code}_company_type_current`]}",
                options="{BUSINESS_TYPES}",
                hidden="{$searchMatches.isHidden('Current Type')}"
              )
              SelectInputField(
                {refreshReportParams},
                specTreeNodeId="{`sd_business_entity_planner_${order.code}_company_type_recommended`}",
                bind:value="{$reportStore[`sd_business_entity_planner_${order.code}_company_type_recommended`]}",
                options="{BUSINESS_TYPES}",
                hidden="{$searchMatches.isHidden('Recommended Type')}"
              )
              +each('BUSINESS_TYPES_CODES as business_type')
                CurrencyInputField(
                  {refreshReportParams},
                  specTreeNodeId="{`sd_business_entity_planner_${order.code}_company_${business_type.code}_se_tax`}",
                  bind:value="{$reportStore[`sd_business_entity_planner_${order.code}_company_${business_type.code}_se_tax`]}",
                  hidden="{$searchMatches.isHidden(`${business_type.name} SE Tax`)}"
                )
                +if('business_type.code == "s_corp"')
                  CurrencyInputField(
                    {refreshReportParams},
                    specTreeNodeId="{`sd_business_entity_planner_${order.code}_company_${business_type.code}_fica_tax`}",
                    bind:value="{$reportStore[`sd_business_entity_planner_${order.code}_company_${business_type.code}_fica_tax`]}",
                    hidden="{$searchMatches.isHidden(`${business_type.name} FICA Tax`)}"
                  )
                +if('business_type.code == "c_corp"')
                  CurrencyInputField(
                    {refreshReportParams},
                    specTreeNodeId="{`sd_business_entity_planner_${order.code}_company_${business_type.code}_income_tax_c`}",
                    bind:value="{$reportStore[`sd_business_entity_planner_${order.code}_company_${business_type.code}_income_tax_c`]}",
                    hidden="{$searchMatches.isHidden(`${business_type.name} Income Tax (Corporate)`)}"
                  )
                CurrencyInputField(
                  {refreshReportParams},
                  specTreeNodeId="{`sd_business_entity_planner_${order.code}_company_${business_type.code}_income_tax`}",
                  bind:value="{$reportStore[`sd_business_entity_planner_${order.code}_company_${business_type.code}_income_tax`]}",
                  hidden="{$searchMatches.isHidden(`${business_type.name} Income Tax`)}"
                )
                CurrencyInputField(
                  {refreshReportParams},
                  specTreeNodeId="{`sd_business_entity_planner_${order.code}_company_${business_type.code}_state_tax`}",
                  bind:value="{$reportStore[`sd_business_entity_planner_${order.code}_company_${business_type.code}_state_tax`]}",
                  hidden="{$searchMatches.isHidden(`${business_type.name} State Tax`)}"
                )
                CurrencyInputField(
                  {refreshReportParams},
                  specTreeNodeId="{`sd_business_entity_planner_${order.code}_company_${business_type.code}_total_tax`}",
                  bind:value="{$reportStore[`sd_business_entity_planner_${order.code}_company_${business_type.code}_total_tax`]}",
                  hidden="{$searchMatches.isHidden(`${business_type.name} Total Tax`)}"
                )
              CurrencyInputField(
                {refreshReportParams},
                specTreeNodeId="{`sd_business_entity_planner_${order.code}_company_savings`}",
                bind:value="{$reportStore[`sd_business_entity_planner_${order.code}_company_savings`]}",
                hidden="{$searchMatches.isHidden(`Savings`)}"
              )
        Dropdown(
          specTreeNodeId="sd_business_entity_planner_how_to_change_business_tree",
          classNames="report-suboption",
          hidden="{$searchMatches.isHidden('How to Сhange Your Business Stucture')}"
        )
          TextareaInputField(
            bind:value="{$reportStore.sd_business_entity_planner_how_to_change_business_stucture}",
            specTreeNodeId="sd_business_entity_planner_how_to_change_business_stucture",
            hidden="{$searchMatches.isHidden('Description')}"
          )

    +if("isPermitted('sd_auto_lease_enable')")
      Dropdown(
        {refreshReportParams},
        specTreeNodeId="sd_auto_lease_enable",
        bind:checked="{$reportStore.sd_auto_lease_enable}",
        classNames="report-suboption",
        hidden="{$searchMatches.isHidden('Auto Lease')}",
        scrollToId='pdfAutoLease'
      )
        TextareaInputField(
          bind:value="{$reportStore.sd_auto_lease_what_it_is_text}",
          specTreeNodeId="sd_auto_lease_what_it_is_text",
          hidden="{$searchMatches.isHidden('Description')}"
        )
        TextareaInputField(
          bind:value="{$reportStore.sd_auto_lease_what_it_is_lease_pros}",
          specTreeNodeId="sd_auto_lease_what_it_is_lease_pros",
          hidden="{$searchMatches.isHidden('Lease Pros')}"
        )
        TextareaInputField(
          bind:value="{$reportStore.sd_auto_lease_what_it_is_lease_cons}",
          specTreeNodeId="sd_auto_lease_what_it_is_lease_cons",
          hidden="{$searchMatches.isHidden('Lease Cons')}"
        )
        TextareaInputField(
          bind:value="{$reportStore.sd_auto_lease_what_it_is_purchase_pros}",
          specTreeNodeId="sd_auto_lease_what_it_is_purchase_pros",
          hidden="{$searchMatches.isHidden('Purchase Pros')}"
        )
        TextareaInputField(
          bind:value="{$reportStore.sd_auto_lease_what_it_is_purchase_cons}",
          specTreeNodeId="sd_auto_lease_what_it_is_purchase_cons",
          hidden="{$searchMatches.isHidden('Purchase Cons')}"
        )
        NumberInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_business_use",
          bind:value="{$reportStore.sd_auto_lease_business_use}",
          hidden="{$searchMatches.isHidden('Business Use')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_purchase_price",
          bind:value="{$reportStore.sd_auto_lease_purchase_price}",
          hidden="{$searchMatches.isHidden('Purchase Price')}"
        )
        TextInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_vehicle_weight",
          bind:value="{$reportStore.sd_auto_lease_vehicle_weight}",
          hidden="{$searchMatches.isHidden('Vehicle Weight')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_lease_payment",
          bind:value="{$reportStore.sd_auto_lease_lease_payment}",
          hidden="{$searchMatches.isHidden('Lease Payment')}"
        )
        NumberInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_lease_term",
          bind:value="{$reportStore.sd_auto_lease_lease_term}",
          hidden="{$searchMatches.isHidden('Lease Term')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_lease_expense_first_year",
          bind:value="{$reportStore.sd_auto_lease_lease_expense_first_year}",
          hidden="{$searchMatches.isHidden('Lease Expense 1st Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_lease_tax_savings_first_year",
          bind:value="{$reportStore.sd_auto_lease_lease_tax_savings_first_year}",
          hidden="{$searchMatches.isHidden('Lease Tax Savings 1st Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_lease_expense_second_year",
          bind:value="{$reportStore.sd_auto_lease_lease_expense_second_year}",
          hidden="{$searchMatches.isHidden('Lease Expense 2nd Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_lease_tax_savings_second_year",
          bind:value="{$reportStore.sd_auto_lease_lease_tax_savings_second_year}",
          hidden="{$searchMatches.isHidden('Lease Tax Savings 2nd Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_lease_expense_third_year",
          bind:value="{$reportStore.sd_auto_lease_lease_expense_third_year}",
          hidden="{$searchMatches.isHidden('Lease Expense 3rd Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_lease_tax_savings_third_year",
          bind:value="{$reportStore.sd_auto_lease_lease_tax_savings_third_year}",
          hidden="{$searchMatches.isHidden('Lease Tax Savings 3rd Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_lease_expense_fourth_year",
          bind:value="{$reportStore.sd_auto_lease_lease_expense_fourth_year}",
          hidden="{$searchMatches.isHidden('Lease Expense 4th Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_lease_tax_savings_fourth_year",
          bind:value="{$reportStore.sd_auto_lease_lease_tax_savings_fourth_year}",
          hidden="{$searchMatches.isHidden('Lease Tax Savings 4th Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_lease_expense_fifth_year",
          bind:value="{$reportStore.sd_auto_lease_lease_expense_fifth_year}",
          hidden="{$searchMatches.isHidden('Lease Expense 5th Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_lease_tax_savings_fifth_year",
          bind:value="{$reportStore.sd_auto_lease_lease_tax_savings_fifth_year}",
          hidden="{$searchMatches.isHidden('Lease Tax Savings 5th Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_purchase_depreciation_first_year",
          bind:value="{$reportStore.sd_auto_lease_purchase_depreciation_first_year}",
          hidden="{$searchMatches.isHidden('Purchase Depreciation 1st Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_purchase_tax_savings_first_year",
          bind:value="{$reportStore.sd_auto_lease_purchase_tax_savings_first_year}",
          hidden="{$searchMatches.isHidden('Purchase Tax Savings 1st Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_purchase_depreciation_second_year",
          bind:value="{$reportStore.sd_auto_lease_purchase_depreciation_second_year}",
          hidden="{$searchMatches.isHidden('Purchase Depreciation 2nd Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_purchase_tax_savings_second_year",
          bind:value="{$reportStore.sd_auto_lease_purchase_tax_savings_second_year}",
          hidden="{$searchMatches.isHidden('Purchase Tax Savings 2nd Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_purchase_depreciation_third_year",
          bind:value="{$reportStore.sd_auto_lease_purchase_depreciation_third_year}",
          hidden="{$searchMatches.isHidden('Purchase Depreciation 3rd Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_purchase_tax_savings_third_year",
          bind:value="{$reportStore.sd_auto_lease_purchase_tax_savings_third_year}",
          hidden="{$searchMatches.isHidden('Purchase Tax Savings 3rd Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_purchase_depreciation_fourth_year",
          bind:value="{$reportStore.sd_auto_lease_purchase_depreciation_fourth_year}",
          hidden="{$searchMatches.isHidden('Purchase Depreciation 4th Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_purchase_tax_savings_fourth_year",
          bind:value="{$reportStore.sd_auto_lease_purchase_tax_savings_fourth_year}",
          hidden="{$searchMatches.isHidden('Purchase Tax Savings 4th Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_purchase_depreciation_fifth_year",
          bind:value="{$reportStore.sd_auto_lease_purchase_depreciation_fifth_year}",
          hidden="{$searchMatches.isHidden('Purchase Depreciation 5th Year')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_auto_lease_purchase_tax_savings_fifth_year",
          bind:value="{$reportStore.sd_auto_lease_purchase_tax_savings_fifth_year}",
          hidden="{$searchMatches.isHidden('Purchase Tax Savings 5th Year')}"
        )

    +if("isPermitted('sd_traditional_and_roth_ira_enable')")
      Dropdown(
        {refreshReportParams},
        specTreeNodeId="sd_traditional_and_roth_ira_enable",
        bind:checked="{$reportStore.sd_traditional_and_roth_ira_enable}",
        classNames="report-suboption",
        hidden="{$searchMatches.isHidden('Traditional and Roth IRA')}"
        scrollToId="pdfTraditionalRothIra"
      )
        Dropdown(
          {refreshReportParams},
          specTreeNodeId="sd_traditional_ira_top_3_widget_options",
          classNames="report-suboption",
          hidden="{$searchMatches.isHidden('Top 3 Widget Options')}"
        )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_traditional_ira_contributions",
            bind:value="{$reportStore.top_3_strategy_picks_traditional_ira_contributions}",
            hidden="{$searchMatches.isHidden('Traditional IRA Contributions')}"
          )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_traditional_ira_saves_you",
            bind:value="{$reportStore.top_3_strategy_picks_traditional_ira_saves_you}",
            hidden="{$searchMatches.isHidden('Traditional IRA Savings')}"
          )
        +each('[["traditional_ira", "Traditional IRA"], ["roth_ira", "Roth IRA"]] as [iraType, iraTitle]')
          Dropdown(
            {refreshReportParams},
            specTreeNodeId="{`sd_${iraType}_tree`}",
            classNames="report-suboption",
            hidden="{$searchMatches.isHidden(iraTitle)}"
          )
            TextInputField(
              specTreeNodeId="{`sd_${iraType}_what_it_is_text`}",
              bind:value="{$reportStore[`sd_${iraType}_what_it_is_text`]}",
              hidden="{$searchMatches.isHidden('Description')}"
            )
            TextInputField(
              specTreeNodeId="{`sd_${iraType}_planner_description`}",
              bind:value="{$reportStore[`sd_${iraType}_planner_description`]}",
              hidden="{$searchMatches.isHidden('Planner Description')}"
            )
            TextInputField(
              specTreeNodeId="{`sd_${iraType}_taxes_contributions_grow`}",
              bind:value="{$reportStore[`sd_${iraType}_taxes_contributions_grow`]}",
              hidden="{$searchMatches.isHidden('Contributions Grow')}"
            )
            TextInputField(
              specTreeNodeId="{`sd_${iraType}_taxes_tax_deductibility`}",
              bind:value="{$reportStore[`sd_${iraType}_taxes_tax_deductibility`]}",
              hidden="{$searchMatches.isHidden('Tax-Deductibility')}"
            )
            TextInputField(
              specTreeNodeId="{`sd_${iraType}_contributions_come_from`}",
              bind:value="{$reportStore[`sd_${iraType}_contributions_come_from`]}",
              hidden="{$searchMatches.isHidden('Contributions Come From')}"
            )
            TextInputField(
              specTreeNodeId="{`sd_${iraType}_contributions_max_contribution`}",
              bind:value="{$reportStore[`sd_${iraType}_contributions_max_contribution`]}",
              hidden="{$searchMatches.isHidden('Max Contribution')}"
            )
            TextInputField(
              specTreeNodeId="{`sd_${iraType}_withdrawals_penalties`}",
              bind:value="{$reportStore[`sd_${iraType}_withdrawals_penalties`]}",
              hidden="{$searchMatches.isHidden('Penalties')}"
            )
            TextInputField(
              specTreeNodeId="{`sd_${iraType}_withdrawals_mandatory_distributions`}",
              bind:value="{$reportStore[`sd_${iraType}_withdrawals_mandatory_distributions`]}",
              hidden="{$searchMatches.isHidden('Mandatory Distributions')}"
            )
            +each('[["taxpayer", "Taxpayer"], ["spouse", "Spouse"]] as [taxpayerType, taxpayerTitle]')
              CurrencyInputField(
                {refreshReportParams},
                specTreeNodeId="{`sd_${iraType}_tax_saving_${taxpayerType}_wages`}",
                bind:value="{$reportStore[`sd_${iraType}_tax_saving_${taxpayerType}_wages`]}",
                hidden="{$searchMatches.isHidden(`${taxpayerTitle} Wages`)}"
              )
              CurrencyInputField(
                {refreshReportParams},
                specTreeNodeId="{`sd_${iraType}_tax_saving_${taxpayerType}_contributions`}",
                bind:value="{$reportStore[`sd_${iraType}_tax_saving_${taxpayerType}_contributions`]}",
                hidden="{$searchMatches.isHidden(`${taxpayerTitle} Current Contributions`)}"
              )
            +each('[["first", "Option A"], ["second", "Option B"], ["third", "Option C"]] as [order, orderTitle]')
              CurrencyInputField(
                {refreshReportParams},
                specTreeNodeId="{`sd_${iraType}_tax_saving_options_${order}_contribute`}",
                bind:value="{$reportStore[`sd_${iraType}_tax_saving_options_${order}_contribute`]}",
                hidden="{$searchMatches.isHidden(`${orderTitle} Contribute`)}"
              )
              CurrencyInputField(
                {refreshReportParams},
                specTreeNodeId="{`sd_${iraType}_tax_saving_options_${order}_saves_you`}",
                bind:value="{$reportStore[`sd_${iraType}_tax_saving_options_${order}_saves_you`]}",
                hidden="{$searchMatches.isHidden(`${orderTitle} And Save`)}"
              )
    +if("isPermitted('sd_health_savings_enable')")
      Dropdown(
        {refreshReportParams},
        specTreeNodeId="sd_health_savings_enable",
        bind:checked="{$reportStore.sd_health_savings_enable}",
        classNames="report-suboption",
        hidden="{$searchMatches.isHidden('Health Savings')}"
        scrollToId='pdfHealthSavings'
      )
        Dropdown(
          {refreshReportParams},
          specTreeNodeId="sd_health_savings_top_3_widget_options",
          classNames="report-suboption",
          hidden="{$searchMatches.isHidden('Top 3 Widget Options')}"
        )
          SelectInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_health_savings_type",
            bind:value="{$reportStore.top_3_strategy_picks_health_savings_type}",
            options="{HEALTH_SAVING_TYPES}",
            hidden="{$searchMatches.isHidden('Health Savings Type')}"
          )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_health_savings_adding",
            bind:value="{$reportStore.top_3_strategy_picks_health_savings_adding}",
            hidden="{$searchMatches.isHidden('Health Savings Contribution')}"
          )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_health_savings_saves_you",
            bind:value="{$reportStore.top_3_strategy_picks_health_savings_saves_you}",
            hidden="{$searchMatches.isHidden('Health Savings Saves You')}"
          )
        TextareaInputField(
          specTreeNodeId="{`sd_health_savings_what_it_is_text`}",
          bind:value="{$reportStore[`sd_health_savings_what_it_is_text`]}",
          hidden="{$searchMatches.isHidden('Description')}"
        )
        TextareaInputField(
          specTreeNodeId="{`sd_health_savings_description`}",
          bind:value="{$reportStore[`sd_health_savings_description`]}",
          hidden="{$searchMatches.isHidden('Notes')}"
        )
        +each('[["previous", "Previous Tax Year"], ["current", "Current Tax Year"]] as [year, year_title]')
          Dropdown(
            {refreshReportParams},
            specTreeNodeId="{`sd_health_savings_contribution_limits_${year}_tree`}",
            classNames="report-suboption",
            hidden="{$searchMatches.isHidden(`${year_title} Contribution Limits`)}"
          )
            TextInputField(
              specTreeNodeId="{`sd_health_savings_contribution_limits_${year}_tax_year`}",
              bind:value="{$reportStore[`sd_health_savings_contribution_limits_${year}_tax_year`]}",
              hidden="{$searchMatches.isHidden(year_title)}"
            )
            +each('[["hsa", "Health Savings Account (HSA)"], ["hra", "Health Reimbursement Agreement (HRA)"], ["fsa", "Flexible Spending Account (FSA)"]] as [type, typeTitle]')
              Dropdown(
                {refreshReportParams},
                specTreeNodeId="{`sd_health_savings_contribution_limits_${year}_tree_${type}_tree`}",
                classNames="report-suboption",
                hidden="{$searchMatches.isHidden(typeTitle)}"
              )
                +each('[["general", "General"], ["self", "Self Only"], ["family", "Family"]] as [limit, limit_title]')
                  CurrencyEmptyInputField(
                    {refreshReportParams},
                    specTreeNodeId="{`sd_health_savings_contribution_limits_${year}_${type}_${limit}`}",
                    bind:value="{$reportStore[`sd_health_savings_contribution_limits_${year}_${type}_${limit}`]}",
                    hidden="{$searchMatches.isHidden(limit_title)}"
                  )
        Dropdown(
          {refreshReportParams},
          specTreeNodeId="{`sd_health_savings_tax_saving_options_tree`}",
          classNames="report-suboption",
          hidden="{$searchMatches.isHidden('Tax Savings')}"
        )
          +each('[["contribute", "Contribute"], ["hsa_saves_you", "Health Savings Account (HSA)"], ["hra_saves_you", "Health Reimbursement Agreement (HRA)"], ["fsa_saves_you", "Flexible Spending Account (FSA)"]] as [type, typeTitle]')
            Dropdown(
              {refreshReportParams},
              specTreeNodeId="{`sd_health_savings_tax_saving_options_${type}_tree`}",
              classNames="report-suboption",
              hidden="{$searchMatches.isHidden(typeTitle)}"
            )
              +each('[["first", "Option A"], ["second", "Option B"], ["third", "Option C"]] as [option, option_title]')
                CurrencyEmptyInputField(
                  {refreshReportParams},
                  specTreeNodeId="{`sd_health_savings_tax_saving_options_${option}_${type}`}",
                  bind:value="{$reportStore[`sd_health_savings_tax_saving_options_${option}_${type}`]}",
                  hidden="{$searchMatches.isHidden(option_title)}"
                )
    +if("isPermitted('sd_defined_benefit_enable')")
      Dropdown(
        {refreshReportParams},
        specTreeNodeId='sd_defined_benefit_enable',
        bind:checked="{$reportStore.sd_defined_benefit_enable}",
        classNames="report-suboption",
        hidden="{$searchMatches.isHidden('Defined Benefit')}",
        scrollToId='pdfDefinedBenefit'
      )
        Dropdown(
          {refreshReportParams},
          specTreeNodeId="sd_defined_benefit_top_3_widget_options",
          classNames="report-suboption",
          hidden="{$searchMatches.isHidden('Top 3 Widget Options')}"
        )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_defined_benefit_adding",
            bind:value="{$reportStore.top_3_strategy_picks_defined_benefit_adding}",
            hidden="{$searchMatches.isHidden('Adding')}"
          )
          CurrencyInputField(
            {refreshReportParams},
            specTreeNodeId="top_3_strategy_picks_defined_benefit_saves_you",
            bind:value="{$reportStore.top_3_strategy_picks_defined_benefit_saves_you}",
            hidden="{$searchMatches.isHidden('Savings')}"
          )
        TextareaInputField(
          bind:value="{$reportStore.sd_defined_benefit_what_it_is_text}",
          specTreeNodeId="sd_defined_benefit_what_it_is_text",
          hidden="{$searchMatches.isHidden('Description')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_defined_benefit_what_it_is_first_item}",
          specTreeNodeId="sd_defined_benefit_what_it_is_first_item",
          hidden="{$searchMatches.isHidden('First Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_defined_benefit_what_it_is_second_item}",
          specTreeNodeId="sd_defined_benefit_what_it_is_second_item",
          hidden="{$searchMatches.isHidden('Second Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_defined_benefit_what_it_is_third_item}",
          specTreeNodeId="sd_defined_benefit_what_it_is_third_item",
          hidden="{$searchMatches.isHidden('Third Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_defined_benefit_what_it_is_fourth_item}",
          specTreeNodeId="sd_defined_benefit_what_it_is_fourth_item",
          hidden="{$searchMatches.isHidden('Fourth Item')}"
        )
        TextareaInputField(
          bind:value="{$reportStore.sd_defined_benefit_ratings_text}",
          specTreeNodeId="sd_defined_benefit_ratings_text",
          hidden="{$searchMatches.isHidden('Description of Ratings')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_defined_benefit_current_taxable_income",
          bind:value="{$reportStore.sd_defined_benefit_current_taxable_income}",
          hidden="{$searchMatches.isHidden('Current Taxable Income')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_defined_benefit_current_pension_contributions",
          bind:value="{$reportStore.sd_defined_benefit_current_pension_contributions}",
          hidden="{$searchMatches.isHidden('Current Pension Contributions')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_defined_benefit_tax_saving_options_first_expense",
          bind:value="{$reportStore.sd_defined_benefit_tax_saving_options_first_expense}",
          hidden="{$searchMatches.isHidden('Option A Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_defined_benefit_tax_saving_options_first_saves_you",
          bind:value="{$reportStore.sd_defined_benefit_tax_saving_options_first_saves_you}",
          hidden="{$searchMatches.isHidden('Option A Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_defined_benefit_tax_saving_options_second_expense",
          bind:value="{$reportStore.sd_defined_benefit_tax_saving_options_second_expense}",
          hidden="{$searchMatches.isHidden('Option B Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_defined_benefit_tax_saving_options_second_saves_you",
          bind:value="{$reportStore.sd_defined_benefit_tax_saving_options_second_saves_you}",
          hidden="{$searchMatches.isHidden('Option B Saves You')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_defined_benefit_tax_saving_options_third_expense",
          bind:value="{$reportStore.sd_defined_benefit_tax_saving_options_third_expense}",
          hidden="{$searchMatches.isHidden('Option C Expense')}"
        )
        CurrencyInputField(
          {refreshReportParams},
          specTreeNodeId="sd_defined_benefit_tax_saving_options_third_saves_you",
          bind:value="{$reportStore.sd_defined_benefit_tax_saving_options_third_saves_you}",
          hidden="{$searchMatches.isHidden('Option C Saves You')}"
        )
    +if("isPermitted('sd_etp_enable')")
      Dropdown(
        {refreshReportParams},
        specTreeNodeId="sd_etp_enable",
        bind:checked="{$reportStore.sd_etp_enable}",
        classNames="report-suboption",
        hidden="{$searchMatches.isHidden('Estimated Tax Payments')}",
        scrollToId='pdfEstimatedTaxPayments'
      )
        TextareaInputField(
          bind:value="{$reportStore.sd_etp_what_it_is_text}",
          specTreeNodeId="sd_etp_what_it_is_text",
          hidden="{$searchMatches.isHidden('Description')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_etp_what_it_is_first_item}",
          specTreeNodeId="sd_etp_what_it_is_first_item",
          hidden="{$searchMatches.isHidden('First Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_etp_what_it_is_second_item}",
          specTreeNodeId="sd_etp_what_it_is_second_item",
          hidden="{$searchMatches.isHidden('Second Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_etp_what_it_is_third_item}",
          specTreeNodeId="sd_etp_what_it_is_third_item",
          hidden="{$searchMatches.isHidden('Third Item')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_etp_previous_tax_year}",
          specTreeNodeId="sd_etp_previous_tax_year",
          hidden="{$searchMatches.isHidden('Previous Tax Year')}"
        )
        TextInputField(
          bind:value="{$reportStore.sd_etp_current_tax_year}",
          specTreeNodeId="sd_etp_current_tax_year",
          hidden="{$searchMatches.isHidden('Current Tax Year')}"
        )
        +each('[["federal", "Federal"], ["state", "State"]] as [type, typeTitle]')
          Dropdown(
            {refreshReportParams},
            specTreeNodeId="{`sd_etp_${type}_tree`}",
            classNames="report-suboption",
            hidden="{$searchMatches.isHidden(typeTitle)}"
          )
            CurrencyInputField(
              {refreshReportParams},
              specTreeNodeId="{`sd_etp_projected_${type}_tax_due`}",
              bind:value="{$reportStore[`sd_etp_projected_${type}_tax_due`]}",
              hidden="{$searchMatches.isHidden('Projected Tax Due')}"
            )
            CurrencyInputField(
              {refreshReportParams},
              specTreeNodeId="{`sd_etp_previous_year_${type}_tax`}",
              bind:value="{$reportStore[`sd_etp_previous_year_${type}_tax`]}",
              hidden="{$searchMatches.isHidden('Tax')}"
            )
            CurrencyInputField(
              {refreshReportParams},
              specTreeNodeId="{`sd_etp_${type}_agi`}",
              bind:value="{$reportStore[`sd_etp_${type}_agi`]}",
              hidden="{$searchMatches.isHidden('AGI')}"
            )
            CurrencyInputField(
              {refreshReportParams},
              specTreeNodeId="{`sd_etp_${type}_safe_harbor`}",
              bind:value="{$reportStore[`sd_etp_${type}_safe_harbor`]}",
              hidden="{$searchMatches.isHidden('Safe Harbor')}"
            )
        +each('[["first", "1st Quarter"], ["second", "2nd Quarter"], ["third", "3rd Quarter"], ["fourth", "4th Quarter"], ["total", "Annual Totals"]] as [order, orderTitle]')
          Dropdown(
            {refreshReportParams},
            specTreeNodeId="{`sd_etp_quarters_${order}_tree`}",
            classNames="report-suboption",
            hidden="{$searchMatches.isHidden(orderTitle)}"
          )
            +if("order != 'total'")
              DatePickerField(
                {refreshReportParams},
                specTreeNodeId="{`sd_etp_quarters_${order}_due_date`}",
                bind:value="{$reportStore[`sd_etp_quarters_${order}_due_date`]}",
                hidden="{$searchMatches.isHidden('Due Date')}"
              )
            +each('[["federal", "Federal"], ["state", "State"]] as [type, typeTitle]')
              Dropdown(
                {refreshReportParams},
                specTreeNodeId="{`sd_etp_quarters_${order}_tree_${type}_tree`}",
                classNames="report-suboption",
                hidden="{$searchMatches.isHidden(typeTitle)}"
              )
                CurrencyInputField(
                  {refreshReportParams},
                  specTreeNodeId="{`sd_etp_quarters_${order}_required_${type}_payment`}",
                  bind:value="{$reportStore[`sd_etp_quarters_${order}_required_${type}_payment`]}",
                  hidden="{$searchMatches.isHidden('Required Payment')}"
                )
                CurrencyInputField(
                  {refreshReportParams},
                  specTreeNodeId="{`sd_etp_quarters_${order}_projected_${type}_payment`}",
                  bind:value="{$reportStore[`sd_etp_quarters_${order}_projected_${type}_payment`]}",
                  hidden="{$searchMatches.isHidden('Projected Payment')}"
                )
                +if("order != 'total'")
                  CurrencyEmptyInputField(
                    {refreshReportParams},
                    specTreeNodeId="{`sd_etp_quarters_${order}_${type}_payment_made`}",
                    bind:value="{$reportStore[`sd_etp_quarters_${order}_${type}_payment_made`]}",
                    hidden="{$searchMatches.isHidden('Payment Made')}"
                  )
                  CurrencyEmptyInputField(
                    {refreshReportParams},
                    specTreeNodeId="{`sd_etp_quarters_${order}_${type}_cumulative_shortfall`}",
                    bind:value="{$reportStore[`sd_etp_quarters_${order}_${type}_cumulative_shortfall`]}",
                    hidden="{$searchMatches.isHidden('Cumulative Shortfall')}"
                  )
                  +else
                    CurrencyInputField(
                      {refreshReportParams},
                      specTreeNodeId="{`sd_etp_quarters_${order}_${type}_payment_made`}",
                      bind:value="{$reportStore[`sd_etp_quarters_${order}_${type}_payment_made`]}",
                      hidden="{$searchMatches.isHidden('Payment Made')}"
                    )
                    CurrencyInputField(
                      {refreshReportParams},
                      specTreeNodeId="{`sd_etp_quarters_${order}_${type}_cumulative_shortfall`}",
                      bind:value="{$reportStore[`sd_etp_quarters_${order}_${type}_cumulative_shortfall`]}",
                      hidden="{$searchMatches.isHidden('Cumulative Shortfall')}"
                    )
</template>
