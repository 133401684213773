<script>
  import { onMount } from "svelte";
  import accounting from "accounting";
  import Chart from "chart.js";
  import _ from "underscore";

  export let dashboard;
  export let currentCompanyId;
  export let syncCompany;
  export let refreshDashboard;

  let syncing;
  let incomeChartCanvas;
  let incomeChartContext;
  let incomeChart;

  $: currentCompany = dashboard.companies.find((c) => c.id === currentCompanyId);
  $: {
    if (currentCompany && incomeChartContext) {
      if (incomeChart) incomeChart.destroy();
      incomeChart = incomeBarChart(currentCompany.widgets.incomeChart, incomeChartContext);
    }
  }

  const sync = _.debounce(async () => {
    syncing = true;
    if (syncCompany && refreshDashboard && currentCompany) {
      await syncCompany(currentCompany.forceSyncPath);
      await refreshDashboard();
    }
    syncing = false;
  }, 400);

  function incomeBarChart(chartParams, context) {
    const color = Chart.helpers.color;
    const barChartData = {
      labels: chartParams.months,
      datasets: [
        {
          backgroundColor: color("#452161").alpha(0.5).rgbString(),
          borderColor: "rgba(255,255,255,0.8)",
          hoverBackgroundColor: "rgba(26,188,156,1)",
          hoverBorderColor: "rgba(255,255,255,0.8)",
          borderWidth: 1,
          data: chartParams.values
        }
      ]
    };

    return new Chart(context, {
      type: "bar",
      data: barChartData,
      options: {
        maintainAspectRatio: false,
        responsive: false,
        aspectRatio: 1,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: chartParams.values.some(function (e) {
                  return e < 0;
                })
              }
            }
          ]
        },
        legend: false,
        tooltips: {
          mode: "single",
          callbacks: {
            label: function (tooltipItem, data) {
              const i = tooltipItem.index;
              const value = accounting.formatMoney(data.datasets[0].data[i], "$", 0);

              return `${data.labels[i]} ${value}`;
            },
            title: () => {}
          }
        }
      }
    });
  }

  onMount(async (_promise) => {
    if (incomeChartCanvas) incomeChartContext = incomeChartCanvas.getContext("2d");
  });
</script>

<template>
  {#if currentCompany}
    <div class="col-sm-12 col-sm-6 col-lg-6 col-xl-3 d-flex flex-column">
      <div class="card flex-align mb-3">
        <div class="card-header">
          <h3 class="card-title mb-0 float-left">Income by Month</h3>
          <a
            hidden={!currentCompany.widgets.incomeChart.active}
            title="Sync Now"
            class="card-title mb-0 btn refresh-btn js-income-chart__refresh-btn float-right"
            on:click={sync}
          >
            <span class="fa fa-refresh refresh-btn__icon" class:refresh-btn__icon-animation={syncing} />
          </a>
          <div class="clearfix" />
        </div>
        <div
          class="card-body aligned-panels__panel js-income-chart"
          class:inactive={!currentCompany.widgets.incomeChart.active}
        >
          <canvas class="js-income-chart__chart" bind:this={incomeChartCanvas} />
        </div>
      </div>
    </div>
  {/if}
</template>

<style lang="sass">
  .inactive
    opacity: 0.2
</style>
