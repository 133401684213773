<script>
  import { isNil } from "lodash";
  import { tooltips } from "utils/tooltips";
  import { visitField, personalProjectionVisitors } from "../Store";
  import { getContext } from "svelte";
  import { fieldsToLabels } from "../FieldsToLabels";

  export let key;
  export let visitKey = key;
  export let value;
  export let hasError;
  export let placeholder = null;
  export let tooltip = null;

  const { type } = getContext("ppType");

  let fieldHtmlId;
  let hasSuccess;

  $: hasSuccess = $personalProjectionVisitors.has(visitKey) && !hasError && type !== "simulator";
  $: fieldHtmlId = `personal_projection_parameters[${key}]`;
</script>

<div class="personal-projection-form-content__column">
  <div class="form-group tpp-form-group">
    <label for={fieldHtmlId} class="col-form-label tpp-form-label">
      {#if !isNil(tooltip)}
        <div use:tooltips={tooltip}>
          <span>{fieldsToLabels[key]}</span>
          <i class="fa fa-info-circle ml-1" />
        </div>
      {:else}
        <div>
          <span>{fieldsToLabels[key]}</span>
        </div>
      {/if}
    </label>
    <input
      bind:value
      on:blur={() => visitField(visitKey)}
      type="string"
      id={fieldHtmlId}
      {placeholder}
      class="personal-projection-form-validation form-control"
      class:is-invalid={hasError}
      class:is-valid={hasSuccess}
    />
    <span class="form-text" />
  </div>
</div>
