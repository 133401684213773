<script>
  import { debounce, cloneDeep, sortBy, isEqual } from "lodash";
  import { onMount } from "svelte";
  import NProgress from "nprogress";
  import { notyf_top_right } from "misc/notyf_top_right";
  import HandyProjectionEntries from "./HandyProjectionEntries.svelte";
  import { fade } from "svelte/transition";

  export let handyProjectionEntriesPath;
  export let updateHandyProjectionEntriesPath;
  export let request;

  let entries = null;
  let initialEntries = null;
  let incomesValid = false;
  let expensesValid = false;

  $: if (!isEqual(entries, initialEntries) && incomesValid && expensesValid) {
    sendDebouncedEntries(cloneDeep(entries));
  }

  async function makeRequest(path, options = {}) {
    NProgress.start();
    try {
      const data = await request(path, options);
      if (data.error) {
        notyf_top_right.error(data.error);
        return null;
      }
      return data;
    } catch (error) {
      console.error(error.message);
      notyf_top_right.error(
        "We apologize for any inconvenience, but something went wrong. Please try again, and if the problem persists, reach out to our support team."
      );
      return null;
    } finally {
      NProgress.done();
    }
  }

  const sendDebouncedEntries = debounce(async (entries) => {
    const data = await makeRequest(updateHandyProjectionEntriesPath, {
      method: "PUT",
      body: JSON.stringify(entries)
    });

    if (!data) return;

    entries = data;
  }, 1500);

  async function loadEntries() {
    const data = await makeRequest(handyProjectionEntriesPath, { method: "GET" });

    if (!data) return;

    entries = {
      incomes: sortBy(data.incomes, ["name"]),
      expenses: sortBy(data.expenses, ["name"])
    };
    initialEntries = cloneDeep(entries);
  }

  onMount(() => {
    loadEntries();
  });
</script>

{#if entries !== null}
  <section in:fade={{ duration: 300 }}>
    <div class="table-responsive">
      <table class="table styled-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Federal</th>
            <th>State</th>
          </tr>
        </thead>
        <tbody id="income">
          <tr>
            <td class="section-title" colspan="3">Income</td>
          </tr>
          <HandyProjectionEntries bind:entries={entries.incomes} bind:valid={incomesValid} />
        </tbody>
        <tbody id="expenses">
          <tr>
            <td class="section-title" colspan="3">Expenses</td>
          </tr>
          <HandyProjectionEntries bind:entries={entries.expenses} bind:valid={expensesValid} />
        </tbody>
      </table>
    </div>
  </section>
{/if}

<style lang="scss">
  .table tbody tr td.section-title {
    border-bottom: 1px solid #ddd;
  }

  .table > tbody + tbody {
    border-top: none;
  }

  .table th + th {
    border-left: 1px solid #ddd;
  }

  .table tbody tr {
    border-bottom: none;
  }

  .table tbody tr td {
    border: none;
    padding: 10px 15px;
  }

  .table {
    th,
    td {
      min-width: 33%;
    }
  }

  .styled-table {
    border-collapse: collapse;
    font-size: 0.9em;
    width: 100%;
  }

  .styled-table thead tr th {
    background-color: #452161;
    color: #ffffff;
    text-align: center;
    padding: 8px;
  }

  .section-title {
    font-size: 1.5em;
    padding: 15px 0;
    font-weight: bold;
    color: #452161;
  }
</style>
