export function buildNewEmployer() {
  return {
    "401k_contributions": 0,
    business_owned: false,
    covered_by_retirement_plan: false,
    age: 21,
    dependent_care_benefits: 0,
    employer_name: "Wages",
    employer_type: "TAXPAYER",
    federal_income_tax_withheld: 0,
    paystub_date: `12/31/${new Date().getFullYear()}`,
    state_income_tax_withheld: 0,
    wages: 0
  };
}

export function buildNewRentalRealEstate() {
  return {
    name: "",
    income: 0.0,
    advertising: 0.0,
    auto_expenses: 0.0,
    cleaning: 0.0,
    commissions: 0.0,
    depreciation: 0.0,
    insurance: 0.0,
    legal_expenses: 0.0,
    management_fees: 0.0,
    mortage_interest: 0.0,
    repairs: 0.0,
    supplies: 0.0,
    taxes: 0.0,
    utilities: 0.0,
    other: 0.0
  };
}
