<script>
  import { onMount } from "svelte";

  export let key;
  export let store;
  export let validation;
  export let visitors;
  export let visitField;
  export let title;
  export let required = false;
  export let disabled = false;
  export let dateFormat = "yyyy-mm-dd";
  export let options = {};
  export let id = Math.random().toString(16).substr(2, 8);

  let datepicker;

  $: window.$(datepicker).datepicker("update", $store[key]);
  $: hasError = $visitors.has(key) && $validation.has(key);

  onMount(() => {
    const currentDatepicker = window.$(datepicker);
    currentDatepicker.datepicker({
      language: "en",
      ...options,
      ...(dateFormat ? { format: dateFormat } : {})
    });

    currentDatepicker.on("changeDate", function () {
      $store[key] = currentDatepicker.datepicker("getFormattedDate");
    });
  });
</script>

<div class="row form-group string {required ? 'required' : 'optional'}">
  <label
    class="col-sm-4 col-form-label string {required ? 'required' : 'optional'}"
    class:text-danger={hasError}
    for={id}
  >
    {title}
  </label>
  <div class="col-sm-8">
    <div
      class="input-group date"
      bind:this={datepicker}
      on:blur={() => visitField(key)}
      data-provide="datepicker"
      data-format={dateFormat}
    >
      <input
        {id}
        type="hidden"
        class="form-control string {required ? 'required' : 'optional'}"
        {disabled}
        class:is-invalid={hasError}
      />
      <!-- <div class="input-group-prepend">
        <span class="input-group-text border-left-0">
          <i class="fa fa-calendar"></i>
        </span>
      </div> -->
    </div>
    {#if hasError}
      <small class="text-danger">{$validation.get(key)}</small>
    {/if}
  </div>
</div>
