<script>
  export let frequency;
  export let delay;
  export let actionUrl;
  export let errors;
  export let authenticityToken;

  delay = delay.toString();

  $: haveErrors = errors["delay"] && errors["delay"].length > 0;

  function handleChangeFrequency(event) {
    return (frequency = event.target.value);
  }

  function handleChangeDelay(event) {
    return (delay = event.target.value);
  }

  function isChecked(freq) {
    return freq === frequency;
  }

  function showDelayInput() {
    return frequency !== "never";
  }

  function daysLabel() {
    return delay === "1" ? "day" : "days";
  }
</script>

<form
  novalidate="novalidate"
  class="simple_form new_news_settings"
  id="new_news_settings"
  action={actionUrl}
  accept-charset="UTF-8"
  method="post"
>
  <input name="utf8" type="hidden" value="✓" />
  <input type="hidden" name="_method" value="patch" />
  <input type="hidden" name="authenticity_token" value={authenticityToken} />
  <div class="row">
    <div class="col-sm-4 form-group">
      <label for="news_settings_frequency_never">
        <label for="news_settings_frequency_never">
          <input
            type="radio"
            value="never"
            checked={isChecked("never")}
            on:change={handleChangeFrequency}
            name="news_settings[frequency]"
            id="news_settings_frequency_never"
          />
          &nbsp;Never
        </label>
      </label>
    </div>
    <div class="col-sm-4 form-group">
      <label for="news_settings_frequency_monthly">
        <label for="news_settings_frequency_monthly">
          <input
            type="radio"
            value="monthly"
            checked={isChecked("monthly")}
            on:change={handleChangeFrequency}
            name="news_settings[frequency]"
            id="news_settings_frequency_monthly"
          />
          &nbsp;Monthly
        </label>
      </label>
    </div>
    <div class="col-sm-4 form-group">
      <label for="news_settings_frequency_quarterly">
        <label for="news_settings_frequency_quarterly">
          <input
            type="radio"
            value="quarterly"
            checked={isChecked("quarterly")}
            on:change={handleChangeFrequency}
            name="news_settings[frequency]"
            id="news_settings_frequency_quarterly"
          />
          &nbsp;Quarterly
        </label>
      </label>
    </div>
  </div>
  {#if showDelayInput()}
    <div class="row">
      <span class="delay-input input-group col-12">
        <span class="delay-input__summary">Delay my summary emails for</span>
        <div class="col-sm-2 string required news_settings_delay">
          <input
            class="string required form-control"
            type="text"
            value={delay}
            on:change={handleChangeDelay}
            name="news_settings[delay]"
            id="news_settings_delay"
          />
        </div>
        <span class="delay-input__days">{daysLabel()}</span>
        {#if haveErrors}
          <span class="form-text error">{errors["delay"][0]}</span>
        {/if}
      </span>
    </div>
  {/if}
  <div class="row">
    <div class="col-12">
      <div class="float-right">
        <input
          type="submit"
          name="commit"
          value="Save Settings"
          class="btn btn-primary"
          class:is-invalid={haveErrors}
        />
      </div>
    </div>
  </div>
</form>
