import { omit } from "lodash";

function reduceTree(items, initialVal, func) {
  let accumulator = initialVal;

  for (const item of items) {
    accumulator = func(accumulator, omit(item, "children"));
    const children = item.children;
    if (children) accumulator = reduceTree(children, accumulator, func);
  }

  return accumulator;
}

export function fmapConcat(func, items) {
  return reduceTree(items, [], (accumulator, item) => {
    return [...accumulator, ...func(item)];
  });
}
