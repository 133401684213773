<script>
  import { onMount } from "svelte";

  export let authenticityToken;
  export let smsCode;
  export let phoneNumber;
  export let confirmCodePath;
  export let signOutPath;
  export let secondsTillNewCode;
  export let secondsTillCodeExpiration;

  function openIntercomMenu() {
    if (typeof Intercom !== "undefined") {
      Intercom("show");
      return;
    }
    alert(
      "Please click the chat button towards the bottom of the screen or drop us an email to support@taxplannerpro.com"
    );
  }

  onMount(() => {
    let newCodeTimer = setInterval(() => {
      if (secondsTillNewCode <= 0) {
        clearInterval(newCodeTimer);
        return;
      }
      secondsTillNewCode -= 1;
    }, 1000);
    let codeExpirationTimer = setInterval(() => {
      if (secondsTillCodeExpiration <= 0) {
        clearInterval(codeExpirationTimer);
        return;
      }
      secondsTillCodeExpiration -= 1;
    }, 1000);
  });
</script>

<div class="devise-form__title">
  <h1>
    <b>
      We've sent you a code
      <br />
      to {phoneNumber}
    </b>
  </h1>
</div>
<div class="devise-form">
  <form id="new_user" class="devise-form__container" novalidate action={confirmCodePath} method="post">
    <input name="_method" type="hidden" value="patch" />
    <p class="devise-form__help-text">Enter the code to confirm that it's you.</p>
    <input type="hidden" name="authenticity_token" value={authenticityToken} />
    <input class="devise-form__input" name="code" type="text" placeholder="Code" value={smsCode} />
    <p class="devise-form__help-text devise-form__mt-1">
      We'll remember your device and won't ask for the code in the next 30 days.
    </p>
    <button type="submit" class="devise-form__button devise-form__sign-in">Proceed</button>
    {#if secondsTillCodeExpiration <= 0}
      <p class="devise-form__help-text" style="color: red">
        The code has expired. Please, request a new one by clicking 'Proceed'.
      </p>
      <div class="devise-form__mt-2" />
    {/if}
    <p class="devise-form__action-link-label devise-form__mt-1">
      Didn't received the code?
      {#if secondsTillNewCode > 0}
        <p class="devise-form__action-link devise-form__action-link_disabled">
          You can request a new code in {secondsTillNewCode} seconds
        </p>
      {:else}
        <a href={confirmCodePath} data-method="delete" class="devise-form__action-link">
          Request a new code.
        </a>
      {/if}
    </p>
    <div class="devise-form__action-link-label">
      Don't have access to your phone?
      <div class="devise-form__action-link" on:click={openIntercomMenu}>Reach out to our support team.</div>
    </div>
    <a
      href={signOutPath}
      style="margin-top: 30px"
      data-method="delete"
      class="devise-form__button devise-form__register-button"
    >
      Sign out
    </a>
  </form>
</div>
