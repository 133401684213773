<script>
  export let caption;
  export let className;
  export let handleConnection;
  export let inProcessCaption;
  export let inProcess;
  export let url;

  function handleClick() {
    handleConnection();
    inProcess = !inProcess;
  }
</script>

<a
  href={url}
  target="_blank"
  disabled={inProcess || null}
  on:click={handleClick}
  class={`btn summary-info__btn-animated btn-sm ${className}`}
  class:summary-info__btn-visible-spinner={!!inProcess}
  class:summary-info__btn-hidden-spinner={!inProcess}
>
  {#if inProcess}
    <i class="fa fa-spinner fa-spin summary-info__btn-spinner" />
    {inProcessCaption}
  {:else}
    {caption}
  {/if}
</a>
