<script>
  import { range } from "underscore";

  export let page;
  export let totalPages;

  $: adjustedCurrentPage = Math.min(page, totalPages);
  $: left = Math.max(1, adjustedCurrentPage - 2);
  $: right = Math.min(totalPages, adjustedCurrentPage + 2);

  let haveSlot = $$slots.default;
</script>

<div class="pagination-line">
  {#if haveSlot}
    <div class="pagination-line__row">
      <slot />
    </div>
  {/if}
  {#if totalPages > 1}
    <div class="pagination-line__row">
      <ul class="pagination pagination-sm pagination-line__list justify-content-end">
        <li class="page-item" class:disabled={page <= 1} on:click|preventDefault={() => (page = page - 1)}>
          <span class="page-link pagination-button">«</span>
        </li>
        {#if left > 1}
          <li class="page-item" class:active={adjustedCurrentPage === 1}>
            <span class="page-link pagination-button" on:click|preventDefault={() => (page = 1)}>1</span>
          </li>
        {/if}
        {#if left > 2}
          <li class="page-item disabled">
            <span class="page-link text-muted">&hellip;</span>
          </li>
        {/if}
        {#each range(left, right + 1) as pageNum}
          <li
            class="page-item"
            class:active={pageNum === adjustedCurrentPage}
            on:click|preventDefault={() => (page = pageNum)}
          >
            <span class="page-link pagination-button">{pageNum}</span>
          </li>
        {/each}
        {#if right < totalPages - 1}
          <li class="page-item disabled">
            <span class="page-link text-muted">&hellip;</span>
          </li>
        {/if}
        {#if right < totalPages}
          <li class="page-item" class:active={adjustedCurrentPage === totalPages}>
            <span class="page-link pagination-button" on:click|preventDefault={() => (page = totalPages)}
              >{totalPages}</span
            >
          </li>
        {/if}
        <li
          class="page-item"
          class:disabled={adjustedCurrentPage === totalPages}
          on:click|preventDefault={() => (page = page + 1)}
        >
          <span class="page-link pagination-button">»</span>
        </li>
      </ul>
    </div>
  {/if}
</div>

<style>
  .pagination-line {
    display: flex;
  }

  .pagination-line__row {
    flex: 0.5;
    min-width: 128px;
  }

  .pagination-line__list {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  li.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  li.active {
    pointer-events: none;
  }

  .pagination-button {
    cursor: pointer;
  }
</style>
