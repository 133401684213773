<script>
  import _, { debounce } from "underscore";
  import { notyf_top_right } from "misc/notyf_top_right";
  import BaseSyncRepository from "repositories/BaseSyncRepository.js";
  import TotalTaxDueChart from "components/dashboard/TotalTaxDueChart.svelte";
  import IncomeByMonthChart from "components/dashboard/IncomeByMonthChart.svelte";
  import BusinessExpensesByDestinationChart from "components/dashboard/BusinessExpensesByDestinationChart.svelte";
  import PersonalIncomeBySourceChart from "components/dashboard/PersonalIncomeBySourceChart.svelte";
  import BusinessSummary from "components/dashboard/BusinessSummary.svelte";
  import PersonalSummary from "components/dashboard/PersonalSummary.svelte";

  export let dashboard;
  export let currentCompanyId;
  export let userHasNoBusiness;
  export let dataPath;

  const selectCompany = (companyId) => (currentCompanyId = companyId);

  const syncCompany = async (forceSyncPath) => {
    const baseSyncRepository = new BaseSyncRepository(forceSyncPath);
    try {
      const data = await baseSyncRepository.baseQuery(baseSyncRepository.baseEndpoint, {
        method: "POST",
        headers: baseSyncRepository.headers,
        body: JSON.stringify({})
      });
      notyf_top_right.success(data.success);
    } catch (error) {
      notyf_top_right.error(error.message);
    }
  };

  const dashboardRepository = new BaseSyncRepository(dataPath);
  const refreshDashboard = async () => {
    try {
      const data = await dashboardRepository.baseQuery(dashboardRepository.baseEndpoint, {
        method: "GET",
        headers: dashboardRepository.headers
      });
      dashboard = data;
    } catch (error) {
      notyf_top_right.error(error.message);
    }
  };
</script>

<template lang="pug">
  .row.grid-flex__chart
    .col-12.col-sm-6.col-lg-6.col-xl-6
      .row.grid-flex__columns-chart
        TotalTaxDueChart(dashboard="{dashboard}")
        IncomeByMonthChart(
          dashboard="{dashboard}",
          currentCompanyId="{currentCompanyId}",
          syncCompany="{syncCompany}",
          refreshDashboard="{refreshDashboard}"
        )
    .col-12.col-sm-6.col-lg-6.col-xl-6
      .row.grid-flex__columns-chart
        BusinessExpensesByDestinationChart(
          dashboard="{dashboard}",
          currentCompanyId="{currentCompanyId}",
          syncCompany="{syncCompany}",
          refreshDashboard="{refreshDashboard}"
        )
        PersonalIncomeBySourceChart(dashboard="{dashboard}")

  .row.dashboard
    .col-12.col-sm-6.col-lg-6.col-xl-6.d-flex.flex-column
      BusinessSummary(
        dashboard="{dashboard}",
        selectCompany="{selectCompany}",
        currentCompanyId="{currentCompanyId}",
        userHasNoBusiness="{userHasNoBusiness}"
      )
    .col-12.col-sm-6.col-lg-6.col-xl-6.d-flex.flex-column
      PersonalSummary(dashboard="{dashboard}")
</template>
