<script>
  import PdfDocument from "./PdfDocument.svelte";
  import GeneratingReport from "./GeneratingReport.svelte";

  export let pdfPath;
  export let areaTrackingPath;
</script>

<div class="report-area">
  <PdfDocument {pdfPath} {areaTrackingPath}>
    <GeneratingReport />
  </PdfDocument>
</div>
