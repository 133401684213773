<script>
  import { getContext } from "svelte";
  import { fly } from "svelte/transition";
  import ManageTemplatesDialog from "../dialogs/ManageTemplatesDialog.svelte";

  const { open, close } = getContext("simple-modal");

  export let templates;
  export let updateTemplate;
  export let deleteTemplate;

  const showDialog = () => {
    open(
      ManageTemplatesDialog,
      {
        templates,
        updateTemplate,
        deleteTemplate,
        close
      },
      {
        styleWindow: { width: "35%", maxHeight: "100%" },
        styleCloseButton: { opacity: 1 },
        transitionWindow: fly,
        closeOnOuterClick: false,
        transitionWindowProps: {
          y: -100,
          duration: 500
        }
      }
    );
  };
</script>

<template lang="pug">
  button.btn(on:click|preventDefault="{showDialog}") Manage Templates
</template>

<style lang="scss">
  button {
    color: #1b83ff;
    background: transparent;
    font-family: "Montserrat";
    font-size: 0.8rem;
    padding: 8px 10px;

    &:before {
      content: "\f0c9";
      font-family: FontAwesome;
      margin-right: 5px;
    }

    &:hover,
    &:active,
    &:focus {
      color: #0060d6;
    }
  }
</style>
