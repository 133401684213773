import * as yup from "yup";

const schema = yup.object().shape({
  selectedBusinessUse: yup.number().required().min(0.01).max(1),
  purchasePrice: yup.number().required().min(1),
  leasePayment: yup.number().required().min(1),
  leaseTerm: yup.number().required().min(1),
  selectedVehicleType: yup.string().required().oneOf(["car", "truck"])
});

export const autoLeaseValidation = (values) => {
  try {
    schema.validateSync(values, { abortEarly: false });
    return new Set();
  } catch (err) {
    return new Set(err.inner.map((err) => err.path));
  }
};
