<script>
  import _ from "lodash";
  import { onMount } from "svelte";
  import Select from "svelte-select";

  export let templates;
  export let onCancel;
  export let onApply;

  $: items = templates.map((template) => {
    return { id: template.id, label: template.name };
  });
  $: disableApply = templateProps === undefined;

  let templateProps;

  const optionIdentifier = "id";
  const isClearable = false;
  const showIndicator = true;

  function _onApply() {
    onApply(templateProps);
  }

  function _onCancel() {
    onCancel();
  }

  function handleSelect(event) {
    const selected_template_id = event.detail.id;
    const template = _.find(templates, { id: selected_template_id });
    const { id, updated_at, created_at, accountant_id, name, ...filteredTemplate } = template;

    templateProps = filteredTemplate;
  }

  onMount(() => {
    document.body.style.position = "initial";
  });
</script>

<h2>Select template to apply</h2>

{#each [...[]] as _}
  <div />
{:else}
  <Select
    {items}
    {optionIdentifier}
    {isClearable}
    {showIndicator}
    on:select={handleSelect}
    --itemIsActiveBG="#27b6af"
    --itemHoverBG="#f6f8f8"
    --borderFocusColor="#452161"
  />
  <div class="buttons">
    <button class="btn btn-success" disabled={disableApply} on:click|preventDefault={_onApply}>Apply</button>
    <button class="btn btn-secondary" on:click|preventDefault={_onCancel}>Cancel</button>
  </div>
{/each}

<style>
  h2 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 25px;
  }

  .buttons {
    margin-top: 1em;
  }
</style>
