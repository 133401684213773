<script>
  import ClientSummaryInfo from "./ClientSummaryInfo.svelte";
  import GhostInBtn from "./GhostInBtn.svelte";

  export let summaryInfo;
  export let hasLinkedCompany;
  export let calculationGhostInUrl;
  export let clientPortalGhostInUrl;
</script>

<div>
  <div class="pageheader">
    <div class="row client-page-header">
      <div class="col-md-12 col-lg-2">
        <h1 class="client-page__block-header-title">Settings</h1>
      </div>
      <div class="col-md-12 col-lg-6 text-center">
        <ClientSummaryInfo info={summaryInfo} {hasLinkedCompany} />
      </div>
      <div class="col-md-12 col-lg-4 text-right">
        <GhostInBtn redirectUrl={clientPortalGhostInUrl} className="float-right" caption="Client Portal" />
        <GhostInBtn
          redirectUrl={calculationGhostInUrl}
          caption="Personal Data Entry"
          className="float-right"
        />
      </div>
    </div>
  </div>
</div>

<style global>
  .client-page-header {
  }

  .client-page-header__title {
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .client-page-header__potential {
    margin-top: 3px;
    margin-bottom: 0px;
  }

  .client-page__block-header-title {
    margin-top: 3px;
    margin-bottom: 0;
  }

  .client-page__block-title {
    margin-bottom: 0;
  }
</style>
