<script>
  import accounting from "accounting";
  import ModuleActionButtons from "./ModuleActionButtons.svelte";

  export let module;
  export let handleChangeState;
  export let planningModulePathBase;

  let moduleHref = planningModulePathBase.replace(":planning_module:", module.id);
  let formattedAmount = accounting.formatMoney(module.amount, "$", 0);

  function onChangeState(newState) {
    return handleChangeState(module.id, newState);
  }
</script>

<a class="col-12 col-sm-4 col-lg-3" href={moduleHref}>
  <div
    class="planning-tile planning-tile____module"
    class:planning-tile____completed={module.status === "completed"}
    class:planning-tile____declined={module.status === "declined"}
  >
    <div class="planning-tile__title">{module.title}</div>
    <div class="planning-tile__value">{formattedAmount}</div>
    <ModuleActionButtons status={module.status} {onChangeState} />
  </div>
</a>
