<script>
  import { fit, parent_style } from "@leveluptuts/svelte-fit";

  export let firstTitle = "Contribute";
  export let firstTotal = "";
  export let secondTitle = "And save";
  export let secondTotal = "";
  export let containerClasses = "";
  export let isLoading = false;
</script>

<!-- TODO: item -> carousel-item ?? -->
<!-- <div class=""> -->
<div class="card-body item text-center wow zoomIn {containerClasses}">
  <span class="title d-block">{firstTitle}</span>
  <span class="total d-block" use:fit={{ max_size: 32 }}>{firstTotal}</span>
  <span class="title d-block">{secondTitle}</span>
  {#if isLoading}
    <div class="planning-children">
      <span class="bounce1" />
      <span class="bounce2" />
      <span class="bounce3" />
    </div>
  {:else}
    <span class="total d-block" use:fit={{ max_size: 32 }}>{secondTotal}</span>
  {/if}
</div>

<!-- </div> -->

<style lang="scss">
  .item {
    min-width: 100%;
  }
</style>
