<script>
  import _ from "underscore/underscore";
  import jQuery from "jquery";
  import ClientSettings from "./ClientSettings.svelte";

  export let clientSettings;
  export let csrfToken;
  export let updateProjectionSettingsSrc;

  let localPlannersAccess = clientSettings.planners_access;
  let plannersAccess = clientSettings.planners_access;
  let changeSettingsInProgress = false;
  let error = "";

  const emitChangeDebounced = _.debounce(submitChange, 500);

  function submitChange() {
    changeSettingsInProgress = true;

    jQuery.ajax({
      url: updateProjectionSettingsSrc,
      method: "PATCH",
      data: { planners_access: localPlannersAccess },
      dataType: "JSON",
      headers: { "X-CSRF-Token": csrfToken },
      success: (_data) => {
        plannersAccess = localPlannersAccess;
        error = "";
      },
      error: (data) => {
        localPlannersAccess = plannersAccess;
        if (data.status == 422) {
          error = data.responseJSON.error;
        } else {
          error = "Fatal error";
        }
      },
      complete: () => (changeSettingsInProgress = false)
    });
  }

  function handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    localPlannersAccess = { ...localPlannersAccess, [name]: value };
    emitChangeDebounced();
  }

  function toggleAllTo(bool_val) {
    localPlannersAccess = _.mapObject(localPlannersAccess, () => bool_val);
    emitChangeDebounced();
  }
</script>

<div class="card mb-3">
  <div class="card-header">
    <h3 class="card-title mb-0">Client Projection Settings</h3>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-lg-12">
        <ClientSettings
          bind:localPlannersAccess
          {plannersAccess}
          {changeSettingsInProgress}
          {error}
          onSettingsChange={handleChange}
          {toggleAllTo}
        />
      </div>
    </div>
  </div>
</div>
