<script>
  export let title;
  export let className;

  const fullClassName = `card simulator-dashboard__item-panel mb-3 ${className || ""}`;
</script>

<div class={fullClassName}>
  <div class="card-header">
    <h3 class="card-title mb-0">{title}</h3>
  </div>
  <div class="card-body">
    <slot />
  </div>
</div>

<style lang="scss">
  .card-body {
    padding-bottom: 0px;
  }
</style>
