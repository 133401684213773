<script>
  import _ from "lodash";
  import { onMount } from "svelte";
  import CompanyConnectionIntuit from "./CompanyConnectionIntuit.svelte";
  import CompanyConnectionXero from "./CompanyConnectionXero.svelte";

  export let sourceType;
  export let newConnectionPath;
  export let checkConnectionPath;
  export let csrfToken;

  export let state = "";
  export let errors = [];

  let reloadPage = false;
  let linkGrantError;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": csrfToken
  };

  const request = async (endpoint, params = {}) => {
    try {
      const response = await fetch(endpoint, { headers, ...params });
      return await response.json();
    } catch (error) {
      throw new Error(
        "Something went wrong. If the error persists, please do not hesitate to reach out for support using the chat button at the bottom right corner of the page."
      );
    }
  };

  const requestConnectionStatus = async () => {
    try {
      const data = await request(checkConnectionPath, { method: "GET" });

      linkGrantError = data.link_grant_error;

      if (data.connected) {
        state = "connected";
        if (reloadPage) window.location.reload(); // TODO: remove this after rewrite on svelte components
      } else if (data.errors.length > 0) {
        errors = data.errors;
        state = "disconnected";
        _.delay(requestConnectionStatus, 3000);
      } else {
        if (state !== "connecting") state = "disconnected";
        _.delay(requestConnectionStatus, 1000);
      }
    } catch (error) {
      _.delay(requestConnectionStatus, 5000);
    }
  };

  const startConnection = () => {
    reloadPage = true;
    state = "connecting";
    window.open(newConnectionPath, "_blank").focus();
  };

  onMount(() => {
    requestConnectionStatus();
  });
</script>

<div class="card">
  <div class="card-header">
    <h3 class="card-title">Connection Management</h3>
  </div>
  <div class="card-body">
    <div class="business-source-connection-pane__errors">
      {#each errors as error}
        <div class="alert alert-danger fade show">
          <button class="close" data-dismiss="alert">x</button>
          {error}
        </div>
      {/each}
    </div>
    {#if state.length !== 0}
      {#if sourceType === "intuit"}
        <CompanyConnectionIntuit {state} onConnectionClick={startConnection} />
      {:else if sourceType === "xero"}
        <CompanyConnectionXero {state} {linkGrantError} onConnectionClick={startConnection} />
      {/if}
    {/if}
  </div>
</div>
