<script>
  import { personalProjectionVisitors } from "components/personal_projection/Store.js";
  import { tabs, invalidTabs, tabComponents, tabFields } from "components/personal_projection/tabsStore.js";
  import { filter, every, intersection } from "lodash";

  export let currentTab;

  let visitedTabs = new Set([]);
  let visitedValidTabs = new Set([]);

  $: tabsWithAllFieldsVisited = new Set(
    filter($tabComponents, (tab) => every($tabFields[tab], (f) => $personalProjectionVisitors.has(f)))
  );
  $: visitedTabs = new Set([...visitedTabs, $tabs[currentTab].componentName]);
  $: visitedValidTabs = new Set(intersection([...tabsWithAllFieldsVisited], [...visitedTabs]));
</script>

{#each $tabs as tab, i}
  <div
    class="personal-projection-form-navigation__item"
    class:personal-projection-form-navigation__item-active={currentTab === i}
    on:click={() => (currentTab = i)}
  >
    {#if $invalidTabs.has(tab.componentName)}
      <i aria-label="Invalid Tab" class="fa fa-times personal-projection-form-navigation__item-invalid" />
    {:else if visitedValidTabs.has(tab.componentName)}
      <i aria-label="Valid Tab" class="fa fa-check personal-projection-form-navigation__item-valid" />
    {/if}
    <span>{tab.title}</span>
  </div>
{/each}
