<script>
  import { pdfBookmarksStore } from "../stores/pdfBookmarksStore.js";

  export let bookmark;

  function scrollTo() {
    if (!$pdfBookmarksStore.has(bookmark)) return;

    if (!document.getElementById(bookmark)) return;

    document.getElementById(bookmark).scrollIntoView({ behavior: "smooth" });
  }
</script>

<span class="scroll-to" on:click={scrollTo}>
  <slot />
</span>

<style>
  .scroll-to {
    text-decoration: underline;
    cursor: pointer;
  }
  .scroll-to:hover {
    text-decoration: none;
  }
</style>
