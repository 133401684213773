<script>
  import { camelCase } from "lodash";
  import {
    businessProjection,
    businessProjectionValidation,
    visitField,
    businessProjectionVisitors
  } from "../Store";

  export let key;
  export let storeKey = camelCase(key);
  export let placeholder = null;
  export let tooltip = null;

  $: fieldHtmlId = `business-projection-${key}`;
  $: hasError = $businessProjectionValidation.has(key);
  $: hasSuccess = $businessProjectionVisitors.has(key) && !$businessProjectionValidation.has(key);
  $: tooltipWithError = $businessProjectionValidation.get(key) || tooltip;
</script>

<div>
  {#if tooltipWithError}
    <label for={fieldHtmlId} class="col-form-label tpp-form-label">
      <div class:text-danger={hasError}>
        {tooltipWithError}
      </div>
    </label>
  {/if}
  <input
    id={fieldHtmlId}
    data-testid={fieldHtmlId}
    type="text"
    {placeholder}
    on:blur={() => visitField(key)}
    bind:value={$businessProjection[storeKey]}
    class="form-control string optional"
    class:is-invalid={hasError}
    class:is-valid={hasSuccess}
  />
</div>
