<script>
  export let status;
  export let onChangeState;

  function handleDecline() {
    onChangeState("declined");
  }

  function handleComplete() {
    onChangeState("completed");
  }
</script>

{#if status === "completed"}
  <div>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="planning-tile-button planning-tile-button_yes" on:click|preventDefault>
      <span class="planning-tile-button__label">Accepted</span>
      <i class="planning-tile-button__icon fa fa-thumbs-o-up" />
    </div>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="planning-tile-button planning-tile-button_no" on:click|preventDefault={handleDecline}>
      <i class="fa fa-thumbs-o-down" />
    </div>
  </div>
{:else if status === "declined"}
  <div>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="planning-tile-button planning-tile-button_yes" on:click|preventDefault={handleComplete}>
      <i class="fa fa-thumbs-o-up" />
    </div>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="planning-tile-button planning-tile-button_no" on:click|preventDefault>
      <span class="planning-tile-button__label">Rejected</span>
      <i class="planning-tile-button__icon fa fa-thumbs-o-down" />
    </div>
  </div>
{/if}
