<script>
  import NProgress from "nprogress";
  import { debounce } from "underscore";
  import _ from "lodash";
  import PlanningChildrenEmployment from "./PlanningChildrenEmployment.svelte";
  import BaseSyncRepository from "repositories/BaseSyncRepository.js";

  export let initialNumberOfChildren;
  export let initialResults;
  export let planningSrc;

  let numberOfChildren = initialNumberOfChildren;
  let results = initialResults;
  let loading = false;

  const baseSyncRepository = new BaseSyncRepository(planningSrc);

  $: model = _({}).extend(results, { number_of_children: numberOfChildren }).value();

  const recalculate = debounce(async () => {
    loading = true;
    NProgress.start();
    try {
      const data = await baseSyncRepository.baseQuery(baseSyncRepository.baseEndpoint, {
        method: "POST",
        headers: baseSyncRepository.headers,
        body: JSON.stringify({
          number_of_children: numberOfChildren
        })
      });
      results = data;
    } catch (error) {
      if (error.name === "AbortError") return;
    } finally {
      loading = false;
      NProgress.done();
    }
  }, 500);

  function handleNumberOfChildrenChanged(newNumberOfChildren) {
    numberOfChildren = newNumberOfChildren;
    recalculate();
  }
</script>

<div>
  <PlanningChildrenEmployment
    {model}
    isLoading={loading}
    onNumberOfChildrenChanged={handleNumberOfChildrenChanged}
  />
</div>
