<script>
  import _ from "lodash";
  import moment from "moment";
  import { specificationTree } from "../report/stores/specificationTree";
  import Modal from "svelte-simple-modal";
  import Layout from "../report/options/Layout.svelte";
  import General from "../report/options/General.svelte";
  import FrontPage from "../report/options/FrontPage.svelte";
  import Abstract from "../report/options/Abstract.svelte";
  import Introduction from "../report/options/Introduction.svelte";
  import StrategyDetails from "../report/options/StrategyDetails.svelte";
  import YourDemographics from "../report/options/YourDemographics.svelte";
  import TaxProjectionSummary from "../report/options/TaxProjectionSummary.svelte";
  import TipsAndAdvicesForYou from "../report/options/TipsAndAdvicesForYou.svelte";
  import Top3StrategyPicks from "../report/options/Top3StrategyPicks.svelte";
  import ImportantTaxReturnDueDates from "../report/options/ImportantTaxReturnDueDates.svelte";
  import ReportsSelector from "../report/ReportsSelector.svelte";
  import CreateReportButton from "../report/buttons/CreateReportButton.svelte";
  import RenameReportButton from "../report/buttons/RenameReportButton.svelte";
  import DeleteReportButton from "../report/buttons/DeleteReportButton.svelte";
  import SaveTemplateButton from "../report/buttons/SaveTemplateButton.svelte";
  import ApplyTemplateButton from "../report/buttons/ApplyTemplateButton.svelte";
  import ManageTemplatesButton from "../report/buttons/ManageTemplatesButton.svelte";
  import SearchInput from "../report/SearchInput.svelte";
  import RefreshParamsButton from "../report/buttons/RefreshParamsButton.svelte";
  import { isDirty } from "../report/stores/DirtyReportParams";
  import { searchMatches } from "../report/stores/searchStore";
  import { syncInfoStore } from "components/report/stores/reportStore";

  export let updateReport;
  export let undoCurrentChanges;
  export let showOptionsPane;
  export let reports;
  export let loadMaterializedReport;
  export let applyReport;
  export let createReport;
  export let updateReportName;
  export let memoizedReport;
  export let deleteReport;
  export let createTemplate;
  export let updateTemplate;
  export let templates;
  export let showTemplateButtons;
  export let deleteTemplate;
  export let refreshReportParams;
  export let reportStatus;
  export let projectionsMatch;
  export let taxYearMismatch;
  export let currentSelectedTaxYear;
  export let unpermittedReportAtrributes;
</script>

<template lang="pug">
  .tpp-report-options-pane(class="{showOptionsPane ? 'tpp-report-options-pane_visible' : ''}")
    +if('reportStatus !== "done"')
      .report-options-loading
        div
          h2 Loading new options
        div
          span.pane-loading-indicator__bounce1
          span.pane-loading-indicator__bounce2
          span.pane-loading-indicator__bounce3

    .tpp-report-options-pane__section
      h2.tpp-report-options-pane__header Reports
      ReportsSelector({reports}, {loadMaterializedReport}, {applyReport})
      CreateReportButton({createReport})
      Modal
        RenameReportButton({updateReportName})
      DeleteReportButton({deleteReport})
      <hr>
      Modal
        SaveTemplateButton({createTemplate}, {updateTemplate}, {templates})
      +if('showTemplateButtons')
        +each('[...[]] as _')
          div
          +else
            Modal
              ApplyTemplateButton({templates}, {updateReport})
        +each('[...[]] as _')
          div
          +else
            Modal
              ManageTemplatesButton({templates}, {updateTemplate}, {deleteTemplate})
      <hr>
      div
        +if('taxYearMismatch')
          .tpp-report-options-pane__sync-info
            .alert.alert-warning
              div {`- Report year ${$syncInfoStore.taxYear || ''} differs from current projection year ${currentSelectedTaxYear || ''}`}
          +elseif('!projectionsMatch')
            .tpp-report-options-pane__sync-info
              .alert.alert-warning
                div - Report is based on the old version of personal projection
                div {`- You did a ${$syncInfoStore.syncType} sync on ${moment($syncInfoStore.syncedAt).format("l")}`}
                  
        h2.tpp-report-options-pane__header
          span Report Options
          .tpp-report-options-pane__not-saved-changes(
            title="There are unsaved changes. Press 'Save' in order to save them",
            data-toggle="tooltip",
            data-placement="right",
            class="{$isDirty ? 'tpp-report-options-pane__not-saved-changes_visible' : ''}"
          )
            i.fa.fa-circle
          RefreshParamsButton({refreshReportParams}, specTreeNodeId="report_options", refreshType="full")
        +if('$syncInfoStore.taxYear')
          .tpp-report-options-pane__notice Based on { $syncInfoStore.taxYear } Tax Year's Data
        .tpp-report-options-pane__options
          SearchInput(placeholder="Field Filter")
          +if('$searchMatches.noMatch')
            | No matching field or sections found
          #accordion.report_options.card.panel-group(
            role="tablist",
            aria-multiselectable="true"
          )
            Layout
            General
            FrontPage({refreshReportParams}, {updateReport})
            Abstract
            Introduction
            YourDemographics({memoizedReport}, {refreshReportParams}, {updateReport})
            TaxProjectionSummary({refreshReportParams})
            +if("!unpermittedReportAtrributes.includes('top_3_strategy_picks_enable')")
              Top3StrategyPicks({refreshReportParams})
            +if("!unpermittedReportAtrributes.includes('sd_enable')")
              StrategyDetails({refreshReportParams}, {unpermittedReportAtrributes})
            TipsAndAdvicesForYou
            ImportantTaxReturnDueDates({refreshReportParams})
    .tpp-report-options-pane__actions
      button.btn.btn-primary(on:click="{function () { updateReport(); }}", disabled="{!$isDirty}") Save
      button.btn.undo-btn(on:click="{undoCurrentChanges}", disabled="{!$isDirty}") Undo Current Changes
</template>

<style lang="sass">
  $tpp-report-options-pane-width: 700px

  div :global(.card-body)
    padding: 0.5rem 1.25rem 0.5rem 2.5rem

  div :global(.card-body .panel-collapse > .card-body)
    padding: 0.5rem 0rem 0.5rem 1.25rem

  .tpp-report-options-pane
    width: $tpp-report-options-pane-width
    background: #F6F8F8
    border-left: 1px solid #dce0e6
    display: none
    &_visible
      display: flex
      flex-direction: column
      position: relative
      height: calc(100vh - 116px)
      overflow-y: auto
    &__notice
      color: #666
      font-size: 8pt
      padding-top: 1px
    &__options
      padding-top: 10px
    &__section
      overflow-y: auto
      overflow-x: hidden
      padding: 15px 10px 0 10px
    &__actions
      padding: 15px 10px
      display: flex
      justify-content: space-between

  div :global(.tpp-report-options-pane__header)
    display: inline-block
    margin-bottom: 0px
  div :global(.tpp-report-options-pane__not-saved-changes)
    vertical-align: top
    align-self: flex-start
    display: inline-block
    visibility: hidden
    margin-left: 5px
    color: #452161
    font-size: 7px
  div :global(.tpp-report-options-pane__not-saved-changes_visible)
    visibility: visible

  div :global(.report_options)
    margin-bottom: 10px
  div :global(.report_option)
    display: flex
    align-items: center
  div :global(.report_option__heading)
    display: flex
    justify-content: space-between
    background: #ccc
    padding: 10px
    align-items: center
    cursor: pointer
  div :global(.report_option__body)
    background: white
    padding: 5px 10px
    transition: 1s
  div :global(.report_option__checkbox)
    margin-right: 5px
  div :global(.report_option__title)
    font-family: 'Montserrat'
    font-size: 14px
    font-weight: 400
    align-self: center
  div :global(.report_option__title-group)
    display: flex
    align-items: center
  div :global(.report_option__header)
    font-size: 15px
    user-select: none
    text-transform: none
    letter-spacing: 0.03rem
    align-self: center
    &:hover
      opacity: 0.75
  div :global(.report_option__toggle)
    padding-right: 5px

  div :global(.d-flex)
    display: flex

  div :global(.form-control)
    padding-top: 0
    padding-bottom: 0

  hr
    margin-top: 0

  div :global(.panel-collapse.collapse.in)
    height: auto !important

  .btn
    transition: all .3s

  .undo-btn
    color: black
    background: #FFFFFF
    border: 1px solid black

    &:hover
      opacity: 1
      background: #f4f4f4
    &:disabled
      opacity: 0.5

  .tpp-report-options-pane__sync-info
    margin: 10px 0
    font-size: 13px
  .tpp-report-options-pane__sync-info > *
    margin-top: 5px
</style>
