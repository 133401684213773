import _ from "lodash";
import moment from "moment";
import { buildNewEmployer, buildNewRentalRealEstate } from "./Models";

// deserializes:
//     {
//       test_1_name: 'Vasya',
//       test_1_surname: 'Pupkin',
//       test_2_name: 'Fedor',
//       test_2_surname: 'Zaloopkin',
//     }
// into:
//     [
//       { name: 'Vasya', surname: 'Pupkin' },
//       { name: 'Fedor', surname: 'Zaloopkin' }
//     ]
// ... missing indicies are ignored
function deserializeFlattenedArray(serialized) {
  const prefixPattern = /.*_(\d+)_/;
  const indexPattern = /.*_(\d+)_.*/;
  const keys = _.keys(serialized);
  const values = _.values(serialized);
  const indicies = _.map(keys, (key) => _.parseInt(key.replace(indexPattern, "$1")) - 1);
  const groupedByIndex = _.chain(indicies)
    .zip(keys, values)
    .groupBy((v) => v[0])
    .value();
  const startIdx = _.min(indicies);
  const endIdx = _.max(indicies);

  return _.flatMap(_.range(startIdx, endIdx + 1), (idx) => {
    const serializedEntry = groupedByIndex[idx - startIdx];

    if (serializedEntry) {
      return [
        _.chain(serializedEntry)
          .keyBy((e) => _.replace(e[1], prefixPattern, ""))
          .mapValues((e) => e[2])
          .value()
      ];
    } else {
      return [];
    }
  });
}

// serializes:
//     [
//       { name: 'Vasya', surname: 'Pupkin' },
//       { name: 'Fedor', surname: 'Zaloopkin' }
//     ]
// into:
//     {
//       test_1_name: 'Vasya',
//       test_1_surname: 'Pupkin',
//       test_2_name: 'Fedor',
//       test_2_surname: 'Zaloopkin',
//     }
// ... with indicies starting with 1
function serializeIntoFlattenedHash(prefix, array) {
  return _.merge(
    {},
    ..._.flatMap(array, (e, i) => {
      const keyBase = `${prefix}_${i + 1}_`;
      return _.chain(e)
        .map((v, k) => [`${keyBase}${k}`, v])
        .keyBy((e) => e[0])
        .mapValues((e) => e[1])
        .value();
    })
  );
}

export function deserializePersonalProjection(p) {
  function fillFlattenedArray(from, matching) {
    function mapBooleans(e) {
      return _.mapValues(e, (value) => {
        if (value === "YES") {
          return true;
        }
        if (value === "NO") {
          return false;
        }
        return value;
      });
    }

    return deserializeFlattenedArray(_.pickBy(from, matching))
      .map(mapBooleans)
      .filter((e) => e.enabled)
      .map((e) => _.omit(e, "enabled"));
  }

  function mapPaystubDate(wage) {
    return {
      ...wage,
      paystub_date: moment(wage.paystub_date, "YYYY-MM-DD").format("MM/DD/YYYY")
    };
  }

  const isWagesArrayKey = (_value, key) => key.match(/^wages_\d/);
  const isRentalRealEstateArrayKey = (_value, key) => key.match(/^rental_real_estate_\d/);

  var result = _.omitBy(
    p,
    (value, key) => isWagesArrayKey(value, key) || isRentalRealEstateArrayKey(value, key)
  );

  result["wages"] = _.map(fillFlattenedArray(p, isWagesArrayKey), mapPaystubDate);
  result["rental_real_estate"] = fillFlattenedArray(p, isRentalRealEstateArrayKey);
  result["rental_real_estate_professional"] = p["rental_real_estate_professional"] === "YES";

  return result;
}

// returns extra missing elements for an array with { enabled: 'NO' }
function augmentArray(array, targetLength, build) {
  return _.map(_.range(array.length, targetLength), (_) => build());
}

export function serializePersonalProjection(p) {
  function enabled(e) {
    return { ...e, enabled: true };
  }

  function disabled(e) {
    return { ...e, enabled: false };
  }

  function mapBooleans(e) {
    return _.mapValues(e, (value) => {
      if (value === true) {
        return "YES";
      }
      if (value === false) {
        return "NO";
      }
      return value;
    });
  }

  // make it an array of 8 by filling in the missing extras with build()'ed records
  function augmentedArray(key, build) {
    return _.concat(p[key].map(enabled), augmentArray(p[key], 8, build).map(disabled)).map(mapBooleans);
  }

  function mapPaystubDate(wage) {
    return {
      ...wage,
      paystub_date: moment(wage.paystub_date, "MM/DD/YYYY").format("YYYY-MM-DD")
    };
  }

  const resultingWages = _.map(augmentedArray("wages", buildNewEmployer), mapPaystubDate);
  const resultingRentalRealEstate = augmentedArray("rental_real_estate", buildNewRentalRealEstate);

  return {
    ..._.omit(p, "wages", "rental_real_estate"),
    ...serializeIntoFlattenedHash("wages", resultingWages),
    ...serializeIntoFlattenedHash("rental_real_estate", resultingRentalRealEstate),
    rental_real_estate_professional: p["rental_real_estate_professional"] ? "YES" : "NO"
  };
}
