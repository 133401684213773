<script>
  import _ from "lodash";
  import accounting from "accounting";

  export let value;

  function formatValue(value) {
    if (_.isNil(value)) return "-";
    if (typeof value === "string") return value;

    return formatMoney(value);
  }

  function formatMoney(val) {
    return accounting.formatMoney(val, {
      symbol: "$",
      precision: 0,
      format: {
        pos: "%s%v",
        neg: "%s(%v)",
        zero: "%s%v"
      }
    });
  }
</script>

{formatValue(value)}
