<script>
  import { reportStore } from "../stores/reportStore";
  import { specificationTree } from "../stores/specificationTree";
  import CheckboxInput from "../inputs/CheckboxInput.svelte";
  import Dropdown from "../Dropdown.svelte";
  import { searchMatches } from "../stores/searchStore";

  let introductionTitle = "Introduction";
  let explainGradingSystemTitle = "Explain Grading System";
</script>

<template lang="pug">
  Dropdown(
    specTreeNodeId="introduction_enable",
    bind:checked="{$reportStore.introduction_enable}",
    hidden="{$searchMatches.isHidden(introductionTitle)}",
    scrollToId="pdfIntroduction"
  )
    CheckboxInput(
      specTreeNodeId="introduction_grading_systems_enable",
      bind:checked="{$reportStore.introduction_grading_systems_enable}",
      hidden="{$searchMatches.isHidden(explainGradingSystemTitle)}"
    )
</template>
