<script>
  import RefreshParamsButton from "../buttons/RefreshParamsButton.svelte";
  import RestoreDefaultButton from "../buttons/RestoreDefaultButton.svelte";
  import { flatSpecTree } from "../stores/specificationTree";
  import DirtyParamTooltip from "../helpers/DirtyParamTooltip.svelte";

  import ColorPicker from "vendor/svelte-awesome-color-picker@2.4.8/components/ColorPicker.svelte";

  export let value;
  export let defaultValue;
  export let hidden;
  export let specTreeNodeId;
  export let refreshReportParams;

  const id = "i" + Math.random().toString(16).substr(2, 8);
  $: reportOption = $flatSpecTree && specTreeNodeId && $flatSpecTree[specTreeNodeId];
</script>

<template>
  <div class="row" {hidden}>
    <div class="col-sm-12">
      <div class="form-group">
        <div class="report_option">
          <label class="report_option__title" for={id}>{reportOption.title}</label>
          <DirtyParamTooltip param={specTreeNodeId} />
          {#if reportOption.updatable}
            <RefreshParamsButton {refreshReportParams} {specTreeNodeId} />
          {/if}
          <RestoreDefaultButton bind:value {defaultValue} />
        </div>
        {#if $$slots.description}
          <div class="description">
            <slot name="description" />
          </div>
        {/if}
        <div class="color-picker-wrapper">
          <ColorPicker
            {id}
            hex="#{value || 'ffffff'}"
            on:input={(e) => {
              value = e.detail.hex.replace("#", "");
            }}
            isAlpha={false}
            sliderDirection="horizontal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  label {
    display: block;
  }
  .color-picker-wrapper {
    font-size: 14px;
    padding: 12px 0 12px 10px;
    background: #f5f5f5;
    border-radius: 25px;
  }
  .description {
    font-size: 12px;
    padding: 0 0 10px 0;
  }
</style>
