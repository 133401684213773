<script>
  import _ from "lodash";
  import { onMount } from "svelte";
  import moment from "moment";
  import NProgress from "nprogress";
  import { notyf_top_right } from "misc/notyf_top_right";
  import { businessProjection, businessProjectionSerialized } from "components/business_projection/Store";
  import { deserializeBusinessProjection } from "components/business_projection/Serialization";

  import NavTabs from "./NavTabs.svelte";
  import BusinessData from "./BusinessData.svelte";
  import HandyProjectionIncomesExpenses from "./HandyProjectionIncomesExpenses.svelte";

  export let businessProjectionData;
  export let sourceType;
  export let companyData;
  export let companyConnectionPath;
  export let savePath;
  export let personalProjectionPath;
  export let handyProjectionEntriesPath;
  export let updateHandyProjectionEntriesPath;

  let unsubscribeBusinessProjectionSerialized = null;
  let statusNotice = null;
  let activeTab = "Business Data";

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": window.$('meta[name="csrf-token"]').attr("content")
  };

  const request = async (endpoint, params = {}) => {
    try {
      const response = await fetch(endpoint, { headers, ...params });
      return await response.json();
    } catch (error) {
      throw new Error(
        "Something went wrong. If the error persists, please do not hesitate to reach out for support using the chat button at the bottom right corner of the page."
      );
    }
  };

  function formatHash(hash) {
    return hash
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  let tabsContent = {
    "Business Data": {
      component: BusinessData,
      props: { statusNotice, sourceType, personalProjectionPath }
    },
    "Income And Expenses": {
      component: HandyProjectionIncomesExpenses,
      props: {
        handyProjectionEntriesPath,
        updateHandyProjectionEntriesPath,
        request
      }
    }
  };

  const debouncedSave = _.debounce(async (bp) => {
    if (bp) {
      statusNotice = "Unsaved changes present. Saving...";
      NProgress.start();
      try {
        const data = await request(savePath, {
          method: "PUT",
          body: JSON.stringify(bp)
        });
        if (data.error) {
          notyf_top_right.error(data.error);
        } else {
          companyData = data["company_data"];
          if (_.isFunction(unsubscribeBusinessProjectionSerialized))
            unsubscribeBusinessProjectionSerialized();
          businessProjection.set(deserializeBusinessProjection(data["business_projection_data"]));
          unsubscribeBusinessProjectionSerialized = subscribeIgnoreFirst(
            businessProjectionSerialized,
            debouncedSave
          );
          statusNotice = `Saved at ${moment().format("h:mm:ss a")}, no unsaved changes`;
        }
      } catch (error) {
        console.log(error);
        notyf_top_right.error(
          "We apologize for any inconvenience, but something went wrong. Please try again, and if the problem persists, reach out to our support team."
        );
      }
      NProgress.done();
    }
  }, 1500);

  function subscribeIgnoreFirst(store, fn) {
    let firedFirst = false;
    return store.subscribe((state) => {
      if (!firedFirst) {
        firedFirst = true;
      } else {
        fn(state);
      }
    });
  }

  function ensureChangesSaved() {
    window.onbeforeunload = () => {
      if (statusNotice === "Unsaved changes present. Saving...") return true;
    };
  }

  onMount(() => {
    activeTab = formatHash(window.location.hash.substring(1)) || "Business Data";

    businessProjection.set(deserializeBusinessProjection(businessProjectionData));
    unsubscribeBusinessProjectionSerialized = subscribeIgnoreFirst(
      businessProjectionSerialized,
      debouncedSave
    );
    ensureChangesSaved();
  });
</script>

<svelte:window on:hashchange={activeTab} />

<div class="pageheader">
  <h1>{companyData.name}</h1>
</div>

<NavTabs bind:activeTab {companyConnectionPath} {companyData} {sourceType} />

<section id="main-content">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        {#if companyData.hasBusinessProjection}
          <svelte:component this={tabsContent[activeTab].component} {...tabsContent[activeTab].props} />
        {:else}
          <div class="card-header">
            <h3 class="card-title">Connection Management</h3>
          </div>
          <div class="card-body">
            <a href={companyConnectionPath} class="btn btn-primary btn-lg">
              Please, connect Business Data Source at the Sync page first
            </a>
          </div>
        {/if}
      </div>
    </div>
  </div>
</section>
