<script>
  export let value;
  export let defaultValue;
</script>

{#if value !== defaultValue}
  <i
    class="fa restore-button"
    on:click|self={() => {
      value = defaultValue;
    }}
  />
{/if}
