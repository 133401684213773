<script>
  export let onValueChanged;
  export let value;
  export let minValue;
  export let maxValue;
  export let title;

  function handleChange(delta) {
    return onValueChanged(value + delta);
  }
</script>

<div class="card-body">
  <span class="total">
    <button class="btn btn-secondary" class:invisible={value <= minValue} on:click={() => handleChange(-1)}>
      -
    </button>
    {value}
    <button class="btn btn-secondary" class:invisible={value >= maxValue} on:click={() => handleChange(+1)}>
      +
    </button>
  </span>
  <span class="title">{title}</span>
</div>
