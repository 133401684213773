<script>
  import TextArea from "./TextArea.svelte";
  import CheckboxInput from "./CheckboxInput.svelte";
  import { intersperse } from "utils/intersperse";

  export let newNotes;
  export let currentNotes;
  export let newSummaryEmailAdminCopy;
  export let currentSummaryEmailAdminCopy;
  export let additionsChangeSubmitted;
  export let changeAdditionsInProgress;
  export let error;
  export let onSubmitChange;
  export let onResetControl;

  $: notesChanged = newNotes !== currentNotes;
  $: summaryEmailAdminCopyChanged = newSummaryEmailAdminCopy !== currentSummaryEmailAdminCopy;
  $: canChangeAdditions = notesChanged || summaryEmailAdminCopyChanged;

  function handleSubmit() {
    onSubmitChange();
  }

  function submittedMessage() {
    let clearedNotes = newNotes.trim().length == 0;
    let messages = [];
    if (clearedNotes) {
      messages.push('<span key="email">You have cleared the notes</span>');
      if (summaryEmailAdminCopyChanged) {
        messages.push(
          `<span key="summaryEmailAdminCopy">and you will ${
            newSummaryEmailAdminCopy ? "" : "not"
          } receive a copy of summary emails</span>`
        );
      }
    } else {
      if (notesChanged) {
        messages.push(`<span key="notes">You have changed the notes to: <b>${newNotes}</b></span>`);
      }
      if (summaryEmailAdminCopyChanged) {
        messages.push(
          `<span key="summaryEmailAdminCopy">${messages.length > 0 ? " and you" : "You"} <b>will ${
            newSummaryEmailAdminCopy ? "" : "not"
          }</b> receive a copy of summary emails</span>`
        );
      }
    }

    return messages;
  }
</script>

{#if additionsChangeSubmitted}
  <div>
    <p>{@html intersperse(submittedMessage(), " ")}</p>
    <p>
      <button href="#" class="btn btn-primary" on:click={onResetControl}>OK, Got It</button>
    </p>
  </div>
{:else}
  <div class="client-additions-form" class:client-additions____in-progress={changeAdditionsInProgress}>
    {#if error && error.length > 0}
      <p class="error">{error}</p>
    {/if}
    <TextArea title="Notes" bind:value={newNotes} serverValue={currentNotes} />
    <CheckboxInput
      title="Send a copy of summary email to me"
      bind:value={newSummaryEmailAdminCopy}
      serverValue={currentSummaryEmailAdminCopy}
    />

    <button class="btn btn-primary" on:click={handleSubmit} disabled={!canChangeAdditions}>
      Update Additions
    </button>
  </div>
{/if}

<style global>
  .client-additions-form {
    transition: opacity 0.5s ease-in-out;
  }

  .client-additions____in-progress {
    opacity: 0.5;
  }
</style>
