<script>
  import { dirtyNodes } from "../stores/DirtyReportParams";

  export let param;

  $: isDirty = $dirtyNodes.has(param);
</script>

<template lang="pug">
  .tpp-report-options-pane__not-saved-changes(
    title="This change is not saved yet. Press 'Save' in order to save it.",
    data-toggle="tooltip",
    data-placement="right",
    class="{isDirty ? 'tpp-report-options-pane__not-saved-changes_visible' : ''}"
  )
    i.fa.fa-circle
</template>
