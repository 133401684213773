<script>
  import { zoom } from "./utils/zoomStore.js";

  export let page;
  export let scrollToId;
  export let areas;

  let canvasRef;
  let renderInProgress = false;
  let debugMode = window.location.href.substring(window.location.href.indexOf("#") + 1) == "debug";

  $: if (canvasRef) renderPage(page, areas, $zoom);

  function renderPage(proxyPage, areas, zoom) {
    if (renderInProgress) return;

    renderInProgress = true;
    const outputScale = window.devicePixelRatio || 1;
    const viewport = proxyPage.getViewport({ scale: zoom, rotation: 0, dontFlip: false });

    canvasRef.width = viewport.width * outputScale;
    canvasRef.height = viewport.height * outputScale;
    canvasRef.style.width = Math.floor(viewport.width) + "px";
    canvasRef.style.height = Math.floor(viewport.height) + "px";
    const ctx = canvasRef.getContext("2d");

    const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

    proxyPage
      .render({
        canvasContext: ctx,
        transform,
        viewport
      })
      .promise.then(() => {
        renderAreas(ctx, areas, canvasRef.width, canvasRef.height);
        renderInProgress = false;
      });
  }

  function renderAreas(ctx, areas, maxWidth, maxHeight) {
    if (debugMode) {
      ctx.globalAlpha = 0.4;
      ctx.fillStyle = "red";
      ctx.fillRect(0, 0, 10, 10);
      ctx.fillStyle = "green";
      ctx.fillRect(maxWidth - 10, 0, maxWidth, 10);
      ctx.fillStyle = "yellow";
      ctx.fillRect(0, maxHeight - 10, 10, maxHeight);
      ctx.fillStyle = "blue";
      ctx.fillRect(maxWidth - 10, maxHeight - 10, maxWidth, maxHeight);
      ctx.globalAlpha = 1.0;
    }
    if (typeof areas === "object") {
      ctx.globalAlpha = debugMode ? 0.1 : 0.0;
      ctx.fillStyle = "blue";
      Object.entries(areas).forEach(([_key, rect]) => {
        if (Array.isArray(rect)) {
          const [topLeft, bottomRight] = [rect[0], rect[1]];
          if (Array.isArray(topLeft) && Array.isArray(bottomRight)) {
            ctx.fillRect(
              topLeft[0] * maxWidth,
              topLeft[1] * maxHeight,
              (bottomRight[0] - topLeft[0]) * maxWidth,
              (bottomRight[1] - topLeft[1]) * maxHeight
            );
          }
        }
      });
      ctx.globalAlpha = 1.0;
    }
  }
</script>

<canvas id={scrollToId} bind:this={canvasRef} />

<style lang="scss">
  canvas {
    display: block;
    margin-bottom: 20px;
    margin-top: 10px;
    box-shadow:
      0 4px 8px 0 rgba(0, 0, 0, 0.05),
      0 6px 20px 0 rgba(0, 0, 0, 0.1);
  }
</style>
