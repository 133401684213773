<script>
  import { reportStore } from "../stores/reportStore";
  import { specificationTree } from "../stores/specificationTree";
  import CurrencyInputField from "../inputs/CurrencyInput.svelte";
  import NumberInputField from "../inputs/NumberInput.svelte";
  import TextInputField from "../inputs/TextInput.svelte";
  import Dropdown from "../Dropdown.svelte";
  import { searchMatches } from "../stores/searchStore";

  export let refreshReportParams;
</script>

<template lang="pug">
  Dropdown(
    {refreshReportParams},
    specTreeNodeId="tps_enable",
    bind:checked="{$reportStore.tps_enable}",
    hidden="{$searchMatches.isHidden('Tax Projection Summary')}",
    scrollToId="pdfTaxProjectionSummary"
  )
    Dropdown(
      {refreshReportParams},
      specTreeNodeId="tps_page_1",
      classNames="report-suboption",
      hidden="{$searchMatches.isHidden('Page 1 Income')}"
    )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_income_projected_revenue",
        bind:value="{$reportStore.tps_page_1_income_projected_revenue}",
        hidden="{$searchMatches.isHidden('Projected Revenue')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_income_projected_expense",
        bind:value="{$reportStore.tps_page_1_income_projected_expense}",
        hidden="{$searchMatches.isHidden('Projected Expense')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_income_projected_expense_sum",
        bind:value="{$reportStore.tps_page_1_income_projected_expense_sum}",
        hidden="{$searchMatches.isHidden('Business Income')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_income_wages",
        bind:value="{$reportStore.tps_page_1_income_wages}",
        hidden="{$searchMatches.isHidden('Wages')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_income_portfolio_income",
        bind:value="{$reportStore.tps_page_1_income_portfolio_income}",
        hidden="{$searchMatches.isHidden('Portfolio Income')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_income_rental_income",
        bind:value="{$reportStore.tps_page_1_income_rental_income}",
        hidden="{$searchMatches.isHidden('Rental Income')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_income_other_income",
        bind:value="{$reportStore.tps_page_1_income_other_income}",
        hidden="{$searchMatches.isHidden('Other Income')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_income_personal_income",
        bind:value="{$reportStore.tps_page_1_income_personal_income}",
        hidden="{$searchMatches.isHidden('Personal Income')}"
      )
    Dropdown(
      {refreshReportParams},
      specTreeNodeId="tps_page_1_deductions_and_credits",
      classNames="report-suboption",
      hidden="{$searchMatches.isHidden('Page 1 Deductions & Credits')}"
    )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_deductions_and_credits_adjustments",
        bind:value="{$reportStore.tps_page_1_deductions_and_credits_adjustments}",
        hidden="{$searchMatches.isHidden('Adjustments')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_deductions_and_credits_passthrough_deduction",
        bind:value="{$reportStore.tps_page_1_deductions_and_credits_passthrough_deduction}",
        hidden="{$searchMatches.isHidden('Passthrough Deductions')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_deductions_and_credits_itemized_deduction",
        bind:value="{$reportStore.tps_page_1_deductions_and_credits_itemized_deduction}",
        hidden="{$searchMatches.isHidden('Itemized Deduction')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_deductions_and_credits_credits",
        bind:value="{$reportStore.tps_page_1_deductions_and_credits_credits}",
        hidden="{$searchMatches.isHidden('Credits')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_deductions_and_credits_deductions_and_credits",
        bind:value="{$reportStore.tps_page_1_deductions_and_credits_deductions_and_credits}",
        hidden="{$searchMatches.isHidden('Deductions And Credits')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_deductions_and_credits_taxable_income",
        bind:value="{$reportStore.tps_page_1_deductions_and_credits_taxable_income}",
        hidden="{$searchMatches.isHidden('Taxable Income')}"
      )
    Dropdown(
      {refreshReportParams},
      specTreeNodeId="tps_page_1_tax_and_payments",
      classNames="report-suboption",
      hidden="{$searchMatches.isHidden('Page 1 Taxes & Payments')}"
    )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_tax_and_payments_tax_fed",
        bind:value="{$reportStore.tps_page_1_tax_and_payments_tax_fed}",
        hidden="{$searchMatches.isHidden('Tax Fed')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_tax_and_payments_tax_state",
        bind:value="{$reportStore.tps_page_1_tax_and_payments_tax_state}",
        hidden="{$searchMatches.isHidden('Tax State')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_tax_and_payments_witholding_and_es_payments_fed",
        bind:value="{$reportStore.tps_page_1_tax_and_payments_witholding_and_es_payments_fed}",
        hidden="{$searchMatches.isHidden('Witholding And Es Payments Fed')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_tax_and_payments_witholding_and_es_payments_stat",
        bind:value="{$reportStore.tps_page_1_tax_and_payments_witholding_and_es_payments_stat}",
        hidden="{$searchMatches.isHidden('Witholding And Es Payments State')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_tax_and_payments_tax_due_fed",
        bind:value="{$reportStore.tps_page_1_tax_and_payments_tax_due_fed}",
        hidden="{$searchMatches.isHidden('Tax Due Fed')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_tax_and_payments_tax_due_state",
        bind:value="{$reportStore.tps_page_1_tax_and_payments_tax_due_state}",
        hidden="{$searchMatches.isHidden('Tax Due State')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_tax_and_payments_next_lower_bracket",
        bind:value="{$reportStore.tps_page_1_tax_and_payments_next_lower_bracket}",
        hidden="{$searchMatches.isHidden('Next Lower Bracket')}"
      )
      NumberInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_tax_and_payments_effective_tax_rate",
        bind:value="{$reportStore.tps_page_1_tax_and_payments_effective_tax_rate}",
        hidden="{$searchMatches.isHidden('Effective Tax Rate')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_1_tax_and_payments_distance_to_bracket",
        bind:value="{$reportStore.tps_page_1_tax_and_payments_distance_to_bracket}",
        hidden="{$searchMatches.isHidden('Distance To Bracket')}"
      )
    Dropdown(
      {refreshReportParams},
      specTreeNodeId="tps_page_2_income",
      classNames="report-suboption",
      hidden="{$searchMatches.isHidden('Page 2 Income')}"
    )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_income_wages",
        bind:value="{$reportStore.tps_page_2_income_wages}",
        hidden="{$searchMatches.isHidden('Wages')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_income_interest",
        bind:value="{$reportStore.tps_page_2_income_interest}",
        hidden="{$searchMatches.isHidden('Interest')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_income_dividents",
        bind:value="{$reportStore.tps_page_2_income_dividents}",
        hidden="{$searchMatches.isHidden('Dividents')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_income_capital_gains",
        bind:value="{$reportStore.tps_page_2_income_capital_gains}",
        hidden="{$searchMatches.isHidden('Capital Gains')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_income_rental_income",
        bind:value="{$reportStore.tps_page_2_income_rental_income}",
        hidden="{$searchMatches.isHidden('Rental Income')}"
      )
      TextInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_income_first_company_name",
        bind:value="{$reportStore.tps_page_2_income_first_company_name}",
        hidden="{$searchMatches.isHidden('First Company Name')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_income_first_company_income",
        bind:value="{$reportStore.tps_page_2_income_first_company_income}",
        hidden="{$searchMatches.isHidden('First Company Income')}"
      )
      TextInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_income_second_company_name",
        bind:value="{$reportStore.tps_page_2_income_second_company_name}",
        hidden="{$searchMatches.isHidden('Second Company Name')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_income_second_company_income",
        bind:value="{$reportStore.tps_page_2_income_second_company_income}",
        hidden="{$searchMatches.isHidden('Second Company Income')}"
      )
      TextInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_income_third_company_name",
        bind:value="{$reportStore.tps_page_2_income_third_company_name}",
        hidden="{$searchMatches.isHidden('Third Company Name')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_income_third_company_income",
        bind:value="{$reportStore.tps_page_2_income_third_company_income}",
        hidden="{$searchMatches.isHidden('Third Company Income')}"
      )
      TextInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_income_fourth_company_name",
        bind:value="{$reportStore.tps_page_2_income_fourth_company_name}",
        hidden="{$searchMatches.isHidden('Fourth Company Name')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_income_fourth_company_income",
        bind:value="{$reportStore.tps_page_2_income_fourth_company_income}",
        hidden="{$searchMatches.isHidden('Fourth Company Income')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_income_extra_s_corp_income",
        bind:value="{$reportStore.tps_page_2_income_extra_s_corp_income}",
        hidden="{$searchMatches.isHidden('Extra S-Corp Income')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_income_extra_schedule_c_income",
        bind:value="{$reportStore.tps_page_2_income_extra_schedule_c_income}",
        hidden="{$searchMatches.isHidden('Extra Schedule C Business Income')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_income_other_income",
        bind:value="{$reportStore.tps_page_2_income_other_income}",
        hidden="{$searchMatches.isHidden('Other Income')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_income_total_income",
        bind:value="{$reportStore.tps_page_2_income_total_income}",
        hidden="{$searchMatches.isHidden('Total Income')}"
      )
    Dropdown(
      {refreshReportParams},
      specTreeNodeId="tps_page_2_adjustments",
      classNames="report-suboption",
      hidden="{$searchMatches.isHidden('Page 2 Adjustments')}"
    )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_adjustments_health_savings_account",
        bind:value="{$reportStore.tps_page_2_adjustments_health_savings_account}",
        hidden="{$searchMatches.isHidden('Health Savings Account')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_adjustments_deductible_se_tax",
        bind:value="{$reportStore.tps_page_2_adjustments_deductible_se_tax}",
        hidden="{$searchMatches.isHidden('Deductible Se Tax')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_adjustments_sep_simple_qual_plans",
        bind:value="{$reportStore.tps_page_2_adjustments_sep_simple_qual_plans}",
        hidden="{$searchMatches.isHidden('Sep Simple Qual Plans')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_adjustments_self_employed_health_ins",
        bind:value="{$reportStore.tps_page_2_adjustments_self_employed_health_ins}",
        hidden="{$searchMatches.isHidden('Self Employed Health Insurance')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_adjustments_ira_deduction",
        bind:value="{$reportStore.tps_page_2_adjustments_ira_deduction}",
        hidden="{$searchMatches.isHidden('Ira Deduction')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_adjustments_other_adjustments",
        bind:value="{$reportStore.tps_page_2_adjustments_other_adjustments}",
        hidden="{$searchMatches.isHidden('Other Adjustments')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_adjustments_total_adjustments",
        bind:value="{$reportStore.tps_page_2_adjustments_total_adjustments}",
        hidden="{$searchMatches.isHidden('Total Adjustments')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_2_adjustments_adjusted_gross_income",
        bind:value="{$reportStore.tps_page_2_adjustments_adjusted_gross_income}",
        hidden="{$searchMatches.isHidden('Adjusted Gross Income')}"
      )
    Dropdown(
      {refreshReportParams},
      specTreeNodeId="tps_page_3_deductions",
      classNames="report-suboption",
      hidden="{$searchMatches.isHidden('Page 3 Deductions')}"
    )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_deductions_medical_related",
        bind:value="{$reportStore.tps_page_3_deductions_medical_related}",
        hidden="{$searchMatches.isHidden('Medical Related')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_deductions_taxes",
        bind:value="{$reportStore.tps_page_3_deductions_taxes}",
        hidden="{$searchMatches.isHidden('Taxes')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_deductions_mortgage_interest",
        bind:value="{$reportStore.tps_page_3_deductions_mortgage_interest}",
        hidden="{$searchMatches.isHidden('Mortgage Interest')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_deductions_charity",
        bind:value="{$reportStore.tps_page_3_deductions_charity}",
        hidden="{$searchMatches.isHidden('Charity')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_deductions_other_itemized_deductions",
        bind:value="{$reportStore.tps_page_3_deductions_other_itemized_deductions}",
        hidden="{$searchMatches.isHidden('Other Itemized Deductions')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_deductions_passthrough_deduction",
        bind:value="{$reportStore.tps_page_3_deductions_passthrough_deduction}",
        hidden="{$searchMatches.isHidden('Passthrough Deduction')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_deductions_total_deductions",
        bind:value="{$reportStore.tps_page_3_deductions_total_deductions}",
        hidden="{$searchMatches.isHidden('Total Deduction')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_deductions_taxable_income",
        bind:value="{$reportStore.tps_page_3_deductions_taxable_income}",
        hidden="{$searchMatches.isHidden('Taxable Income')}"
      )
    Dropdown(
      {refreshReportParams},
      specTreeNodeId="tps_page_3_taxes_and_payments",
      classNames="report-suboption",
      hidden="{$searchMatches.isHidden('Page 3 Taxes & Payments')}"
    )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_tax_fed",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_tax_fed}",
        hidden="{$searchMatches.isHidden('Tax Fed')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_tax_state",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_tax_state}",
        hidden="{$searchMatches.isHidden('Tax State')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_alternative_min_tax",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_alternative_min_tax}",
        hidden="{$searchMatches.isHidden('Alternative Min Tax')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_self_employment_tax",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_self_employment_tax}",
        hidden="{$searchMatches.isHidden('Self Employment Tax')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_other_taxes",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_other_taxes}",
        hidden="{$searchMatches.isHidden('Other Taxes')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_total_tax_fed",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_total_tax_fed}",
        hidden="{$searchMatches.isHidden('Total Tax Fed')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_total_tax_state",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_total_tax_state}",
        hidden="{$searchMatches.isHidden('Total Tax State')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_credits",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_credits}",
        hidden="{$searchMatches.isHidden('Credits')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_state_tax_credit",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_state_tax_credit}",
        hidden="{$searchMatches.isHidden('State Tax Credit')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_witholding_fed",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_witholding_fed}",
        hidden="{$searchMatches.isHidden('Witholding Fed')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_witholding_state",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_witholding_state}",
        hidden="{$searchMatches.isHidden('Witholding State')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_estimated_tax_payments_fed",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_estimated_tax_payments_fed}",
        hidden="{$searchMatches.isHidden('Estimated Tax Payments Fed')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_estimated_tax_payments_state",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_estimated_tax_payments_state}",
        hidden="{$searchMatches.isHidden('Estimated Tax Payments State')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_other_payments",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_other_payments}",
        hidden="{$searchMatches.isHidden('Other Payments')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_total_federal_tax_credits",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_total_federal_tax_credits}",
        hidden="{$searchMatches.isHidden('Total Federal Tax Credits')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_total_state_tax_credits",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_total_state_tax_credits}",
        hidden="{$searchMatches.isHidden('Total State Tax Credits')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_tax_due",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_tax_due}",
        hidden="{$searchMatches.isHidden('Tax Due')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="tps_page_3_taxes_and_payments_state_tax_due",
        bind:value="{$reportStore.tps_page_3_taxes_and_payments_state_tax_due}",
        hidden="{$searchMatches.isHidden('State Tax Due')}"
      )
</template>
