import moment from "moment";

export function deserializeCompany(data) {
  return {
    name: data.name,
    businessType: data.business_type,
    share: data.share,
    owner: data.owner,
    startDate: moment(data.start_date, "YYYY-MM-DD").format("MM/DD/YYYY")
  };
}

export function serializeCompany(data) {
  return {
    name: data.name,
    business_type: data.businessType,
    share: data.share,
    owner: data.owner,
    start_date: moment(data.startDate, "MM/DD/YYYY").format("YYYY-MM-DD")
  };
}
