<script>
  import { isNil } from "lodash";
  import {
    personalProjection,
    personalProjectionValidation,
    statesOfResidencyOptions,
    filingStatusOptions
  } from "../Store";
  import NumericField from "../fields/NumericField.svelte";
  import SelectField from "../fields/SelectField.svelte";

  export let statusNotice = null;
</script>

<div class="">
  <div class="personal-projection-form-content__section-header">
    <h2 class="personal-projection-form-content__section-name">Demographics</h2>
    {#if !isNil(statusNotice)}
      <small
        class="personal-projection-form-content__section-header-status font-weight-light col-lg-3 text-right text-muted"
      >
        {statusNotice}
      </small>
    {/if}
  </div>
  <div>
    <div class="personal-projection-form-content__fields">
      <SelectField
        key="type"
        bind:value={$personalProjection.type}
        hasError={$personalProjectionValidation.has("type")}
        caption="Select Filing Status"
        options={filingStatusOptions}
      />
      <SelectField
        key="state_of_residency"
        bind:value={$personalProjection.state_of_residency}
        hasError={$personalProjectionValidation.has("state_of_residency")}
        caption="Select State of Residency"
        options={statesOfResidencyOptions}
      />
      <NumericField
        key="fed_par_no_of_dependents_from_0_to_6"
        bind:value={$personalProjection.fed_par_no_of_dependents_from_0_to_6}
        hasError={$personalProjectionValidation.has("fed_par_no_of_dependents_from_0_to_6")}
      />
      <NumericField
        key="fed_par_no_of_dependents_from_6_to_17"
        bind:value={$personalProjection.fed_par_no_of_dependents_from_6_to_17}
        hasError={$personalProjectionValidation.has("fed_par_no_of_dependents_from_6_to_17")}
      />
      <NumericField
        key="fed_par_no_of_dependents_over_17"
        bind:value={$personalProjection.fed_par_no_of_dependents_over_17}
        hasError={$personalProjectionValidation.has("fed_par_no_of_dependents_over_17")}
      />
    </div>
  </div>
</div>
