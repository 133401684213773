<script>
  import { isNil } from "lodash";
  import TaxpayerSpouseNumericField from "../fields/TaxpayerSpouseNumericField.svelte";

  export let statusNotice = null;
</script>

<div class="">
  <div class="personal-projection-form-content__section-header">
    <h2 class="personal-projection-form-content__section-name">Adjustments</h2>
    {#if !isNil(statusNotice)}
      <small
        class="personal-projection-form-content__section-header-status font-weight-light col-lg-3 text-right text-muted"
      >
        {statusNotice}
      </small>
    {/if}
  </div>
  <div>
    <div class="personal-projection-form-content__fields">
      <TaxpayerSpouseNumericField key="fed_inc_adj_health_insurance_premium" />
      <TaxpayerSpouseNumericField key="ira_deduction" />
      <TaxpayerSpouseNumericField key="per_ira_deduction" />
      <TaxpayerSpouseNumericField key="per_health_savings_acct" />
      <TaxpayerSpouseNumericField key="other_deductions" />
    </div>
  </div>
</div>
