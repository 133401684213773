<script>
  import _ from "underscore";
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import NProgress from "nprogress";
  import { autoLeaseValidation } from "components/planning/schemas/autoLeaseSchema";
  import AutoLeaseRepository from "repositories/AutoLeaseRepository.js";

  export let autoLeaseEndpoint;
  export let autoLeasePlanner;

  const autoLeaseRepository = new AutoLeaseRepository(autoLeaseEndpoint);

  let vehicles = [
    { id: 1, text: "Under 6,000 lbs", type: "car" },
    { id: 2, text: "Over 6,000 lbs", type: "truck" }
  ];

  let values = {
    selectedBusinessUse: 1,
    purchasePrice: 0,
    leasePayment: 0,
    leaseTerm: 12,
    selectedVehicleType: "car"
  };

  let endOfPage = null;
  let invalidFields = new Set([]);
  let valuesAlreadyCalculated = { dummy: "initial value" };
  let calculationUpToDate = false;
  let purchaseSummary = [];
  let extraBlankPurchaseRows = 0;
  let leaseSummary = [];
  let extraBlankLeaseRows = 0;
  let depreciationTaxSavings;
  let leaseTaxSavings;

  const recalculate = _.debounce(async () => {
    NProgress.start();

    try {
      const response = await autoLeaseRepository.update({
        business_use: values.selectedBusinessUse,
        purchase_price: values.purchasePrice,
        lease_payment: values.leasePayment,
        lease_term: values.leaseTerm,
        vehicle_type: values.selectedVehicleType
      });

      if (response.status == 200) {
        const data = await autoLeaseRepository.recalculate();

        purchaseSummary = data.purchase_summary;
        leaseSummary = data.lease_summary;

        valuesAlreadyCalculated = _.clone(values);
        endOfPage.scrollIntoView();
      }
    } catch (error) {
      if (error.name === "AbortError") return;
    } finally {
      NProgress.done();
    }
  }, 500);

  $: invalidFields = autoLeaseValidation(values);

  $: calculationUpToDate = _.isMatch(values, valuesAlreadyCalculated);

  $: if (invalidFields.size === 0) {
    recalculate(values);
  }

  $: depreciationTaxSavings = purchaseSummary.reduce((acc, val) => acc + (val.tax_savings || 0), 0);

  $: leaseTaxSavings = leaseSummary.reduce((acc, val) => acc + (val.tax_savings || 0), 0);

  $: {
    const purchaseSummaryRows = purchaseSummary.length;
    const leaseSummaryRows = leaseSummary.length;

    extraBlankPurchaseRows = 0;
    extraBlankLeaseRows = 0;

    if (_.isNumber(purchaseSummaryRows) && _.isNumber(leaseSummaryRows)) {
      if (purchaseSummaryRows > leaseSummaryRows) {
        extraBlankLeaseRows = purchaseSummaryRows - leaseSummaryRows;
      } else {
        extraBlankPurchaseRows = leaseSummaryRows - purchaseSummaryRows;
      }
    }
  }

  onMount(() => {
    if (autoLeasePlanner) {
      values = {
        selectedBusinessUse: autoLeasePlanner.business_use,
        purchasePrice: autoLeasePlanner.purchase_price,
        leasePayment: autoLeasePlanner.lease_payment,
        leaseTerm: autoLeasePlanner.lease_term,
        selectedVehicleType: autoLeasePlanner.vehicle_type
      };
    }
  });
</script>

<div class="row">
  <div class="col-lg-6 offset-3">
    <div class="form-group">
      <label class="col-form-label col-sm-4" for="businessUse">Business Use %:</label>
      <div class="col-sm-8">
        <select
          id="businessUse"
          class="form-control"
          class:is-invalid={invalidFields.has("selectedBusinessUse")}
          bind:value={values.selectedBusinessUse}
        >
          {#each Array(100) as _, i}
            <option value={(i + 1) / 100}>
              {i + 1}%
            </option>
          {/each}
        </select>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-6">
    <div class="form-group">
      <label class="col-form-label col-sm-4" for="purchasePrice">Purchase Price:</label>
      <div class="col-sm-8">
        <input
          id="purchasePrice"
          class="form-control"
          class:is-invalid={invalidFields.has("purchasePrice")}
          type="number"
          min="1"
          max="10"
          bind:value={values.purchasePrice}
        />
      </div>
    </div>
    <div class="form-group">
      <label class="col-form-label col-sm-4" for="vehicle">Vehicle Weight:</label>
      <div class="col-sm-8">
        <select
          id="vehicle"
          class="form-control"
          class:is-invalid={invalidFields.has("selectedVehicleType")}
          bind:value={values.selectedVehicleType}
        >
          {#each vehicles as vehicle}
            <option value={vehicle.type}>
              {vehicle.text}
            </option>
          {/each}
        </select>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="form-group">
      <label class="col-form-label col-sm-4" for="leasePayment">Lease Payment:</label>
      <div class="col-sm-8">
        <input
          id="leasePayment"
          class="form-control"
          class:is-invalid={invalidFields.has("leasePayment")}
          bind:value={values.leasePayment}
          type="number"
        />
      </div>
    </div>
    <div class="form-group">
      <label class="col-form-label col-sm-4" for="leaseTerm">Lease Term (months):</label>
      <div class="col-sm-8">
        <input
          id="leaseTerm"
          class="form-control"
          class:is-invalid={invalidFields.has("leaseTerm")}
          bind:value={values.leaseTerm}
          type="number"
        />
      </div>
    </div>
  </div>
</div>

{#if calculationUpToDate}
  <div class="row summary" transition:fade>
    <div class="col-lg-6">
      <div class="text-center summary-text">Vehicle Purchase Summary</div>
      {#if purchaseSummary.length > 0}
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col" />
              <th scope="col">Depreciation</th>
              <th scope="col">Tax Savings</th>
            </tr>
          </thead>
          <tbody>
            {#each purchaseSummary as { depreciation, tax_savings }, i}
              <tr>
                <th scope="row">Year {i + 1}</th>
                <td>${depreciation}</td>
                <td>${tax_savings}</td>
              </tr>
            {/each}
            {#each Array(extraBlankPurchaseRows) as _}
              <tr><td colspan="3">&nbsp;</td></tr>
            {/each}
            <tr>
              <th scope="row" colspan="2" class="text-right">Total:</th>
              <td>${depreciationTaxSavings}</td>
            </tr>
          </tbody>
        </table>
      {:else}
        <div class="text-center">No Data</div>
      {/if}
    </div>
    <div class="col-lg-6">
      <div class="text-center summary-text">Vehicle Lease Summary</div>
      {#if leaseSummary.length > 0}
        <table class="table table-sm">
          <thead>
            <tr>
              <th />
              <th>Lease Expense</th>
              <th>Tax Savings</th>
            </tr>
          </thead>
          <tbody>
            {#each leaseSummary as { lease_expense, tax_savings }, i}
              <tr>
                <th scope="row">Year {i + 1}</th>
                <td>${lease_expense}</td>
                <td>${tax_savings}</td>
              </tr>
            {/each}
            {#each Array(extraBlankLeaseRows) as _}
              <tr><td colspan="3">&nbsp;</td></tr>
            {/each}
            <tr>
              <th scope="row" colspan="2" class="text-right">Total:</th>
              <td>${leaseTaxSavings}</td>
            </tr>
          </tbody>
        </table>
      {:else}
        <div class="text-center">No Data</div>
      {/if}
    </div>
  </div>
{:else}
  <div class="row summary" transition:fade>
    <div class="col-lg-12 text-center data-placeholder">
      {#if invalidFields.size > 0}
        <i>Please, update the fields to have valid values</i>
      {:else}
        <i>Just a second...</i>
      {/if}
    </div>
  </div>
{/if}

<div bind:this={endOfPage} />

<style lang="scss">
  .summary {
    margin: 40px 0 20px;
  }

  .summary-text {
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 10px;
  }

  .form-group {
    display: flex;
    align-items: center;
    margin: 20px 0 10px 0;
  }

  .data-placeholder {
    line-height: 150px;
  }
</style>
