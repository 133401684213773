<script>
  import { onMount } from "svelte";
  import _ from "lodash";
  import accounting from "accounting";
  import { notyf_top_right } from "misc/notyf_top_right";
  import BaseSyncRepository from "repositories/BaseSyncRepository.js";

  export let healthSavingsEndpoint;
  export let previousTaxYear;
  export let currentTaxYear;
  export let colHeaders;
  export let rowHeaders;
  export let staticBody;
  export let contributionLimits;
  export let taxSavings;

  let recalculating = true;
  let contributionLimitsFormated = formatCurrency(contributionLimits);
  let tableBody = staticBody.concat(contributionLimitsFormated);
  let rowHeadersWithYears = addYearsToHeaders(rowHeaders, previousTaxYear, currentTaxYear);

  const baseSyncRepository = new BaseSyncRepository(healthSavingsEndpoint);

  const recalculate = _.debounce(async () => {
    recalculating = true;
    try {
      const data = await baseSyncRepository.baseQuery(baseSyncRepository.baseEndpoint, {
        method: "POST",
        headers: baseSyncRepository.headers,
        body: JSON.stringify({
          healthSavings: {}
        })
      });

      previousTaxYear = data.previousTaxYear;
      currentTaxYear = data.currentTaxYear;
      colHeaders = data.colHeaders;
      rowHeaders = data.rowHeaders;
      staticBody = data.staticBody;
      contributionLimits = data.contributionLimits;
      taxSavings = data.taxSavings;
      contributionLimitsFormated = formatCurrency(contributionLimits);
      tableBody = staticBody.concat(contributionLimitsFormated);
      rowHeadersWithYears = addYearsToHeaders(rowHeaders, previousTaxYear, currentTaxYear);
    } catch (error) {
      notyf_top_right.error(error.message);
    } finally {
      recalculating = false;
    }
  }, 1000);

  function currency(value) {
    return accounting.formatMoney(value, {
      symbol: "$",
      precision: 0,
      format: {
        pos: "%s%v",
        neg: "%s(%v)",
        zero: "%s%v"
      }
    });
  }

  function addYearsToHeaders(rowHeaders, previousTaxYear, currentTaxYear) {
    const rowHeadersWithYears = rowHeaders;
    rowHeadersWithYears[rowHeaders.length - 1] = [rowHeaders[rowHeaders.length - 1], currentTaxYear].join(
      " "
    );
    rowHeadersWithYears[rowHeaders.length - 2] = [rowHeaders[rowHeaders.length - 2], previousTaxYear].join(
      " "
    );
    return rowHeadersWithYears;
  }

  function formatCurrency(contributionLimits) {
    return contributionLimits.map((a) =>
      a.map((v) => {
        return !_.isNil(v) ? currency(v) : "None";
      })
    );
  }

  onMount(recalculate);
</script>

<div class="col-sm-12 container widget-div">
  <div class="col-lg-12 overflow-x__auto">
    <div class="company-type__spinner" hidden={!recalculating}>
      <span class="company-type__spinner-bounce1" />
      <span class="company-type__spinner-bounce2" />
      <span class="company-type__spinner-bounce3" />
    </div>
    <div class:company-type__blur={recalculating}>
      <table class="table table-striped flash-table">
        <thead>
          <tr>
            <th />
            {#each colHeaders as colHeader}
              <th class="table-header">{colHeader}</th>
            {/each}
          </tr>
        </thead>
        <tbody>
          {#each rowHeadersWithYears as rowHeader, i}
            <tr>
              <td class="header">
                {rowHeader}
              </td>
              {#each tableBody[i] as row}
                <td>
                  {row}
                </td>
              {/each}
            </tr>
          {/each}
        </tbody>
        <tfoot class="table-footer">
          <tr>
            <td>
              <b>Tax Savings</b>
              <small>(Contributions)</small>
            </td>
            <td colspan="3">&nbsp;</td>
          </tr>
          <tr>
            <td class="no-line">
              {#each Object.keys(taxSavings) as value}
                <p><b>{currency(value)}</b></p>
              {/each}
            </td>
            {#each Object.values(taxSavings) as column}
              <td class="no-line">
                {#each Object.values(column) as value}
                  {#if !_.isNil(value)}
                    <p class="negative" class:positive={value >= 0}>{currency(value)}</p>
                  {:else}
                    <p>N/A<sup>*</sup></p>
                  {/if}
                {/each}
              </td>
            {/each}
          </tr>
        </tfoot>
      </table>
      <div class="summary">
        <p><sup>*</sup> N/A means the contribution amount is too high for your situation</p>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .company-type {
    transition: 0.2s filter linear;
  }
  .company-type__blur {
    filter: blur(1px) opacity(0.2);
  }
  .company-type__spinner {
    position: absolute;
    z-index: 100;
    width: 100%;
    top: 50%;
    text-align: center;
  }
  .company-type__spinner-bounce1,
  .company-type__spinner-bounce2,
  .company-type__spinner-bounce3 {
    width: 18px;
    height: 18px;
    background-color: #1d212a;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  .company-type__spinner-bounce1 {
    animation-delay: -0.32s;
  }
  .company-type__spinner-bounce2 {
    animation-delay: -0.16s;
  }
  .company-type__overflow-x {
    overflow-x: auto;
  }
  .no-line {
    border: none;
    padding-top: 0;
  }
  .container {
    padding: 10px;
  }
  .flash-table {
    border-spacing: 0px;
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border-top: 0px;
        }
      }
    }
    > thead > tr {
      > th,
      > td {
        border-bottom-width: 0px;
      }
    }
  }
  .widget-div {
    display: flex;
    flex-wrap: wrap;
  }
  .value {
    text-align: end;
  }
  .negative,
  .positive {
    font-weight: bold;
  }
  .negative {
    color: red;
  }
  .positive {
    color: green;
  }
  .header {
    font-weight: bold;
  }
  .table-header {
    background-color: #452461;
    color: #ffffff;
    padding: 8px;
  }
  .table-footer {
    background-color: #f9f9f9;
  }
</style>
