const fieldsWithLabels = [
  [["type"], ["Demographics", "Filing Status"]],
  [["state_of_residency"], ["Demographics", "State of Residency"]],
  [["fed_par_no_of_dependents_from_0_to_6"], ["Demographics", "Number of Dependents from 0 to 6"]],
  [["fed_par_no_of_dependents_from_6_to_17"], ["Demographics", "Number of Dependents from 6 to 17"]],
  [["fed_par_no_of_dependents_over_17"], ["Demographics", "Number of Dependents over 17"]],
  ...[...Array(8)].flatMap((_el, i) => {
    return [
      [[`wages_${i + 1}_enabled`], ["Wages/W-2s", `Tab ${i + 1}`, "Enabled"]],
      [
        [`employer_name_${i}`, `wages_${i + 1}_employer_name`],
        ["Wages/W-2s", `Tab ${i + 1}`, "Employer Name"]
      ],
      [
        [`employer_type_${i}`, `wages_${i + 1}_employer_type`],
        ["Wages/W-2s", `Tab ${i + 1}`, "Taxpayer or Spouse"]
      ],
      [
        [`paystub_date_${i}`, `wages_${i + 1}_paystub_date`],
        ["Wages/W-2s", `Tab ${i + 1}`, "Paystub Date"]
      ],
      [
        [`age_${i}`, `wages_${i + 1}_age`],
        ["Wages/W-2s", `Tab ${i + 1}`, "Age"]
      ],
      [
        [`wages_${i}`, `wages_${i + 1}_wages`],
        ["Wages/W-2s", `Tab ${i + 1}`, "Wages"]
      ],
      [
        [`federal_income_tax_withheld_${i}`, `wages_${i + 1}_federal_income_tax_withheld`],
        ["Wages/W-2s", `Tab ${i + 1}`, "Federal Income Tax Withheld"]
      ],
      [
        [`state_income_tax_withheld_${i}`, `wages_${i + 1}_state_income_tax_withheld`],
        ["Wages/W-2s", `Tab ${i + 1}`, "State Income Tax Withheld"]
      ],
      [
        [`dependent_care_benefits_${i}`, `wages_${i + 1}_dependent_care_benefits`],
        ["Wages/W-2s", `Tab ${i + 1}`, "Dependent Care Benefits"]
      ],
      [
        [`401k_contributions_${i}`, `wages_${i + 1}_401k_contributions`],
        ["Wages/W-2s", `Tab ${i + 1}`, "401K Contributions"]
      ],
      [
        [`business_owned_${i}`, `wages_${i + 1}_business_owned`],
        ["Wages/W-2s", `Tab ${i + 1}`, "Business Owned by Taxpayer/Spouse?"]
      ],
      [
        [`covered_by_retirement_plan_${i}`, `wages_${i + 1}_covered_by_retirement_plan`],
        ["Wages/W-2s", `Tab ${i + 1}`, "Covered by retirement plan at work"]
      ]
    ];
  }),
  [["social_security_taxpayer"], ["Income", "Social Security"]],
  [["social_security_spouse"], ["Income", "Spouse Social Security"]],
  [["fed_inc_bank_1_interest_taxpayer"], ["Income", "Interest Income"]],
  [["fed_inc_ordinary_dividends_taxpayer"], ["Income", "Ordinary Dividends"]],
  [["fed_inc_money_market_1_interest_taxpayer"], ["Income", "IRA Distribution Income"]],
  [["fed_inc_stock_qualified_div_taxpayer"], ["Income", "Qualified Dividends"]],
  [["fed_inc_stock_st_div_gains_taxpayer"], ["Income", "Short Term Capital Gains"]],
  [["fed_inc_stock_lt_cap_gains_taxpayer"], ["Income", "Long Term Cap Gains"]],
  [["fed_inc_adj_capital_losses_this_year_taxpayer"], ["Income", "Capital Losses"]],
  [["fed_inc_stock_60man_cap_gains_taxpayer"], ["Income", "60 Month Capital Gains"]],
  [["fed_inc_other_income_taxpayer"], ["Income", "Other Income"]],
  [["fed_inc_bank_1_interest_spouse"], ["Income", "Spouse Interest Income"]],
  [["fed_inc_ordinary_dividends_spouse"], ["Income", "Spouse Ordinary Dividends"]],
  [["fed_inc_money_market_1_interest_spouse"], ["Income", "Spouse IRA Distribution Income"]],
  [["fed_inc_stock_qualified_div_spouse"], ["Income", "Spouse Qualified Dividends"]],
  [["fed_inc_stock_st_div_gains_spouse"], ["Income", "Spouse Short Term Capital Gains"]],
  [["fed_inc_stock_lt_cap_gains_spouse"], ["Income", "Spouse Long Term Cap Gains"]],
  [["fed_inc_adj_capital_losses_this_year_spouse"], ["Income", "Spouse Capital Losses"]],
  [["fed_inc_stock_60man_cap_gains_spouse"], ["Income", "Spouse 60 Month Capital Gains"]],
  [["fed_inc_other_income_spouse"], ["Income", "Spouse Other Income"]],
  [["fed_inc_ca_tax_refund_taxpayer"], ["Income", "State Tax Refund"]],
  [["rental_real_estate_professional"], ["Rental Real Estate", "I am a rental real estate professional"]],
  ...[...Array(8)].flatMap((_el, i) => {
    return [
      [[`rental_real_estate_${i + 1}_enabled`], ["Rental Real Estate", `Tab ${i + 1}`, "Enabled"]],
      [
        [`name_${i}`, `rental_real_estate_${i + 1}_name`],
        ["Rental Real Estate", `Tab ${i + 1}`, "Real Estate Name"]
      ],
      [
        [`income_${i}`, `rental_real_estate_${i + 1}_income`],
        ["Rental Real Estate", `Tab ${i + 1}`, "Income"]
      ],
      [
        [`advertising_${i}`, `rental_real_estate_${i + 1}_advertising`],
        ["Rental Real Estate", `Tab ${i + 1}`, "Advertising"]
      ],
      [
        [`auto_expenses_${i}`, `rental_real_estate_${i + 1}_auto_expenses`],
        ["Rental Real Estate", `Tab ${i + 1}`, "Auto Expenses"]
      ],
      [
        [`cleaning_${i}`, `rental_real_estate_${i + 1}_cleaning`],
        ["Rental Real Estate", `Tab ${i + 1}`, "Cleaning"]
      ],
      [
        [`commissions_${i}`, `rental_real_estate_${i + 1}_commisions`],
        ["Rental Real Estate", `Tab ${i + 1}`, "Commissions"]
      ],
      [
        [`depreciation_${i}`, `rental_real_estate_${i + 1}_depreciation`],
        ["Rental Real Estate", `Tab ${i + 1}`, "Depreciation"]
      ],
      [
        [`insurance_${i}`, `rental_real_estate_${i + 1}_insurance`],
        ["Rental Real Estate", `Tab ${i + 1}`, "Insurance"]
      ],
      [
        [`legal_expenses_${i}`, `rental_real_estate_${i + 1}_legal_expenses`],
        ["Rental Real Estate", `Tab ${i + 1}`, "Legal Expenses"]
      ],
      [
        [`management_fees_${i}`, `rental_real_estate_${i + 1}_management_fees`],
        ["Rental Real Estate", `Tab ${i + 1}`, "Management Fees"]
      ],
      [
        [`mortage_interest_${i}`, `rental_real_estate_${i + 1}_mortage_interest`],
        ["Rental Real Estate", `Tab ${i + 1}`, "Mortage Interest"]
      ],
      [
        [`repairs_${i}`, `rental_real_estate_${i + 1}_repairs`],
        ["Rental Real Estate", `Tab ${i + 1}`, "Repairs"]
      ],
      [
        [`supplies_${i}`, `rental_real_estate_${i + 1}_supplies`],
        ["Rental Real Estate", `Tab ${i + 1}`, "Supplies"]
      ],
      [
        [`taxes_${i}`, `rental_real_estate_${i + 1}_taxes`],
        ["Rental Real Estate", `Tab ${i + 1}`, "Taxes"]
      ],
      [
        [`utilities_${i}`, `rental_real_estate_${i + 1}_utilities`],
        ["Rental Real Estate", `Tab ${i + 1}`, "Utilities"]
      ],
      [
        [`other_${i}`, `rental_real_estate_${i + 1}_other`],
        ["Rental Real Estate", `Tab ${i + 1}`, "Other"]
      ]
    ];
  }),
  [["fed_inc_adj_health_insurance_premium_taxpayer"], ["Adjustments", "Self Employed Health Insurance"]],
  [["ira_deduction_taxpayer"], ["Adjustments", "SEP IRA Contribution"]],
  [["per_ira_deduction_taxpayer"], ["Adjustments", "Traditional IRA Deduction"]],
  [["per_health_savings_acct_taxpayer"], ["Adjustments", "Health Savings Account"]],
  [["other_deductions_taxpayer"], ["Adjustments", "Other Deductions"]],
  [["fed_ded_mortgage_interest_taxpayer"], ["Deductions", "Mortgage Interest"]],
  [["fed_ded_state_income_taxes_taxpayer"], ["Deductions", "State Income Taxes"]],
  [["fed_ded_local_income_taxes_taxpayer"], ["Deductions", "Local/City Income Taxes"]],
  [["fed_ded_prior_state_inc_taxes_taxpayer"], ["Deductions", "Prior Year State Income Taxes"]],
  [["fed_ded_property_taxes_taxpayer"], ["Deductions", "Property Taxes"]],
  [["fed_ded_personal_property_taxes_taxpayer"], ["Deductions", "Personal Property Taxes"]],
  [["fed_ded_home_loan_points_taxpayer"], ["Deductions", "Home Loan Points"]],
  [["fed_ded_medical_and_dental_taxpayer"], ["Deductions", "Medical and Dental Costs"]],
  [["fed_ded_cash_donations_taxpayer"], ["Deductions", "Cash Donations"]],
  [["fed_ded_noncash_donations_taxpayer"], ["Deductions", "Non Cash Donations"]],
  [["fed_ded_other_deductions_taxpayer"], ["Deductions", "Other Deductions"]],
  [["usr_scorp_business_income_taxpayer"], ["Other Business Income", "Other S-corp Business Income"]],
  [["usr_schedule_c_income_taxpayer"], ["Other Business Income", "Other Schedule C Business Income"]],
  [["fed_tax_previous_year_taxpayer"], ["Taxes", "Previous Year Federal Tax"]],
  [["state_tax_previous_year_taxpayer"], ["Taxes", "Previous Year State Tax"]],
  [["fed_tax_previous_year_spouse"], ["Taxes", "Spouse Previous Year Federal Tax"]],
  [["state_tax_previous_year_spouse"], ["Taxes", "Spouse Previous Year State Tax"]],
  [["fed_tax_owed_other_taxes_taxpayer"], ["Taxes", "Other Taxes"]],
  [["fed_tax_owed_prior_year_amt_carryforward_taxpayer"], ["Taxes", "Prior Year AMT Carryforward"]],
  [["fed_tax_owed_tax_credits_taxpayer"], ["Taxes", "Tax Credits"]],
  [["fed_tax_owed_child_tax_credit_override_taxpayer"], ["Taxes", "Child Tax Credits"]],
  [["res_inc_itemized_override_taxpayer"], ["Taxes", "Itemized Deductions"]],
  [["res_passthrough_deduction_override_taxpayer"], ["Taxes", "Passthrough Deduction"]],
  [["res_se_tax_override_taxpayer"], ["Taxes", "SE Tax"]],
  [["res_passthrough_deduction_override_taxpayer"], ["Taxes", "Passthrough Deduction"]],
  [["res_total_tax_override_taxpayer"], [("Taxes", "Total Federal Tax")]],
  [["res_state_tax_before_payments_override_taxpayer"], ["Taxes", "Total State Tax"]],
  [["business_inc_ccorp_fed_tax_override_taxpayer"], ["Taxes", "C Corp Federal Tax"]],
  [["business_inc_ccorp_state_tax_override_taxpayer"], ["Taxes", "C Corp State Tax"]],
  [["fed_est_tax_pay_march_taxpayer"], ["Payments", "Federal Q1 Estimated Tax Payment"]],
  [["fed_est_tax_pay_june_taxpayer"], ["Payments", "Federal Q2 Estimated Tax Payment"]],
  [["fed_est_tax_pay_september_taxpayer"], ["Payments", "Federal Q3 Estimated Tax Payment"]],
  [["fed_est_tax_pay_december_taxpayer"], ["Payments", "Federal Q4 Estimated Tax Payment"]],
  [["state_estimated_tax_payments_q1_taxpayer"], ["Payments", "State Q1 Estimated Tax Payment"]],
  [["state_estimated_tax_payments_q2_taxpayer"], ["Payments", "State Q2 Estimated Tax Payment"]],
  [["state_estimated_tax_payments_q3_taxpayer"], ["Payments", "State Q3 Estimated Tax Payment"]],
  [["state_estimated_tax_payments_q4_taxpayer"], ["Payments", "State Q4 Estimated Tax Payment"]],
  [["state_529_plan_contributions_taxpayer"], ["State Adjustments", "529 Plan Contributions"]],
  [["state_dependent_education_expenses_taxpayer"], ["State Adjustments", "Dependent Education Expenses"]],
  [["state_child_dependent_care_expenses_taxpayer"], ["State Adjustments", "Child Dependent Care Expenses"]],
  [
    ["state_unreimbursed_employee_expenses_taxpayer"],
    ["State Adjustments", "Unreimbursed Employee Expenses"]
  ],
  [["tax_exempt_interest_taxpayer"], ["State Adjustments", "Tax Exempt Interest"]],
  [["taxpayer_age"], ["State Adjustments", "Taxpayer Age"]],
  [["state_in_county_code"], ["State Adjustments", "County"]],
  [["state_md_county_code"], ["State Adjustments", "County"]],
  [["state_sec_179_deduction_taxpayer"], ["State Adjustments", "Section 179 deduction from K1 or Sch C"]],
  [["state_depreciation_adjustment_taxpayer"], ["State Adjustments", "Depreciation Adjustment"]],
  [["fed_estate_trust_income_taxpayer"], ["State Adjustments", "Estate or Trust Income"]],
  [["fed_gambling_income_taxpayer"], ["State Adjustments", "Net Gambling Winnings"]],
  [["fed_tax_free_interest_taxpayer"], ["State Adjustments", "US Govt Bond Interest"]],
  [["fed_passive_activity_losses_taxpayer"], ["State Adjustments", "Passive Activity Losses"]],
  [["fed_inc_tax_refund_taxpayer"], ["State Adjustments", "Federal Income Tax Refund"]],
  [["state_ks_tax_credits_taxpayer"], ["State Adjustments", "Other Credits"]],
  [["state_sc_college_tuition_taxpayer"], ["State Adjustments", "College Tuition Credit"]],
  [["state_adj_additions_taxpayer"], ["State Adjustments", "Additions"]],
  [["state_adj_substractions_taxpayer"], ["State Adjustments", "Subtractions"]],
  [["state_adj_tax_credit_taxpayer"], ["State Adjustments", "Tax Credit"]],
  [["fed_inc_adj_health_insurance_premium_spouse"], ["Adjustments", "Spouse Self Employed Health Insurance"]],
  [["ira_deduction_spouse"], ["Adjustments", "Spouse SEP IRA Contribution"]],
  [["per_ira_deduction_spouse"], ["Adjustments", "Spouse Traditional IRA Deduction"]],
  [["per_health_savings_acct_spouse"], ["Adjustments", "Spouse Health Savings Account"]],
  [["other_deductions_spouse"], ["Adjustments", "Spouse Other Deductions"]],
  [["fed_ded_mortgage_interest_spouse"], ["Deductions", "Spouse Mortgage Interest"]],
  [["fed_ded_state_income_taxes_spouse"], ["Deductions", "Spouse State Income Taxes"]],
  [["fed_ded_local_income_taxes_spouse"], ["Deductions", "Spouse Local/City Income Taxes"]],
  [["fed_ded_prior_state_inc_taxes_spouse"], ["Deductions", "Spouse Prior Year State Income Taxes"]],
  [["fed_ded_property_taxes_spouse"], ["Deductions", "Spouse Property Taxes"]],
  [["fed_ded_personal_property_taxes_spouse"], ["Deductions", "Spouse Personal Property Taxes"]],
  [["fed_ded_home_loan_points_spouse"], ["Deductions", "Spouse Home Loan Points"]],
  [["fed_ded_medical_and_dental_spouse"], ["Deductions", "Spouse Medical and Dental Costs"]],
  [["fed_ded_cash_donations_spouse"], ["Deductions", "Spouse Cash Donations"]],
  [["fed_ded_noncash_donations_spouse"], ["Deductions", "Spouse Non Cash Donations"]],
  [["fed_ded_other_deductions_spouse"], ["Deductions", "Spouse Other Deductions"]],
  [["usr_scorp_business_income_spouse"], ["Other Business Income", "Spouse Other S-corp Business Income"]],
  [["usr_schedule_c_income_spouse"], ["Other Business Income", "Spouse Other Schedule C Business Income"]],
  [["fed_tax_owed_other_taxes_spouse"], ["Taxes", "Spouse Other Taxes"]],
  [["fed_tax_owed_prior_year_amt_carryforward_spouse"], ["Taxes", "Spouse Prior Year AMT Carryforward"]],
  [["fed_tax_owed_tax_credits_spouse"], ["Taxes", "Spouse Tax Credits"]],
  [["fed_tax_owed_child_tax_credit_override_spouse"], ["Taxes", "Spouse Child Tax Credits"]],
  [["res_inc_itemized_override_spouse"], ["Taxes", "Spouse Itemized Deductions"]],
  [["res_passthrough_deduction_override_spouse"], ["Taxes", "Spouse Passthrough Deduction"]],
  [["res_se_tax_override_spouse"], ["Taxes", "Spouse SE Tax"]],
  [["res_passthrough_deduction_override_spouse"], ["Taxes", "Spouse Passthrough Deduction"]],
  [["res_total_tax_override_spouse"], [("Taxes", "Spouse Total Federal Tax")]],
  [["res_state_tax_before_payments_override_spouse"], ["Taxes", "Spouse Total State Tax"]],
  [["business_inc_ccorp_fed_tax_override_spouse"], ["Taxes", "Spouse C Corp Federal Tax"]],
  [["business_inc_ccorp_state_tax_override_spouse"], ["Taxes", "Spouse C Corp State Tax"]],
  [["fed_est_tax_pay_march_spouse"], ["Payments", "Spouse Federal Q1 Estimated Tax Payment"]],
  [["fed_est_tax_pay_june_spouse"], ["Payments", "Spouse Federal Q2 Estimated Tax Payment"]],
  [["fed_est_tax_pay_september_spouse"], ["Payments", "Spouse Federal Q3 Estimated Tax Payment"]],
  [["fed_est_tax_pay_december_spouse"], ["Payments", "Spouse Federal Q4 Estimated Tax Payment"]],
  [["state_estimated_tax_payments_q1_spouse"], ["Payments", "Spouse State Q1 Estimated Tax Payment"]],
  [["state_estimated_tax_payments_q2_spouse"], ["Payments", "Spouse State Q2 Estimated Tax Payment"]],
  [["state_estimated_tax_payments_q3_spouse"], ["Payments", "Spouse State Q3 Estimated Tax Payment"]],
  [["state_estimated_tax_payments_q4_spouse"], ["Payments", "Spouse State Q4 Estimated Tax Payment"]],
  [["state_adj_additions_spouse"], ["State Adjustments", "Spouse Additions"]],
  [["state_adj_substractions_spouse"], ["State Adjustments", "Spouse Subtractions"]],
  [["state_adj_tax_credit_spouse"], ["State Adjustments", "Spouse Tax Credit"]],
  ...[...Array(8)].flatMap((_el, i) => {
    return [
      [
        [`revenue_${i}`, `business_inc_${i + 1}_revenue`],
        ["Business Income & Expense", `Tab ${i + 1}`, "Total Business Revenue"]
      ],
      [
        [`expense_${i}`, `business_inc_${i + 1}_expense`],
        ["Business Income & Expense", `Tab ${i + 1}`, "Total Business Expense"]
      ]
    ];
  })
];

export const resultFieldsToLabels = [
  ["res_wages", "Wages"],
  ["res_interest_and_dividents", "Interest & Dividends"],
  ["res_capital_gains", "Capital Gains"],
  ["business_income", "Business Income"],
  ["res_rental_income", "Rental Income"],
  ["res_other_income", "Other Income"],
  ["res_inc_total", "Total Income"],
  ["res_inc_adjustments", "Adjustments"],
  ["res_inc_itemized", "Itemized Deductions"],
  ["res_taxable_income", "Taxable Income"],
  ["fed_tax_owed_tax_credits", "Credits"],
  ["payments_and_withholdings", "Payments & Withholding"],
  ["total_deductions", "Total Deductions & Payments"],
  ["res_federal_tax_remaining", "Current Tax Due"],
  ["res_state_tax", "State Tax"]
];

// This is to show labels
export const fieldsToLabels = {};
// Labels for simulator in correct order
export const orderedInitialFieldsToLabels = [];

// e.g. filedsToLabels will become { expense_1: 'Total Business Expense' }
// e.g. orderedInitialFieldsToLabels will become { ['business_inc_1_expense', ["Business Income & Expense", "Tab 1", "Total Business Expense"]]
// e.g. initialFieldsToLabels will become { business_inc_1_expense: ["Business Income & Expense", "Tab 1", "Total Business Expense"] }
fieldsWithLabels.forEach((item) => {
  fieldsToLabels[item[0][0]] = item[1].slice(-1)[0];
  orderedInitialFieldsToLabels.push([item[0][1] || item[0][0], item[1]]);
});

// This is for simulator to show which labels have changed
export const initialFieldsToLabels = Object.fromEntries(orderedInitialFieldsToLabels);
