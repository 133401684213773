<script>
  export let sort = "name_asc";

  let columns = [
    { title: "Name", name: "name", sortable: true },
    { title: "", sortable: false },
    { title: "Total tax", sortable: false },
    { title: "W/H & Payments", sortable: false },
    { title: "Tax Due", sortable: false },
    { title: "Last Activity", name: "last_activity_at", sortable: true }
  ];

  function sortClients(name) {
    let newSortOrder = "asc";
    if (sort.startsWith(name)) {
      newSortOrder = sort.endsWith("asc") ? "desc" : "asc";
    }
    sort = `${name}_${newSortOrder}`;
  }
</script>

<thead>
  {#each columns as { name, title, sortable }}
    {#if sortable}
      <th {name} on:click|preventDefault={() => sortClients(name)}>
        <span class="sortable">
          {#if sort.startsWith(name)}
            {title}&nbsp;{sort.endsWith("asc") ? "↑" : "↓"}
          {:else}
            {title}
          {/if}
        </span>
      </th>
    {:else}
      <th>{title}</th>
    {/if}
  {/each}
</thead>

<style>
  .sortable {
    cursor: pointer;
  }

  .sortable:hover {
    outline: none;
    text-decoration: none;
    color: #999;
  }

  thead th {
    border-top: 0;
    border-bottom: 0;
  }
</style>
