<script>
  import { visitField, personalProjectionVisitors } from "../Store";
  import { getContext } from "svelte";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();
  const { type } = getContext("ppType");

  export let key;
  export let label = "";
  export let placeholder = "0.0";
  export let actionLabel = "";
  export let actionShow;
  export let visitKey = key;
  export let value = 0;
  export let hasError;
  export let handleChange = null;

  let fieldHtmlId;

  function performAction() {
    dispatch("click");
  }

  $: fieldHtmlId = `personal_projection_parameters[${key}]`;
  $: hasSuccess = $personalProjectionVisitors.has(visitKey) && !hasError && type !== "simulator";
</script>

<div class="form-control-twin">
  <div class="form-control-twin__type-label">{label}</div>
  <input
    bind:value
    on:blur={() => visitField(visitKey)}
    on:input={handleChange}
    required=""
    {placeholder}
    type="number"
    id={fieldHtmlId}
    class="personal-projection-form-section-4 personal-projection-form-validation form-control rounded-0"
    class:is-invalid={hasError}
    class:is-valid={hasSuccess}
  />
  {#if actionShow}
    <div on:click={performAction} class="form-control-twin__action">{actionLabel}</div>
  {/if}
</div>
