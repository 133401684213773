<script>
  export let deleteReport;
</script>

<template lang="pug">
  button.btn(on:click|preventDefault="{deleteReport}") Delete Report
</template>

<style lang="scss">
  button {
    color: #1b83ff;
    background: transparent;
    font-family: "Montserrat";
    font-size: 0.8rem;
    padding: 8px 10px;

    &:before {
      content: "\f014";
      font-family: FontAwesome;
      margin-right: 5px;
    }

    &:hover,
    &:active,
    &:focus {
      color: #0060d6;
    }
  }
</style>
