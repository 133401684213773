<script>
  export let name;
  export let hasError;
  export let value;
  export let onInput;

  function handleInput(value) {
    value = value === "" ? "" : parseFloat(value);
    onInput(value);
  }
</script>

<input
  class="form-control"
  {name}
  type="number"
  class:input-error={hasError}
  placeholder="0.0"
  {value}
  on:input={(event) => handleInput(event.target.value)}
/>

<style lang="scss">
  .input-error {
    border-color: #f44336;
  }
</style>
