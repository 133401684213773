import * as yup from "yup";
import { set } from "lodash";

yup.addMethod(yup.object, "uniqueProperty", function (propertyName, message) {
  return this.test("unique", message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    if (this.parent.filter((v) => v !== value).some((v) => v[propertyName] === value[propertyName])) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
        message: message
      });
    }

    return true;
  });
});

const validationSchema = yup.array().of(
  yup
    .object()
    .shape({
      name: yup.string().trim().required("Field is empty"),
      federalValue: yup.number().typeError("Field is empty or not a number").required("Field is empty"),
      stateValue: yup.number().typeError("Field is empty or not a number").required("Field is empty"),
      errorMessages: yup.object().default(() => ({ name: "", federalValue: "", stateValue: "" }))
    })
    .uniqueProperty("name", "Name is not unique")
);

export const entriesValidation = (entries) => {
  try {
    entries.forEach((entry) => {
      entry.errorMessages = { name: "", federalValue: "", stateValue: "" };
    });
    validationSchema.validateSync(entries, { abortEarly: false });
  } catch (err) {
    err.inner.forEach((error) => {
      const [idx, fieldName] = error.path.split(".");
      set(entries, `${idx}.errorMessages.${fieldName}`, error.message);
    });
  } finally {
    return entries;
  }
};
