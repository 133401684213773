import Cookies from "js-cookie";
import jQuery from "jquery";

jQuery(function () {
  return jQuery("#toggle-left").on("click", function () {
    var bodyEl;
    bodyEl = jQuery("#main-wrapper");
    if (jQuery(window).width() > 767) {
      bodyEl.toggleClass("sidebar-mini");
    } else {
      bodyEl.toggleClass("sidebar-opened");
    }
    return Cookies.set("sidebar_classes", bodyEl.attr("class"));
  });
});
