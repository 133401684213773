<script>
  import { onMount, afterUpdate } from "svelte";
  import { visitField } from "../Store";
  import { fieldsToLabels } from "../FieldsToLabels";

  export let key;
  export let visitKey = key;
  export let value;

  let fieldHtmlId;

  $: fieldHtmlId = `personal_projection_parameters[${key}]`;

  function markFieldVisited() {
    visitField(visitKey);
  }

  // always visited
  onMount(markFieldVisited);
  afterUpdate(markFieldVisited);
</script>

<div class="personal-projection-form-content__column">
  <div>
    <input type="checkbox" id={fieldHtmlId} bind:checked={value} />
    <label for={fieldHtmlId}>&nbsp;{fieldsToLabels[key]}</label>
  </div>
</div>
