<script>
  import { onMount } from "svelte";

  import Dropzone from "dropzone";

  export let csrfToken;
  export let uploadPath;
  export let cloudImageSvg;

  onMount(async () => {
    Dropzone.options.newVaultDoc = {
      paramName: "vault_doc[document]",
      uploadMultiple: false,
      dictDefaultMessage: document.querySelector("#vault-upload").innerHTML,
      dictFallbackMessage: "",
      init: function () {
        this.on("complete", function (file) {
          window.location.reload();
        });
      }
    };
    // The auto discover feature has been removed in Dropzone version 6.0.0!
    // If you depend on this you can simply add this code to the end of your document:
    Dropzone.discover();
  });
</script>

<!-- Display none here because inner vault-upload used as template -->
<div style="display: none">
  <div class="vault-upload" id="vault-upload">
    <div class="row justify-content-center">
      <div class="col-lg-8 order-md-2">
        {@html cloudImageSvg}
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12" style="padding-top: 30px">Drop a file here or click to upload</div>
    </div>
  </div>
</div>

<style lang="sass" global>
  .vault-docs__doc_upload
    width: 350px

  @media (max-width: 450px)
    .vault-docs__doc_upload
      width: inherit

  .vault-form__submit
    margin-top: 27px

  @media(max-width:991px)
    .vault-form__submit
      margin-top: inherit

  .dropzone .dz-button
    background: none
    border: 1px solid white

  // cloudImageSvg animation
  .dz-drag-hover .vault-upload__arrow
    animation: upload-arrow-animation linear 0.8s
    transform-origin: 50% 50%
    animation-iteration-count: infinite

  @keyframes upload-arrow-animation
    0%
      transform: translate(195px, 130px)
    15%
      transform: translate(195px, 105px)
    30%
      transform: translate(195px, 130px)
    45%
      transform: translate(195px, 115px)
    60%
      transform: translate(195px, 130px)
    75%
      transform: translate(195px, 125px)
    100%
      transform: translate(195px, 130px)
  
  // Legacy dropzone code
  .dropzone,
  .dropzone *,
  .dropzone-previews,
  .dropzone-previews *
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box
  .dropzone
    position: relative
    /*border: 1px solid rgba(0,0,0,0.08)*/
    /*background: rgba(0,0,0,0.02)*/
    padding: 1em
  .dropzone.dz-clickable
    cursor: pointer
  .dropzone.dz-clickable .dz-message,
  .dropzone.dz-clickable .dz-message span
    cursor: pointer
  .dropzone.dz-clickable *
    cursor: default
  .dropzone .dz-message
    opacity: 1
    -ms-filter: none
    filter: none
  .dropzone.dz-drag-hover
    border: 1px dashed rgba(0,0,0,0.15)
  .dropzone.dz-started .dz-message
    display: none
  .dropzone .dz-preview,
  .dropzone-previews .dz-preview
    background: rgba(255,255,255,0.8)
    position: relative
    display: inline-block
    margin: 17px
    vertical-align: top
    border: 1px solid #acacac
    padding: 6px 6px 6px 6px
  .dropzone .dz-preview.dz-file-preview [data-dz-thumbnail],
  .dropzone-previews .dz-preview.dz-file-preview [data-dz-thumbnail]
    display: none
  .dropzone .dz-preview .dz-details,
  .dropzone-previews .dz-preview .dz-details
    width: 100px
    height: 100px
    position: relative
    background: #ebebeb
    padding: 5px
    margin-bottom: 22px
  .dropzone .dz-preview .dz-details .dz-filename,
  .dropzone-previews .dz-preview .dz-details .dz-filename
    overflow: hidden
    height: 100%
  .dropzone .dz-preview .dz-details img,
  .dropzone-previews .dz-preview .dz-details img
    position: absolute
    top: 0
    left: 0
    width: 100px
    height: 100px
  .dropzone .dz-preview .dz-details .dz-size,
  .dropzone-previews .dz-preview .dz-details .dz-size
    position: absolute
    bottom: -28px
    left: 3px
    height: 28px
    line-height: 28px
  .dropzone .dz-preview.dz-error .dz-error-mark,
  .dropzone-previews .dz-preview.dz-error .dz-error-mark
    display: block
  .dropzone .dz-preview.dz-success .dz-success-mark,
  .dropzone-previews .dz-preview.dz-success .dz-success-mark
    display: block
  .dropzone .dz-preview:hover .dz-details img,
  .dropzone-previews .dz-preview:hover .dz-details img
    display: none
  .dropzone .dz-preview .dz-success-mark,
  .dropzone-previews .dz-preview .dz-success-mark,
  .dropzone .dz-preview .dz-error-mark,
  .dropzone-previews .dz-preview .dz-error-mark
    display: none
    position: absolute
    width: 40px
    height: 40px
    font-size: 30px
    text-align: center
    right: -10px
    top: -10px
  .dropzone .dz-preview .dz-success-mark,
  .dropzone-previews .dz-preview .dz-success-mark
    color: #8cc657
  .dropzone .dz-preview .dz-error-mark,
  .dropzone-previews .dz-preview .dz-error-mark
    color: #ee162d
  .dropzone .dz-preview .dz-progress,
  .dropzone-previews .dz-preview .dz-progress
    position: absolute
    top: 100px
    left: 6px
    right: 6px
    height: 6px
    background: #d7d7d7
    display: none
  .dropzone .dz-preview .dz-progress .dz-upload,
  .dropzone-previews .dz-preview .dz-progress .dz-upload
    display: block
    position: absolute
    top: 0
    bottom: 0
    left: 0
    width: 0%
    background-color: #8cc657
  .dropzone .dz-preview.dz-processing .dz-progress,
  .dropzone-previews .dz-preview.dz-processing .dz-progress
    display: block
  .dropzone .dz-preview .dz-error-message,
  .dropzone-previews .dz-preview .dz-error-message
    display: none
    position: absolute
    top: -5px
    left: -20px
    background: rgba(245,245,245,0.8)
    padding: 8px 10px
    color: #800
    min-width: 140px
    max-width: 500px
    z-index: 500
  .dropzone .dz-preview:hover.dz-error .dz-error-message,
  .dropzone-previews .dz-preview:hover.dz-error .dz-error-message
    display: block
</style>
