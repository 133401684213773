<script>
  import { isNil } from "lodash";
  import ModuleTile from "./ModuleTile.svelte";
  import ModuleLoadingTile from "./ModuleLoadingTile.svelte";

  export let id;
  export let active;
  export let modules;
  export let handleChangeState;
  export let planningModulePathBase;
</script>

<div {id} class="tab-pane fade active" class:active class:show={active}>
  <div class="row">
    {#each modules as module}
      {#if isNil(module.amount)}
        <ModuleLoadingTile />
      {:else}
        <ModuleTile {module} {handleChangeState} {planningModulePathBase} />
      {/if}
    {/each}
  </div>
</div>
