<script>
  import jQuery from "jquery";
  import _ from "lodash";
  import NProgress from "nprogress";
  import { initNProgress } from "../../utils/apiHelper";
  import { notyf_top_right } from "misc/notyf_top_right";
  import { afterUpdate, onMount } from "svelte";
  import Pagination from "./Pagination.svelte";
  import SearchBox from "./SearchBox.svelte";
  import GhostInBtn from "./client_page/GhostInBtn.svelte";
  import SortColumns from "./SortColumns.svelte";
  import FakeClientTable from "./FakeClientTable.svelte";

  import {
    of,
    BehaviorSubject,
    combineLatest,
    map,
    catchError,
    switchMap,
    startWith,
    debounceTime,
    share,
    merge,
    distinctUntilChanged
  } from "rxjs";
  import { fromFetch } from "rxjs/fetch";

  export let clientsPath;
  export let clientPath;
  export let newClientPath;
  export let clientGhostInPathBase;
  export let logos;
  export let hasSubscription;

  const defaultClients = {
    clients_with_details: [],
    page: 0,
    total_pages: 0
  };

  function newSubject(seed) {
    let subj = new BehaviorSubject(seed);
    subj.set = subj.next;
    return subj;
  }

  let clientListUpdateRequests = newSubject({});
  let searchQuery = newSubject("");
  let page = newSubject(1);
  let sort = newSubject("name_asc");

  function combinedInputs(t) {
    return combineLatest(
      combineLatest(page, searchQuery.pipe(debounceTime(t)), sort).pipe(distinctUntilChanged(_.isEqual)),
      clientListUpdateRequests
    ).pipe(map(_.head));
  }

  function reactiveFetchClients(page, query, sort) {
    const queryParams = new URLSearchParams({
      page: page,
      q: query,
      sort: sort
    });
    return fromFetch(`${clientsPath}?${queryParams}`).pipe(
      switchMap((response) => {
        if (response.ok) {
          return response.json();
        } else {
          notyf_top_right.error(
            "Something went wrong. If the error persists, please do not hesitate to reach out for support using the chat button at the bottom right corner of the page."
          );
          return of({ error: true, message: `Error ${response.statusText}` });
        }
      }),
      catchError((err) => of({ error: true, message: err.message }))
    );
  }

  let currentServerResponse = combinedInputs(500).pipe(
    switchMap(([p, q, s]) => reactiveFetchClients(p, q, s)),
    share(),
    startWith(defaultClients)
  );

  let isRequestInFlight = merge(
    combinedInputs(0).pipe(map((_) => true)),
    currentServerResponse.pipe(map((_) => false))
  );

  let resultingClients = currentServerResponse.pipe(map((x) => x.clients_with_details));
  let totalPages = currentServerResponse.pipe(map((x) => x.total_pages));

  $: {
    if ($isRequestInFlight) {
      NProgress.start();
    } else {
      NProgress.done();
    }
  }

  let newClients = [];
  let notices = [];

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": window.$('meta[name="csrf-token"]').attr("content")
  };

  const request = async (endpoint, params = {}) => {
    try {
      const response = await fetch(endpoint, { headers, ...params });
      return await response.json();
    } catch (error) {
      // throw error;
      throw new Error(
        "Something went wrong. If the error persists, please do not hesitate to reach out for support using the chat button at the bottom right corner of the page."
      );
    }
  };

  const removeClient = async (clientId) => {
    if (confirm("Once deleted the data cannot be recovered. Are you sure?") == true) {
      try {
        const data = await request(clientPath.replace("@", clientId), { method: "DELETE" });
        if (data.success) {
          clientListUpdateRequests.next({});
          notify(data.success);
        }
        if (data.error) notify(data.error, "alert-danger");
      } catch (error) {
        notify(error.message, "alert-danger");
      }
    }
  };

  const createClient = async (clientUuid) => {
    const client = newClients.find((c) => c.uuid === clientUuid);
    const clientData = {
      name: client.newName,
      email: client.newEmail,
      notes: client.newNotes,
      summary_email_admin_copy: !!client.newSendCopyCheckbox
    };
    try {
      const { success, error } = await request(clientsPath, {
        method: "POST",
        body: JSON.stringify(clientData)
      });
      if (success) {
        clientListUpdateRequests.next({});
        removeNewClient(clientUuid);
        notify(success);
      }
      if (error) notify(error, "alert-danger");
    } catch (error) {
      notify(error.message, "alert-danger");
    }
  };

  const addNewClient = async () => {
    try {
      const data = await request(newClientPath, { method: "GET" });
      if (data.error) {
        notify(data.error, "alert-danger");
      } else {
        newClients = [
          ...newClients,
          { uuid: uuid(), newName: null, newEmail: null, newNotes: null, newSendCopyCheckbox: null }
        ];
      }
    } catch (error) {
      notify(error.message, "alert-danger");
    }
  };

  function removeNewClient(clientUuid) {
    newClients = newClients.filter((client) => client.uuid != clientUuid);
  }

  const createClients = async () => {
    newClients.forEach((client) => client.newName && createClient(client.uuid));
  };

  function removeNewClients() {
    newClients = [];
  }

  function notify(message, style = "alert-info") {
    notices = [...notices, { uuid: uuid(), message, style }];
  }

  function removeNotice(uuid) {
    notices = notices.filter((e) => e.uuid !== uuid);
  }

  function uuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      let r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  function accountantNumberToCurrency(value) {
    if (value) {
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return (value >= 0 ? formatter.format(value) : `(${formatter.format(-value)})`).replace(".00", "");
    } else {
      return "-";
    }
  }

  function resolveLogo(connection) {
    const resolved = logos[connection.data_source_type];
    return resolved != null ? resolved : logos["unknown"];
  }

  function resolvedConnectionStatus(connections) {
    if (_.every(connections, (c) => !c.is_connected) || connections.length === 0) {
      return "none";
    }

    if (_.every(connections, (c) => c.is_connected)) {
      return "all";
    }

    return "some";
  }

  onMount(() => {
    initNProgress();
  });

  afterUpdate(() => {
    jQuery('[data-toggle="popover-hover"]').popover({
      trigger: "hover",
      animation: false
    });
  });
</script>

{#each notices as notice}
  <div class="alert animated fadeIn {notice.style ? notice.style : 'alert-info'}">
    <button class="close" on:click={() => removeNotice(notice.uuid)}>x</button>
    {@html notice.message}
  </div>
{/each}
<div id="nprogressBar" class="pageheader">
  <h1>Client List</h1>
</div>

<section id="main-content">
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <Pagination bind:page={$page} totalPages={$totalPages}>
            <SearchBox bind:query={$searchQuery} />
          </Pagination>
          {#if $isRequestInFlight && $resultingClients.length < 1}
            <FakeClientTable />
          {:else if $resultingClients && $resultingClients.length > 0}
            <table
              id="client-list"
              class="table table-striped"
              class:client-list_loading={$isRequestInFlight}
            >
              <SortColumns bind:sort={$sort} />
              <tbody class="border-top-2">
                {#each $resultingClients as client}
                  <tr>
                    <td>
                      {#if client.notes && client.notes.trim().length > 0}
                        <span data-toggle="popover-hover" data-content={client.notes}>
                          <a href="/accountant/clients/{client.id}">{client.name}</a>
                          &nbsp;
                          <i class="fa fa-sticky-note-o fa-xs" />
                        </span>
                      {:else}
                        <a href="/accountant/clients/{client.id}">{client.name}</a>
                      {/if}
                    </td>
                    {#if client.client_detail}
                      <td>
                        {#each client.client_detail.connections as connection}
                          <img
                            src={resolveLogo(connection)}
                            class="connection-logo"
                            class:connection-logo____dimmed={!connection.is_connected}
                            title={`${connection.company_name}: ${
                              connection.is_connected ? "Connected" : "Disconnected"
                            }`}
                          />
                        {/each}

                        {#if resolvedConnectionStatus(client.client_detail.connections) === "all"}
                          <div class="badge badge-success badge-success_qbo">All Connected</div>
                        {:else if resolvedConnectionStatus(client.client_detail.connections) === "some"}
                          <div class="badge badge-warning">Some Disconnected</div>
                        {:else}
                          <div class="badge badge-danger">All Disconnected</div>
                        {/if}
                      </td>
                      <td>{accountantNumberToCurrency(client.client_detail.total_federal_tax)}</td>
                      <td>{accountantNumberToCurrency(client.client_detail.with_holding_and_payments)}</td>
                      <td>{accountantNumberToCurrency(client.client_detail.federal_tax_due)}</td>
                      <td>
                        {#if client.client_detail.last_activity}{new Intl.DateTimeFormat("en-US").format(
                            Date.parse(client.client_detail.last_activity)
                          )}{/if}
                      </td>
                    {:else}
                      <td colspan="5" />
                    {/if}
                    <td class="text-right">
                      <a
                        href="/accountant/clients/{client.id}"
                        class="btn btn-sm btn-primary summary-info__btn"
                      >
                        <i class="fa fa-fw fa-tachometer" />
                        &nbsp; Summary
                      </a>
                      <GhostInBtn
                        redirectUrl={clientGhostInPathBase.replace(":client_id:", client.id)}
                        caption="Client Portal"
                      />
                      <button
                        class="btn btn-sm btn-danger summary-info__btn"
                        on:click={() => removeClient(client.id)}
                      >
                        <i class="fa fa-trash-o" />
                      </button>
                    </td>
                  </tr>
                {/each}
              </tbody>
            </table>

            <Pagination bind:page={$page} totalPages={$totalPages} />
          {:else}
            <h2 class="text-center">No clients here</h2>
          {/if}
          {#if newClients.length > 0}
            <table id="new-client-list" class="table table-striped mt-4 border-top-0">
              <thead>
                <th />
                <th />
                <th />
                <th />
                <th />
              </thead>
              <tbody class="new-clients-body">
                {#each newClients as newClient}
                  <tr>
                    <td class="col-md-2">
                      <input
                        bind:value={newClient.newName}
                        class="form-control string required"
                        type="text"
                        placeholder="Name"
                      />
                    </td>
                    <td class="col-md-2">
                      <input
                        bind:value={newClient.newEmail}
                        class="form-control string email optional"
                        type="email"
                        placeholder="Email"
                      />
                    </td>
                    <td class="col-md-4">
                      <textarea
                        bind:value={newClient.newNotes}
                        class="form-control text optional"
                        rows="1"
                        placeholder="Notes"
                      />
                    </td>
                    <td class="col-md-2">
                      <div class="form-check">
                        <input
                          id="client_summary_email_admin_copy"
                          bind:checked={newClient.newSendCopyCheckbox}
                          type="checkbox"
                        />
                        <label class="form-check-label" for="client_summary_email_admin_copy">
                          <span data-toggle="popover-hover" data-content="Send a copy of summary email to me">
                            &nbsp;Email copy
                          </span>
                        </label>
                      </div>
                    </td>
                    <td class="text-right">
                      <button
                        class="btn btn-sm btn-success"
                        on:click={() => createClient(newClient.uuid)}
                        disabled={!newClient.newName}
                      >
                        <i class="fa fa-check-square-o">&nbsp;Accept</i>
                      </button>
                      &nbsp;
                      <button class="btn btn-sm btn-danger" on:click={() => removeNewClient(newClient.uuid)}>
                        <i class="fa fa-trash-o" />
                      </button>
                    </td>
                  </tr>
                {/each}
              </tbody>
            </table>
          {/if}
          <div>
            {#if hasSubscription || (!hasSubscription && $resultingClients.length + newClients.length < 1)}
              <button class="btn btn-primary add-client-btn" on:click={() => addNewClient()}>
                <i class="fa fa-plus-square-o">&nbsp;</i>
                Add New Client
              </button>
            {/if}
            {#if newClients.length > 0 && hasSubscription}
              <button
                class="btn btn-success add-client-btn"
                on:click={() => createClients()}
                disabled={!newClients.some((client) => client.newName)}
              >
                <i class="fa fa-check-square-o">&nbsp;Accept All</i>
              </button>
              <button class="btn btn-danger add-client-btn" on:click={() => removeNewClients()}>
                <i class="fa fa-trash-o">&nbsp;Clear New</i>
              </button>
            {/if}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style lang="scss">
  .table > tbody > tr > td {
    vertical-align: middle;
  }

  .table thead th {
    padding: 0;
    border-bottom: 0px;
  }

  .border-top-2 {
    border-top: 2px solid #dee2e6;
  }

  .client-list_loading {
    pointer-events: none;
    opacity: 0.4;
  }

  .connection-logo {
    width: auto;
    height: 40px;
    margin-right: 10px;
  }

  .connection-logo____dimmed {
    opacity: 0.25;
  }
</style>
