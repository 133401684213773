<script>
  import { onMount } from "svelte";

  export let onSave;
  export let onCancel;
  export let templates = [];

  let name = "";

  $: existingTemplate = templates.find((template) => template.name === name);

  function _onSave() {
    onSave(name, existingTemplate);
  }

  function _onCancel() {
    onCancel();
  }

  onMount(() => {
    document.body.style.position = "initial";
  });
</script>

<div>
  <input
    placeholder="New Template"
    class="form-control"
    bind:value={name}
    on:keypress={(e) => e.key === "Enter" && _onSave()}
  />
  {#if existingTemplate}
    <div class="help-text">The updated template will replace the existing one</div>
  {/if}
  {#each [...[]] as _}
    <div />
  {:else}
    <div class="header">Existing Templates</div>
    <ul class="list-group">
      {#each templates as template (template.id)}
        <li on:click={() => (name = template.name)}>
          {template.name}
        </li>
      {/each}
    </ul>
  {/each}
</div>

<div class="buttons">
  <button class="btn btn-success" disabled={name === ""} on:click|preventDefault={_onSave}>Save</button>
  <button class="btn btn-secondary" on:click|preventDefault={_onCancel}>Cancel</button>
</div>

<style lang="scss">
  .header {
    font-size: 0.8rem;
    color: grey;
    margin-top: 25px;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    padding: 0.7em 0.7em;
    font-size: 1rem;
  }

  .buttons {
    margin-top: 1em;
  }

  li {
    cursor: pointer;
    border: 1px #ddd solid;
    position: relative;
    display: block;
    padding: 8px 10px;
    background-color: #fff;

    &:hover {
      font-weight: 600;
      background-color: #f6f7f7;
    }

    & + & {
      border-top: none;
    }
  }

  .help-text {
    font-size: 0.8em;
    color: dimgrey;
    margin-top: 5px;
  }
</style>
