<script>
  import { showCreateCompanyModal } from "components/companies/create_company/Store";

  export let companies = [];
  export let active_company = "";
  export let client_company_path = "";
  export let can_create_company = true;

  function openCreateCompanyModal() {
    showCreateCompanyModal.set(true);
  }
</script>

<ul class="nav nav-pills flex-column">
  {#each companies as company}
    <li class:active={company.id == active_company} class="nav-item">
      <a class="nav-link" href={client_company_path.replace("@", company.id)}>
        <i class="fa fa-fw fa-users"></i>
        {company.name}
      </a>
    </li>
  {/each}
  {#if can_create_company}
    <li class="nav-item">
      <a class="nav-link" href="#" on:click|preventDefault={openCreateCompanyModal}>
        <i class="fa fa-plus"></i>
        Add Company
      </a>
    </li>
  {/if}
</ul>
