<script>
  import { isNil } from "lodash";
  import { tooltips } from "utils/tooltips";
  import { visitField, personalProjectionVisitors } from "../Store";
  import { getContext } from "svelte";
  import { fieldsToLabels } from "../FieldsToLabels";

  export let key;
  export let visitKey = key;
  export let value = 0;
  export let hasError;
  export let inputAddon = null;
  export let tooltip = null;
  export let handleChange = null;

  let fieldHtmlId;
  let hasSuccess;

  const { type } = getContext("ppType");

  $: hasSuccess = $personalProjectionVisitors.has(visitKey) && !hasError && type !== "simulator";
  $: fieldHtmlId = `personal_projection_parameters[${key}]`;
</script>

<div class="personal-projection-form-content__column">
  <div class="form-group tpp-form-group">
    <label for={fieldHtmlId} class="col-form-label tpp-form-label">
      {#if !isNil(tooltip)}
        <div use:tooltips={tooltip}>
          <span>{fieldsToLabels[key]}</span>
          <i class="fa fa-info-circle ml-1" />
        </div>
      {:else}
        <div>
          <span>{fieldsToLabels[key]}</span>
        </div>
      {/if}
    </label>
    <span class="input-group">
      {#if !isNil(inputAddon)}
        <div class="input-group-prepend">
          <span class="input-group-text">{inputAddon}</span>
        </div>
      {/if}
      <input
        bind:value
        on:blur={() => visitField(visitKey)}
        on:input={handleChange}
        required=""
        placeholder="0.0"
        type="number"
        id={fieldHtmlId}
        class="personal-projection-form-section-4 personal-projection-form-validation form-control"
        class:is-invalid={hasError}
        class:is-valid={hasSuccess}
      />
    </span>
    <span class="form-text" />
  </div>
</div>
