<script>
  import { getContext } from "svelte";
  import { fly } from "svelte/transition";
  import UserEditDialog from "./UserEditDialog.svelte";

  const { open, close } = getContext("simple-modal");

  export let user;
  export let updateEmailOnServer;

  const onCancel = () => {
    close();
  };

  const onSave = (email) => {
    updateEmailOnServer(user.id, email);
    close();
  };

  const showDialog = () => {
    open(
      UserEditDialog,
      {
        userEmail: user.email,
        onSave,
        onCancel
      },
      {
        styleWindow: { width: "35%", height: "80%" },
        closeButton: false,
        closeOnEsc: false,
        closeOnOuterClick: false,
        transitionWindow: fly,
        transitionWindowProps: {
          y: -100,
          duration: 500
        }
      }
    );
  };
</script>

<button class="btn btn-sm btn-success" on:click={showDialog}>Edit</button>

<style lang="scss">
  .btn.btn-sm {
    margin: 0 0 0 5px;
  }
</style>
