<script>
  import { reportStore } from "../stores/reportStore";
  import { specificationTree } from "../stores/specificationTree";
  import TextInputField from "../inputs/TextInput.svelte";
  import Dropdown from "../Dropdown.svelte";
  import { searchMatches } from "../stores/searchStore";
</script>

<template lang="pug">
  Dropdown(specTreeNodeId="general_params", hidden="{$searchMatches.isHidden('Report Title')}")
    TextInputField(
      specTreeNodeId="front_page_report_title",
      bind:value="{$reportStore.front_page_report_title}",
      hidden="{$searchMatches.isHidden('Report Title')}"
    )
</template>
