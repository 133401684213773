<script>
  import { isEqual } from "lodash";
  import accounting from "accounting";

  import Panel from "./Panel.svelte";

  export let loading;
  export let results;

  let initialFedTax;
  let initialStateTax;
  let formattedResults = {};

  $: resultsNotEmpty = !isEqual(results, {});
  $: {
    if (resultsNotEmpty) {
      formattedResults["currentTaxDue"] = accounting.formatMoney(results.res_federal_tax_remaining, "$", 0);
      formattedResults["stateTax"] = accounting.formatMoney(results.res_state_tax, "$", 0);
      formattedResults["wages"] = accounting.formatMoney(results.res_wages, "$", 0);
      formattedResults["interestDividends"] = accounting.formatMoney(
        results.res_interest_and_dividents,
        "$",
        0
      );
      formattedResults["capitalGains"] = accounting.formatMoney(results.res_capital_gains, "$", 0);
      formattedResults["businessIncome"] = accounting.formatMoney(results.business_income, "$", 0);
      formattedResults["rentalIncome"] = accounting.formatMoney(results.res_rental_income, "$", 0);
      formattedResults["otherIncome"] = accounting.formatMoney(results.res_other_income, "$", 0);
      formattedResults["adjustments"] = accounting.formatMoney(results.res_inc_adjustments, "$", 0);
      formattedResults["itemizedDeductions"] = accounting.formatMoney(results.res_inc_itemized, "$", 0);
      formattedResults["passthroughDeduction"] = accounting.formatMoney(
        results.res_passthrough_deduction,
        "$",
        0
      );
      formattedResults["taxableIncome"] = accounting.formatMoney(results.res_taxable_income, "$", 0);
      formattedResults["credits"] = accounting.formatMoney(
        results.fed_tax_owed_tax_credits + results.fed_tax_owed_child_tax_credit,
        "$",
        0
      );
      formattedResults["paymentsWithholdings"] = accounting.formatMoney(
        results.fed_tax_owed_tax_withheld + results.fed_est_tax_pay_total,
        "$",
        0
      );

      initialFedTax = initialFedTax || results.res_federal_tax_remaining;
      initialStateTax = initialStateTax || results.res_state_tax;
    }
  }
  $: fedTaxDifference = resultsNotEmpty ? Math.round(results.res_federal_tax_remaining - initialFedTax) : 0;
  $: stateTaxDifference = resultsNotEmpty ? Math.round(results.res_state_tax - initialStateTax) : 0;
</script>

<div class="row simulator-dashboard" class:simulator-dashboard____loading={loading !== null}>
  <div class="simulator-dashboard__row">
    <Panel title="Federal Tax Due" className="panel-turquoise--red simulator-dashboard__row-item">
      <div class="simulator-dashboard__tax-due">
        {formattedResults.currentTaxDue}
        {#if fedTaxDifference !== 0}
          <h3 class="difference-text">
            {fedTaxDifference > 0 ? "more" : "less"} by
            <span>
              {accounting.formatMoney(Math.abs(fedTaxDifference), "$", 0)}
            </span>
          </h3>
        {/if}
      </div>
    </Panel>
    <Panel title="State Tax Due" className="panel-turquoise simulator-dashboard__row-item">
      <div class="simulator-dashboard__state-tax-due">
        {formattedResults.stateTax}
        {#if stateTaxDifference !== 0}
          <h3 class="difference-text">
            {stateTaxDifference > 0 ? "more" : "less"} by
            <span>
              {accounting.formatMoney(Math.abs(stateTaxDifference), "$", 0)}
            </span>
          </h3>
        {/if}
      </div>
    </Panel>
  </div>
  <div class="simulator-dashboard__item">
    <Panel title="Income">
      <table class="table table-no-first-line-border mb-0">
        <tbody>
          <tr>
            <td class="simulator-stats__title">Wages:</td>
            <td class="simulator-stats__value">{formattedResults.wages}</td>
          </tr>
          <tr>
            <td class="simulator-stats__title">Interest & Dividends:</td>
            <td class="simulator-stats__value">{formattedResults.interestDividends}</td>
          </tr>
          <tr>
            <td class="simulator-stats__title">Capital Gains:</td>
            <td class="simulator-stats__value">{formattedResults.capitalGains}</td>
          </tr>
          <tr>
            <td class="simulator-stats__title">Business Income:</td>
            <td class="simulator-stats__value">{formattedResults.businessIncome}</td>
          </tr>
          <tr>
            <td class="simulator-stats__title">Rental Income:</td>
            <td class="simulator-stats__value">{formattedResults.rentalIncome}</td>
          </tr>
          <tr>
            <td class="simulator-stats__title">Other Income:</td>
            <td class="simulator-stats__value">{formattedResults.otherIncome}</td>
          </tr>
        </tbody>
      </table>
    </Panel>
  </div>
  <div class="simulator-dashboard__item">
    <Panel title="Deductions & Payments">
      <table class="table table-no-first-line-border mb-0">
        <tbody>
          <tr>
            <td class="simulator-stats__title">Adjustments:</td>
            <td class="simulator-stats__value">{formattedResults.adjustments}</td>
          </tr>
          <tr>
            <td class="simulator-stats__title">Itemized Deductions:</td>
            <td class="simulator-stats__value">{formattedResults.itemizedDeductions}</td>
          </tr>
          <tr>
            <td class="simulator-stats__title">Passthrough Deduction:</td>
            <td class="simulator-stats__value">{formattedResults.passthroughDeduction}</td>
          </tr>
          <tr>
            <td class="simulator-stats__title">Taxable Income:</td>
            <td class="simulator-stats__value">{formattedResults.taxableIncome}</td>
          </tr>
          <tr>
            <td class="simulator-stats__title">Credits:</td>
            <td class="simulator-stats__value">{formattedResults.credits}</td>
          </tr>
          <tr>
            <td class="simulator-stats__title">Payments & Withholdings:</td>
            <td class="simulator-stats__value">{formattedResults.paymentsWithholdings}</td>
          </tr>
        </tbody>
      </table>
    </Panel>
  </div>
</div>

<style lang="scss" global>
  .difference-text {
    color: white;
    font-size: 14px;
    padding: 5px 0;
  }

  .panel-turquoise {
    background: #27b8af;
    color: white;

    .card-header {
      background: #27b8af;
      color: white;
      border-color: #eff2f7;
    }

    .card-title {
      color: white;
    }
  }

  .panel-turquoise--red {
    background: #e25d5d;
    color: white;

    .card-header {
      background: #e25d5d;
      color: white;
      border-color: #eff2f7;
    }

    .card-title {
      color: white;
    }
  }
</style>
