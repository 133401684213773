<script>
  import accounting from "accounting";

  export let amount;
  export let ranged;
  export let type;

  function formatValue(value) {
    if (typeof value === "string") return value;

    return formatMoney(value);
  }

  function formatMoney(val) {
    return accounting.formatMoney(val, {
      symbol: "$",
      precision: 0,
      format: {
        pos: "%s%v",
        neg: "%s(%v)",
        zero: "%s%v"
      }
    });
  }
</script>

<template>
  <div
    class="widget-mini results__total flex-grow-1 {(amount > 0 && 'card-solid-danger') ||
      (amount <= 0 && 'card-solid-success') ||
      (amount === 'Some' && 'card-solid-success') ||
      'card-solid-danger'}"
  >
    {#if ranged}
      <div class="card-body">
        <div class="span total text-center">
          {#if typeof amount === "number" && amount < 0}
            {formatValue(-amount)}
          {:else}
            {formatValue(amount)}
          {/if}
        </div>
        <div class="span title text-center">
          {#if amount === "Some"}
            {`${type} Tax Refund`}
          {:else}
            {`${type} Tax Due`}
          {/if}
        </div>
      </div>
    {:else}
      <div class="card-body">
        <div class="span total text-center">
          {#if typeof amount === "number" && amount < 0}
            {formatValue(-amount)}
          {:else}
            {formatValue(amount)}
          {/if}
        </div>
        <div class="span title text-center">
          {#if typeof amount === "number" && amount < 0}
            {`${type} Tax Refund`}
          {:else}
            {`${type} Tax Due`}
          {/if}
        </div>
      </div>
    {/if}
  </div>
</template>
