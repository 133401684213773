<script>
  import { isNil } from "lodash";
  import TaxpayerSpouseNumericField from "../fields/TaxpayerSpouseNumericField.svelte";

  export let statusNotice = null;
</script>

<div class="">
  <div class="personal-projection-form-content__section-header">
    <h2 class="personal-projection-form-content__section-name">Deductions</h2>
    {#if !isNil(statusNotice)}
      <small
        class="personal-projection-form-content__section-header-status font-weight-light col-lg-3 text-right text-muted"
      >
        {statusNotice}
      </small>
    {/if}
  </div>
  <div>
    <div class="personal-projection-form-content__fields">
      <TaxpayerSpouseNumericField key="fed_ded_mortgage_interest" />
      <TaxpayerSpouseNumericField key="fed_ded_state_income_taxes" />
      <TaxpayerSpouseNumericField key="fed_ded_local_income_taxes" />
      <TaxpayerSpouseNumericField key="fed_ded_prior_state_inc_taxes" />
      <TaxpayerSpouseNumericField key="fed_ded_property_taxes" />
      <TaxpayerSpouseNumericField key="fed_ded_personal_property_taxes" />
      <TaxpayerSpouseNumericField key="fed_ded_home_loan_points" />
      <TaxpayerSpouseNumericField key="fed_ded_medical_and_dental" />
      <TaxpayerSpouseNumericField key="fed_ded_cash_donations" />
      <TaxpayerSpouseNumericField key="fed_ded_noncash_donations" />
      <TaxpayerSpouseNumericField key="fed_ded_other_deductions" />
    </div>
  </div>
</div>
