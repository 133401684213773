<script>
  import { reportStore } from "../stores/reportStore";
  import { specificationTree } from "../stores/specificationTree";
  import DatePickerField from "../inputs/DatePicker.svelte";
  import TextareaInputField from "../inputs/TextareaInput.svelte";
  import Dropdown from "../Dropdown.svelte";
  import { TAX_RETURN_DUE_DATE_TYPES } from "utils/business_objects";
  import { searchMatches } from "../stores/searchStore";

  export let refreshReportParams;
</script>

<template lang="pug">
  Dropdown(
    {refreshReportParams},
    specTreeNodeId="tax_return_due_dates_enable",
    bind:checked="{$reportStore.tax_return_due_dates_enable}",
    hidden="{$searchMatches.isHidden('Important Tax Return Due Dates')}",
    scrollToId="pdfImportantTaxReturnDueDates"
  )
    TextareaInputField(
      specTreeNodeId="{`tax_return_due_dates_remember_text`}",
      bind:value="{$reportStore[`tax_return_due_dates_remember_text`]}",
      hidden="{$searchMatches.isHidden('Remember')}"
    )
    +each('Object.entries(TAX_RETURN_DUE_DATE_TYPES) as [tag, label]')
      DatePickerField(
        {refreshReportParams},
        specTreeNodeId="{`tax_return_due_dates_${tag}`}",
        bind:value="{$reportStore[`tax_return_due_dates_${tag}`]}",
        hidden="{$searchMatches.isHidden(`${label}`)}"
      )
</template>
