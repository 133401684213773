<script>
  import _ from "underscore/underscore";

  const PLANNERS_ACCESS_MATCHER = {
    qbid_salary_maximizer_enabled: "QBID Salary Maximizer",
    auto_lease_enabled: "Auto Lease",
    charity_enabled: "Charity",
    children_employment_enabled: "Children Employment",
    company_type_enabled: "Company Type",
    deductions_missing_enabled: "Missing Deductions",
    defined_benefit_enabled: "Defined Benefit",
    estimated_tax_payments_enabled: "Estimated Tax Payments",
    expense_acceleration_enabled: "Expense Acceleration",
    health_savings_enabled: "Health Savings",
    home_office_accountable_enabled: "Home Office Accountable",
    home_rental_enabled: "14 Day Home Rental",
    income_deferment_enabled: "Income Deferment",
    mfj_mfs_enabled: "Married Filing Joint vs Married Filing Separate",
    plan401k_enabled: "401K",
    scorp_election_enabled: "S Corporation Election",
    section179_enabled: "Section 179 Expense",
    sep_ira_enabled: "SEP IRA",
    traditional_roth_ira_enabled: "Traditional & Roth IRA"
  };

  export let changeSettingsInProgress;
  export let error;
  export let toggleAllTo;
  export let localPlannersAccess;
  export let onSettingsChange;

  let hasAnyPlanners = Object.entries(localPlannersAccess).length > 0;
</script>

<div
  class="projection-settings-form"
  class:projection-settings-form____in-progress={changeSettingsInProgress}
>
  {#if error && error.length > 0}
    <p class="error">{error}</p>
  {/if}
  {#if hasAnyPlanners}
    <div>
      <button
        class="btn btn-success projection-settings-form__button"
        type="submit"
        on:click={() => toggleAllTo(true)}
      >
        Enable All
      </button>
      <button
        class="btn btn-danger projection-settings-form__button"
        type="submit"
        on:click={() => toggleAllTo(false)}
      >
        Disable All
      </button>
    </div>
    <span>Planner Modules:&nbsp;&nbsp;</span>
    <div class="projection-settings-form__planners">
      {#each _.sortBy(Object.entries(localPlannersAccess), (entry) => entry[0]) as [planner, enabled]}
        <div class="checkbox projection-settings-form__checkbox" key={planner}>
          <label class="checkbox projection-settings-form__checkbox">
            <input
              id={planner}
              key={planner}
              name={planner}
              type="checkbox"
              checked={enabled}
              on:change={onSettingsChange}
            />
            {PLANNERS_ACCESS_MATCHER[planner]}
          </label>
        </div>
      {/each}
    </div>
    <div class="projection-settings-form__info">
      <small>
        Enable planners to be visible in client portal. Disable planners to hide from client portal. Disabled
        planners will not calculate in the report or be visible while ghosting in.
      </small>
    </div>
  {:else}
    <span>You don't have access to planning modules on this plan</span>
  {/if}
</div>

<style global>
  .projection-settings-form {
    transition: opacity 0.5s ease-in-out;
  }

  .projection-settings-form____in-progress {
    opacity: 0.5;
  }

  .projection-settings-form__label {
    margin-left: 10px;
    margin-right: 10px;
  }

  .projection-settings-form__button {
    padding: 5px 10px;
    margin: 5px 5px 15px 0;
  }

  .projection-settings-form__planners {
    columns: 2 auto;
    margin-top: 15px;
  }

  .checkbox.projection-settings-form__checkbox {
    margin-top: 0;
  }

  .projection-settings-form__info {
    color: #7b808c;
    margin-top: 15px;
  }
</style>
