<script>
  import { isNil, isNumber, isObject } from "lodash";
  import numeral from "numeral";
  import TaxpayerSpouseNumericField from "../fields/TaxpayerSpouseNumericField.svelte";
  import NumericField from "../fields/NumericField.svelte";
  import SelectField from "../fields/SelectField.svelte";
  import {
    allStateParameters,
    indianaCountyOptions,
    marylandCountyOptions,
    personalProjection,
    personalProjectionValidation,
    statesOfResidencyOptions
  } from "../Store";

  export let serverCalculatedParameters;
  export let statusNotice = null;

  const stateCode = $personalProjection.state_of_residency;

  const stateName = statesOfResidencyOptions.find(
    (s) => s.value === $personalProjection.state_of_residency
  )?.title;

  const thisPerStateParameters = allStateParameters[stateCode];
</script>

<div class="">
  <div class="personal-projection-form-content__section-header">
    <h2 class="personal-projection-form-content__section-name">{stateName || ""} State Adjustments</h2>
    {#if !isNil(statusNotice)}
      <small
        class="personal-projection-form-content__section-header-status font-weight-light col-lg-3 text-right text-muted"
      >
        {statusNotice}
      </small>
    {/if}
  </div>
  <div>
    {#if !isNil(thisPerStateParameters)}
      <div class="personal-projection-form-content__fields">
        {#each thisPerStateParameters as parameter}
          {#if parameter === "taxpayer_age"}
            <NumericField
              key={parameter}
              bind:value={$personalProjection[parameter]}
              hasError={$personalProjectionValidation.has(parameter)}
            />
          {:else if parameter === "state_in_county_code"}
            <SelectField
              key={parameter}
              bind:value={$personalProjection[parameter]}
              hasError={$personalProjectionValidation.has(parameter)}
              options={indianaCountyOptions}
            />
          {:else if parameter === "state_md_county_code"}
            <SelectField
              key={parameter}
              bind:value={$personalProjection[parameter]}
              hasError={$personalProjectionValidation.has(parameter)}
              options={marylandCountyOptions}
            />
          {:else}
            <TaxpayerSpouseNumericField key={parameter} />
          {/if}
        {/each}
      </div>
    {/if}
    <div class="personal-projection-form-content__fields">
      <div class="personal-projection-form-content__column">
        <div class="form-group tpp-form-group">
          <label class="col-form-label tpp-form-label results__row-title">
            <span>Taxable Income Before Adjustments</span>
          </label>
          <div class="input-group">
            {#if isNumber(serverCalculatedParameters.state_adj_taxable_income_before_adjustments)}
              <div class="personal-projection-form-calculation-result-field results__row-title">
                {numeral(serverCalculatedParameters.state_adj_taxable_income_before_adjustments).format(
                  "$0,0[.]00"
                )}
              </div>
            {:else}
              <div class="personal-projection-form-calculation-result-field__loading" />
            {/if}
          </div>
        </div>
      </div>
    </div>
    <div class="personal-projection-form-content__fields">
      <TaxpayerSpouseNumericField key="state_adj_additions" />
      <TaxpayerSpouseNumericField key="state_adj_substractions" />
    </div>
    <div class="personal-projection-form-content__fields">
      <TaxpayerSpouseNumericField key="state_adj_tax_credit" tooltip="State Tax Credit" />
    </div>
    <div class="personal-projection-form-content__fields">
      <div class="personal-projection-form-content__column">
        <div class="form-group tpp-form-group">
          <label class="col-form-label tpp-form-label results__row-title">
            <span>Adjusted Taxable Income</span>
          </label>
          <div class="input-group">
            {#if isNumber(serverCalculatedParameters.state_adj_adjusted_taxable_income)}
              <div class="personal-projection-form-calculation-result-field results__row-title">
                {numeral(serverCalculatedParameters.state_adj_adjusted_taxable_income).format("$0,0[.]00")}
              </div>
            {:else}
              <div class="personal-projection-form-calculation-result-field__loading" />
            {/if}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
