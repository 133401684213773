<script>
  export let companyName;
  export let availableTenantsPath;
  export let changeTenantPath;
  export let csrfToken;
  export let loadingErrorDefaultMsg;

  let menuIsOpened = false;
  let tenants;

  let isLoading = false;
  let loadingErrorMsg;

  function dismissError() {
    loadingErrorMsg = undefined;
  }

  function toggleOrganizationsList() {
    if (tenants !== undefined) {
      menuIsOpened = !menuIsOpened;
      return;
    }

    isLoading = true;
    loadingErrorMsg = undefined;

    fetch(availableTenantsPath)
      .then((response) => {
        const contentType = response.headers.get("Content-Type");

        if (
          !(contentType === undefined || contentType === null) &&
          contentType.startsWith("application/json")
        )
          return response;

        throw new Error(
          `Incorrect response content-type: '${contentType}'. Should be 'application/json'. Response Status: ${response.status}`
        );
      })
      .then((response) =>
        response.json().then((data) => {
          return { data: data, status: response.status };
        })
      )
      .then((response) => {
        const data = response.data;
        const status = response.status;

        let error = new Error();

        if (data.hasOwnProperty("error")) {
          error.message = data;
          throw error;
        } else if (!(status >= 200 && status < 300)) {
          error.message = `Incorrect response status: ${status}. Should be 200...300`;
          throw error;
        }

        return data;
      })
      .then((response) => {
        tenants = response.tenants;
        isLoading = false;
        menuIsOpened = true;
      })
      .catch((error) => {
        console.error(error.message);
        isLoading = false;
        loadingErrorMsg = loadingErrorDefaultMsg;
      });
  }
</script>

<div class="xero-tenant-switch">
  <span class="remote-company-name">
    <b>Connected to:</b>
    {companyName}
  </span>
  <button class="btn btn-sm btn-light xero-tenants-list__show-btn" on:click={toggleOrganizationsList}>
    {#if isLoading}
      <i class="fa fa-spinner" />
    {:else if menuIsOpened}
      <i class="fa fa-angle-up" />
    {:else if !menuIsOpened}
      <i class="fa fa-angle-down" />
    {/if}
    Change organization
  </button>
  <div class="xero-tenants-list">
    {#if loadingErrorMsg}
      <div class="errors">
        <div class="alert alert-danger xero-tenants-list-error" role="alert">
          <span class="xero-tenants-list-error__msg">{@html loadingErrorMsg}</span>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" on:click={dismissError}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    {/if}
    {#if menuIsOpened && tenants !== undefined}
      {#each tenants as tenant}
        <form action={changeTenantPath} method="post">
          <input type="hidden" name="_method" value="put" />
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <input name="tenant_id" type="hidden" value={tenant.id} />
          <div class="xero-tenants-list-organization xero-tenants-list-organization_{tenant.id}">
            <div class="xero-tenants-list-organization__name">{tenant.name}</div>
            <button
              class="btn btn-sm btn-primary btn-block xero-tenants-list-organization__switch-btn"
              type="submit"
              disabled={tenant.current}
            >
              {#if tenant.current}
                Current
              {:else}
                Switch
              {/if}
            </button>
          </div>
        </form>
      {/each}
    {/if}
  </div>
</div>

<style lang="scss">
  .xero-tenant-switch {
    font-size: 0.875em;
  }

  .xero-tenants-list {
    padding: 5px 7px;

    &__show-btn {
      margin: 0 0 3px 5px;
      padding: 3px 8px;
    }
  }

  .xero-tenants-list-organization {
    display: flex;
    padding: 8px 10px;
    background: #efefef;
    margin-bottom: 4px;
    align-items: center;
    justify-content: space-between;

    &__switch-btn {
      width: 65px;
      margin-bottom: 0;
    }
  }

  .xero-tenants-list-error {
    padding: 10px;
    display: flex;
  }

  i {
    padding: 0 5px 0 0;
  }
</style>
