<script>
  import _ from "underscore";
  import accounting from "accounting";
  import ResultRow from "components/common/ResultRow.svelte";

  export let personalProjection;

  let eblLimitApplied =
    personalProjection.resBusinessIncome > personalProjection.businessIncomeWithoutEblLimit;

  function formatTooltip(obj) {
    return Object.entries(obj)
      .filter(([_, data]) => (Array.isArray(data) ? data[0] != 0 : data != 0))
      .map(
        ([name, data]) =>
          `<b>${_.escape(name)}:</b> ${
            Array.isArray(data)
              ? `${formatMoney(data[0])} ${
                  data[1] < 0.0 ? `- ${formatMoney(-data[1])}` : `+ ${formatMoney(data[1])}`
                }`
              : formatMoney(data)
          }`
      )
      .join("<br>");
  }

  function formatMoney(val) {
    return accounting.formatMoney(val, {
      symbol: "$",
      precision: 0,
      format: {
        pos: "%s%v",
        neg: "%s(%v)",
        zero: "%s%v"
      }
    });
  }

  function isEven() {
    return (
      [
        personalProjection.changeInWages != null,
        personalProjection.newWages != null,
        personalProjection.changeInBusinessIncome != null,
        personalProjection.changeInPayrollTax != null,
        personalProjection.newBusinessIncome != null,
        !(personalProjection.resRentalIncome == 0 && personalProjection.rentalRealEstateExpenses == 0)
      ].filter((e) => e).length %
        2 ===
      0
    );
  }
</script>

<template lang="pug">
  table.summary-block.table.table-striped
    tbody
      ResultRow(title="Wages", value="{personalProjection.resWages}")
      +if('personalProjection.changeInWages != null')
        ResultRow(
          title="Change in Wages",
          value="{personalProjection.changeInWages}",
          additionalClasses="results__row__sub",
          tooltip="{formatTooltip(personalProjection.changeInWagesByCompanies)}"
        )
      +if('personalProjection.newWages != null')
        ResultRow(title="New Wage Income", value="{personalProjection.newWages}")
      ResultRow(title="Interest & Dividends", value="{personalProjection.resInterestAndDividents}")
      ResultRow(title="Capital Gains", value="{personalProjection.resCapitalGains}")
      ResultRow(
        title="Business Income",
        value="{personalProjection.resBusinessIncome}",
        tooltip="{`Only $${personalProjection.resBusinessIncome * -1} of total $${Math.round(personalProjection.businessIncomeWithoutEblLimit * -1)} business loss was accounted for due to EBL`}",
        priceAdditionalInfo="EBL Limited",
        showTooltip="{eblLimitApplied}"
      )
      +if('personalProjection.changeInBusinessIncome != null')
        ResultRow(
          title="Change in Business Income",
          value="{personalProjection.changeInBusinessIncome}",
          additionalClasses="results__row__sub",
          tooltip="{formatTooltip(personalProjection.changeInBusinessIncomeByCompanies)}"
        )
      +if('personalProjection.changeInPayrollTax != null')
        ResultRow(
          title="Change in Payroll Tax Paid",
          value="{personalProjection.changeInPayrollTax}",
          additionalClasses="results__row__sub",
          tooltip="{formatTooltip(personalProjection.changeInPayrollTaxByCompanies)}"
        )
      +if('personalProjection.newBusinessIncome != null')
        ResultRow(title="New Business Income", value="{personalProjection.newBusinessIncome}")
      +if('!(personalProjection.resRentalIncome == 0 && personalProjection.rentalRealEstateExpenses == 0)')
        ResultRow(title="Rental Income", value="{personalProjection.resRentalIncome}")
      ResultRow(title="Other Income", value="{personalProjection.resOtherIncome}")
      ResultRow(
        title="Total Income",
        value="{personalProjection.resIncTotal}",
        additionalClasses="results__row__total"
      )

  table.summary-block.table.table-striped
    tbody
      +if('!isEven()')
        ResultRow
      ResultRow(title="Adjustments", value="{personalProjection.resIncAdjustments}")
      ResultRow(title="Itemized Deductions", value="{personalProjection.resIncItemized}")
      ResultRow(title="Passthrough Deduction", value="{personalProjection.resPassthroughDeduction}")
      ResultRow(
        title="Taxable Income",
        value="{personalProjection.resTaxableIncome}",
        additionalClasses="results__row__total"
      )

  table.summary-block.table.table-striped
    tbody
      +if('!isEven()')
        ResultRow
      ResultRow(title="Income Tax", value="{personalProjection.resIncomeTax}")
      ResultRow(title="SE Tax", value="{personalProjection.resSeTax}")
      ResultRow(
        title="Total Federal Tax",
        value="{personalProjection.resTotalTax}",
        additionalClasses="results__row__total"
      )
      +if('personalProjection.resStateTaxShouldDisplay')
        ResultRow(
          title="Total State Tax",
          value="{personalProjection.resStateTaxBeforePayments}",
          additionalClasses="results__row__total"
        )
      +if('personalProjection.resPaymentsAndCredits != null')
        ResultRow(title="Payments & Credits", value="{personalProjection.resPaymentsAndCredits}")
</template>
