<script>
  import { getContext } from "svelte";
  import { fly } from "svelte/transition";
  import ApplyTemplateDialog from "../dialogs/ApplyTemplateDialog.svelte";
  import { reportStore } from "../stores/reportStore";

  export let updateReport;
  export let templates;

  const { open, close } = getContext("simple-modal");

  function onApply(templateProps) {
    reportStore.update((prevReport) => ({ ...prevReport, ...templateProps }));
    updateReport();
    close();
  }

  function onCancel() {
    close();
  }

  const showDialog = () => {
    open(
      ApplyTemplateDialog,
      {
        onApply,
        onCancel,
        templates
      },
      {
        styleWindow: { width: "35%", height: "80%" },
        styleContent: { overflow: "initial" },
        closeButton: false,
        transitionWindow: fly,
        transitionWindowProps: {
          y: -100,
          duration: 500
        }
      }
    );
  };
</script>

<template lang="pug">
  button.btn(on:click|preventDefault="{showDialog}") Apply Template
</template>

<style lang="scss">
  button {
    color: #1b83ff;
    background: transparent;
    font-family: "Montserrat";
    font-size: 0.8rem;
    padding: 8px 10px;

    &:before {
      content: "\f046";
      font-family: FontAwesome;
      margin-right: 5px;
    }

    &:hover,
    &:active,
    &:focus {
      color: #0060d6;
    }

    &:disabled {
      background: transparent;
      color: #1b83ff;
      opacity: 0.6;
    }
  }
</style>
