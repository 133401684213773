<script>
  import { select, selectAll } from "d3-selection";
  import { axisBottom, axisLeft } from "d3-axis";
  export let width;
  export let height;
  export let margin;
  export let position;
  export let scale;
  export let label = (x) => x;
  export let caption;
  let transform;
  let g;
  $: {
    select(g).selectAll("*").remove();
    let axis;
    let labelTransform;
    switch (position) {
      case "bottom":
        axis = axisBottom(scale).tickSizeOuter(0).tickFormat(label).ticks(5, "f");
        transform = `translate(0, ${height - margin.bottom})`;
        labelTransform = `translate(${(width + margin.left) / 2}, 40)`;
        break;
      case "left":
        axis = axisLeft(scale).tickSizeOuter(0).tickFormat(label);
        transform = `translate(${margin.left}, 0)`;
        labelTransform = `rotate(-90),translate(-${(height - margin.bottom) / 2}, -60)`;
    }
    select(g)
      .call(axis)
      .append("text")
      .attr("transform", labelTransform)
      .attr("fill", "black")
      .style("text-anchor", "middle")
      .text(caption);
  }
</script>

<g class="axis" bind:this={g} {transform} />
