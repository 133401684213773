<script>
  import { isNumber, omit } from "lodash";
  import { fade } from "svelte/transition";
  import { entriesValidation } from "./schemas/handyProjectionEntrySchema";
  import NumericField from "./fields/handy_projection_entries/NumericField.svelte";

  export let entries;
  export let valid;

  let viewEntries = entriesToViewEntries(entries);

  $: entries = viewEntriesToEntries(viewEntries);
  $: {
    viewEntries = removeDeletedEntries(viewEntries);
    viewEntries = validateEntries(viewEntries);
    if (viewEntries.length === 0 || isValidEntry(viewEntries[viewEntries.length - 1])) {
      viewEntries = [...viewEntries, emptyForm()];
    }
    valid = areEntriesValid(viewEntries);
  }

  function entriesToViewEntries(entries) {
    return entries.map((entry) => {
      return {
        ...entry,
        toDelete: false,
        errorMessages: { name: "", federalValue: "", stateValue: "" }
      };
    });
  }

  function viewEntriesToEntries(entries) {
    return entries.map((entry) => omit(entry, ["toDelete", "errorMessages"])).slice(0, -1);
  }

  function removeDeletedEntries(viewEntries) {
    return viewEntries.filter((entry) => !entry.toDelete);
  }

  function areEntriesValid(viewEntries) {
    return !viewEntries.slice(0, -1).some((entry) => {
      return Object.values(entry.errorMessages).some((errorMessage) => errorMessage !== "");
    });
  }

  function isValidEntry(entry) {
    return entry.name !== "" && isValidNumber(entry.stateValue) && isValidNumber(entry.federalValue);
  }

  function isValidNumber(value) {
    return value !== "" && isNumber(value) && !isNaN(value);
  }

  function emptyForm() {
    return {
      name: "",
      stateValue: 0.0,
      federalValue: 0.0,
      toDelete: false,
      errorMessages: { name: "", federalValue: "", stateValue: "" }
    };
  }

  function validateEntries(viewEntries) {
    const validatedEntries = entriesValidation(viewEntries.slice(0, -1));
    return viewEntries.length ? [...validatedEntries, viewEntries[viewEntries.length - 1]] : validatedEntries;
  }

  function hasError(errorMessage) {
    return errorMessage !== "";
  }

  function handleFederalValueChange(i, newValue) {
    if (viewEntries[i].stateValue === viewEntries[i].federalValue) {
      viewEntries[i].stateValue = newValue;
    }
    viewEntries[i].federalValue = newValue;
  }

  function handleStateValueChange(i, newValue) {
    viewEntries[i].stateValue = newValue;
  }
</script>

{#each viewEntries as entry, i}
  <tr in:fade={{ duration: 300 }}>
    <td>
      <div class="input-container">
        <input
          class="form-control"
          name={`name-${i}`}
          class:input-error={hasError(entry.errorMessages.name)}
          placeholder="Manual Entry Input"
          bind:value={entry.name}
        />
        <div class="error-message" name={`name-error-${i}`} class:active={hasError(entry.errorMessages.name)}>
          {entry.errorMessages.name}
        </div>
      </div>
    </td>
    <td>
      <div class="input-container">
        <span class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <NumericField
            name={`federal-${i}`}
            hasError={hasError(entry.errorMessages.federalValue)}
            value={entry.federalValue}
            onInput={(value) => handleFederalValueChange(i, value)}
          />
        </span>
        <div
          class="error-message"
          name={`federal-error-${i}`}
          class:active={hasError(entry.errorMessages.federalValue)}
        >
          {entry.errorMessages.federalValue}
        </div>
      </div>
    </td>
    <td>
      <div class="input-container">
        <span class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text">$</span>
          </div>
          <NumericField
            name={`state-${i}`}
            hasError={hasError(entry.errorMessages.stateValue)}
            value={entry.stateValue}
            onInput={(value) => handleStateValueChange(i, value)}
          />
        </span>
        <div
          class="error-message"
          name={`state-error-${i}`}
          class:active={hasError(entry.errorMessages.stateValue)}
        >
          {entry.errorMessages.stateValue}
        </div>
      </div>
      <button
        on:click|preventDefault={() => (entry.toDelete = true)}
        class="fa fa-remove delete-button"
        class:hidden-button={viewEntries.length - 1 === i}
      />
    </td>
  </tr>
{/each}

<style lang="scss">
  .table tbody tr td {
    border: none;
  }

  td {
    padding: 10px 15px 0px 15px;
    position: relative;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 15px;

    .input-group {
      width: 100%;
    }
  }

  .hidden-button {
    visibility: hidden;
  }

  .delete-button {
    position: absolute;
    top: 20px;
    right: 10px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    color: #f44336;
    font-size: 16px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      color: #b71c1c;
    }
  }

  .field-container {
    display: flex;
  }

  tr:hover .delete-button {
    opacity: 1;
  }

  .error-message {
    color: #f44336;
    padding-top: 3px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .error-message.active {
    opacity: 1;
  }

  .form-control {
    width: 100%;
    box-sizing: border-box;
    flex-grow: 0;
    transition: border-color 0.3s ease-in-out;

    &:focus {
      border-color: #452161;
      box-shadow: 0px 0px 5px rgba(69, 33, 97, 0.5);
    }
  }
</style>
