<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import ManagedItem from "../../common/ManagedItem.svelte";

  export let templates;
  export let updateTemplate;
  export let deleteTemplate;
  export let close;

  async function handleDelete(id) {
    const response = await deleteTemplate(id);

    if (response && response.name !== "Error") {
      templates = response.templates;

      if (templates.length === 0) close();
    }
  }

  onMount(() => {
    document.body.style.position = "initial";
  });
</script>

{#each [...[]] as _}
  <div />
{:else}
  <ul class="list-group">
    {#each templates as template (template.id)}
      <li transition:fade class="list-group-item">
        <ManagedItem id={template.id} name={template.name} handleUpdate={updateTemplate} {handleDelete} />
      </li>
    {/each}
  </ul>
{/each}

<style lang="scss">
  ul {
    padding-top: 2.5em;
  }
</style>
