import { writable, derived } from "svelte/store";
import { fromPairs, omit } from "lodash";
import { map } from "underscore";
import { fmapConcat } from "../utils";
import { BUSINESS_TYPES_CODES, ORDERS, TAX_RETURN_DUE_DATE_TYPES } from "utils/business_objects";

// "id" is needed to locate this node.
// "title" is necessary for search purposes.
// "updatable" means a refresh button will be displayed next to its title.
// "param" indicates that this parameter will be included in the list of parameters to be refreshed.
let defaultTree = [
  {
    title: "Report Options",
    id: "report_options",
    updatable: true,
    children: [
      {
        title: "Layout",
        id: "layout_params",
        updatable: false,
        children: [
          {
            title: "Name",
            id: "layout_name",
            updatable: false
          },
          {
            title: "Primary",
            id: "layout_violet_color_text_primary",
            updatable: false
          },
          {
            title: "Secondary",
            id: "layout_violet_color_text_secondary",
            updatable: false
          },
          {
            title: "Light",
            id: "layout_violet_color_text_reverse",
            updatable: false
          },
          {
            title: "Profit",
            id: "layout_violet_color_text_green",
            updatable: false
          },
          {
            title: "Profit (on dark background)",
            id: "layout_violet_color_text_green_light",
            updatable: false
          },
          {
            title: "Losses",
            id: "layout_violet_color_text_red",
            updatable: false
          },
          {
            title: "Primary Background",
            id: "layout_violet_color_bg_primary",
            updatable: false
          },
          {
            title: "Secondary Background",
            id: "layout_violet_color_bg_secondary",
            updatable: false
          },
          {
            title: "Tertiary Background",
            id: "layout_violet_color_bg_tertiary",
            updatable: false
          },
          {
            title: "Primary",
            id: "layout_orange_color_text_primary",
            updatable: false
          },
          {
            title: "Light",
            id: "layout_orange_color_text_reverse",
            updatable: false
          },
          {
            title: "Profit",
            id: "layout_orange_color_text_green",
            updatable: false
          },
          {
            title: "Profit (on dark background)",
            id: "layout_orange_color_text_green_light",
            updatable: false
          },
          {
            title: "Losses",
            id: "layout_orange_color_text_red",
            updatable: false
          },
          {
            title: "Primary Background",
            id: "layout_orange_color_bg_primary",
            updatable: false
          },
          {
            title: "Secondary Background",
            id: "layout_orange_color_bg_secondary",
            updatable: false
          }
        ]
      },
      // WARNING: Don't put in general params updatable fields.
      // If you include such fields, than with partial update tax_year will be updated and we need to avoid that.
      {
        title: "Report Title",
        id: "general_params",
        updatable: false,
        children: [
          {
            title: "Report Title",
            id: "front_page_report_title",
            updatable: false
          },
          {
            id: "tax_year",
            updatable: true,
            param: ["tax_year"]
          }
        ]
      },
      {
        title: "Front Page",
        id: "front_page_enable",
        updatable: true,
        children: [
          {
            title: "Prepared for",
            id: "front_page_prepared_for_organization",
            param: ["front_page_prepared_for_organization"],
            updatable: true
          },
          {
            title: "Prepared by",
            id: "front_page_prepared_by_organization",
            param: ["front_page_prepared_by_organization"],
            updatable: true
          },
          {
            title: "Preparation Date",
            id: "front_page_date",
            param: ["front_page_date"],
            updatable: true
          },
          {
            title: "Show front page image",
            id: "front_page_center_image_enable",
            updatable: false
          }
        ]
      },
      {
        title: "Abstract",
        id: "abstract_enable",
        updatable: false,
        children: [
          {
            title: "Introduction",
            id: "abstract_introduction",
            updatable: false
          },
          {
            title: "Title",
            id: "abstract_title",
            updatable: false
          },
          {
            title: "Inscription",
            id: "abstract_inscription",
            updatable: false
          },
          {
            title: "Description",
            id: "abstract_description",
            updatable: false
          },
          {
            title: "Postscript",
            id: "abstract_postscript",
            updatable: false
          },
          {
            title: "Post-postscript",
            id: "abstract_post_postscript",
            updatable: false
          }
        ]
      },
      {
        title: "Introduction",
        id: "introduction_enable",
        updatable: false,
        children: [
          {
            title: "Explain Grading System",
            id: "introduction_grading_systems_enable",
            updatable: false
          }
        ]
      },
      {
        title: "Tax Projection Summary",
        id: "tps_enable",
        updatable: true,
        children: [
          {
            title: "Page 1 Income",
            id: "tps_page_1",
            updatable: true,
            children: [
              {
                title: "Projected Revenue",
                id: "tps_page_1_income_projected_revenue",
                param: ["tps_page_1_income_projected_revenue"],
                updatable: true
              },
              {
                title: "Projected Expense",
                id: "tps_page_1_income_projected_expense",
                param: ["tps_page_1_income_projected_expense"],
                updatable: true
              },
              {
                title: "Business Income",
                id: "tps_page_1_income_projected_expense_sum",
                param: ["tps_page_1_income_projected_expense_sum"],
                updatable: true
              },
              {
                title: "Wages",
                id: "tps_page_1_income_wages",
                param: ["tps_page_1_income_wages"],
                updatable: true
              },
              {
                title: "Portfolio Income",
                id: "tps_page_1_income_portfolio_income",
                param: ["tps_page_1_income_portfolio_income"],
                updatable: true
              },
              {
                title: "Rental Income",
                id: "tps_page_1_income_rental_income",
                param: ["tps_page_1_income_rental_income"],
                updatable: true
              },
              {
                title: "Other Income",
                id: "tps_page_1_income_other_income",
                param: ["tps_page_1_income_other_income"],
                updatable: true
              },
              {
                title: "Personal Income",
                id: "tps_page_1_income_personal_income",
                param: ["tps_page_1_income_personal_income"],
                updatable: true
              }
            ]
          },
          {
            title: "Page 1 Deductions & Credits",
            id: "tps_page_1_deductions_and_credits",
            updatable: true,
            children: [
              {
                title: "Adjustments",
                id: "tps_page_1_deductions_and_credits_adjustments",
                param: ["tps_page_1_deductions_and_credits_adjustments"],
                updatable: true
              },
              {
                title: "Passthrough Deductions",
                id: "tps_page_1_deductions_and_credits_passthrough_deduction",
                param: ["tps_page_1_deductions_and_credits_passthrough_deduction"],
                updatable: true
              },
              {
                title: "Itemized Deduction",
                id: "tps_page_1_deductions_and_credits_itemized_deduction",
                param: ["tps_page_1_deductions_and_credits_itemized_deduction"],
                updatable: true
              },
              {
                title: "Credits",
                id: "tps_page_1_deductions_and_credits_credits",
                param: ["tps_page_1_deductions_and_credits_credits"],
                updatable: true
              },
              {
                title: "Deductions And Credits",
                id: "tps_page_1_deductions_and_credits_deductions_and_credits",
                param: ["tps_page_1_deductions_and_credits_deductions_and_credits"],
                updatable: true
              },
              {
                title: "Taxable Income",
                id: "tps_page_1_deductions_and_credits_taxable_income",
                param: ["tps_page_1_deductions_and_credits_taxable_income"],
                updatable: true
              }
            ]
          },
          {
            title: "Page 1 Taxes & Payments",
            id: "tps_page_1_tax_and_payments",
            updatable: true,
            children: [
              {
                title: "Tax Fed",
                id: "tps_page_1_tax_and_payments_tax_fed",
                param: ["tps_page_1_tax_and_payments_tax_fed"],
                updatable: true
              },
              {
                title: "Tax State",
                id: "tps_page_1_tax_and_payments_tax_state",
                param: ["tps_page_1_tax_and_payments_tax_state"],
                updatable: true
              },
              {
                title: "Witholding And Es Payments Fed",
                id: "tps_page_1_tax_and_payments_witholding_and_es_payments_fed",
                param: ["tps_page_1_tax_and_payments_witholding_and_es_payments_fed"],
                updatable: true
              },
              {
                title: "Witholding And Es Payments State",
                id: "tps_page_1_tax_and_payments_witholding_and_es_payments_stat",
                param: ["tps_page_1_tax_and_payments_witholding_and_es_payments_stat"],
                updatable: true
              },
              {
                title: "Tax Due Fed",
                id: "tps_page_1_tax_and_payments_tax_due_fed",
                param: ["tps_page_1_tax_and_payments_tax_due_fed"],
                updatable: true
              },
              {
                title: "Tax Due State",
                id: "tps_page_1_tax_and_payments_tax_due_state",
                param: ["tps_page_1_tax_and_payments_tax_due_state"],
                updatable: true
              },
              {
                title: "Next Lower Bracket",
                id: "tps_page_1_tax_and_payments_next_lower_bracket",
                param: ["tps_page_1_tax_and_payments_next_lower_bracket"],
                updatable: true
              },
              {
                title: "Effective Tax Rate",
                id: "tps_page_1_tax_and_payments_effective_tax_rate",
                param: ["tps_page_1_tax_and_payments_effective_tax_rate"],
                updatable: true
              },
              {
                title: "Distance To Bracket",
                id: "tps_page_1_tax_and_payments_distance_to_bracket",
                param: ["tps_page_1_tax_and_payments_distance_to_bracket"],
                updatable: true
              }
            ]
          },
          {
            title: "Page 2 Income",
            id: "tps_page_2_income",
            updatable: true,
            children: [
              {
                title: "Wages",
                id: "tps_page_2_income_wages",
                param: ["tps_page_2_income_wages"],
                updatable: true
              },
              {
                title: "Interest",
                id: "tps_page_2_income_interest",
                param: ["tps_page_2_income_interest"],
                updatable: true
              },
              {
                title: "Dividents",
                id: "tps_page_2_income_dividents",
                param: ["tps_page_2_income_dividents"],
                updatable: true
              },
              {
                title: "Capital Gains",
                id: "tps_page_2_income_capital_gains",
                param: ["tps_page_2_income_capital_gains"],
                updatable: true
              },
              {
                title: "Rental Income",
                id: "tps_page_2_income_rental_income",
                param: ["tps_page_2_income_rental_income"],
                updatable: true
              },
              {
                title: "First Company Name",
                id: "tps_page_2_income_first_company_name",
                param: ["tps_page_2_income_first_company_name"],
                updatable: true
              },
              {
                title: "First Company Income",
                id: "tps_page_2_income_first_company_income",
                param: ["tps_page_2_income_first_company_income"],
                updatable: true
              },
              {
                title: "Second Company Name",
                id: "tps_page_2_income_second_company_name",
                param: ["tps_page_2_income_second_company_name"],
                updatable: true
              },
              {
                title: "Second Company Income",
                id: "tps_page_2_income_second_company_income",
                param: ["tps_page_2_income_second_company_income"],
                updatable: true
              },
              {
                title: "Third Company Name",
                id: "tps_page_2_income_third_company_name",
                param: ["tps_page_2_income_third_company_name"],
                updatable: true
              },
              {
                title: "Third Company Income",
                id: "tps_page_2_income_third_company_income",
                param: ["tps_page_2_income_third_company_income"],
                updatable: true
              },
              {
                title: "Fourth Company Name",
                id: "tps_page_2_income_fourth_company_name",
                param: ["tps_page_2_income_fourth_company_name"],
                updatable: true
              },
              {
                title: "Fourth Company Income",
                id: "tps_page_2_income_fourth_company_income",
                param: ["tps_page_2_income_fourth_company_income"],
                updatable: true
              },
              {
                title: "Extra S-Corp Income",
                id: "tps_page_2_income_extra_s_corp_income",
                param: ["tps_page_2_income_extra_s_corp_income"],
                updatable: true
              },
              {
                title: "Extra Schedule C Business Income",
                id: "tps_page_2_income_extra_schedule_c_income",
                param: ["tps_page_2_income_extra_schedule_c_income"],
                updatable: true
              },
              {
                title: "Other Income",
                id: "tps_page_2_income_other_income",
                param: ["tps_page_2_income_other_income"],
                updatable: true
              },
              {
                title: "Total Income",
                id: "tps_page_2_income_total_income",
                param: ["tps_page_2_income_total_income"],
                updatable: true
              }
            ]
          },
          {
            title: "Page 2 Adjustments",
            id: "tps_page_2_adjustments",
            updatable: true,
            children: [
              {
                title: "Health Savings Account",
                id: "tps_page_2_adjustments_health_savings_account",
                param: ["tps_page_2_adjustments_health_savings_account"],
                updatable: true
              },
              {
                title: "Deductible Se Tax",
                id: "tps_page_2_adjustments_deductible_se_tax",
                param: ["tps_page_2_adjustments_deductible_se_tax"],
                updatable: true
              },
              {
                title: "Sep Simple Qual Plans",
                id: "tps_page_2_adjustments_sep_simple_qual_plans",
                param: ["tps_page_2_adjustments_sep_simple_qual_plans"],
                updatable: true
              },
              {
                title: "Self Employed Health Insurance",
                id: "tps_page_2_adjustments_self_employed_health_ins",
                param: ["tps_page_2_adjustments_self_employed_health_ins"],
                updatable: true
              },
              {
                title: "Ira Deduction",
                id: "tps_page_2_adjustments_ira_deduction",
                param: ["tps_page_2_adjustments_ira_deduction"],
                updatable: true
              },
              {
                title: "Other Adjustments",
                id: "tps_page_2_adjustments_other_adjustments",
                param: ["tps_page_2_adjustments_other_adjustments"],
                updatable: true
              },
              {
                title: "Total Adjustments",
                id: "tps_page_2_adjustments_total_adjustments",
                param: ["tps_page_2_adjustments_total_adjustments"],
                updatable: true
              },
              {
                title: "Adjusted Gross Income",
                id: "tps_page_2_adjustments_adjusted_gross_income",
                param: ["tps_page_2_adjustments_adjusted_gross_income"],
                updatable: true
              }
            ]
          },
          {
            title: "Page 3 Deductions",
            id: "tps_page_3_deductions",
            updatable: true,
            children: [
              {
                title: "Medical Related",
                id: "tps_page_3_deductions_medical_related",
                param: ["tps_page_3_deductions_medical_related"],
                updatable: true
              },
              {
                title: "Taxes",
                id: "tps_page_3_deductions_taxes",
                param: ["tps_page_3_deductions_taxes"],
                updatable: true
              },
              {
                title: "Mortgage Interest",
                id: "tps_page_3_deductions_mortgage_interest",
                param: ["tps_page_3_deductions_mortgage_interest"],
                updatable: true
              },
              {
                title: "Charity",
                id: "tps_page_3_deductions_charity",
                param: ["tps_page_3_deductions_charity"],
                updatable: true
              },
              {
                title: "Other Itemized Deductions",
                id: "tps_page_3_deductions_other_itemized_deductions",
                param: ["tps_page_3_deductions_other_itemized_deductions"],
                updatable: true
              },
              {
                title: "Passthrough Deduction",
                id: "tps_page_3_deductions_passthrough_deduction",
                param: ["tps_page_3_deductions_passthrough_deduction"],
                updatable: true
              },
              {
                title: "Total Deduction",
                id: "tps_page_3_deductions_total_deductions",
                param: ["tps_page_3_deductions_total_deductions"],
                updatable: true
              },
              {
                title: "Taxable Income",
                id: "tps_page_3_deductions_taxable_income",
                param: ["tps_page_3_deductions_taxable_income"],
                updatable: true
              }
            ]
          },
          {
            title: "Page 3 Taxes & Payments",
            id: "tps_page_3_taxes_and_payments",
            updatable: true,
            children: [
              {
                title: "Tax Fed",
                id: "tps_page_3_taxes_and_payments_tax_fed",
                param: ["tps_page_3_taxes_and_payments_tax_fed"],
                updatable: true
              },
              {
                title: "Tax State",
                id: "tps_page_3_taxes_and_payments_tax_state",
                param: ["tps_page_3_taxes_and_payments_tax_state"],
                updatable: true
              },
              {
                title: "Alternative Min Tax",
                id: "tps_page_3_taxes_and_payments_alternative_min_tax",
                param: ["tps_page_3_taxes_and_payments_alternative_min_tax"],
                updatable: true
              },
              {
                title: "Self Employment Tax",
                id: "tps_page_3_taxes_and_payments_self_employment_tax",
                param: ["tps_page_3_taxes_and_payments_self_employment_tax"],
                updatable: true
              },
              {
                title: "Other Taxes",
                id: "tps_page_3_taxes_and_payments_other_taxes",
                param: ["tps_page_3_taxes_and_payments_other_taxes"],
                updatable: true
              },
              {
                title: "Total Tax Fed",
                id: "tps_page_3_taxes_and_payments_total_tax_fed",
                param: ["tps_page_3_taxes_and_payments_total_tax_fed"],
                updatable: true
              },
              {
                title: "Total Tax State",
                id: "tps_page_3_taxes_and_payments_total_tax_state",
                param: ["tps_page_3_taxes_and_payments_total_tax_state"],
                updatable: true
              },
              {
                title: "Credits",
                id: "tps_page_3_taxes_and_payments_credits",
                param: ["tps_page_3_taxes_and_payments_credits"],
                updatable: true
              },
              {
                title: "State Tax Credit",
                id: "tps_page_3_taxes_and_payments_state_tax_credit",
                param: ["tps_page_3_taxes_and_payments_state_tax_credit"],
                updatable: true
              },
              {
                title: "Witholding Fed",
                id: "tps_page_3_taxes_and_payments_witholding_fed",
                param: ["tps_page_3_taxes_and_payments_witholding_fed"],
                updatable: true
              },
              {
                title: "Witholding State",
                id: "tps_page_3_taxes_and_payments_witholding_state",
                param: ["tps_page_3_taxes_and_payments_witholding_state"],
                updatable: true
              },
              {
                title: "Estimated Tax Payments Fed",
                id: "tps_page_3_taxes_and_payments_estimated_tax_payments_fed",
                param: ["tps_page_3_taxes_and_payments_estimated_tax_payments_fed"],
                updatable: true
              },
              {
                title: "Estimated Tax Payments State",
                id: "tps_page_3_taxes_and_payments_estimated_tax_payments_state",
                param: ["tps_page_3_taxes_and_payments_estimated_tax_payments_state"],
                updatable: true
              },
              {
                title: "Other Payments",
                id: "tps_page_3_taxes_and_payments_other_payments",
                param: ["tps_page_3_taxes_and_payments_other_payments"],
                updatable: true
              },
              {
                title: "Total Federal Tax Credits",
                id: "tps_page_3_taxes_and_payments_total_federal_tax_credits",
                param: ["tps_page_3_taxes_and_payments_total_federal_tax_credits"],
                updatable: true
              },
              {
                title: "Total State Tax Credits",
                id: "tps_page_3_taxes_and_payments_total_state_tax_credits",
                param: ["tps_page_3_taxes_and_payments_total_state_tax_credits"],
                updatable: true
              },
              {
                title: "Tax Due",
                id: "tps_page_3_taxes_and_payments_tax_due",
                param: ["tps_page_3_taxes_and_payments_tax_due"],
                updatable: true
              },
              {
                title: "State Tax Due",
                id: "tps_page_3_taxes_and_payments_state_tax_due",
                param: ["tps_page_3_taxes_and_payments_state_tax_due"],
                updatable: true
              }
            ]
          }
        ]
      },
      {
        title: "Top 3 Strategy Picks",
        id: "top_3_strategy_picks_enable",
        updatable: false,
        children: [
          {
            title: "Strategy Pick #1",
            id: "top_3_strategy_picks_first_type",
            param: ["top_3_strategy_picks_first_type"],
            updatable: false
          },
          {
            title: "Strategy Pick #2",
            id: "top_3_strategy_picks_second_type",
            param: ["top_3_strategy_picks_second_type"],
            updatable: false
          },
          {
            title: "Strategy Pick #3",
            id: "top_3_strategy_picks_third_type",
            param: ["top_3_strategy_picks_third_type"],
            updatable: false
          }
        ]
      },
      {
        title: "Strategy Details",
        id: "sd_enable",
        updatable: true,
        children: [
          {
            title: "Section 179",
            id: "sd_sec_179_expense_enable",
            updatable: true,
            children: [
              {
                title: "Top 3 Widget Options",
                id: "sd_sec_179_top_3_widget_options",
                updatable: true,
                children: [
                  {
                    title: "Adding",
                    id: "top_3_strategy_picks_section179_adding",
                    updatable: true,
                    param: ["top_3_strategy_picks_section179_adding"]
                  },
                  {
                    title: "Savings",
                    id: "top_3_strategy_picks_section179_saves_you",
                    updatable: true,
                    param: ["top_3_strategy_picks_section179_saves_you"]
                  }
                ]
              },
              {
                title: "Description",
                id: "sd_sec_179_expense_what_it_is_text",
                updatable: false
              },
              {
                title: "Second Description",
                id: "sd_sec_179_expense_what_it_is_text_extra",
                updatable: false
              },
              {
                title: "First Item",
                id: "sd_sec_179_expense_what_it_is_first_item",
                updatable: false
              },
              {
                title: "Second Item",
                id: "sd_sec_179_expense_what_it_is_second_item",
                updatable: false
              },
              {
                title: "Third Item",
                id: "sd_sec_179_expense_what_it_is_third_item",
                updatable: false
              },
              {
                title: "Fourth Item",
                id: "sd_sec_179_expense_what_it_is_fourth_item",
                updatable: false
              },
              {
                title: "Fifth Item",
                id: "sd_sec_179_expense_what_it_is_fifth_item",
                updatable: false
              },
              {
                title: "Description of Ratings",
                id: "sd_sec_179_expense_ratings_text",
                updatable: false
              },
              {
                title: "Option A Expense",
                param: ["sd_sec_179_expense_tax_saving_options_first_expense"],
                id: "sd_sec_179_expense_tax_saving_options_first_expense",
                updatable: true
              },
              {
                title: "Option A Saves You",
                param: ["sd_sec_179_expense_tax_saving_options_first_saves_you"],
                id: "sd_sec_179_expense_tax_saving_options_first_saves_you",
                updatable: true
              },
              {
                title: "Option B Expense",
                param: ["sd_sec_179_expense_tax_saving_options_second_expense"],
                id: "sd_sec_179_expense_tax_saving_options_second_expense",
                updatable: true
              },
              {
                title: "Option B Saves You",
                param: ["sd_sec_179_expense_tax_saving_options_second_saves_you"],
                id: "sd_sec_179_expense_tax_saving_options_second_saves_you",
                updatable: true
              },
              {
                title: "Option C Expense",
                param: ["sd_sec_179_expense_tax_saving_options_third_expense"],
                id: "sd_sec_179_expense_tax_saving_options_third_expense",
                updatable: true
              },
              {
                title: "Option C Saves You",
                param: ["sd_sec_179_expense_tax_saving_options_third_saves_you"],
                id: "sd_sec_179_expense_tax_saving_options_third_saves_you",
                updatable: true
              },
              {
                title: "Max Section 179 Deduction",
                param: ["sd_sec_179_expense_current_max_sec_179_deduction"],
                id: "sd_sec_179_expense_current_max_sec_179_deduction",
                updatable: true
              }
            ]
          },
          {
            title: "Expense Acceleration",
            id: "sd_expense_acceleration_enable",
            updatable: true,
            children: [
              {
                title: "Top 3 Widget Options",
                id: "sd_expense_acceleration_top_3_widget_options",
                updatable: true,
                children: [
                  {
                    title: "Adding",
                    id: "top_3_strategy_picks_expense_acceleration_adding",
                    updatable: true,
                    param: ["top_3_strategy_picks_expense_acceleration_adding"]
                  },
                  {
                    title: "Savings",
                    id: "top_3_strategy_picks_expense_acceleration_saves_you",
                    updatable: true,
                    param: ["top_3_strategy_picks_expense_acceleration_saves_you"]
                  }
                ]
              },
              {
                title: "Description",
                id: "sd_expense_acceleration_what_it_is_text",
                updatable: false
              },
              {
                title: "First Item",
                id: "sd_expense_acceleration_what_it_is_first_item",
                updatable: false
              },
              {
                title: "Second Item",
                id: "sd_expense_acceleration_what_it_is_second_item",
                updatable: false
              },
              {
                title: "Third Item",
                id: "sd_expense_acceleration_what_it_is_third_item",
                updatable: false
              },
              {
                title: "Fourth Item",
                id: "sd_expense_acceleration_what_it_is_fourth_item",
                updatable: false
              },
              {
                title: "Fifth Item",
                id: "sd_expense_acceleration_what_it_is_fifth_item",
                updatable: false
              },
              {
                title: "Sixth Item",
                id: "sd_expense_acceleration_what_it_is_sixth_item",
                updatable: false
              },
              {
                title: "Seventh Item",
                id: "sd_expense_acceleration_what_it_is_seventh_item",
                updatable: false
              },
              {
                title: "Description of Ratings",
                id: "sd_expense_acceleration_ratings_text",
                updatable: false
              },
              {
                title: "Option A Expense",
                param: ["sd_expense_acceleration_tax_saving_options_first_expense"],
                id: "sd_expense_acceleration_tax_saving_options_first_expense",
                updatable: true
              },
              {
                title: "Option A Saves You",
                param: ["sd_expense_acceleration_tax_saving_options_first_saves_you"],
                id: "sd_expense_acceleration_tax_saving_options_first_saves_you",
                updatable: true
              },
              {
                title: "Option B Expense",
                param: ["sd_expense_acceleration_tax_saving_options_second_expense"],
                id: "sd_expense_acceleration_tax_saving_options_second_expense",
                updatable: true
              },
              {
                title: "Option B Saves You",
                param: ["sd_expense_acceleration_tax_saving_options_second_saves_you"],
                id: "sd_expense_acceleration_tax_saving_options_second_saves_you",
                updatable: true
              },
              {
                title: "Option C Expense",
                param: ["sd_expense_acceleration_tax_saving_options_third_expense"],
                id: "sd_expense_acceleration_tax_saving_options_third_expense",
                updatable: true
              },
              {
                title: "Option C Saves You",
                param: ["sd_expense_acceleration_tax_saving_options_third_saves_you"],
                id: "sd_expense_acceleration_tax_saving_options_third_saves_you",
                updatable: true
              }
            ]
          },
          {
            title: "Missing Deductions",
            id: "sd_missing_deductions_enable",
            updatable: true,
            children: [
              {
                title: "Top 3 Widget Options",
                id: "sd_missing_deductions_top_3_widget_options",
                updatable: true,
                children: [
                  {
                    title: "Adding",
                    id: "top_3_strategy_picks_missing_deductions_adding",
                    updatable: true,
                    param: ["top_3_strategy_picks_missing_deductions_adding"]
                  },
                  {
                    title: "Savings",
                    id: "top_3_strategy_picks_missing_deductions_saves_you",
                    updatable: true,
                    param: ["top_3_strategy_picks_missing_deductions_saves_you"]
                  }
                ]
              },
              {
                title: "Description",
                id: "sd_missing_deductions_what_it_is_text",
                updatable: false
              },
              {
                title: "Second Description",
                id: "sd_missing_deductions_what_it_is_text_extra",
                updatable: false
              },
              {
                title: "First Item",
                id: "sd_missing_deductions_what_it_is_first_item",
                updatable: false
              },
              {
                title: "Second Item",
                id: "sd_missing_deductions_what_it_is_second_item",
                updatable: false
              },
              {
                title: "Third Item",
                id: "sd_missing_deductions_what_it_is_third_item",
                updatable: false
              },
              {
                title: "Fourth Item",
                id: "sd_missing_deductions_what_it_is_fourth_item",
                updatable: false
              },
              {
                title: "Fifth Item",
                id: "sd_missing_deductions_what_it_is_fifth_item",
                updatable: false
              },
              {
                title: "Sixth Item",
                id: "sd_missing_deductions_what_it_is_sixth_item",
                updatable: false
              },
              {
                title: "Seventh Item",
                id: "sd_missing_deductions_what_it_is_seventh_item",
                updatable: false
              },
              {
                title: "Eighth Item",
                id: "sd_missing_deductions_what_it_is_eighth_item",
                updatable: false
              },
              {
                title: "Ninth Item",
                id: "sd_missing_deductions_what_it_is_ninth_item",
                updatable: false
              },
              {
                title: "Tenth Item",
                id: "sd_missing_deductions_what_it_is_tenth_item",
                updatable: false
              },
              {
                title: "Eleventh Item",
                id: "sd_missing_deductions_what_it_is_eleventh_item",
                updatable: false
              },
              {
                title: "Twelfth Item",
                id: "sd_missing_deductions_what_it_is_twelfth_item",
                updatable: false
              },
              {
                title: "Current Meeting Expense",
                param: ["sd_missing_deductions_annual_meeting_expense_current"],
                id: "sd_missing_deductions_annual_meeting_expense_current",
                updatable: true
              },
              {
                title: "Recommended Meeting Expense",
                param: ["sd_missing_deductions_annual_meeting_expense_recommended"],
                id: "sd_missing_deductions_annual_meeting_expense_recommended",
                updatable: true
              },
              {
                title: "Current Meals",
                param: ["sd_missing_deductions_meals_current"],
                id: "sd_missing_deductions_meals_current",
                updatable: true
              },
              {
                title: "Recommended Meals",
                param: ["sd_missing_deductions_meals_recommended"],
                id: "sd_missing_deductions_meals_recommended",
                updatable: true
              },
              {
                title: "Current Travel",
                param: ["sd_missing_deductions_travel_current"],
                id: "sd_missing_deductions_travel_current",
                updatable: true
              },
              {
                title: "Recommended Travel",
                param: ["sd_missing_deductions_travel_recommended"],
                id: "sd_missing_deductions_travel_recommended",
                updatable: true
              },
              {
                title: "Current Telephone",
                param: ["sd_missing_deductions_telephone_current"],
                id: "sd_missing_deductions_telephone_current",
                updatable: true
              },
              {
                title: "Recommended Telephone",
                param: ["sd_missing_deductions_telephone_recommended"],
                id: "sd_missing_deductions_telephone_recommended",
                updatable: true
              },
              {
                title: "Current Internet",
                param: ["sd_missing_deductions_internet_current"],
                id: "sd_missing_deductions_internet_current",
                updatable: true
              },
              {
                title: "Recommended Internet",
                param: ["sd_missing_deductions_internet_recommended"],
                id: "sd_missing_deductions_internet_recommended",
                updatable: true
              },
              {
                title: "Current Auto",
                param: ["sd_missing_deductions_auto_current"],
                id: "sd_missing_deductions_auto_current",
                updatable: true
              },
              {
                title: "Recommended Auto",
                param: ["sd_missing_deductions_auto_recommended"],
                id: "sd_missing_deductions_auto_recommended",
                updatable: true
              },
              {
                title: "Current Office",
                param: ["sd_missing_deductions_office_current"],
                id: "sd_missing_deductions_office_current",
                updatable: true
              },
              {
                title: "Recommended Office",
                param: ["sd_missing_deductions_office_recommended"],
                id: "sd_missing_deductions_office_recommended",
                updatable: true
              },
              {
                title: "Current Legal",
                param: ["sd_missing_deductions_legal_current"],
                id: "sd_missing_deductions_legal_current",
                updatable: true
              },
              {
                title: "Recommended Legal",
                param: ["sd_missing_deductions_legal_recommended"],
                id: "sd_missing_deductions_legal_recommended",
                updatable: true
              },
              {
                title: "Current Mortgage",
                param: ["sd_missing_deductions_mortgage_current"],
                id: "sd_missing_deductions_mortgage_current",
                updatable: true
              },
              {
                title: "Recommended Mortgage",
                param: ["sd_missing_deductions_mortgage_recommended"],
                id: "sd_missing_deductions_mortgage_recommended",
                updatable: true
              },
              {
                title: "Current Health Savings Account",
                param: ["sd_missing_deductions_health_savings_account_current"],
                id: "sd_missing_deductions_health_savings_account_current",
                updatable: true
              },
              {
                title: "Recommended Health Savings Account",
                param: ["sd_missing_deductions_health_savings_account_recommended"],
                id: "sd_missing_deductions_health_savings_account_recommended",
                updatable: true
              },
              {
                title: "Current Charity Donations",
                param: ["sd_missing_deductions_cash_charity_donations_current"],
                id: "sd_missing_deductions_cash_charity_donations_current",
                updatable: true
              },
              {
                title: "Recommended Charity Donations",
                param: ["sd_missing_deductions_cash_charity_donations_recommended"],
                id: "sd_missing_deductions_cash_charity_donations_recommended",
                updatable: true
              },
              {
                title: "Current Non Cash Donations",
                param: ["sd_missing_deductions_non_cash_donations_current"],
                id: "sd_missing_deductions_non_cash_donations_current",
                updatable: true
              },
              {
                title: "Recommended Non Cash Donations",
                param: ["sd_missing_deductions_non_cash_donations_recommended"],
                id: "sd_missing_deductions_non_cash_donations_recommended",
                updatable: true
              },
              {
                title: "Total Tax Savings",
                param: ["sd_missing_deductions_total_tax_savings_comes_to"],
                id: "sd_missing_deductions_total_tax_savings_comes_to",
                updatable: true
              }
            ]
          },
          {
            title: "Income Deferment",
            id: "sd_income_deferment_enable",
            updatable: true,
            children: [
              {
                title: "Top 3 Widget Options",
                id: "sd_income_deferment_top_3_widget_options",
                updatable: true,
                children: [
                  {
                    title: "Adding",
                    id: "top_3_strategy_picks_income_deferment_adding",
                    updatable: true,
                    param: ["top_3_strategy_picks_income_deferment_adding"]
                  },
                  {
                    title: "Savings",
                    id: "top_3_strategy_picks_income_deferment_saves_you",
                    updatable: true,
                    param: ["top_3_strategy_picks_income_deferment_saves_you"]
                  }
                ]
              },
              {
                title: "Description",
                id: "sd_income_deferment_what_it_is_text",
                updatable: false
              },
              {
                title: "First Item",
                id: "sd_income_deferment_what_it_is_first_item",
                updatable: false
              },
              {
                title: "Second Item",
                id: "sd_income_deferment_what_it_is_second_item",
                updatable: false
              },
              {
                title: "Third Item",
                id: "sd_income_deferment_what_it_is_third_item",
                updatable: false
              },
              {
                title: "Fourth Item",
                id: "sd_income_deferment_what_it_is_fourth_item",
                updatable: false
              },
              {
                title: "Fifth Item",
                id: "sd_income_deferment_what_it_is_fifth_item",
                updatable: false
              },
              {
                title: "Description of Ratings",
                id: "sd_income_deferment_ratings_text",
                updatable: false
              },
              {
                title: "Option A Expense",
                param: ["sd_income_deferment_tax_saving_options_first_expense"],
                id: "sd_income_deferment_tax_saving_options_first_expense",
                updatable: true
              },
              {
                title: "Option A Saves You",
                param: ["sd_income_deferment_tax_saving_options_first_saves_you"],
                id: "sd_income_deferment_tax_saving_options_first_saves_you",
                updatable: true
              },
              {
                title: "Option B Expense",
                param: ["sd_income_deferment_tax_saving_options_second_expense"],
                id: "sd_income_deferment_tax_saving_options_second_expense",
                updatable: true
              },
              {
                title: "Option B Saves You",
                param: ["sd_income_deferment_tax_saving_options_second_saves_you"],
                id: "sd_income_deferment_tax_saving_options_second_saves_you",
                updatable: true
              },
              {
                title: "Option C Expense",
                param: ["sd_income_deferment_tax_saving_options_third_expense"],
                id: "sd_income_deferment_tax_saving_options_third_expense",
                updatable: true
              },
              {
                title: "Option C Saves You",
                param: ["sd_income_deferment_tax_saving_options_third_saves_you"],
                id: "sd_income_deferment_tax_saving_options_third_saves_you",
                updatable: true
              }
            ]
          },
          {
            title: "SEP IRA",
            id: "sd_sep_ira_enable",
            updatable: true,
            children: [
              {
                title: "Top 3 Widget Options",
                id: "sd_sep_ira_top_3_widget_options",
                updatable: true,
                children: [
                  {
                    title: "Adding",
                    id: "top_3_strategy_picks_sep_ira_adding",
                    updatable: true,
                    param: ["top_3_strategy_picks_sep_ira_adding"]
                  },
                  {
                    title: "Savings",
                    id: "top_3_strategy_picks_sep_ira_saves_you",
                    updatable: true,
                    param: ["top_3_strategy_picks_sep_ira_saves_you"]
                  }
                ]
              },
              {
                title: "Description",
                id: "sd_sep_ira_what_it_is_text",
                updatable: false
              },
              {
                title: "First Item",
                id: "sd_sep_ira_what_it_is_first_item",
                updatable: false
              },
              {
                title: "Second Item",
                id: "sd_sep_ira_what_it_is_second_item",
                updatable: false
              },
              {
                title: "Third Item",
                id: "sd_sep_ira_what_it_is_third_item",
                updatable: false
              },
              {
                title: "Fourth Item",
                id: "sd_sep_ira_what_it_is_fourth_item",
                updatable: false
              },
              {
                title: "Description of Ratings",
                id: "sd_sep_ira_ratings_text",
                updatable: false
              },
              {
                title: "Option A Expense",
                param: ["sd_sep_ira_tax_saving_options_first_expense"],
                id: "sd_sep_ira_tax_saving_options_first_expense",
                updatable: true
              },
              {
                title: "Option A Saves You",
                param: ["sd_sep_ira_tax_saving_options_first_saves_you"],
                id: "sd_sep_ira_tax_saving_options_first_saves_you",
                updatable: true
              },
              {
                title: "Option B Expense",
                param: ["sd_sep_ira_tax_saving_options_second_expense"],
                id: "sd_sep_ira_tax_saving_options_second_expense",
                updatable: true
              },
              {
                title: "Option B Saves You",
                param: ["sd_sep_ira_tax_saving_options_second_saves_you"],
                id: "sd_sep_ira_tax_saving_options_second_saves_you",
                updatable: true
              },
              {
                title: "Option C Expense",
                param: ["sd_sep_ira_tax_saving_options_third_expense"],
                id: "sd_sep_ira_tax_saving_options_third_expense",
                updatable: true
              },
              {
                title: "Option C Saves You",
                param: ["sd_sep_ira_tax_saving_options_third_saves_you"],
                id: "sd_sep_ira_tax_saving_options_third_saves_you",
                updatable: true
              },
              {
                title: "Option A W-2 Wages Expense",
                param: ["sd_sep_ira_w_2_wages_tax_saving_options_first_expense"],
                id: "sd_sep_ira_w_2_wages_tax_saving_options_first_expense",
                updatable: true
              },
              {
                title: "Option A W-2 Wages Saves You",
                param: ["sd_sep_ira_w_2_wages_tax_saving_options_first_saves_you"],
                id: "sd_sep_ira_w_2_wages_tax_saving_options_first_saves_you",
                updatable: true
              },
              {
                title: "Option B W-2 Wages Expense",
                param: ["sd_sep_ira_w_2_wages_tax_saving_options_second_expense"],
                id: "sd_sep_ira_w_2_wages_tax_saving_options_second_expense",
                updatable: true
              },
              {
                title: "Option B W-2 Wages Saves You",
                param: ["sd_sep_ira_w_2_wages_tax_saving_options_second_saves_you"],
                id: "sd_sep_ira_w_2_wages_tax_saving_options_second_saves_you",
                updatable: true
              },
              {
                title: "Option C W-2 Wages Expense",
                param: ["sd_sep_ira_w_2_wages_tax_saving_options_third_expense"],
                id: "sd_sep_ira_w_2_wages_tax_saving_options_third_expense",
                updatable: true
              },
              {
                title: "Option C W-2 Wages Saves You",
                param: ["sd_sep_ira_w_2_wages_tax_saving_options_third_saves_you"],
                id: "sd_sep_ira_w_2_wages_tax_saving_options_third_saves_you",
                updatable: true
              },
              {
                title: "SEP Income",
                param: ["sd_sep_ira_based_on_sep_income_of"],
                id: "sd_sep_ira_based_on_sep_income_of",
                updatable: true
              },
              {
                title: "W-2 Wages",
                param: ["sd_sep_ira_increase_w_2_wages_to"],
                id: "sd_sep_ira_increase_w_2_wages_to",
                updatable: true
              }
            ]
          },
          {
            title: "14 Day Home Rental",
            id: "sd_fourteen_day_home_rental_enable",
            updatable: true,
            children: [
              {
                title: "Top 3 Widget Options",
                id: "sd_fourteen_day_home_rental_top_3_widget_options",
                updatable: true,
                children: [
                  {
                    title: "Adding",
                    id: "top_3_strategy_picks_fourteen_day_home_rental_adding",
                    updatable: true,
                    param: ["top_3_strategy_picks_fourteen_day_home_rental_adding"]
                  },
                  {
                    title: "Savings",
                    id: "top_3_strategy_picks_fourteen_day_home_rental_saves_you",
                    updatable: true,
                    param: ["top_3_strategy_picks_fourteen_day_home_rental_saves_you"]
                  }
                ]
              },
              {
                title: "Description",
                id: "sd_fourteen_day_home_rental_what_it_is_text",
                updatable: false
              },
              {
                title: "Description of Ratings",
                id: "sd_fourteen_day_home_rental_ratings_text",
                updatable: false
              },
              {
                title: "Option A Expense",
                param: ["sd_fourteen_day_home_rental_tax_saving_options_first_expense"],
                id: "sd_fourteen_day_home_rental_tax_saving_options_first_expense",
                updatable: true
              },
              {
                title: "Option A Saves You",
                param: ["sd_fourteen_day_home_rental_tax_saving_options_first_saves_you"],
                id: "sd_fourteen_day_home_rental_tax_saving_options_first_saves_you",
                updatable: true
              },
              {
                title: "Option B Expense",
                param: ["sd_fourteen_day_home_rental_tax_saving_options_second_expense"],
                id: "sd_fourteen_day_home_rental_tax_saving_options_second_expense",
                updatable: true
              },
              {
                title: "Option B Saves You",
                param: ["sd_fourteen_day_home_rental_tax_saving_options_second_saves_you"],
                id: "sd_fourteen_day_home_rental_tax_saving_options_second_saves_you",
                updatable: true
              },
              {
                title: "Option C Expense",
                param: ["sd_fourteen_day_home_rental_tax_saving_options_third_expense"],
                id: "sd_fourteen_day_home_rental_tax_saving_options_third_expense",
                updatable: true
              },
              {
                title: "Option C Saves You",
                param: ["sd_fourteen_day_home_rental_tax_saving_options_third_saves_you"],
                id: "sd_fourteen_day_home_rental_tax_saving_options_third_saves_you",
                updatable: true
              }
            ]
          },
          {
            title: "Employing Your Children",
            id: "sd_employing_your_children_enable",
            updatable: true,
            children: [
              {
                title: "Top 3 Widget Options",
                id: "sd_employing_your_children_top_3_widget_options",
                updatable: true,
                children: [
                  {
                    title: "Adding",
                    id: "top_3_strategy_picks_employing_your_children_adding",
                    updatable: true,
                    param: ["top_3_strategy_picks_employing_your_children_adding"]
                  },
                  {
                    title: "Savings",
                    id: "top_3_strategy_picks_employing_your_children_saves_you",
                    updatable: true,
                    param: ["top_3_strategy_picks_employing_your_children_saves_you"]
                  }
                ]
              },
              {
                title: "Description",
                id: "sd_employing_your_children_what_it_is_text",
                updatable: false
              },
              {
                title: "Description of Ratings",
                id: "sd_employing_your_children_ratings_text",
                updatable: false
              },
              {
                title: "Option A Expense",
                param: ["sd_employing_your_children_tax_saving_options_first_expense"],
                id: "sd_employing_your_children_tax_saving_options_first_expense",
                updatable: true
              },
              {
                title: "Option A Saves You",
                param: ["sd_employing_your_children_tax_saving_options_first_saves_you"],
                id: "sd_employing_your_children_tax_saving_options_first_saves_you",
                updatable: true
              },
              {
                title: "Option B Expense",
                param: ["sd_employing_your_children_tax_saving_options_second_expense"],
                id: "sd_employing_your_children_tax_saving_options_second_expense",
                updatable: true
              },
              {
                title: "Option B Saves You",
                param: ["sd_employing_your_children_tax_saving_options_second_saves_you"],
                id: "sd_employing_your_children_tax_saving_options_second_saves_you",
                updatable: true
              },
              {
                title: "Option C Expense",
                param: ["sd_employing_your_children_tax_saving_options_third_expense"],
                id: "sd_employing_your_children_tax_saving_options_third_expense",
                updatable: true
              },
              {
                title: "Option C Saves You",
                param: ["sd_employing_your_children_tax_saving_options_third_saves_you"],
                id: "sd_employing_your_children_tax_saving_options_third_saves_you",
                updatable: true
              }
            ]
          },
          {
            title: "401K",
            id: "sd_plan_401k_enable",
            updatable: true,
            children: [
              {
                title: "Top 3 Widget Options",
                id: "sd_plan_401k_top_3_widget_options",
                updatable: true,
                children: [
                  {
                    title: "Contributions",
                    id: "top_3_strategy_picks_plan_401k_contributions",
                    updatable: true,
                    param: ["top_3_strategy_picks_plan_401k_contributions"]
                  },
                  {
                    title: "Savings",
                    id: "top_3_strategy_picks_plan_401k_saves_you",
                    updatable: true,
                    param: ["top_3_strategy_picks_plan_401k_saves_you"]
                  }
                ]
              },
              {
                title: "Description",
                id: "sd_plan_401k_what_it_is_text",
                updatable: false
              },
              {
                title: "Description of Ratings",
                id: "sd_plan_401k_ratings_text",
                updatable: false
              },
              {
                title: "Option A Expense",
                param: ["sd_plan_401k_tax_saving_options_first_contribute"],
                id: "sd_plan_401k_tax_saving_options_first_contribute",
                updatable: true
              },
              {
                title: "Option A Saves You",
                param: ["sd_plan_401k_tax_saving_options_first_saves_you"],
                id: "sd_plan_401k_tax_saving_options_first_saves_you",
                updatable: true
              },
              {
                title: "Option B Expense",
                param: ["sd_plan_401k_tax_saving_options_second_contribute"],
                id: "sd_plan_401k_tax_saving_options_second_contribute",
                updatable: true
              },
              {
                title: "Option B Saves You",
                param: ["sd_plan_401k_tax_saving_options_second_saves_you"],
                id: "sd_plan_401k_tax_saving_options_second_saves_you",
                updatable: true
              },
              {
                title: "Option C Expense",
                param: ["sd_plan_401k_tax_saving_options_third_contribute"],
                id: "sd_plan_401k_tax_saving_options_third_contribute",
                updatable: true
              },
              {
                title: "Option C Saves You",
                param: ["sd_plan_401k_tax_saving_options_third_saves_you"],
                id: "sd_plan_401k_tax_saving_options_third_saves_you",
                updatable: true
              },
              {
                title: "Taxpayer Wages",
                param: ["sd_plan_401k_taxpayer_wages"],
                id: "sd_plan_401k_taxpayer_wages",
                updatable: true
              },
              {
                title: "Spouse Wages",
                param: ["sd_plan_401k_spouse_wages"],
                id: "sd_plan_401k_spouse_wages",
                updatable: true
              },
              {
                title: "Taxpayer 401k Contributions",
                param: ["sd_plan_401k_current_taxpayer_401k_contributions"],
                id: "sd_plan_401k_current_taxpayer_401k_contributions",
                updatable: true
              },
              {
                title: "Spouse 401k Contributions",
                param: ["sd_plan_401k_current_spouse_401k_contributions"],
                id: "sd_plan_401k_current_spouse_401k_contributions",
                updatable: true
              }
            ]
          },
          {
            title: "Auto Lease",
            id: "sd_auto_lease_enable",
            updatable: true,
            children: [
              {
                title: "Description",
                id: "sd_auto_lease_what_it_is_text",
                updatable: false
              },
              {
                title: "Lease Pros",
                id: "sd_auto_lease_what_it_is_lease_pros",
                updatable: false
              },
              {
                title: "Lease Cons",
                id: "sd_auto_lease_what_it_is_lease_cons",
                updatable: false
              },
              {
                title: "Purchase Pros",
                id: "sd_auto_lease_what_it_is_purchase_pros",
                updatable: false
              },
              {
                title: "Purchase Cons",
                id: "sd_auto_lease_what_it_is_purchase_cons",
                updatable: false
              },
              {
                title: "Business Use",
                param: ["sd_auto_lease_business_use"],
                id: "sd_auto_lease_business_use",
                updatable: true
              },
              {
                title: "Purchase Price",
                param: ["sd_auto_lease_purchase_price"],
                id: "sd_auto_lease_purchase_price",
                updatable: true
              },
              {
                title: "Vehicle Weight",
                param: ["sd_auto_lease_vehicle_weight"],
                id: "sd_auto_lease_vehicle_weight",
                updatable: true
              },
              {
                title: "Lease Payment",
                param: ["sd_auto_lease_lease_payment"],
                id: "sd_auto_lease_lease_payment",
                updatable: true
              },
              {
                title: "Lease Term",
                param: ["sd_auto_lease_lease_term"],
                id: "sd_auto_lease_lease_term",
                updatable: true
              },
              {
                title: "Purchase Depreciation 1st Year",
                param: ["sd_auto_lease_purchase_depreciation_first_year"],
                id: "sd_auto_lease_purchase_depreciation_first_year",
                updatable: true
              },
              {
                title: "Purchase Depreciation 2nd Year",
                param: ["sd_auto_lease_purchase_depreciation_second_year"],
                id: "sd_auto_lease_purchase_depreciation_second_year",
                updatable: true
              },
              {
                title: "Purchase Depreciation 3rd Year",
                param: ["sd_auto_lease_purchase_depreciation_third_year"],
                id: "sd_auto_lease_purchase_depreciation_third_year",
                updatable: true
              },
              {
                title: "Purchase Depreciation 4th Year",
                param: ["sd_auto_lease_purchase_depreciation_fourth_year"],
                id: "sd_auto_lease_purchase_depreciation_fourth_year",
                updatable: true
              },
              {
                title: "Purchase Depreciation 5th Year",
                param: ["sd_auto_lease_purchase_depreciation_fifth_year"],
                id: "sd_auto_lease_purchase_depreciation_fifth_year",
                updatable: true
              },
              {
                title: "Purchase Tax Savings 1st Year",
                param: ["sd_auto_lease_purchase_tax_savings_first_year"],
                id: "sd_auto_lease_purchase_tax_savings_first_year",
                updatable: true
              },
              {
                title: "Purchase Tax Savings 2nd Year",
                param: ["sd_auto_lease_purchase_tax_savings_second_year"],
                id: "sd_auto_lease_purchase_tax_savings_second_year",
                updatable: true
              },
              {
                title: "Purchase Tax Savings 3rd Year",
                param: ["sd_auto_lease_purchase_tax_savings_third_year"],
                id: "sd_auto_lease_purchase_tax_savings_third_year",
                updatable: true
              },
              {
                title: "Purchase Tax Savings 4th Year",
                param: ["sd_auto_lease_purchase_tax_savings_fourth_year"],
                id: "sd_auto_lease_purchase_tax_savings_fourth_year",
                updatable: true
              },
              {
                title: "Purchase Tax Savings 5th Year",
                param: ["sd_auto_lease_purchase_tax_savings_fifth_year"],
                id: "sd_auto_lease_purchase_tax_savings_fifth_year",
                updatable: true
              },
              {
                title: "Lease Tax Savings 1st Year",
                param: ["sd_auto_lease_lease_tax_savings_first_year"],
                id: "sd_auto_lease_lease_tax_savings_first_year",
                updatable: true
              },
              {
                title: "Lease Tax Savings 2nd Year",
                param: ["sd_auto_lease_lease_tax_savings_second_year"],
                id: "sd_auto_lease_lease_tax_savings_second_year",
                updatable: true
              },
              {
                title: "Lease Tax Savings 3rd Year",
                param: ["sd_auto_lease_lease_tax_savings_third_year"],
                id: "sd_auto_lease_lease_tax_savings_third_year",
                updatable: true
              },
              {
                title: "Lease Tax Savings 4th Year",
                param: ["sd_auto_lease_lease_tax_savings_fourth_year"],
                id: "sd_auto_lease_lease_tax_savings_fourth_year",
                updatable: true
              },
              {
                title: "Lease Tax Savings 5th Year",
                param: ["sd_auto_lease_lease_tax_savings_fifth_year"],
                id: "sd_auto_lease_lease_tax_savings_fifth_year",
                updatable: true
              },
              {
                title: "Lease Expense 1st Year",
                param: ["sd_auto_lease_lease_expense_first_year"],
                id: "sd_auto_lease_lease_expense_first_year",
                updatable: true
              },
              {
                title: "Lease Expense 2nd Year",
                param: ["sd_auto_lease_lease_expense_second_year"],
                id: "sd_auto_lease_lease_expense_second_year",
                updatable: true
              },
              {
                title: "Lease Expense 3rd Year",
                param: ["sd_auto_lease_lease_expense_third_year"],
                id: "sd_auto_lease_lease_expense_third_year",
                updatable: true
              },
              {
                title: "Lease Expense 4th Year",
                param: ["sd_auto_lease_lease_expense_fourth_year"],
                id: "sd_auto_lease_lease_expense_fourth_year",
                updatable: true
              },
              {
                title: "Lease Expense 5th Year",
                param: ["sd_auto_lease_lease_expense_fifth_year"],
                id: "sd_auto_lease_lease_expense_fifth_year",
                updatable: true
              }
            ]
          },
          {
            title: "Business Entity Planner",
            id: "sd_business_entity_planner_enable",
            updatable: true,
            children: [
              {
                title: "What it is",
                id: "sd_business_entity_planner_what_it_is_tree",
                updatable: false,
                children: [
                  {
                    title: "Description",
                    id: "sd_business_entity_planner_what_it_is_text",
                    updatable: false
                  },
                  ...map(BUSINESS_TYPES_CODES, function (business_type) {
                    return [
                      {
                        title: `${business_type.name} Description`,
                        id: `sd_business_entity_planner_what_it_is_${business_type.code}_description`,
                        updatable: false
                      },
                      {
                        title: `${business_type.name} Pros`,
                        id: `sd_business_entity_planner_what_it_is_${business_type.code}_pros`,
                        updatable: false
                      },
                      {
                        title: `${business_type.name} Cons`,
                        id: `sd_business_entity_planner_what_it_is_${business_type.code}_cons`,
                        updatable: false
                      },
                      {
                        title: `${business_type.name} Percent`,
                        id: `sd_business_entity_planner_what_it_is_${business_type.code}_percent`,
                        updatable: false
                      }
                    ];
                  }).flat()
                ]
              },
              {
                title: "Comparison",
                id: "sd_business_entity_planner_comparison_tree",
                updatable: true,
                children: [
                  {
                    title: "Description",
                    id: "sd_business_entity_planner_comparison_description",
                    updatable: false
                  },
                  ...map(ORDERS, function (order) {
                    return {
                      title: `${order.name} Company`,
                      id: `sd_business_entity_planner_${order.code}_company_tree`,
                      updatable: true,
                      children: [
                        {
                          title: "Name",
                          id: `sd_business_entity_planner_${order.code}_company_name`,
                          param: [`sd_business_entity_planner_${order.code}_company_name`],
                          updatable: true
                        },
                        {
                          title: "Current Type",
                          id: `sd_business_entity_planner_${order.code}_company_type_current`,
                          param: [`sd_business_entity_planner_${order.code}_company_type_current`],
                          updatable: true
                        },
                        {
                          title: "Recommended Type",
                          id: `sd_business_entity_planner_${order.code}_company_type_recommended`,
                          param: [`sd_business_entity_planner_${order.code}_company_type_recommended`],
                          updatable: true
                        },
                        ...map(BUSINESS_TYPES_CODES, function (business_type) {
                          return [
                            {
                              title: `${business_type.name} SE Tax`,
                              id: `sd_business_entity_planner_${order.code}_company_${business_type.code}_se_tax`,
                              param: [
                                `sd_business_entity_planner_${order.code}_company_${business_type.code}_se_tax`
                              ],
                              updatable: true
                            },
                            ...(business_type.code == "s_corp"
                              ? [
                                  {
                                    title: `${business_type.name} FICA Tax`,
                                    id: `sd_business_entity_planner_${order.code}_company_${business_type.code}_fica_tax`,
                                    param: [
                                      `sd_business_entity_planner_${order.code}_company_${business_type.code}_fica_tax`
                                    ],
                                    updatable: true
                                  }
                                ]
                              : []),
                            ...(business_type.code == "c_corp"
                              ? [
                                  {
                                    title: `${business_type.name} Income Tax (Corporate)`,
                                    id: `sd_business_entity_planner_${order.code}_company_${business_type.code}_income_tax_c`,
                                    param: [
                                      `sd_business_entity_planner_${order.code}_company_${business_type.code}_income_tax_c`
                                    ],
                                    updatable: true
                                  }
                                ]
                              : []),
                            {
                              title: `${business_type.name} Income Tax`,
                              id: `sd_business_entity_planner_${order.code}_company_${business_type.code}_income_tax`,
                              param: [
                                `sd_business_entity_planner_${order.code}_company_${business_type.code}_income_tax`
                              ],
                              updatable: true
                            },
                            {
                              title: `${business_type.name} State Tax`,
                              id: `sd_business_entity_planner_${order.code}_company_${business_type.code}_state_tax`,
                              param: [
                                `sd_business_entity_planner_${order.code}_company_${business_type.code}_state_tax`
                              ],
                              updatable: true
                            },
                            {
                              title: `${business_type.name} Total Tax`,
                              id: `sd_business_entity_planner_${order.code}_company_${business_type.code}_total_tax`,
                              param: [
                                `sd_business_entity_planner_${order.code}_company_${business_type.code}_total_tax`
                              ],
                              updatable: true
                            }
                          ];
                        }).flat(),
                        {
                          title: "Savings",
                          id: `sd_business_entity_planner_${order.code}_company_savings`,
                          param: [`sd_business_entity_planner_${order.code}_company_savings`],
                          updatable: true
                        }
                      ]
                    };
                  })
                ]
              },
              {
                title: "How to Сhange Your Business Stucture",
                id: "sd_business_entity_planner_how_to_change_business_tree",
                updatable: false,
                children: [
                  {
                    title: "Description",
                    id: "sd_business_entity_planner_how_to_change_business_stucture",
                    updatable: false
                  }
                ]
              },
              {
                title: "Top 3 Widget Options",
                id: "sd_business_entity_planner_top_3_widget_options",
                updatable: true,
                children: [
                  {
                    title: "Company Name",
                    id: "top_3_strategy_picks_business_entity_best_company_name",
                    updatable: true,
                    param: ["top_3_strategy_picks_business_entity_best_company_name"]
                  },
                  {
                    title: "Recommended Type",
                    id: "top_3_strategy_picks_business_entity_best_company_recommended",
                    updatable: true,
                    param: ["top_3_strategy_picks_business_entity_best_company_recommended"]
                  },
                  {
                    title: "Savings",
                    id: "top_3_strategy_picks_business_entity_best_company_saves_you",
                    updatable: true,
                    param: ["top_3_strategy_picks_business_entity_best_company_saves_you"]
                  },
                  {
                    title: "Other Companies Savings",
                    id: "top_3_strategy_picks_business_entity_other_companies_saves_you",
                    updatable: true,
                    param: ["top_3_strategy_picks_business_entity_other_companies_saves_you"]
                  }
                ]
              }
            ]
          },
          {
            title: "Traditional and Roth IRA",
            id: "sd_traditional_and_roth_ira_enable",
            updatable: true,
            children: [
              {
                title: "Top 3 Widget Options",
                id: "sd_traditional_ira_top_3_widget_options",
                updatable: true,
                children: [
                  {
                    title: "Traditional IRA Contributions",
                    id: "top_3_strategy_picks_traditional_ira_contributions",
                    updatable: true,
                    param: ["top_3_strategy_picks_traditional_ira_contributions"]
                  },
                  {
                    title: "Traditional IRA Savings",
                    id: "top_3_strategy_picks_traditional_ira_saves_you",
                    updatable: true,
                    param: ["top_3_strategy_picks_traditional_ira_saves_you"]
                  }
                ]
              },
              ...map(
                [
                  ["traditional_ira", "Traditional IRA"],
                  ["roth_ira", "Roth IRA"]
                ],
                ([iraType, iraTitle]) => {
                  return {
                    title: iraTitle,
                    id: `sd_${iraType}_tree`,
                    updatable: true,
                    children: [
                      {
                        title: "Description",
                        id: `sd_${iraType}_what_it_is_text`,
                        param: [`sd_${iraType}_what_it_is_text`],
                        updatable: false
                      },
                      {
                        title: "Planner Description",
                        id: `sd_${iraType}_planner_description`,
                        param: [`sd_${iraType}_planner_description`],
                        updatable: false
                      },
                      {
                        title: "Contributions Grow",
                        id: `sd_${iraType}_taxes_contributions_grow`,
                        param: [`sd_${iraType}_taxes_contributions_grow`],
                        updatable: false
                      },
                      {
                        title: "Tax-Deductibility",
                        id: `sd_${iraType}_taxes_tax_deductibility`,
                        param: [`sd_${iraType}_taxes_tax_deductibility`],
                        updatable: false
                      },
                      {
                        title: "Contributions Come From",
                        id: `sd_${iraType}_contributions_come_from`,
                        param: [`sd_${iraType}_contributions_come_from`],
                        updatable: false
                      },
                      {
                        title: "Max Contribution",
                        id: `sd_${iraType}_contributions_max_contribution`,
                        param: [`sd_${iraType}_contributions_max_contribution`],
                        updatable: false
                      },
                      {
                        title: "Penalties",
                        id: `sd_${iraType}_withdrawals_penalties`,
                        param: [`sd_${iraType}_withdrawals_penalties`],
                        updatable: false
                      },
                      {
                        title: "Mandatory Distributions",
                        id: `sd_${iraType}_withdrawals_mandatory_distributions`,
                        param: [`sd_${iraType}_withdrawals_mandatory_distributions`],
                        updatable: false
                      },
                      ...map(
                        [
                          ["taxpayer", "Taxpayer"],
                          ["spouse", "Spouse"]
                        ],
                        ([taxpayerType, taxpayerTitle]) => {
                          return [
                            {
                              title: `${taxpayerTitle} Wages`,
                              id: `sd_${iraType}_tax_saving_${taxpayerType}_wages`,
                              param: [`sd_${iraType}_tax_saving_${taxpayerType}_wages`],
                              updatable: true
                            },
                            {
                              title: `${taxpayerTitle} Current Contributions`,
                              id: `sd_${iraType}_tax_saving_${taxpayerType}_contributions`,
                              param: [`sd_${iraType}_tax_saving_${taxpayerType}_contributions`],
                              updatable: true
                            }
                          ];
                        }
                      ).flat(),
                      ...map(
                        [
                          ["first", "Option A"],
                          ["second", "Option B"],
                          ["third", "Option C"]
                        ],
                        ([order, orderTitle]) => {
                          return [
                            {
                              title: `${orderTitle} Contribute`,
                              id: `sd_${iraType}_tax_saving_options_${order}_contribute`,
                              param: [`sd_${iraType}_tax_saving_options_${order}_contribute`],
                              updatable: true
                            },
                            {
                              title: `${orderTitle} And Save`,
                              id: `sd_${iraType}_tax_saving_options_${order}_saves_you`,
                              param: [`sd_${iraType}_tax_saving_options_${order}_saves_you`],
                              updatable: true
                            }
                          ];
                        }
                      ).flat()
                    ]
                  };
                }
              )
            ]
          },
          {
            title: "Health Savings",
            id: "sd_health_savings_enable",
            updatable: true,
            children: [
              {
                title: "Top 3 Widget Options",
                id: "sd_health_savings_top_3_widget_options",
                updatable: true,
                children: [
                  {
                    title: "Health Savings Type",
                    id: `top_3_strategy_picks_health_savings_type`,
                    param: [`top_3_strategy_picks_health_savings_type`],
                    updatable: true
                  },
                  {
                    title: "Health Savings Contribution",
                    id: `top_3_strategy_picks_health_savings_adding`,
                    param: [`top_3_strategy_picks_health_savings_adding`],
                    updatable: true
                  },
                  {
                    title: "Health Savings Saves You",
                    id: `top_3_strategy_picks_health_savings_saves_you`,
                    param: [`top_3_strategy_picks_health_savings_saves_you`],
                    updatable: true
                  }
                ]
              },
              {
                title: "Description",
                id: `sd_health_savings_what_it_is_text`,
                param: [`sd_health_savings_what_it_is_text`],
                updatable: false
              },
              {
                title: "Notes",
                id: `sd_health_savings_description`,
                param: [`sd_health_savings_description`],
                updatable: false
              },
              ...map(
                [
                  ["previous", "Previous Tax Year"],
                  ["current", "Current Tax Year"]
                ],
                ([year, year_title]) => {
                  return {
                    title: `${year_title} Contribution Limits`,
                    id: `sd_health_savings_contribution_limits_${year}_tree`,
                    updatable: true,
                    children: [
                      {
                        title: year_title,
                        param: [`sd_health_savings_contribution_limits_${year}_tax_year`],
                        id: `sd_health_savings_contribution_limits_${year}_tax_year`,
                        updatable: true
                      },
                      ...map(
                        [
                          ["hsa", "Health Savings Account (HSA)"],
                          ["hra", "Health Reimbursement Agreement (HRA)"],
                          ["fsa", "Flexible Spending Account (FSA)"]
                        ],
                        ([type, typeTitle]) => {
                          return {
                            title: typeTitle,
                            id: `sd_health_savings_contribution_limits_${year}_tree_${type}_tree`,
                            updatable: true,
                            children: [
                              ...map(
                                [
                                  ["general", "General"],
                                  ["self", "Self Only"],
                                  ["family", "Family"]
                                ],
                                ([limit, limit_title]) => {
                                  return {
                                    title: limit_title,
                                    param: [`sd_health_savings_contribution_limits_${year}_${type}_${limit}`],
                                    id: `sd_health_savings_contribution_limits_${year}_${type}_${limit}`,
                                    updatable: true
                                  };
                                }
                              )
                            ]
                          };
                        }
                      )
                    ]
                  };
                }
              ),
              {
                title: "Tax Savings",
                id: `sd_health_savings_tax_saving_options_tree`,
                updatable: true,
                children: [
                  ...map(
                    [
                      ["contribute", "Contribute"],
                      ["hsa_saves_you", "Health Savings Account (HSA)"],
                      ["hra_saves_you", "Health Reimbursement Agreement (HRA)"],
                      ["fsa_saves_you", "Flexible Spending Account (FSA)"]
                    ],
                    ([type, typeTitle]) => {
                      return {
                        title: typeTitle,
                        id: `sd_health_savings_tax_saving_options_${type}_tree`,
                        updatable: true,
                        children: [
                          ...map(
                            [
                              ["first", "Option A"],
                              ["second", "Option B"],
                              ["third", "Option C"]
                            ],
                            ([option, option_title]) => {
                              return {
                                title: option_title,
                                param: [`sd_health_savings_tax_saving_options_${option}_${type}`],
                                id: `sd_health_savings_tax_saving_options_${option}_${type}`,
                                updatable: true
                              };
                            }
                          )
                        ]
                      };
                    }
                  )
                ]
              }
            ]
          },
          {
            title: "Defined Benefit",
            id: "sd_defined_benefit_enable",
            updatable: true,
            children: [
              {
                title: "Top 3 Widget Options",
                id: "sd_defined_benefit_top_3_widget_options",
                updatable: true,
                children: [
                  {
                    title: "Adding",
                    id: "top_3_strategy_picks_defined_benefit_adding",
                    updatable: true,
                    param: ["top_3_strategy_picks_defined_benefit_adding"]
                  },
                  {
                    title: "Savings",
                    id: "top_3_strategy_picks_defined_benefit_saves_you",
                    updatable: true,
                    param: ["top_3_strategy_picks_defined_benefit_saves_you"]
                  }
                ]
              },
              {
                title: "Description",
                id: "sd_defined_benefit_what_it_is_text",
                updatable: false
              },
              {
                title: "First Item",
                id: "sd_defined_benefit_what_it_is_first_item",
                updatable: false
              },
              {
                title: "Second Item",
                id: "sd_defined_benefit_what_it_is_second_item",
                updatable: false
              },
              {
                title: "Third Item",
                id: "sd_defined_benefit_what_it_is_third_item",
                updatable: false
              },
              {
                title: "Fourth Item",
                id: "sd_defined_benefit_what_it_is_fourth_item",
                updatable: false
              },
              {
                title: "Description of Ratings",
                id: "sd_defined_benefit_ratings_text",
                updatable: false
              },
              {
                title: "Current Taxable Income",
                param: ["sd_defined_benefit_current_taxable_income"],
                id: "sd_defined_benefit_current_taxable_income",
                updatable: true
              },
              {
                title: "Current Pension Contributions",
                param: ["sd_defined_benefit_current_pension_contributions"],
                id: "sd_defined_benefit_current_pension_contributions",
                updatable: true
              },
              {
                title: "Option A Expense",
                param: ["sd_defined_benefit_tax_saving_options_first_expense"],
                id: "sd_defined_benefit_tax_saving_options_first_expense",
                updatable: true
              },
              {
                title: "Option A Saves You",
                param: ["sd_defined_benefit_tax_saving_options_first_saves_you"],
                id: "sd_defined_benefit_tax_saving_options_first_saves_you",
                updatable: true
              },
              {
                title: "Option B Expense",
                param: ["sd_defined_benefit_tax_saving_options_second_expense"],
                id: "sd_defined_benefit_tax_saving_options_second_expense",
                updatable: true
              },
              {
                title: "Option B Saves You",
                param: ["sd_defined_benefit_tax_saving_options_second_saves_you"],
                id: "sd_defined_benefit_tax_saving_options_second_saves_you",
                updatable: true
              },
              {
                title: "Option C Expense",
                param: ["sd_defined_benefit_tax_saving_options_third_expense"],
                id: "sd_defined_benefit_tax_saving_options_third_expense",
                updatable: true
              },
              {
                title: "Option C Saves You",
                param: ["sd_defined_benefit_tax_saving_options_third_saves_you"],
                id: "sd_defined_benefit_tax_saving_options_third_saves_you",
                updatable: true
              }
            ]
          },
          {
            title: "Estimated Tax Payments",
            id: "sd_etp_enable",
            updatable: true,
            children: [
              {
                title: "Description",
                id: "sd_etp_what_it_is_text",
                updatable: false
              },
              {
                title: "First Item",
                id: "sd_etp_what_it_is_first_item",
                updatable: false
              },
              {
                title: "Second Item",
                id: "sd_etp_what_it_is_second_item",
                updatable: false
              },
              {
                title: "Third Item",
                id: "sd_etp_what_it_is_third_item",
                updatable: false
              },
              {
                title: "Previous Tax Year",
                param: ["sd_etp_previous_tax_year"],
                id: "sd_etp_previous_tax_year",
                updatable: true
              },
              {
                title: "Current Tax Year",
                param: ["sd_etp_current_tax_year"],
                id: "sd_etp_current_tax_year",
                updatable: true
              },
              ...map(
                [
                  ["federal", "Federal"],
                  ["state", "State"]
                ],
                ([type, typeTitle]) => {
                  return {
                    title: typeTitle,
                    id: `sd_etp_${type}_tree`,
                    updatable: true,
                    children: [
                      {
                        title: "Projected Tax Due",
                        param: [`sd_etp_projected_${type}_tax_due`],
                        id: `sd_etp_projected_${type}_tax_due`,
                        updatable: true
                      },
                      {
                        title: "Tax",
                        param: [`sd_etp_previous_year_${type}_tax`],
                        id: `sd_etp_previous_year_${type}_tax`,
                        updatable: true
                      },
                      {
                        title: "AGI",
                        param: [`sd_etp_${type}_agi`],
                        id: `sd_etp_${type}_agi`,
                        updatable: true
                      },
                      {
                        title: "Safe Harbor",
                        param: [`sd_etp_${type}_safe_harbor`],
                        id: `sd_etp_${type}_safe_harbor`,
                        updatable: true
                      }
                    ]
                  };
                }
              ),
              ...map(
                [
                  ["first", "1st Quarter"],
                  ["second", "2nd Quarter"],
                  ["third", "3rd Quarter"],
                  ["fourth", "4th Quarter"],
                  ["total", "Annual Totals"]
                ],
                ([order, orderTitle]) => {
                  return {
                    title: orderTitle,
                    id: `sd_etp_quarters_${order}_tree`,
                    updatable: true,
                    children: [
                      ...(order != "total"
                        ? [
                            {
                              title: "Due Date",
                              id: `sd_etp_quarters_${order}_due_date`,
                              param: [`sd_etp_quarters_${order}_due_date`],
                              updatable: true
                            }
                          ]
                        : []),
                      ...map(
                        [
                          ["federal", "Federal"],
                          ["state", "State"]
                        ],
                        ([type, typeTitle]) => {
                          return {
                            title: typeTitle,
                            id: `sd_etp_quarters_${order}_tree_${type}_tree`,
                            updatable: true,
                            children: [
                              {
                                title: "Required Payment",
                                id: `sd_etp_quarters_${order}_required_${type}_payment`,
                                param: [`sd_etp_quarters_${order}_required_${type}_payment`],
                                updatable: true
                              },
                              {
                                title: "Projected Payment",
                                id: `sd_etp_quarters_${order}_projected_${type}_payment`,
                                param: [`sd_etp_quarters_${order}_projected_${type}_payment`],
                                updatable: true
                              },
                              {
                                title: "Payment Made",
                                id: `sd_etp_quarters_${order}_${type}_payment_made`,
                                param: [`sd_etp_quarters_${order}_${type}_payment_made`],
                                updatable: true
                              },
                              {
                                title: "Cumulative Shortfall",
                                id: `sd_etp_quarters_${order}_${type}_cumulative_shortfall`,
                                param: [`sd_etp_quarters_${order}_${type}_cumulative_shortfall`],
                                updatable: true
                              }
                            ]
                          };
                        }
                      )
                    ]
                  };
                }
              )
            ]
          }
        ]
      },
      {
        title: "Tips And Advice For You",
        id: "tips_and_advices_for_you_enable",
        updatable: false,
        children: [
          {
            title: "Description",
            id: "tips_and_advices_for_you_text",
            updatable: false
          },
          {
            title: "Font Size",
            id: "tips_and_advices_for_you_font_size",
            updatable: false
          }
        ]
      },
      {
        title: "Important Tax Return Due Dates",
        id: "tax_return_due_dates_enable",
        updatable: true,
        children: [
          {
            title: "Remember",
            id: `tax_return_due_dates_remember_text`,
            param: [`tax_return_due_dates_remember_text`],
            updatable: false
          },
          ...map(Object.entries(TAX_RETURN_DUE_DATE_TYPES), ([tag, label]) => {
            return [
              {
                title: `${label}`,
                id: `tax_return_due_dates_${tag}`,
                param: [`tax_return_due_dates_${tag}`],
                updatable: true
              }
            ];
          }).flat()
        ]
      },
      {
        title: "Your Demographics",
        id: "your_demographics_enable",
        updatable: true,
        children: [
          {
            title: "Personal Data",
            id: "your_demographics_personal_data",
            updatable: true,
            children: [
              {
                title: "Name",
                id: "your_demographics_personal_data_name",
                param: ["your_demographics_personal_data_name"],
                updatable: true
              },
              {
                title: "Number Of Dependents",
                id: "your_demographics_personal_data_number_of_dependents",
                param: ["your_demographics_personal_data_number_of_dependents"],
                updatable: true
              },
              {
                title: "State Of Residency",
                id: "your_demographics_personal_data_state_of_residency",
                param: ["your_demographics_personal_data_state_of_residency"],
                updatable: true
              },
              {
                title: "Filing Status",
                id: "filing_status",
                param: ["filing_status"],
                updatable: true
              },
              {
                title: "Marginal Tax Rate",
                id: "your_demographics_personal_data_tax_brackets",
                param: ["your_demographics_personal_data_tax_brackets"],
                updatable: true
              },
              {
                title: "Extra Field Name",
                id: "your_demographics_personal_data_extra_field_name",
                param: ["your_demographics_personal_data_extra_field_name"],
                updatable: false
              },
              {
                title: "Extra Field",
                id: "your_demographics_personal_data_extra_field",
                param: ["your_demographics_personal_data_extra_field"],
                updatable: false
              }
            ]
          },
          {
            title: "First Business Data",
            id: "your_demographics_business_data_first_enable",
            updatable: true,
            children: [
              {
                title: "Name",
                id: "your_demographics_business_data_first_business_name",
                param: ["your_demographics_business_data_first_business_name"],
                updatable: true
              },
              {
                title: "Type",
                id: "your_demographics_business_data_first_business_entity_type",
                param: ["your_demographics_business_data_first_business_entity_type"],
                updatable: true
              },
              {
                title: "Ownership Share",
                id: "your_demographics_business_data_first_ownership_share",
                param: ["your_demographics_business_data_first_ownership_share"],
                updatable: true
              },
              {
                title: "Start Date",
                id: "your_demographics_business_data_first_start_date",
                param: ["your_demographics_business_data_first_start_date"],
                updatable: true
              }
            ]
          },
          {
            title: "Second Business Data",
            id: "your_demographics_business_data_second_enable",
            updatable: true,
            children: [
              {
                title: "Name",
                id: "your_demographics_business_data_second_business_name",
                param: ["your_demographics_business_data_second_business_name"],
                updatable: true
              },
              {
                title: "Type",
                id: "your_demographics_business_data_second_business_entity_type",
                param: ["your_demographics_business_data_second_business_entity_type"],
                updatable: true
              },
              {
                title: "Ownership Share",
                id: "your_demographics_business_data_second_ownership_share",
                param: ["your_demographics_business_data_second_ownership_share"],
                updatable: true
              },
              {
                title: "Start Date",
                id: "your_demographics_business_data_second_start_date",
                param: ["your_demographics_business_data_second_start_date"],
                updatable: true
              }
            ]
          },
          {
            title: "Third Business Data",
            id: "your_demographics_business_data_third_enable",
            updatable: true,
            children: [
              {
                title: "Name",
                id: "your_demographics_business_data_third_business_name",
                param: ["your_demographics_business_data_third_business_name"],
                updatable: true
              },
              {
                title: "Type",
                id: "your_demographics_business_data_third_business_entity_type",
                param: ["your_demographics_business_data_third_business_entity_type"],
                updatable: true
              },
              {
                title: "Ownership Share",
                id: "your_demographics_business_data_third_ownership_share",
                param: ["your_demographics_business_data_third_ownership_share"],
                updatable: true
              },
              {
                title: "Start Date",
                id: "your_demographics_business_data_third_start_date",
                param: ["your_demographics_business_data_third_start_date"],
                updatable: true
              }
            ]
          },
          {
            title: "Tax Info",
            id: "tax_info",
            updatable: true,
            children: [
              {
                title: "Federal Tax Due",
                id: "your_demographics_taxes_federal_tax_due",
                param: ["your_demographics_taxes_federal_tax_due"],
                updatable: true
              },
              {
                title: "Payments And Credits",
                id: "your_demographics_taxes_payments_and_credits",
                param: ["your_demographics_taxes_payments_and_credits"],
                updatable: true
              },
              {
                title: "Remaining Amount Due",
                id: "your_demographics_taxes_remaining_amount_due",
                param: ["your_demographics_taxes_remaining_amount_due"],
                updatable: true
              },
              {
                title: "Business State Tax Due",
                id: "your_demographics_taxes_business_state_tax_due",
                param: ["your_demographics_taxes_business_state_tax_due"],
                updatable: true
              },
              {
                title: "Business Payments And Credits",
                id: "your_demographics_taxes_business_payments_and_credits",
                param: ["your_demographics_taxes_business_payments_and_credits"],
                updatable: true
              },
              {
                title: "Business Remaining Amount Due",
                id: "your_demographics_taxes_business_remaining_amount_due",
                param: ["your_demographics_taxes_business_remaining_amount_due"],
                updatable: true
              },
              {
                title: "Next Lower Level Tax Bracket Income Level",
                id: "your_demographics_next_lower_tax_bracket_income_level",
                param: ["your_demographics_next_lower_tax_bracket_income_level"],
                updatable: true
              },
              {
                title: "Lower Taxable Income By",
                id: "your_demographics_lower_taxable_income_by",
                param: ["your_demographics_lower_taxable_income_by"],
                updatable: true
              },
              {
                title: "Federal Tax Savings If Filed Reverse Type: Joint or Separate",
                id: "your_demographics_married_and_separate_fed_tax_savings",
                param: ["your_demographics_married_and_separate_fed_tax_savings"],
                updatable: true
              },
              {
                title: "Federal Tax Savings Because of Current S-Corp Companies",
                id: "your_demographics_being_s_corp_fed_tax_has_saved",
                param: ["your_demographics_being_s_corp_fed_tax_has_saved"],
                updatable: true
              },
              {
                title: "Federal Tax Savings If Switch the Rest of the Companies to S-Corp",
                id: "your_demographics_being_s_corp_fed_tax_can_save",
                param: ["your_demographics_being_s_corp_fed_tax_can_save"],
                updatable: true
              }
            ]
          }
        ]
      }
    ]
  }
];

export const specificationTree = writable(defaultTree);

export const flatSpecTree = derived(specificationTree, ($specificationTree, set) => {
  set(fromPairs(fmapConcat((item) => [[item.id, item]], $specificationTree)));
});

export function updateTitles(idTitleNodes) {
  return function (item) {
    const companyTitle = idTitleNodes[item.id];

    if (companyTitle) {
      return { ...item, title: companyTitle };
    } else {
      return item;
    }
  };
}

export function updateSpecTreeNodes(func, tree) {
  return tree.map((node) => {
    const updatedItem = func(omit(node, "children"));
    const children = node.children;

    if (children) {
      return { ...updatedItem, children: updateSpecTreeNodes(func, children) };
    } else {
      return updatedItem;
    }
  });
}
