<script>
  import { onMount } from "svelte";
  import _ from "underscore/underscore";
  import ClientPageHeaderContainer from "./ClientPageHeaderContainer.svelte";
  import CredentialsChangerContainer from "./CredentialsChangerContainer.svelte";
  import ClientAdditionsContainer from "./ClientAdditionsContainer.svelte";
  import ClientSettingsContainer from "./ClientSettingsContainer.svelte";
  import ClientPhoneNumber from "./ClientPhoneNumber.svelte";
  import ClientCompaniesContainer from "./ClientCompaniesContainer.svelte";

  export let updateCredentialsSrc;
  export let updateAdditionsSrc;
  export let updateProjectionSettingsSrc;
  export let removePhoneNumberSrc;
  export let summaryInfoSrc;
  export let initial;
  export let clientCredentials;
  export let clientSettings;
  export let clientAdditions;
  export let calculationGhostInUrl;
  export let clientPortalGhostInUrl;
  export let companyConnectUrlBase;
  export let companyDisconnectUrlBase;
  export let csrfToken;
  export let clientSrc;

  let summaryInfo = {
    tax_savings_potential: null
  };
  let server = initial;

  const fetchSummaryInfo = (function () {
    let _request = null;
    let sendRequest = _.debounce(() => {
      _request = jQuery
        .get(summaryInfoSrc, (response) => {
          summaryInfo = response;
        })
        .fail(() => {
          _request.abort();
        });
    }, 1000);

    return function () {
      if (_request) _request.abort();
      return sendRequest();
    };
  })();

  onMount(() => {
    if (server.has_linked_company) fetchSummaryInfo();
  });
</script>

<div>
  <ClientPageHeaderContainer
    {summaryInfo}
    hasLinkedCompany={server.has_linked_company}
    {calculationGhostInUrl}
    {clientPortalGhostInUrl}
  />
  <section id="main-content">
    <div class="row">
      <div class="col-12 col-lg-6">
        <CredentialsChangerContainer {clientCredentials} {updateCredentialsSrc} {csrfToken} />
        <ClientAdditionsContainer {clientAdditions} {updateAdditionsSrc} {csrfToken} />
        <ClientPhoneNumber {removePhoneNumberSrc} {csrfToken} hasPhoneNumber={server.has_phone_number} />
      </div>
      <div class="col-12 col-lg-6">
        <ClientSettingsContainer {clientSettings} {updateProjectionSettingsSrc} {csrfToken} />
        <ClientCompaniesContainer
          bind:server
          companies={server.companies}
          {clientSrc}
          {companyConnectUrlBase}
          {companyDisconnectUrlBase}
        />
      </div>
    </div>
  </section>
</div>
