<script>
  import { reportStore } from "../stores/reportStore";
  import { specificationTree, updateSpecTreeNodes, updateTitles } from "../stores/specificationTree";
  import CurrencyInputField from "../inputs/CurrencyInput.svelte";
  import TextInputField from "../inputs/TextInput.svelte";
  import NumberInputField from "../inputs/NumberInput.svelte";
  import DatePicker from "../inputs/DatePicker.svelte";
  import SelectInputField from "../inputs/SelectInput.svelte";
  import Dropdown from "../Dropdown.svelte";
  import { FILING_TYPES, US_STATES, BUSINESS_TYPES } from "utils/business_objects";
  import { searchMatches } from "../stores/searchStore";

  export let memoizedReport;
  export let refreshReportParams;

  $: firstCompanyName = memoizedReport.your_demographics_business_data_first_business_name || "First";
  $: secondCompanyName = memoizedReport.your_demographics_business_data_second_business_name || "Second";
  $: thirdCompanyName = memoizedReport.your_demographics_business_data_third_business_name || "Third";

  $: companyIdTitles = {
    your_demographics_business_data_first_enable: `${firstCompanyName} Business Data`,
    your_demographics_business_data_second_enable: `${secondCompanyName} Business Data`,
    your_demographics_business_data_third_enable: `${thirdCompanyName} Business Data`
  };

  $: if ($specificationTree) {
    specificationTree.set(updateSpecTreeNodes(updateTitles(companyIdTitles), $specificationTree));
  }
</script>

<template lang="pug">
  Dropdown(
    {refreshReportParams},
    specTreeNodeId="your_demographics_enable",
    bind:checked="{$reportStore.your_demographics_enable}",
    hidden="{$searchMatches.isHidden('Your Demographics')}",
    scrollToId="pdfYourDemographics"
  )
    Dropdown(
      {refreshReportParams},
      specTreeNodeId="your_demographics_personal_data",
      classNames="report-suboption",
      hidden="{$searchMatches.isHidden('Personal Data')}"
    )
      TextInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_personal_data_name",
        bind:value="{$reportStore.your_demographics_personal_data_name}",
        hidden="{$searchMatches.isHidden('Name')}"
      )
      NumberInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_personal_data_number_of_dependents",
        bind:value="{$reportStore.your_demographics_personal_data_number_of_dependents}",
        hidden="{$searchMatches.isHidden('Number Of Dependents')}"
      )
      SelectInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_personal_data_state_of_residency",
        bind:value="{$reportStore.your_demographics_personal_data_state_of_residency}",
        options="{US_STATES}",
        hidden="{$searchMatches.isHidden('State Of Residency')}"
      )
      SelectInputField(
        {refreshReportParams},
        specTreeNodeId="filing_status",
        bind:value="{$reportStore.filing_status}",
        options="{FILING_TYPES}",
        hidden="{$searchMatches.isHidden('Filing Status')}"
      )
      NumberInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_personal_data_tax_brackets",
        bind:value="{$reportStore.your_demographics_personal_data_tax_brackets}",
        hidden="{$searchMatches.isHidden('Marginal Tax Rate')}"
      )
      TextInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_personal_data_extra_field_name",
        bind:value="{$reportStore.your_demographics_personal_data_extra_field_name}",
        hidden="{$searchMatches.isHidden('Extra Field Name')}"
      )
      TextInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_personal_data_extra_field",
        bind:value="{$reportStore.your_demographics_personal_data_extra_field}",
        hidden="{$searchMatches.isHidden('Extra Field')}"
      )
    Dropdown(
      {refreshReportParams},
      specTreeNodeId="your_demographics_business_data_first_enable",
      bind:checked="{$reportStore.your_demographics_business_data_first_enable}",
      classNames="report-suboption",
      hidden="{$searchMatches.isHidden(`${companyIdTitles.your_demographics_business_data_first_enable}`)}"
    )
      TextInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_business_data_first_business_name",
        bind:value="{$reportStore.your_demographics_business_data_first_business_name}",
        hidden="{$searchMatches.isHidden('Name')}"
      )
      SelectInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_business_data_first_business_entity_type",
        bind:value="{$reportStore.your_demographics_business_data_first_business_entity_type}",
        options="{BUSINESS_TYPES}",
        hidden="{$searchMatches.isHidden('Type')}"
      )
      NumberInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_business_data_first_ownership_share",
        bind:value="{$reportStore.your_demographics_business_data_first_ownership_share}",
        hidden="{$searchMatches.isHidden('Ownership Share')}"
      )
      DatePicker(
        {refreshReportParams},
        specTreeNodeId="your_demographics_business_data_first_start_date",
        bind:value="{$reportStore.your_demographics_business_data_first_start_date}",
        hidden="{$searchMatches.isHidden('Start Date')}"
      )
    Dropdown(
      {refreshReportParams},
      specTreeNodeId="your_demographics_business_data_second_enable",
      bind:checked="{$reportStore.your_demographics_business_data_second_enable}",
      classNames="report-suboption",
      hidden="{$searchMatches.isHidden(`${companyIdTitles.your_demographics_business_data_second_enable}`)}"
    )
      TextInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_business_data_second_business_name",
        bind:value="{$reportStore.your_demographics_business_data_second_business_name}",
        hidden="{$searchMatches.isHidden('Name')}"
      )
      SelectInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_business_data_second_business_entity_type",
        bind:value="{$reportStore.your_demographics_business_data_second_business_entity_type}",
        options="{BUSINESS_TYPES}",
        hidden="{$searchMatches.isHidden('Type')}"
      )
      NumberInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_business_data_second_ownership_share",
        bind:value="{$reportStore.your_demographics_business_data_second_ownership_share}",
        hidden="{$searchMatches.isHidden('Ownership Share')}"
      )
      DatePicker(
        {refreshReportParams},
        specTreeNodeId="your_demographics_business_data_second_start_date",
        bind:value="{$reportStore.your_demographics_business_data_second_start_date}",
        hidden="{$searchMatches.isHidden('Start Date')}"
      )
    Dropdown(
      {refreshReportParams},
      specTreeNodeId="your_demographics_business_data_third_enable",
      bind:checked="{$reportStore.your_demographics_business_data_third_enable}",
      classNames="report-suboption",
      hidden="{$searchMatches.isHidden(`${companyIdTitles.your_demographics_business_data_third_enable}`)}"
    )
      TextInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_business_data_third_business_name",
        bind:value="{$reportStore.your_demographics_business_data_third_business_name}",
        hidden="{$searchMatches.isHidden('Name')}"
      )
      SelectInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_business_data_third_business_entity_type",
        bind:value="{$reportStore.your_demographics_business_data_third_business_entity_type}",
        options="{BUSINESS_TYPES}",
        hidden="{$searchMatches.isHidden('Type')}"
      )
      NumberInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_business_data_third_ownership_share",
        bind:value="{$reportStore.your_demographics_business_data_third_ownership_share}",
        hidden="{$searchMatches.isHidden('Ownership Share')}"
      )
      DatePicker(
        {refreshReportParams},
        specTreeNodeId="your_demographics_business_data_third_start_date",
        bind:value="{$reportStore.your_demographics_business_data_third_start_date}",
        hidden="{$searchMatches.isHidden('Start Date')}"
      )
    Dropdown(
      {refreshReportParams},
      specTreeNodeId="tax_info",
      classNames="report-suboption",
      hidden="{$searchMatches.isHidden('Tax Info')}"
    )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_taxes_federal_tax_due",
        bind:value="{$reportStore.your_demographics_taxes_federal_tax_due}",
        hidden="{$searchMatches.isHidden('Federal Tax Due')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_taxes_payments_and_credits",
        bind:value="{$reportStore.your_demographics_taxes_payments_and_credits}",
        hidden="{$searchMatches.isHidden('Payments And Credits')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_taxes_remaining_amount_due",
        bind:value="{$reportStore.your_demographics_taxes_remaining_amount_due}",
        hidden="{$searchMatches.isHidden('Remaining Amount Due')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_taxes_business_state_tax_due",
        bind:value="{$reportStore.your_demographics_taxes_business_state_tax_due}",
        hidden="{$searchMatches.isHidden('Business State Tax Due')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_taxes_business_payments_and_credits",
        bind:value="{$reportStore.your_demographics_taxes_business_payments_and_credits}",
        hidden="{$searchMatches.isHidden('Business Payments And Credits')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_taxes_business_remaining_amount_due",
        bind:value="{$reportStore.your_demographics_taxes_business_remaining_amount_due}",
        hidden="{$searchMatches.isHidden('Business Remaining Amount Due')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_next_lower_tax_bracket_income_level",
        bind:value="{$reportStore.your_demographics_next_lower_tax_bracket_income_level}",
        hidden="{$searchMatches.isHidden('Next Lower Level Tax Bracket Income Level')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_lower_taxable_income_by",
        bind:value="{$reportStore.your_demographics_lower_taxable_income_by}",
        hidden="{$searchMatches.isHidden('Lower Taxable Income By')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_married_and_separate_fed_tax_savings",
        bind:value="{$reportStore.your_demographics_married_and_separate_fed_tax_savings}",
        hidden="{$searchMatches.isHidden('Federal Tax Savings If Filed Reverse Type: Joint or Separate')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_being_s_corp_fed_tax_has_saved",
        bind:value="{$reportStore.your_demographics_being_s_corp_fed_tax_has_saved}",
        hidden="{$searchMatches.isHidden('Federal Tax Savings Because of Current S-Corp Companies')}"
      )
      CurrencyInputField(
        {refreshReportParams},
        specTreeNodeId="your_demographics_being_s_corp_fed_tax_can_save",
        bind:value="{$reportStore.your_demographics_being_s_corp_fed_tax_can_save}",
        hidden="{$searchMatches.isHidden('Federal Tax Savings If Switch the Rest of the Companies to S-Corp')}"
      )
</template>
