<script>
  import PdfPage from "./PdfPage.svelte";
  import { camelCase } from "lodash";
  import { pdfBookmarksStore } from "../report/stores/pdfBookmarksStore.js";

  export let pdfPath;
  export let areaTrackingPath;

  let pdfPages = [];
  let pdfjs;
  let pdf;
  let areaTracking = {};
  let debugMode = window.location.href.substring(window.location.href.indexOf("#") + 1) == "debug";

  $: promise = getPdfPagesInfo(pdfPath, areaTrackingPath);

  const headers = {
    method: "GET",
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": window.$('meta[name="csrf-token"]').attr("content")
  };

  const request = async (endpoint, params = {}) => {
    try {
      const response = await fetch(endpoint, { headers, ...params });
      return await response.json();
    } catch (error) {
      throw new Error(
        "Something went wrong. If the error persists, please do not hesitate to reach out for support using the chat button at the bottom right corner of the page."
      );
    }
  };

  async function initPdfjs() {
    if (pdfjs) return;

    pdfjs = await import("pdfjs-dist/legacy/build/pdf.js");
    // workersSrc requires url string, thats why we loading it from public/pdfjs,
    // version of worker should be the same as pdfjs
    pdfjs.GlobalWorkerOptions.workerSrc = "/pdfjs/pdf.worker.min-13dce91a31bde2c245f9eeccdd1e6775.js";
  }

  async function getPdfPagesInfo(pdfPath, areaTrackingPath) {
    await initPdfjs();
    pdf = await pdfjs.getDocument(pdfPath).promise;
    areaTracking = (await request(areaTrackingPath)) || {};
    if (debugMode) console.log("Area Tracking:", areaTracking);

    let newPdfPages = [];

    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      newPdfPages.push({ scrollToId: null, page: await pdf.getPage(pageNum), areas: areaTracking[pageNum] });
    }

    async function setScrollToIdForBookmarks(bookmarks) {
      for await (const bookmark of bookmarks) {
        const dest = await pdf.getDestination(bookmark.dest);
        const pageNumIndex = await pdf.getPageIndex(dest[0]);
        const scrollToId = camelCase(`pdf${bookmark.title}`);
        newPdfPages[pageNumIndex].scrollToId = scrollToId;

        if (bookmark.items) await setScrollToIdForBookmarks(bookmark.items);
      }
    }

    const bookmarks = await pdf.getOutline();
    await setScrollToIdForBookmarks(bookmarks, 0);
    pdfBookmarksStore.set(new Set(newPdfPages.map((e) => e.scrollToId).filter((id) => id != null)));
    pdfPages = newPdfPages;
  }
</script>

{#await promise}
  <slot />
{:then}
  {#each pdfPages as pdfPage}
    <PdfPage {...pdfPage} />
  {/each}
{/await}
