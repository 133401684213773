<script>
  export let state;
  export let onConnectionClick = () => {};
</script>

{#if state === "connected"}
  QuickBooks connection has been successfully established!
{:else if state === "connecting"}
  <p>QuickBooks connection is being established.</p>
  <div class="progress progress-striped active">
    <div class="progress-bar progress-bar-primary container-full" />
  </div>
{:else}
  <button
    class="btn business-source-connect-btn business-source-connect-btn_qbo start-connection-btn"
    on:click={onConnectionClick()}
  >
    Connect to QuickBooks
  </button>
{/if}

<style lang="scss">
  .container-full {
    width: 100%;
  }
</style>
