<script>
  import accounting from "accounting/accounting";

  export let info;
  export let hasLinkedCompany;

  function printMoney(amount) {
    return accounting.formatMoney(amount, "$", 0);
  }
</script>

{#if info.tax_savings_potential != null}
  <div>
    <span class="summary-info__item">
      User Last Activity
      <br />
      <b class="tax-savings-potential">{info.last_activity_at}</b>
    </span>
    <span class="summary-info__item">
      Total Tax
      <br />
      <b class="tax-savings-potential">{printMoney(info.total_tax)}</b>
    </span>
    <span class="summary-info__item">
      Withholding & Payments
      <br />
      <b class="tax-savings-potential">{printMoney(info.withholding_and_payment)}</b>
    </span>
    <span class="summary-info__item">
      Net Tax Due
      <br />
      <b class="tax-savings-potential">{printMoney(info.net_tax_due)}</b>
    </span>
    <span class="summary-info__item">
      Tax Savings Potential
      <br />
      <b class="tax-savings-potential">{printMoney(info.tax_savings_potential)}</b>
    </span>
  </div>
{:else if hasLinkedCompany}
  <span>
    Loading summary information
    <br />
    <div class="tsp-loading">
      <span class="bounce1" />
      <span class="bounce2" />
      <span class="bounce3" />
    </div>
  </span>
{/if}

<style global>
  .tsp-loading > span {
    width: 10px;
    height: 10px;
    background-color: #565656;
    margin: 0 3px;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .tsp-loading .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .tsp-loading .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
</style>
