<script>
  import { isNil } from "lodash";
  export let state, linkGrantError;
  export let onConnectionClick = () => {};
</script>

{#if state === "connected"}
  Xero connection has been successfully established!
{:else if state === "connecting"}
  <p>Xero connection is being established.</p>
  <div class="progress progress-striped active">
    <div class="progress-bar progress-bar-primary container-full" />
  </div>
{:else if !isNil(linkGrantError)}
  <div class="business-source-connect-error">
    {@html linkGrantError}
  </div>
{:else}
  <button
    class="btn business-source-connect-btn business-source-connect-btn_xero start-connection-btn"
    on:click={onConnectionClick()}
  >
    Connect to Xero
  </button>
{/if}

<style lang="scss">
  .container-full {
    width: 100%;
  }
</style>
