<script>
  import { zoom } from "./zoomStore.js";

  const zoomLevels = [50, 60, 70, 80, 90, 100, 110, 125];

  let selectedIndex = zoomLevels.length - 1;

  function handleDropdownChange(e) {
    const newIndex = parseInt(e.target.value);
    updateZoom(newIndex);
  }

  const updateZoom = (newIndex) => {
    selectedIndex = newIndex;
    zoom.set(zoomLevels[selectedIndex] / 100);
  };

  const decreaseZoom = () => {
    if (selectedIndex > 0) updateZoom(selectedIndex - 1);
  };

  const increaseZoom = () => {
    if (selectedIndex < zoomLevels.length - 1) updateZoom(selectedIndex + 1);
  };
</script>

<div class="zoom-controls">
  <button class="btn btn-sm btn-info" on:click={decreaseZoom} disabled={selectedIndex === 0}>
    <i class="fa fa-search-minus" />
  </button>
  <div class="zoom-selector">
    <select bind:value={selectedIndex} on:change={handleDropdownChange}>
      {#each zoomLevels as level, index}
        <option value={index}>{level}%</option>
      {/each}
    </select>
  </div>
  <button
    class="btn btn-sm btn-info"
    on:click={increaseZoom}
    disabled={selectedIndex === zoomLevels.length - 1}
  >
    <i class="fa fa-search-plus" />
  </button>
</div>

<style lang="scss">
  .zoom-controls {
    display: flex;
    margin-left: 15px;
  }

  .zoom-selector {
    padding: 0 5px;
    font-size: 16px;
  }

  .btn-info {
    margin: 0px;
    padding: 0px 3px;
  }
</style>
