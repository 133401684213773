import _ from "lodash";
import { writable, derived } from "svelte/store";
import Demographics from "components/personal_projection/tabs/Demographics.svelte";
import Wages from "components/personal_projection/tabs/Wages.svelte";
import Income from "components/personal_projection/tabs/Income.svelte";
import RentalRealEstate from "components/personal_projection/tabs/RentalRealEstate.svelte";
import Adjustments from "components/personal_projection/tabs/Adjustments.svelte";
import Deductions from "components/personal_projection/tabs/Deductions.svelte";
import OtherBusinessIncome from "components/personal_projection/tabs/OtherBusinessIncome.svelte";
import Taxes from "components/personal_projection/tabs/Taxes.svelte";
import Payments from "components/personal_projection/tabs/Payments.svelte";
import StateAdjustments from "components/personal_projection/tabs/StateAdjustments.svelte";
import {
  personalProjectionFlattenedFields,
  personalProjectionValidation,
  businessCompaniesValidation,
  businessCompaniesStore
} from "components/personal_projection/Store.js";

export const tabFields = derived(
  [personalProjectionFlattenedFields, businessCompaniesStore],
  ([$personalProjectionFlattenedFields, $businessCompaniesStore]) => {
    let fields = {
      Demographics: [
        "type",
        "state_of_residency",
        "fed_par_no_of_dependents_from_0_to_6",
        "fed_par_no_of_dependents_from_6_to_17",
        "fed_par_no_of_dependents_over_17"
      ],
      Wages: _.filter($personalProjectionFlattenedFields, (f) => _.startsWith(f, "wages[")),
      Income: [
        "social_security_taxpayer",
        "social_security_spouse",
        "fed_inc_bank_1_interest_taxpayer",
        "fed_inc_ordinary_dividends_taxpayer",
        "fed_inc_money_market_1_interest_taxpayer",
        "fed_inc_stock_qualified_div_taxpayer",
        "fed_inc_stock_st_div_gains_taxpayer",
        "fed_inc_stock_lt_cap_gains_taxpayer",
        "fed_inc_adj_capital_losses_this_year_taxpayer",
        "fed_inc_stock_60man_cap_gains_taxpayer",
        "fed_inc_other_income_taxpayer",
        "fed_inc_bank_1_interest_spouse",
        "fed_inc_ordinary_dividends_spouse",
        "fed_inc_money_market_1_interest_spouse",
        "fed_inc_stock_qualified_div_spouse",
        "fed_inc_stock_st_div_gains_spouse",
        "fed_inc_stock_lt_cap_gains_spouse",
        "fed_inc_adj_capital_losses_this_year_spouse",
        "fed_inc_stock_60man_cap_gains_spouse",
        "fed_inc_other_income_spouse",
        "fed_inc_ca_tax_refund_taxpayer",
        "fed_inc_ca_tax_refund_spouse"
      ],
      RentalRealEstate: _.filter($personalProjectionFlattenedFields, (f) =>
        _.startsWith(f, "rental_real_estate[")
      ),
      Adjustments: [
        "fed_inc_adj_health_insurance_premium_taxpayer",
        "ira_deduction_taxpayer",
        "per_ira_deduction_taxpayer",
        "per_health_savings_acct_taxpayer",
        "other_deductions_taxpayer",
        "fed_inc_adj_health_insurance_premium_spouse",
        "ira_deduction_spouse",
        "per_ira_deduction_spouse",
        "per_health_savings_acct_spouse",
        "other_deductions_spouse"
      ],
      Deductions: [
        "fed_ded_mortgage_interest_taxpayer",
        "fed_ded_state_income_taxes_taxpayer",
        "fed_ded_local_income_taxes_taxpayer",
        "fed_ded_prior_state_inc_taxes_taxpayer",
        "fed_ded_property_taxes_taxpayer",
        "fed_ded_personal_property_taxes_taxpayer",
        "fed_ded_home_loan_points_taxpayer",
        "fed_ded_medical_and_dental_taxpayer",
        "fed_ded_cash_donations_taxpayer",
        "fed_ded_noncash_donations_taxpayer",
        "fed_ded_other_deductions_taxpayer",
        "fed_ded_mortgage_interest_spouse",
        "fed_ded_state_income_taxes_spouse",
        "fed_ded_local_income_taxes_spouse",
        "fed_ded_prior_state_inc_taxes_spouse",
        "fed_ded_property_taxes_spouse",
        "fed_ded_personal_property_taxes_spouse",
        "fed_ded_home_loan_points_spouse",
        "fed_ded_medical_and_dental_spouse",
        "fed_ded_cash_donations_spouse",
        "fed_ded_noncash_donations_spouse",
        "fed_ded_other_deductions_spouse"
      ],
      OtherBusinessIncome: [
        "usr_scorp_business_income_taxpayer",
        "usr_schedule_c_income_taxpayer",
        "usr_scorp_business_income_spouse",
        "usr_schedule_c_income_spouse"
      ],
      Taxes: [
        "fed_tax_previous_year_taxpayer",
        "state_tax_previous_year_taxpayer",
        "fed_tax_owed_other_taxes_taxpayer",
        "fed_tax_owed_prior_year_amt_carryforward_taxpayer",
        "fed_tax_owed_tax_credits_taxpayer",
        "fed_tax_owed_child_tax_credit_override_taxpayer",
        "fed_tax_owed_child_tax_credit_override_guard_taxpayer",
        "res_inc_itemized_override_taxpayer",
        "res_inc_itemized_override_guard_taxpayer",
        "res_passthrough_deduction_override_taxpayer",
        "res_passthrough_deduction_override_guard_taxpayer",
        "res_se_tax_override_taxpayer",
        "res_se_tax_override_guard_taxpayer",
        "res_total_tax_override_taxpayer",
        "res_total_tax_override_guard_taxpayer",
        "res_state_tax_before_payments_override_taxpayer",
        "res_state_tax_before_payments_override_guard_taxpayer",
        "business_inc_ccorp_fed_tax_override_taxpayer",
        "business_inc_ccorp_fed_tax_override_guard_taxpayer",
        "business_inc_ccorp_state_tax_override_taxpayer",
        "business_inc_ccorp_state_tax_override_guard_taxpayer",
        "fed_tax_previous_year_spouse",
        "state_tax_previous_year_spouse",
        "fed_tax_owed_other_taxes_spouse",
        "fed_tax_owed_prior_year_amt_carryforward_spouse",
        "fed_tax_owed_tax_credits_spouse",
        "fed_tax_owed_child_tax_credit_override_spouse",
        "fed_tax_owed_child_tax_credit_override_guard_spouse",
        "res_inc_itemized_override_spouse",
        "res_inc_itemized_override_guard_spouse",
        "res_passthrough_deduction_override_spouse",
        "res_passthrough_deduction_override_guard_spouse",
        "res_se_tax_override_spouse",
        "res_se_tax_override_guard_spouse",
        "res_total_tax_override_spouse",
        "res_total_tax_override_guard_spouse",
        "res_state_tax_before_payments_override_spouse",
        "res_state_tax_before_payments_override_guard_spouse",
        "business_inc_ccorp_fed_tax_override_spouse",
        "business_inc_ccorp_fed_tax_override_guard_spouse",
        "business_inc_ccorp_state_tax_override_spouse",
        "business_inc_ccorp_state_tax_override_guard_spouse"
      ],
      Payments: [
        "fed_est_tax_pay_march_taxpayer",
        "fed_est_tax_pay_june_taxpayer",
        "fed_est_tax_pay_september_taxpayer",
        "fed_est_tax_pay_december_taxpayer",
        "state_estimated_tax_payments_q1_taxpayer",
        "state_estimated_tax_payments_q2_taxpayer",
        "state_estimated_tax_payments_q3_taxpayer",
        "state_estimated_tax_payments_q4_taxpayer",
        "fed_est_tax_pay_march_spouse",
        "fed_est_tax_pay_june_spouse",
        "fed_est_tax_pay_september_spouse",
        "fed_est_tax_pay_december_spouse",
        "state_estimated_tax_payments_q1_spouse",
        "state_estimated_tax_payments_q2_spouse",
        "state_estimated_tax_payments_q3_spouse",
        "state_estimated_tax_payments_q4_spouse"
      ],
      StateAdjustments: [
        "state_adj_additions_taxpayer",
        "state_adj_substractions_taxpayer",
        "state_adj_tax_credit_taxpayer",
        "state_adj_additions_spouse",
        "state_adj_substractions_spouse",
        "state_adj_tax_credit_spouse"
      ]
    };
    if (Object.keys($businessCompaniesStore).length > 0) {
      fields = {
        ...fields,
        BusinessIncomeExpense: businessCompaniesFields($businessCompaniesStore)
      };
    }
    return fields;
  }
);

function businessCompaniesFields(bc) {
  let result = [];
  Object.entries(bc).forEach(([k, val]) => {
    Object.keys(_.omit(val, "name")).forEach((el) => result.push(`${k}.${el}`));
  });
  return result;
}

export const tabs = writable([
  {
    component: Demographics,
    title: "Demographics",
    componentName: "Demographics"
  },
  { component: Wages, title: "Wages/W-2s", componentName: "Wages" },
  { component: Income, title: "Income", componentName: "Income" },
  {
    component: RentalRealEstate,
    title: "Rental Real Estate",
    componentName: "RentalRealEstate"
  },
  {
    component: Adjustments,
    title: "Adjustments",
    componentName: "Adjustments"
  },
  { component: Deductions, title: "Deductions", componentName: "Deductions" },
  {
    component: OtherBusinessIncome,
    title: "Other Business Income",
    componentName: "OtherBusinessIncome"
  },
  { component: Taxes, title: "Taxes", componentName: "Taxes" },
  { component: Payments, title: "Payments", componentName: "Payments" },
  {
    component: StateAdjustments,
    title: "State Adjustments",
    componentName: "StateAdjustments"
  }
]);

export const tabComponents = derived(tabs, ($tabs) => _.map($tabs, "componentName"));

export const invalidTabs = derived(
  [tabComponents, tabFields, personalProjectionValidation, businessCompaniesValidation],
  ([$tabComponents, $tabFields, $ppValidation, $bcValidation]) => {
    return new Set(
      _.filter($tabComponents, (tab) => {
        return _.some($tabFields[tab], (field) => {
          return $ppValidation.has(field) || $bcValidation.has(field);
        });
      })
    );
  }
);
