export const BUSINESS_TYPES = {
  "S-Corporation": "S-Corporation",
  "C-Corporation": "C-Corporation",
  Partnership: "Partnership",
  "Sole-Proprietorship": "Sole-Proprietorship"
};

export const BUSINESS_TYPES_CODES = [
  { name: "Sole Prop", code: "sole_prop" },
  { name: "C-Corp", code: "c_corp" },
  { name: "S-Corp", code: "s_corp" }
];

export const ORDERS = [
  { name: "First", code: "first" },
  { name: "Second", code: "second" },
  { name: "Third", code: "third" },
  { name: "Fourth", code: "fourth" }
];

export const FILING_TYPES = {
  Single: "Single",
  "Head-of-Household": "Head-of-Household",
  "Married Joint": "Married Joint",
  "Married Separate": "Married Separate"
};

export const HEALTH_SAVING_TYPES = {
  HSA: "Health Savings Account",
  HRA: "Health Reimbursement Account",
  FSA: "Flexible Spending Account"
};

export const TAX_RETURN_DUE_DATE_TYPES = {
  s_corp_file_by: "S-corporation File By",
  s_corp_extend_to: "S-corporation Extend To",
  partnership_file_by: "Partnership File By",
  partnership_extend_to: "Partnership Extend To",
  c_corp_file_by: "C-corporation File By",
  c_corp_extend_to: "C-corporation Extend To",
  personal_1040_file_by: "Personal (1040) File By",
  personal_1040_extend_to: "Personal (1040) Extend To",
  trust_file_by: "Trust File By",
  trust_extend_to: "Trust Extend To",
  non_profit_file_by: "Non-Profit File By",
  non_profit_extend_to: "Non-Profit Extend To",
  personal_1q_file_by: "Personal 1st Quarter File By",
  personal_2q_file_by: "Personal 2nd Quarter File By",
  personal_3q_file_by: "Personal 3rd Quarter File By",
  personal_4q_file_by: "Personal 4th Quarter File By"
};

export const US_STATES = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MP: "Northern Mariana Islands",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  VI: "Virgin Islands",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming"
};
